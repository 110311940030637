import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-nature-type',
  templateUrl: './select-nature-type.component.html',
  styleUrls: ['./select-nature-type.component.scss']
})
export class SelectNatureTypeComponent implements OnInit {
  @Input() selected = "";
  @Output() onSelectChanged = new EventEmitter<string>();

  feature = '公办,民办,中外合作,';
  features : string[];

  constructor() {
    this.selected = this.feature;
   }

  ngOnInit(): void {
    this.features = this.feature.split(",").filter(c=> c && c !== "");
    this.feature = "";
  }

  contains(str, sub: string): boolean {
    return str.indexOf(sub) >= 0;
  }

  click($event: string){
    if (this.contains(this.feature, $event)){
      this.feature = this.feature.replace($event+",", "");
    }else{
      this.feature = this.feature + $event +",";
    }

    this.onSelectChanged.emit(this.feature);
  }
}
