import { Component, Input, OnInit } from '@angular/core';
import { Topics } from '~/app/shared/topics.type';

@Component({
  selector: 'app-comp-video',
  templateUrl: './comp-video.component.html',
  styleUrls: ['./comp-video.component.scss']
})

export class CompVideoComponent implements OnInit {

  @Input() data: Topics;

  constructor() { }

  ngOnInit(): void {
  }
}
