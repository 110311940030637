export function isValidCourseSelection(provinceType: string, courses: string): boolean {
  const courseArr = courses.split(",");

  if (provinceType !== 'C'){
    const physics = courseArr.includes("物理");
    const politics = courseArr.includes("历史");

    if (physics && politics){
      return false;
    }

    return physics || politics;
  }

  return true;
}
