<div id="echart">

</div>

<nz-table #basicTable [nzData]="data">
    <thead>
        <tr>
            <td align="center" class="normal bold">序号</td>
            <td align="center" class="normal bold">省份</td>
            <td align="center" class="normal bold">年度</td>
            <td align="center" class="normal bold">批次</td>
            <td align="center" class="normal bold">类型</td>
            <td align="center" class="normal bold">分数</td>
            <td align="center" class="normal bold">数量</td>
            <td align="center" class="normal bold">排名</td>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor=" let dt of basicTable.data; let idx = index">
            <td align="center">{{idx+1}}</td>
            <td align="center">{{dt.province}}</td>
            <td align="center">{{dt.year}}</td>
            <td align="center">{{dt.batch}}</td>
            <td align="center">{{dt.course}}</td>
            <td align="center">{{dt.scoreMin}}</td>
            <td align="center">{{dt.amountScore}}</td>
            <td align="center">{{dt.rankMin}}</td>
        </tr>
    </tbody>
</nz-table>
