import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '~/app/service/http.service';
import { UserService } from '~/app/service/user.service';
import { Router } from '@angular/router';
import {
  ExamReport,
  MajorDetail,
  MyCollegeMajor,
  MyFootPrint,
} from './data.types';
import { family2StudentCode } from '~/app/utility/domain';
import { UsersInfo } from '~/app/shared/user.type';
import { Topics } from '~/app/shared/topics.type';
import { DeepReport } from '~/app/shared/enroll.typs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '~/environments/environment';

@Component({
  selector: 'app-my',
  templateUrl: './my.component.html',
  styleUrls: ['./my.component.scss'],
})
export class MyComponent implements OnInit {
  cumbs = ['首页', '我的'];

  data: any[] = [];
  currPage: any[] = [];
  isVisible: boolean = false;
  isParent = false;
  studentCode = '';
  items = [
    '我的测评报告',
    '我的志愿深度分析报告',
    '我的志愿表',
    '我的题库',
    '我的成绩档案',
    '我的足迹',
    '我的收藏',
  ];
  // '我的选科咨询服务',
  // '我的志愿填报咨询服务',
  // '我的心理咨询服务',
  // '我的学业规划咨询服务',
  // '我的学习效能咨询服务',

  constructor(
    private client: HttpClient,
    private http: HttpService,
    private message: NzMessageService,
    private router: Router,
    private userService: UserService
  ) {}

  async ngOnInit() {
    this.show(this.items[0]);
    await this.fetchIsParent();
    if (this.userService.isTeacher()) {
      // this.items = [
      //   '我的测评报告',
      //   '我的题库',
      //   '我的足迹',
      //   '学生的志愿表',
      //   '学生的测评报告',
      //   '学生的志愿深度分析报告',
      //   '学生的成绩档案',
      //   '学生的选科',
      // ];
    } else if (this.userService.isFamily()) {
      // this.items = [
      //   '我的测评报告',
      //   '我的足迹',
      //   '孩子的测评报告',
      //   '孩子的志愿表',
      //   '孩子的题库',
      //   '孩子的足迹',
      //   '孩子的志愿深度分析报告',
      //   '孩子的成绩档案',
      //   '孩子的选科咨询服务',
      //   '孩子的志愿填报咨询服务',
      //   '孩子的心理咨询服务',
      //   '孩子的学业规划咨询服务',
      //   '孩子的学习效能咨询服务',
      // ];
    }
    if (this.userService.userType() != '机构用户' || this.isParent) {
      this.items.push('我是家长');
    }
  }

  title: string = this.items[0];

  async fetchFamilyUser(code) {
    const action = '/v1/user/list-family-users';
    const params = {
      userCode: code,
    };

    this.data = await this.http.post(action, params);
  }
  async fetchIsParent() {
    const action = '/v1/user/user-is-parent';
    const params = {
      userCode: this.userService.code(),
    };

    this.isParent = await this.http.post(action, params);
  }
  bingStudent() {
    this.isVisible = true;
  }
  async handleOk() {
    if (!this.studentCode) {
      this.message.error('请输入学生账户');
      return;
    }
    if (this.userService.userType() == '机构用户') {
      this.message.error('家长账号不能为机构用户');
      return;
    }
    if (this.userService.code() == this.studentCode) {
      this.message.error('学生账户不能和家长账户一致');
      return;
    }
    const action = '/v1/user/bind-family-student';
    const params = {
      userCode: this.userService.code(),
      studentCode: this.studentCode,
    };
    await this.http.post(action, params);
    this.message.success('绑定成功');
    this.fetchFamilyUser(this.userService.code());
    this.isVisible = false;
  }
  async removeBindConfirm(code) {
    const action = '/v1/user/remove-bind-family-student';
    const params = {
      userCode: this.userService.code(),
      studentCode: code,
    };
    await this.http.post(action, params);
    this.message.success('解除成功');
    this.fetchFamilyUser(this.userService.code());
  }
  viewStudentDetail(code) {
    // this.router.navigate([`/archives?code=${code}`]);
    window.open(
      `${environment.baseFilesUrl}/#/archives?code=${code}`,
      '_blank'
    );
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  handlemajorCancel() {
    this.showDetail = false;
  }
  selectedIndex = 0;
  myCollegePage = [];
  mymajorPage = [];
  myprofessionPage = [];
  mycoursePage = [];
  myInfoPage = [];
  addCompare() {
    this.handletabset(1);
  }
  majorCompare() {
    this.handletabset(2);
  }
  professionCompare() {
    this.handletabset(3);
  }
  async handletabset(index) {
    let action = '';
    const params = {
      userCode: this.userService.code(),
    };
    switch (index) {
      case 1:
        action = '/v1/MyFavorite/list-my-favorite-college';
        this.myCollegePage = await this.http.post(action, params);
        break;
      case 2:
        action = '/v1/MyFavorite/list-my-favorite-major';
        this.mymajorPage = await this.http.post(action, params);
        break;
      case 3:
        action = '/v1/MyFavorite/list-my-favorite-profession';
        this.myprofessionPage = await this.http.post(action, params);
        break;
      case 4:
        action = '/v1/MyFavorite/list-my-favorite';
        const res = await this.http.post(action, params);
        this.mycoursePage = res?.resources?.filter(
          (item) => item.mainType == '课程'
        );
        break;
      case 5:
        action = '/v1/MyFavorite/list-my-favorite';
        const resInfo = await this.http.post(action, params);
        this.myInfoPage = resInfo?.resources?.filter(
          (item) => item.mainType != '课程'
        );
        break;
      default:
        break;
    }
  }
  mycoursedetail(item) {
    window.open(item.url, '_blank');
  }
  async mycourseDel(item) {
    const action = '/v1/MyFavorite/delete-my-favorite';
    const params = {
      code: '',
      resourceType: '资源',
      userCode: this.userService.code(),
      resourceId: item.id,
    };
    await this.http.post(action, params);
    this.message.success('取消收藏成功');
    this.handletabset(4);
  }
  myInfodetail(item) {
    window.open(item.url, '_blank');
  }
  async myInfoDel(item) {
    const action = '/v1/MyFavorite/delete-my-favorite';
    const params = {
      code: '',
      resourceType: '资源',
      userCode: this.userService.code(),
      resourceId: item.id,
    };
    await this.http.post(action, params);
    this.message.success('取消收藏成功');
    this.handletabset(5);
  }
  showDetail = false;
  major: any = {};
  majorDesc = [];
  async majordetail($event) {
    this.major = null;

    let url = `/major/details/${$event}.json`;
    let detail = await this.http.get(url);
    this.major = detail['major'];

    url = `/major/feature/${$event}.json`;
    let desc = await this.http.get(url);
    this.majorDesc = desc['result'];

    this.showDetail = this.major !== null;
  }
  getDirectionDetail(): string {
    return this.major.studyDirection && this.major.studyDirection !== ''
      ? this.major.studyDirection
      : this.major.zhuanToBenOrient;
  }
  async show($event) {
    switch ($event) {
      case '我的志愿表':
        this.titleShow = '我的志愿表';
        await this.showCollegeMajors(this.userService.code());
        break;
      case '我的测评报告':
        this.titleShow = '我的测评报告';
        await this.showExamReports(this.userService.code());
        break;
      case '我的题库':
        this.titleShow = '我的题库';
        await this.showCollection(this.userService.code());
        break;
      case '我的足迹':
        this.titleShow = '我的足迹';
        await this.showFootPrint(this.userService.code());
        break;
      case '我的志愿深度分析报告':
        this.titleShow = '我的志愿深度分析报告';
        await this.showDeepReport(this.userService.code());
        break;
      case '孩子的志愿表':
        this.titleShow = '孩子的志愿表';
        await this.showCollegeMajors(
          family2StudentCode(this.userService.code())
        );
        break;
      case '孩子的志愿深度分析报告':
        this.titleShow = '孩子的志愿深度分析报告';
        await this.showDeepReport(family2StudentCode(this.userService.code()));
        break;
      case '孩子的测评报告':
        this.titleShow = '孩子的测评报告';
        await this.showExamReports(family2StudentCode(this.userService.code()));
        break;
      case '孩子的题库':
        this.titleShow = '孩子的题库';
        await this.showCollection(family2StudentCode(this.userService.code()));
        break;
      case '孩子的足迹':
        this.titleShow = '孩子的足迹';
        await this.showFootPrint(family2StudentCode(this.userService.code()));
        break;
      case '学生的志愿表':
        this.titleShow = '学生的志愿表';
        await this.showStudentUnivMajor(this.userService.code());
        break;
      case '学生的测评报告':
        this.titleShow = '学生的测评报告';
        await this.showStudentExamReports(this.userService.code());
        break;
      case '我是家长':
        this.titleShow = '我是家长';
        await this.fetchFamilyUser(this.userService.code());
        break;
      case '我的收藏':
        this.titleShow = '我的收藏';
        this.handletabset(1);
        break;
      default:
        this.message.warning('暂无数据，敬请期待！');
        return;
    }

    this.title = $event;

    this.cumbs = ['首页', '我的'];
    this.cumbs.push($event);
  }

  titleShow: string = this.items[0];

  examReports: ExamReport[] = [];
  async fetchExamReports(code: string) {
    const action = '/v1/career-exam/list-exam-result';
    const params = {
      userCode: code,
    };

    this.examReports = await this.http.post(action, params);
    this.data = this.examReports;
  }

  async showExamReports(code: string) {
    await this.fetchExamReports(code);
  }

  currentReport: ExamReport = null;
  showExamReport = false;
  showMyExamReport(id: number) {
    this.currentReport = this.examReports.find((c) => c.id === id);
    this.showExamReport = true;
  }

  showSecondTab(): boolean {
    return this.currentReport.explains.length >= 3;
  }

  getEchartOptions() {
    const explains = this.currentReport.explains.sort(
      (a, b) => b.score - a.score
    );

    const truncted = explains.slice(0, 5);
    const indicator = [];

    for (const item of truncted) {
      const rader = {
        name: item.label,
        max: truncted[0].score,
      };
      indicator.push(rader);
    }

    const value = truncted.map((c) => c.score);

    return {
      radar: {
        indicator: indicator,
      },
      series: [
        {
          type: 'radar',
          data: [{ name: '主要结果对比', value: value }],
        },
      ],
    };
  }

  tabChange($event) {
    if ($event !== 1) {
      return;
    }

    const option = this.getEchartOptions();
    const chart = echarts.init(document.getElementById('echart'));
    chart.setOption(option, true);
  }

  closeExamReport() {
    this.showExamReport = false;
  }

  footPrints: MyFootPrint[] = [];
  async fetchMyFootPrint(code: string) {
    const action = '/v1/resource/list-my-footprint';
    const params = {
      userCode: code,
    };

    this.footPrints = await this.http.post(action, params);
    this.data = this.footPrints;
  }

  async showFootPrint(code: string) {
    await this.fetchMyFootPrint(code);
  }

  myCollection: Topics[] = [];
  async fetchMyCollection(code: string) {
    const action = '/v1/MyResource/list-my-resource';
    const params = {
      userCode: code,
    };

    this.myCollection = await this.http.post(action, params);
    this.data = this.myCollection;
  }

  async showCollection(code: string) {
    await this.fetchMyCollection(code);
  }

  async deleteCollection(code: string, id: number) {
    const action = '/v1/MyResource/delete-my-resource';
    const params = {
      id: id,
    };

    await this.http.post(action, params);
    await this.fetchMyCollection(code);
  }

  myCollegeMajors: MyCollegeMajor[] = [];
  async fetchMyCollegeMajor(code: string) {
    const action = '/v1/enroll2/list-my-selection';
    const params = {
      userCode: code,
    };

    this.myCollegeMajors = await this.http.post(action, params);
    this.data = this.myCollegeMajors;
  }

  async showCollegeMajors(code: string) {
    await this.fetchMyCollegeMajor(code);
  }

  myCollegeMajor: MyCollegeMajor = null;
  async findMyCollegeMajor(id: number) {
    const action = '/v1/enroll2/find-my-selection';
    const params = {
      id: id,
    };

    this.myCollegeMajor = await this.http.post(action, params);
  }

  showCollegeMajorModal = false;

  async showCollegeMajor(id: number) {
    await this.findMyCollegeMajor(id);
    this.gotoPage();

    this.showCollegeMajorModal = true;
  }

  currMajorPage: MajorDetail[] = [];
  pageIndex = 1;
  pageSize = 5;
  gotoPage() {
    this.currMajorPage = this.myCollegeMajor.detail.slice(
      (this.pageIndex - 1) * this.pageSize,
      this.pageIndex * this.pageSize
    );
  }

  closeCollegeMajor() {
    this.showCollegeMajorModal = false;
  }

  async deleteCollegeMajor(id: number) {
    const action = '/v1/enroll2/delete-my-selection';
    const params = {
      id: id,
    };

    await this.http.post(action, params);
    await this.fetchMyCollegeMajor(this.userService.code());
  }

  goto(url: string) {
    window.open(url, '_blank');
  }

  myStudents: UsersInfo[] = [];
  async getMyStudents(code: string) {
    const action = '/v1/user/list-my-student';
    const params = {
      userCode: code,
    };

    let result: UsersInfo[] = await this.http.post(action, params);
    this.myStudents = result;
  }

  async showStudentUnivMajor(code: string) {
    this.getMyStudents(code);
  }

  async showStudentExamReports(code: string) {
    this.getMyStudents(code);
  }

  deepReports: DeepReport[] = [];
  reportFileName = 'report.xlsx';

  async showDeepReport(code: string) {
    const action = '/v1/enroll2/list-my-report-list';
    const params = {
      userCode: this.userService.code(),
    };

    this.deepReports = await this.http.post(action, params);
  }

  async down($event) {
    const action = '/v1/enroll2/get-my-report-byid';
    const params = {
      id: $event,
    };

    const blobData = await this.http.postForBlob(action, params);
    this.saveAs(blobData, this.reportFileName);
  }

  private saveAs(bdata: Blob, fileName: string) {
    const tmpLink = document.createElement('a');
    const blob = new Blob([bdata], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const objectUrl = URL.createObjectURL(blob);
    tmpLink.href = objectUrl;
    tmpLink.download = fileName;

    tmpLink.click();
    URL.revokeObjectURL(objectUrl);
  }
}
