<section>
    <div class="major">
      <div class="title">
        <p>{{ major.major }}</p>
        <p>({{ major.majorCode }})</p>
      </div>
      <div class="detail">
        <p>招生数量：{{ major.total }}人，选科覆盖：{{ major.count }} 人</p>
        <p class="emphsis">覆盖率：{{ major.percent }}%</p>
      </div>
      <div class="right"  *ngIf="hasChildren()" (click)="expand()">
        <!-- <button nz-button nzType="primary" >查看</button> -->
        <span nz-icon nzType="caret-down" nz-theme="fill" *ngIf="!expanded"></span>
        <span nz-icon nzType="caret-up" nz-theme="fill" *ngIf="expanded"></span>
      </div>
      <div class="second" *ngIf="expanded">
          <div *ngFor="let child of major.children">
              <app-third [major]="child"></app-third>
          </div>
      </div>
    </div>
  </section>