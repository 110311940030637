import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-level',
  templateUrl: './select-level.component.html',
  styleUrls: ['./select-level.component.scss']
})
export class SelectLevelComponent implements OnInit {
  @Input() selected = "";
  @Input() multiple = true;
  @Output() onSelectChanged = new EventEmitter<string>();

  feature = '本科,专科,';
  features : string[];

  constructor() {
    // this.selected = this.feature;
   }

  ngOnInit(): void {
    this.features = this.feature.split(",").filter(c=> c && c !== "");
    this.feature = this.selected;
  }

  contains(str, sub: string): boolean {
    return str.indexOf(sub) >= 0;
  }

  click($event: string){
    if(!this.multiple) {
      this.feature = $event +",";
    }else {
      if (this.contains(this.feature, $event)){
        this.feature = this.feature.replace($event+",", "");
      }else{
        this.feature = this.feature + $event +",";
      }
    }

    this.onSelectChanged.emit(this.feature);
  }

}
