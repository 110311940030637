<app-header></app-header>

<section>
  <div class="container">
    <app-breadcumb [items]="cumbs"></app-breadcumb>
    <div class="select-major border">
      <h4>我的升学通路</h4>
      <div class="options">
        <div *ngFor="let item of intentionList; let i = index">
          <span class="text border">{{ item.name }}</span>
          <span
            nz-icon
            nzType="close-circle"
            nzTheme="fill"
            (click)="removeIntention(i)"
          ></span>
        </div>
      </div>
      <div class="button">
        <button nz-button nzType="primary" (click)="confirm()">完成</button>
      </div>
    </div>
    <div class="content-flex">
      <div class="content border">
        <div *ngFor="let item of currPage">
          <span>{{ item.name }}</span>
          <button
            nz-button
            nzType="default"
            nzDanger
            (click)="addIntention(item)"
          >
            {{ filter(item.name) ? "取消意向" : "加入意向" }}
          </button>
        </div>
        <nz-pagination
          class="pright"
          [nzPageIndex]="1"
          [nzTotal]="this.filtered.length"
          (nzPageIndexChange)="gotoPage($event)"
        ></nz-pagination>
      </div>
      <div class="circle" (click)="viewenroll()">
        <span
          nz-icon
          nzType="question-circle"
          class="question-circle"
          nzTheme="outline"
        ></span>
        <span>升学通路介绍</span>
      </div>
    </div>
    <nz-modal
      [(nzVisible)]="isVisible"
      nzCentered="true"
      nzWidth="960px"
      nzTitle="升学通路介绍"
      (nzOnCancel)="handleCancel()"
      (nzOnOk)="handleCancel()"
    >
      <ng-container *nzModalContent>
        <img
          nz-image
          width="910px"
          nzSrc="https://resources.delesson.cn/000000/enroll.jpg"
          alt=""
        />
      </ng-container>
    </nz-modal>
  </div>
</section>

<app-footer></app-footer>
