import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '~/app/service/http.service';
import { UserService } from '~/app/service/user.service';
import { Location } from '@angular/common';
import { family2StudentCode } from '~/app/utility/domain';

@Component({
  selector: 'select-major',
  templateUrl: './select-major.component.html',
  styleUrls: ['./select-major.component.scss'],
})
export class SelectMajorComponent implements OnInit {
  cumbs = ['首页', '院校百科'];
  busy = false;
  loading = false;
  university = [];
  course = ""
  courses = ""
  code = this.userService.isFamily()
    ? family2StudentCode(this.userService.code())
    : this.userService.code();
  colleges = [];
  constructor(
    private http: HttpService,
    private message: NzMessageService,
    private userService: UserService,
    private location: Location,
    private router: Router
  ) {}
  async ngOnInit() {
    this.university = JSON.parse(localStorage.getItem('university'));
    await this.fetchSubject()
    await this.fetchData();
  }
  async fetchSubject() {
    let code = this.userService.code();
    const action = '/v1/personal/get-my-courses';
    const params = {
      userCode: code,
    };
    const data = await this.http.post(action, params);
    this.course = data.course
    this.courses = data.courses
  }
  async fetchData() {
    const list = [];
    const promises = [];
    this.university.forEach(async (item) => {
      const major = [
        {
          name: '第一目标专业',
          code: null,
          value: null
        },
        {
          name: '第二目标专业',
          code: null,
          value: null
        },
        {
          name: '第三目标专业',
          code: null,
          value: null
        },
        {
          name: '第四目标专业',
          code: null,
          value: null
        },
        {
          name: '第五目标专业',
          code: null,
          value: null
        },
        {
          name: '第六目标专业',
          code: null,
          value: null
        },
      ]
      list.push({
        myCollege: item.name,
        gbCode: item.gbCode,
        feature: item.feature,
        cityName: item.provinceName + item.cityName,
        category: item.category,
        myMajor: '',
        eduLevel: item.eduLevel == '本科' || item.eduLevel == 'ben' ? 'ben' : 'zhuan',
        userCode: this.code,
        major,
        options: [],
      });
      promises.push(this.makeRequest(item));
    });
    const data = await Promise.all(promises);
    data.forEach((item, index) => {
      list[index].options = item.options;
    });
    this.colleges = list;
  }
  async makeRequest(item) {
    const action = '/v1/personal/list-majors-college';
    const params = {
      collegeName: item.name,
      provinceCode: this.userService.code().substring(0, 2),
      course: this.course,
      courses: this.courses
    };
    const data = await this.http.post(action, params);
    return {
      gbCode: item.gbCode,
      options: data,
    };
  }
  deleteCompare(idx) {
    this.colleges.splice(idx, 1)
    this.university.splice(idx, 1)
    localStorage.setItem('university', JSON.stringify(this.university))
  }
  handleSelect(event, options, tmp) {
    tmp.code = options.find(item => item.major == event)?.majorCode
  }
  back() {
    this.location.back();
  }
  moveUp(item, index) {
    const temp = this.colleges[index - 1]
    this.colleges.splice(index - 1, 1, item)
    this.colleges.splice(index, 1, temp)
  }
  moveDown(item, index) {
    const temp = this.colleges[index + 1]
    this.colleges.splice(index + 1, 1, item)
    this.colleges.splice(index, 1, temp)
  }
  async save() {
    const major = this.colleges.map(item => {
      let value = item.major.find(tmp => tmp.value)
      return value
    })
    if(!major.find(item => item)) {
      this.message.warning('至少选择一个专业');
      return
    }
    const myColleges = []
    const temp = []
    const repeat = []
    this.colleges.forEach(el => {
      el.major.forEach((item, index) => {
        if(item.value) {
          if(!temp.includes(`${item.value}${el.myCollege}`)) {
            temp.push(`${item.value}${el.myCollege}`)
          }else {
            !repeat.includes(`${el.myCollege}-${item.value}`) && repeat.push(`${el.myCollege}-${item.value}`)
          }
          myColleges.push({
            choiceOrder: index,
            eduLevel: el.eduLevel,
            gbCode: el.gbCode,
            selectType: '大学',
            myCollege: el.myCollege,
            feature: el.feature,
            course: this.courses,
            cityName: el.cityName,
            myMajor: item.value,
            category: el.category,
            myMajorCode: item.code,
            userCode: el.userCode,
          })
        }
      })
    })
    if(temp.length < myColleges.length) {
      this.message.warning(`目标专业"${repeat.join()}"重复，请重新选择`);
      return
    }
    this.loading = true
    const action = '/v1/personal/set-user-choice';
    const params = {
      colleges: myColleges,
    };
    try{
      await this.http.post(action, params);
      this.loading = false
      this.message.success('保存成功');
      this.router.navigate(['/career'])
    }catch {
      this.loading = false
    }
  }
}
