<div class="container" *ngIf="courses?.length > 0">
  <div class="heading">
    <p class="bold">{{header}}</p>

    <button (click)="more()">查看更多</button>
  </div>

  <div class="content">
    <div class="row">
      <app-card-desc [data]="courses[0]"></app-card-desc>
      <app-card-desc [data]="courses[1]"></app-card-desc>
      <app-card-desc [data]="courses[2]"></app-card-desc>
    </div>
  </div>
</div>
