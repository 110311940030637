import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Topics } from '~/app/shared/topics.type';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {

  @Input() courses: Topics[];

  constructor(private route: Router) { }

  ngOnInit(): void {
  }

  more(){
    this.route.navigate(["videos", "生涯课堂"]);
  }
}
