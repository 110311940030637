import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { LocalStorageService } from './local-storage.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import {DataService} from "~/app/layout/data.service";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpService,
    private message: NzMessageService,
    private storage: LocalStorageService,
    private dataService: DataService
  ) {}

  public getGrade(grade: number): string {
    switch (grade) {
      case 1:
        return '初一';
      case 2:
        return '初二';
      case 3:
        return '初三';
      case 4:
        return '高一';
      case 5:
        return '高二';
      default:
        return '高三';
    }
  }

  public getProviceCode(code: string): string {
    return code.substring(0, 2);
  }

  public async login(uname: string, password: string): Promise<boolean> {
    const login_url = '/v1/user/web-login';
    const params = { userName: uname, password: password };

    let data = await this.http.post('/v1/user/web-login', params);

    if (data) {
      // data['grade'] = 4;

      this.storage.set('token', data['code']);
      this.storage.set('code', data['code']);
      this.storage.set('name', data['name']);
      this.storage.set('userName', data['userName']);
      this.storage.set('grade', data['grade']);
      this.storage.set('gradeChinese', this.getGrade(data['grade']));
      this.storage.set('province', data['province']);
      this.storage.set('province_code', this.getProviceCode(data['code']));
      this.storage.set('school', data['unitName']);
      this.storage.set('avatar_url', data['avatorUrl']);
      this.storage.set('mobilePhone', data['mobilePhone']);
      this.storage.set('user-type', data['userType']);

      localStorage.setItem('username',uname);
      localStorage.setItem('pwd',password);

      await this.fetchEnrollMode();
    }

    return data;
  }


  public async login_new(uname: string, password: string, types: number): Promise<boolean> {
    let login_url = '/v1/user/student_login';
    if (types === 2) {
      login_url = '/v1/user/parent_login';
    }
    const params = {userName: uname, password: password};

    let data = await this.http.post(login_url, params);

    if (data) {
      this.storage.set('token', data['code']);
      this.storage.set('code', data['code']);
      this.storage.set('name', data['name']);
      this.storage.set('userName', data['userName']);
      this.storage.set('grade', data['grade']);
      this.storage.set('gradeChinese', this.getGrade(data['grade']));
      this.storage.set('province', data['province']);
      this.storage.set('province_code', this.getProviceCode(data['code']));
      this.storage.set('school', data['unitName']);
      this.storage.set('avatar_url', data['avatorUrl']);
      this.storage.set('mobilePhone', data['mobilePhone']);
      this.storage.set('user-type', data['userType']);
      localStorage.setItem('username', uname);
      localStorage.setItem('pwd', password);

      //TODO 判断用户身份存入缓存
      const action = '/v1/user/check-vip';
      const params1 = {
        userCode: uname,
      };
      const result =  await this.http.post(action, params1);
      // 是否是vip
      localStorage.setItem('isvip', result.isvip);
      // result.type =2 永久会员 result.type =1 临时会员
      localStorage.setItem('viptype', result.type);
      // vip天数
      localStorage.setItem('vipts', result.ts);
      if(types === 2){
        // 用户身份 - 家长
        localStorage.setItem('usertype', '2');
        // 设置学生code
        localStorage.setItem('studentCode', data['studentCode']);
        localStorage.setItem('parentCode', data['code']);
      }else{
        // 用户身份 - 学生
        localStorage.setItem('usertype', '1');
      }
      await this.fetchEnrollMode();
    }

    return data;
  }

  public code(): string {
    return this.storage.get('code');
  }

  public mobilePhone(): string {
    return this.storage.get('mobilePhone');
  }

  public grade(): string {
    return this.storage.get('grade');
  }

  public name(): string {
    return this.storage.get('name');
  }

  public province(): string {
    return this.storage.get('province');
  }

  public provinceCode(): string {
    return this.storage.get('province_code');
  }

  public school(): string {
    return this.storage.get('school');
  }

  public avatarUrl(): string {
    return this.storage.get('avatar_url');
  }

  public isTeacher(): boolean {
    return this.code().indexOf('T') >= 0;
  }

  public isStudent(): boolean {
    return this.code().indexOf('S') >= 0;
  }

  public isFamily(): boolean {
    return this.code().length >= 18;
  }

  public userType(): string {
    return this.storage.get('user-type');
  }

  public isGuest(): boolean {
   // return false;
    return this.userType() === "游客";
  }

  public enrollMode(): string {
    return this.storage.get('enrollMode');
  }

  async fetchEnrollMode() {
    const action = '/v1/enroll2/find-province-mode';
    const params = {
      code: this.provinceCode(),
    };

    const data = await this.http.post(action, params);
    this.storage.set('enrollMode', data['mode']);
  }

  public logout() {
    this.storage.clear();
  }
}
