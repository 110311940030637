import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BreadcumbComponent } from './breadcumb/breadcumb.component';
import { CompAttentionComponent } from './comp-attention/comp-attention.component';
import { SelectNatureTypeComponent } from './univ-select/select-nature-type/select-nature-type.component';
import { SelectProvinceComponent } from './univ-select/select-province/select-province.component';
import { SelectCategoryComponent } from './univ-select/select-category/select-category.component';
import { SelectFeatureComponent } from './univ-select/select-feature/select-feature.component';
import { SelectUnivComponent } from './univ-select/select-univ/select-univ.component';
import { SelectLevelComponent } from './univ-select/select-level/select-level.component';
import { CompVideoComponent } from './comp-video/comp-video.component';
import { ComCoursesComponent } from './com-courses/com-courses.component';
import { CourseComponent } from './com-courses/course/course.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzButtonModule } from 'ng-zorro-antd/button';


@NgModule({
  declarations: [
    BreadcumbComponent,
    CompAttentionComponent,
    SelectNatureTypeComponent,
    SelectProvinceComponent,
    SelectCategoryComponent,
    SelectFeatureComponent,
    SelectUnivComponent,
    SelectLevelComponent,
    CompVideoComponent,
    ComCoursesComponent,
    CourseComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzMessageModule,
    NzInputModule,
    NzButtonModule,
    NzModalModule
  ],
  exports: [
    BreadcumbComponent,
    CompAttentionComponent,
    CompVideoComponent,
    SelectUnivComponent,
    ComCoursesComponent,
  ]
})

export class ComponentsModule { }
