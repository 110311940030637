import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { chdir } from 'process';
import { HttpService } from 'src/app/service/http.service';
import { LocalStorageService } from 'src/app/service/local-storage.service';
import { UserService } from 'src/app/service/user.service';
import { MajorDesc } from '~/app/shared/major.type';

@Component({
  selector: 'app-list-major-by-courses',
  templateUrl: './list-major-by-courses.component.html',
  styleUrls: ['./list-major-by-courses.component.scss'],
})
export class ListMajorByCoursesComponent implements OnInit {
  cumbs = ['首页', '模拟选科'];
  data: MajorDesc[] = [];
  filtered: MajorDesc[] = [];
  courses: string;

  constructor(
    private http: HttpService,
    private storage: LocalStorageService,
    private userService: UserService,
    private message: NzMessageService,
  ) {}

  async ngOnInit() {
    this.fetchData();
  }

  async fetchData() {
    const action = '/v1/enroll2/list-major-by-courses';

    this.courses = this.storage.get('courses');
    if (!this.courses || this.courses === '') {
      this.message.error("你没有选择课程,请选择课程后重新进行！")
      return;
    } else {
      const provinceCode = this.userService.provinceCode();
      const params = {
        courses: this.courses,
        provinceCode: provinceCode,
      };

      this.data = await this.http.post(action, params);
      if (!this.data) {
        this.message.error('数据获取失败');
      }else{
        this.filtered = this.data;
      }
    }
  }

  changeCourse($event){
    this.courses = $event;
    this.search();
  }

  search(){
    this.storage.set("courses", this.courses);
    this.fetchData();
  }

  value: string='';
  doSearch(){
    if (!this.value || this.value === ''){
      this.filtered = this.data;
      return;
    }

    let filtered: MajorDesc[] = [];

    for (let m of this.data){
      if (m.major.includes(this.value)){
        filtered.push(m);
      }else {
        for (let child of m.children){
          if (child.major.includes(this.value)){
            filtered.push(m);
            break;    
          }else {
            let contained = false;
            for(let g of child.children){
              if (g.major.includes(this.value)){
                filtered.push(m);
                contained = true;
                break;
              }

              if (contained){
                break;
              }
            }
          }
        }
      }
    }

    this.filtered = filtered;
  }
}
