import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '~/app/service/http.service';
import { UserService } from '~/app/service/user.service';
import { PriceData } from '~/app/shared/payprice.type';
import { isWeb } from '~/app/utility/common';
import { environment } from '~/environments/environment';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() id = 0;
  @Input() imgUrl: string;
  @Input() url: string;
  @Input() type: number;
  @Input() allowGuest: boolean = true;
  @Input() types: number;

  constructor(
    private message: NzMessageService,
    private http: HttpService,
    private userService: UserService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    if (!this.imgUrl) {
      this.imgUrl = 'https://resources.delesson.cn/志愿课堂/视频1.jpg';
    }
  }

  async click() {
    const isGuest = this.userService.isGuest();
    const allowEnter = isGuest ? this.allowGuest : true;

    if(this.types == 1){
      window.open('https://bk.dlxgk.com#/majors?sign-'+ window.btoa( localStorage.getItem("username")+','+localStorage.getItem("pwd")), '_top');
      return;
    }else if(this.types == 2){
      window.open('https://bk.dlxgk.com#/colleges?sign-'+ window.btoa( localStorage.getItem("username")+','+localStorage.getItem("pwd")), '_top');
      return;
    }else if(this.types == 3){
      window.open('https://bk.dlxgk.com#/profession?sign-'+ window.btoa( localStorage.getItem("username")+','+localStorage.getItem("pwd")), '_top');
      return;
    }else if(this.types == 4){
      window.open('https://bk.dlxgk.com#/advanceBatch?sign-'+ window.btoa( localStorage.getItem("username")+','+localStorage.getItem("pwd")), '_top');
      return;
    }else if(this.types == 5){
      window.open('https://bk.dlxgk.com#/expandRecruitment?sign-'+ window.btoa( localStorage.getItem("username")+','+localStorage.getItem("pwd")), '_top');
      return;
    }else if(this.types == 6){
      window.open('https://bk.dlxgk.com#/probability?sign-'+ window.btoa( localStorage.getItem("username")+','+localStorage.getItem("pwd")), '_top');
      return;
    }

    if (allowEnter.toString() === 'false') {
      await this.showMPay();
      return;
    } else {
      if (isWeb(this.url)) {
        this.viewResource();
        window.open(this.url, '_blank');
        return;
      }

      if (this.url && this.url !== '') {
        this.viewResource();

        if (this.type === 0) {
          window.open(this.url, '_blank');
        } else {
          this.router.navigate([this.url]);
        }
      } else {
        this.message.info('功能陆续开放中，敬请等待!');
      }
    }
  }

  async viewResource() {
    if (this.id) {
      const action = '/v1/resource/view-resource';
      const params = {
        id: this.id,
        userCode: this.userService.code(),
      };

      await this.http.post(action, params);
    }
  }

  showPay = false;
  prices: PriceData[] = [];
  cur: PriceData = null;

  async showMPay() {
    this.cur = null;

    const action = '/v1/pay-ali/pay-sys';
    const param = {
      code: environment.appId,
    };

    this.prices = await this.http.post(action, param);

    this.showPay = true;
  }

  async pay(cur: PriceData) {
    this.cur = cur;

    const action = '/v1/pay-ali/qrcode';
    const param = {
      appId: environment.appId,
      id: cur.id,
      userCode: this.userService.code(),
    };

    const zhifu = await this.http.post(action, param);
    const zf = zhifu['codeStr'];
    if (!zf){
      this.message.error("您无权再次购买此服务，如有问题，请联系服务人员！");
    }else{
      this.setFrameContent(zf);
    }
  }

  setFrameContent(zhifu) {
    const div = document.getElementById('right');
    div.innerHTML =
      '<iframe id="alipayFrame" height="100%" frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no" allowtransparency="yes"></iframe>';
    const iframe = document.getElementById('alipayFrame');
    const window = (<HTMLIFrameElement>iframe).contentWindow;
    const ed = window.document;

    ed.open();
    ed.write(zhifu);
    ed.close();

    ed.designMode = 'on';

    this.message.info('扫码支付成功后，请退出系统重新登录，尽享会员服务！');
  }

  payClose() {
    this.showPay = false;
  }
}
