<div class="container" *ngIf="courses?.length > 0">
  <div class="heading">
    <p class="bold">高校展播</p>

    <button (click)="more()">查看更多</button>
  </div>

  <nz-tabset>
    <nz-tab nzTitle="招办主任面对面">
      <div class="content">
        <div class="row">
          <app-card-desc [data]="courses[0]"></app-card-desc>
          <app-card-desc [data]="courses[1]"></app-card-desc>
          <app-card-desc [data]="courses[2]"></app-card-desc>
        </div>
      </div>
    </nz-tab>

    <nz-tab nzTitle="学长说">
      <div class="content">
        <div class="row">
          <app-card-desc [data]="courses[3]"></app-card-desc>
          <app-card-desc [data]="courses[4]"></app-card-desc>
          <app-card-desc [data]="courses[5]"></app-card-desc>
        </div>
      </div>
    </nz-tab>

    <nz-tab nzTitle="小信访校">
      <div class="content">
        <div class="row">
          <app-card-desc [data]="courses[6]"></app-card-desc>
          <app-card-desc [data]="courses[7]"></app-card-desc>
          <app-card-desc [data]="courses[8]"></app-card-desc>
        </div>
      </div>
    </nz-tab>
  </nz-tabset>
</div>
