import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from 'src/app/service/http.service';
import { UserService } from 'src/app/service/user.service';
import { Router } from '@angular/router';
import { Topics } from '~/app/shared/topics.type';
import { teacher2StudentCode, family2StudentCode } from '~/app/utility/domain';

@Component({
  selector: 'app-psychology',
  templateUrl: './psychology.component.html',
  styleUrls: ['./psychology.component.scss'],
})
export class PsychologyComponent implements OnInit {
  titles = ['心理健康课堂', '家校共育'];

  warningUrls = [
    'https://resources.delesson.cn/imgs/心理预警信息.png',
    'https://resources.delesson.cn/imgs/咨询预约服务.png',
  ];

  studentIds = [32, 12, 34, 22, 23, 37];
  studentUrls = [
    'https://resources.delesson.cn/imgs/psychology/student/001.jpg',
    'https://resources.delesson.cn/imgs/psychology/student/002.jpg',
    'https://resources.delesson.cn/imgs/psychology/student/003.jpg',
    'https://resources.delesson.cn/imgs/psychology/student/004.jpg',
    'https://resources.delesson.cn/imgs/psychology/student/005.jpg',
    'https://resources.delesson.cn/imgs/psychology/student/006.jpg',
  ];
  teacherIds = [7, 8, 9, 10, 0, 0];
  teacherUrls = [
    'https://resources.delesson.cn/imgs/psychology/teacher/001.jpg',
    'https://resources.delesson.cn/imgs/psychology/teacher/002.jpg',
    'https://resources.delesson.cn/imgs/psychology/teacher/003.jpg',
    'https://resources.delesson.cn/imgs/psychology/teacher/004.jpg',
    'https://resources.delesson.cn/imgs/psychology/teacher/005.jpg',
    'https://resources.delesson.cn/imgs/psychology/teacher/006.jpg',
  ];

  familyIds = [4, 5, 6, 29, 30, 0];
  familyUrls = [
    'https://resources.delesson.cn/imgs/psychology/family/001.jpg',
    'https://resources.delesson.cn/imgs/psychology/family/002.jpg',
    'https://resources.delesson.cn/imgs/psychology/family/003.jpg',
    'https://resources.delesson.cn/imgs/psychology/family/004.jpg',
    'https://resources.delesson.cn/imgs/psychology/family/005.jpg',
    'https://resources.delesson.cn/imgs/psychology/family/006.jpg',
  ];

  testIds = [];
  testUrls = [];

  data: any;
  courses: Topics[] = [];
  familyCourses: Topics[] = [];

  isFamily: boolean = false;
  isTeacher: boolean = false;

  constructor(
    private message: NzMessageService,
    private userService: UserService,
    private route: Router,
    private http: HttpService
  ) {
    this.isFamily = this.userService.isFamily();
    this.isTeacher = this.userService.isTeacher();
  }

  async ngOnInit() {
    if (this.isTeacher) {
      this.testIds = this.teacherIds;
      this.testUrls = this.teacherUrls;
    } else if (this.isFamily) {
      this.testIds = this.familyIds;
      this.testUrls = this.familyUrls;
    } else {
      this.testIds = this.studentIds;
      this.testUrls = this.studentUrls;
    }

    this.fetchData();
  }

  async fetchData() {
    let code = this.userService.code();
    const grade = this.userService.grade();
    const name = this.userService.name();
    const province = this.userService.province();
    const unitName = this.userService.school();

    if (this.userService.isTeacher()) {
      code = teacher2StudentCode(code);
    } else if (this.userService.isFamily()) {
      code = family2StudentCode(code);
    }

    const param = {
      code: code,
      grade: grade,
      name: name,
      province: province,
      unitName: unitName,
    };

    const action = '/v1/personal/psychology';
    this.data = await this.http.post(action, param);

    this.courses = this.fetchField('psychologyShows');

    if (this.isTeacher || this.isFamily) {
      const action = '/v1/resource/list-by-type';
      const params = {
        mainType: '家校共育',
        type: '',
      };

      this.familyCourses = await this.http.post(action, params);
    }
  }

  private fetchField(field: string): Topics[] {
    const result = this.data[field];
    return result;
  }

  warningInfo() {
    this.message.info('本功能将陆续开放，敬请期待！');
  }

  reserve() {
    this.warningInfo();
  }

  more() {
    this.route.navigate(['psychology-test']);
  }
}
