<app-header></app-header>

<div class="section">
  <div class="contain">
      <app-breadcumb [items]="cumbs"></app-breadcumb>
  </div>
  <div class="contain border">
    <p>生涯定位</p>
    <app-career-test-card [data]="data[0]"></app-career-test-card>
    <app-career-test-card [data]="data[1]"></app-career-test-card>
    <app-career-test-card [data]="data[2]"></app-career-test-card>
  </div>

  <div class="contain border">
    <p>特质测评</p>
    <app-career-test-card [data]="data[3]"></app-career-test-card>
    <app-career-test-card [data]="data[4]"></app-career-test-card>
    <app-career-test-card [data]="data[5]"></app-career-test-card>
    <app-career-test-card [data]="data[6]"></app-career-test-card>
    <app-career-test-card [data]="data[7]"></app-career-test-card>
    <app-career-test-card [data]="data[8]"></app-career-test-card>
    <app-career-test-card [data]="data[9]"></app-career-test-card>
  </div>
</div>

<app-footer></app-footer>
