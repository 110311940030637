<section>
  <form nz-form [formGroup]="regForm">
    <p class="title center">欢迎使用生涯教育APP</p>
    <p class="title center">请在手机浏览器打开填写信息</p>

    <p class="title">手机号码</p>
    <nz-form-item>
      <nz-form-control nzErrorTip="手机号码">
        <nz-input-group nzPrefixIcon="mobile" nzSize="large">
          <input
            type="text"
            nz-input
            formControlName="phone"
            placeholder="手机号码"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <p class="title">验证码</p>
    <nz-form-item>
      <nz-form-control nzErrorTip="验证码">
        <nz-input-group class="code" nzPrefixIcon="message" nzSize="large">
          <input
            type="text"
            nz-input
            formControlName="smsCode"
            placeholder="验证码"
          />
        </nz-input-group>
        <button nz-button nzType="primary" (click)="mobileCode()">
         {{buttonText}}
        </button>
      </nz-form-control>
    </nz-form-item>

    <p class="buttons">
      <span>
        <button nz-button nzType="primary" (click)="submit()">
          下载Android APP
        </button>
      </span>
    </p>
  </form>
</section>
