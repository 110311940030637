export interface EnrollData {
	id: number;
	year: number;
	provinceCode: string;
	collegeCode?: any;
	collegeName: string;
	eduLevel: string;
	batch: string;
	course: string;
	majorCode: string;
	major: string;
	amount: number;
	studyYear?: any;
	studyFee: number;
	majorOptions: string;
	majorRequirements: string;
	speciality: string;
	others: string;
}


export interface PiciData {
  id: number;
  province: string;
  provinceCode: string;
  year: number;
  batch: string;
  course: string;
  score: number;
  memo: string;
}

export interface F1d1Data {
  year: number;
  province: string;
  provinceCode: string;
  course: string;
  batch: string;
  scoreMin: number;
  amountScore: number;
  rankMin: number;
}

export interface CollegeScore {
  id: Number;
  collegeCode: string;
  college: string;
  course: string;
  batch: string;
  majorOption: string;
  year: number;
  scoreMin: Number;
  rankMin: Number;
  amount: Number;
  collegeProvince: string;
  city: string;
  memo: string;
}

export interface EnrollData {
	id: number;
	year: number;
	provinceCode: string;
	gbCode: string;
	course: string;
	collegeName: string;
  enrollCode: string;
	majorCode: string;
	major: string;
	majorRemark: string;
	majorRequirement: string;
	majorOptions: string;
  majorEnrorllCode: string;
	scoreMax: number;
	scoreMin: number;
	scoreAvg: number;
	rankMin: number;
	rankMax?: any;
	rankAvg?: any;
	amount: number;
}


export interface BriefUnivInfo {
  category: string;
  collegeCode: string;
  eduLevel: string;
  enName: string;
  feature: string;
  gbCode: string;
  id: number;
  logoUrl: string;
  name: string;
  url: string;
  natureType: string;
  provinceName: string;
  shortName: string;
}

export interface EnrollInfo {
  amount: Number;
  collegeName: string;
  courses: string;
  gbCode: string;
  id: string;
  major: string;
  majorCode: string;
  majorOption: string;
  majorRemark: string;
  majorRequirement: string;
  provinceCode: string;
  rankAvg: Number;
  randMax: Number;
  rankMin: Number;
  scoreAvg: Number;
  scoreMax: Number;
  scoreMin: Number;
  year: Number;
}

export interface Info {
  brief: BriefUnivInfo;
  info: EnrollInfo[];
  probability: number;
  profressions: any[];
  type: string;
  ord: number;
}

export interface MajorEnrollInfo{
  year: number;
	course: string;
	provinceCode: string;
	gbCode: string;
	collegeName: string;
	batch: string;
	eduLevel: string;
	major: string;
	majorRemark: string;
	majorRequirement: string;
	majorOption: string;
	amount: number;
	scoreMin: number;
	scoreMax: number;
	scoreAvg: number;
	rankMin: number;
	rankMax?: number;
	rankAvg?: number;
}

export interface ScoreF1D1 {
	year: number;
	minScore: number;
	maxScore: number;
	minRank: number;
	maxRank: number;
}

export function compareMajor(a, b: MajorEnrollInfo): number {
  if (a.year < b.year) {
    return 1;
  }

  if (a.year > b.year) {
    return -1;
  }

  return a.major > b.major ? -1 : 1;
}

export function compareScore(a, b: CollegeScore) {
  if (a.year < b.year) {
    return 1;
  }

  if (a.year > b.year) {
    return -1;
  }

  return a.course > b.course ? -1 : 1;
}

export function compareEnrollPlan(a, b: EnrollData) {
  if (a.year < b.year) {
    return 1;
  }

  if (a.year > b.year) {
    return -1;
  }

  return a.majorCode > b.majorCode ? 1 : -1;
}

export interface DeepReport {
	id: number;
	userCode: string;
	filePath: string;
	sucess: boolean;
	uploadOn: string;
}

