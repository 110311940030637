import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from '~/app/service/user.service';
import { isValidCourseSelection } from '~/app/utility/course-util';
import { canSelectCourse } from '~/app/utility/domain';

@Component({
  selector: 'app-com-courses',
  templateUrl: './com-courses.component.html',
  styleUrls: ['./com-courses.component.scss'],
})
export class ComCoursesComponent implements OnInit {
  @Input() courses: string = '';
  @Input() score? = null;
  @Output() courseSelectChanged = new EventEmitter<string>();
  @Output() scoreConfirmed = new EventEmitter<string>();

  showScore: boolean = false;
  canSelect = true;

  physics = '物理';
  physicsChecked = false;
  physicsUrl = 'physics.png';

  chemistry = '化学';
  chemistryChecked = false;
  chemistryUrl = 'chemistry.png';

  biology = '生物';
  biologyChecked = false;
  biologyUrl = 'biology.png';

  history = '历史';
  historyChecked = false;
  historyUrl = 'history.png';

  geography = '地理';
  geographyChecked = false;
  geographyUrl = 'geography.png';

  politics = '政治';
  politicsChecked = false;
  politicsUrl = 'politics.png';

  private imgPath = '../../../assets/logos/';

  courseSelected: Map<string, boolean> = new Map();
  mode: string = 'C';

  constructor(
    private messageService: NzMessageService,
    private userService: UserService
  ) {
    this.mode = userService.enrollMode();
  }

  ngOnInit(): void {
    this.showScore = this.score && this.score !== '';
    this.initSelectedCourses();
    this.setChecked();
  }

  initSelectedCourses() {
    this.physicsChecked = this.courses.indexOf(this.physics) >= 0;
    this.courseSelected.set(this.physics, this.physicsChecked);

    this.chemistryChecked = this.courses.indexOf(this.chemistry) >= 0;
    this.courseSelected.set(this.chemistry, this.chemistryChecked);

    this.biologyChecked = this.courses.indexOf(this.biology) >= 0;
    this.courseSelected.set(this.biology, this.biologyChecked);

    this.historyChecked = this.courses.indexOf(this.history) >= 0;
    this.courseSelected.set(this.history, this.historyChecked);

    this.geographyChecked = this.courses.indexOf(this.geography) >= 0;
    this.courseSelected.set(this.geography, this.geographyChecked);

    this.politicsChecked = this.courses.indexOf(this.politics) >= 0;
    this.courseSelected.set(this.politics, this.politicsChecked);

    const checkedCount = this.countSelect();
    this.canSelect = checkedCount < 3;
  }

  courseSelect(course: string) {
    const checked = this.courseSelected.get(course);
    this.courseSelected.set(course, !checked);

    this.setChecked();

    const checkedCount = this.countSelect();
    this.canSelect = checkedCount < 3;
    if (checkedCount > 3) {
      this.messageService.error('选择科目不能多于3门');
      return;
    } else if (
      !canSelectCourse(this.courseSelected, course, this.mode) &&
      !checked
    ) {
      this.messageService.error('物理和历史不能同时选择');
      return;
    } else {
      const courses = this.mergeCourse();
      this.courseSelectChanged.emit(courses);
    }
  }

  private setChecked() {
    this.physicsChecked = this.courseSelected.get(this.physics);
    this.physicsChecked = this.courseSelected.get(this.physics);
    this.physicsUrl = this.physicsChecked
      ? this.imgPath + 'physics.png'
      : this.imgPath + 'physics-2.png';
    this.chemistryChecked = this.courseSelected.get(this.chemistry);
    this.chemistryUrl = this.chemistryChecked
      ? this.imgPath + 'chemistry.png'
      : this.imgPath + 'chemistry-2.png';
    this.biologyChecked = this.courseSelected.get(this.biology);
    this.biologyUrl = this.biologyChecked
      ? this.imgPath + 'biology.png'
      : this.imgPath + 'biology-2.png';
    this.historyChecked = this.courseSelected.get(this.history);
    this.historyUrl = this.historyChecked
      ? this.imgPath + 'history.png'
      : this.imgPath + 'history-2.png';
    this.geographyChecked = this.courseSelected.get(this.geography);
    this.geographyUrl = this.geographyChecked
      ? this.imgPath + 'geography.png'
      : this.imgPath + 'geography-2.png';
    this.politicsChecked = this.courseSelected.get(this.politics);
    this.politicsUrl = this.politicsChecked
      ? this.imgPath + 'politics.png'
      : this.imgPath + 'politics-2.png';
  }

  countSelect(): number {
    return Array.from(this.courseSelected.values()).filter((c) => c).length;
  }

  private mergeCourse(): string {
    let result = '';

    this.courseSelected.forEach((value, key) => {
      if (value) {
        result = result + ',' + key;
      }
    });

    if (result.length > 0) {
      result = result.substring(1, result.length);
    }

    return result;
  }

  scoreOk() {
    if (isValidCourseSelection(this.userService.enrollMode(), this.mergeCourse())===false) {
      this.messageService.error('物理和历史不能同时选择');
    } else {
      this.scoreConfirmed.emit(this.score);
    }
  }
}
