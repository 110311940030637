<section>
  <div class="container">
    <nz-table #report [nzData]="reportData">
        <thead>
          <tr>
            <th>填报时间</th>
            <th>选科</th>
            <th>分数</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of report.data">
            <td>{{ data.uploadOn }}</td>
            <td>{{ data.courses }}</td>
            <td>{{ data.score }}</td>
            <td>
              <a (click)="dowload(data.id)">下载</a>
            </td>
          </tr>
        </tbody>
      </nz-table>
  </div>
</section>