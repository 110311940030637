import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '~/app/service/http.service';
import { MajorDetail, MajorInfo } from '~/app/shared/major.type';
import { ProfessionDetail, ProfessionInfo } from '~/app/shared/profession.type';
import {
  College,
  Major,
  Profession,
  SearchResult,
} from '~/app/shared/search.type';
import { UnivInfo } from '~/app/shared/university.type';
import { Future, TypeResult } from '~/app/shared/majordesc.type';

export interface DataType {
  colleges: UnivInfo[];
  majors: MajorInfo[];
  professions: ProfessionInfo[];
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  cumbs = ['首页', '搜索'];
  data: SearchResult = null;
  colleges: College[] = [];
  professions: Profession[] = [];
  majors: Major[] = [];

  str: string;

  busy: boolean = false;
  navigationSubscription: any;

  constructor(
    private http: HttpService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.navigationSubscription = this.router.events.subscribe((event: any) => {
      this.str = this.route.snapshot.params['str'];
      this.fetchData(this.str);
    });
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    this.str = this.route.snapshot.params['str'];
    await this.fetchData(this.str);
  }

  async fetchData(str:string) {
    this.busy = true;
    const action = '/v1/personal-search/search-all';
    const params = {
      content: str,
    };

    this.data = await this.http.post(action, params);
    this.colleges = this.data?.colleges ?? [];
    this.majors = this.data?.majors ?? [];
    this.professions = this.data?.professions ?? [];

    this.busy = false;
  }

  showMajor = false;
  major: MajorDetail;

  majorDesc: TypeResult[] = [];

  async majorDetail($event) {
    this.major = null;

    let url = `/major/details/${$event}.json`;
    let detail = await this.http.get(url);
    this.major = detail['major'];

    url = `/major/feature/${$event}.json`;
    let desc = await this.http.get(url);
    this.majorDesc = desc['result'];

    this.showMajor = this.major !== null;
  }

  getDirection(): string {
    return this.major.eduLevel === 'zhuan' ? '升本方向' : '考研方向';
  }

  getDirectionDetail(): string {
    return this.major.studyDirection && this.major.studyDirection !== ''
      ? this.major.studyDirection
      : this.major.zhuanToBenOrient;
  }

  majorClose() {
    this.showMajor = false;
  }

  select($event) {
    let div: string = null;
    let option: any = [];

    if ($event === 1) {
      div = 'salary';
      option = this.getXinZiPJOption();
    } else if ($event === 2) {
      div = 'zhiye';
      option = this.getPieOption('ZhiYeFX');
    } else if ($event === 3) {
      div = 'hangye';
      option = this.getPieOption('HangYeFB');
    } else if ($event === 4) {
      div = 'chengshi';
      option = this.getPieOption('ChengShiFB');
    }

    if (div) {
      const doc = document.getElementById(div);
      const echart = echarts.init(doc);
      echart.setOption(option, true);
    }
  }

  show(type: string): boolean {
    let data = this.getFutureData(type);
    if (!data) {
      return false;
    }

    let filtered = data.filter((c) => c.name && c.value);
    return filtered.length > 0;
  }

  getFutureData(type: string): Future[] {
    return this.majorDesc.find((c) => c.type === type)?.futures;
  }

  getXinZiPJOption() {
    let data = this.getFutureData('XinZiPJ');

    if (!data) {
      return;
    }
    let years = data.map((c) => c.name);
    let values = data.map((c) => c.value);

    return {
      title: {
        text: '月薪资(元)',
      },
      tooltip: {
        show: true,
        formatter: function (params) {
          return params['name'] + '年平均' + params['value'] + '元';
        },
      },
      xAxis: {
        type: 'category',
        data: years,
        name: '年',
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: values,
          type: 'line',
          itemStyle: {
            normal: {
              color: '#FF0000', //改变折线点的颜色
              lineStyle: {
                color: '#a80000', //改变折线颜色
              },
            },
          },
        },
      ],
    };
  }

  getPieOption(type: string) {
    let data = this.getFutureData(type);

    if (!data) {
      return;
    }

    let names = data.map((c) => c.name);
    let values = data.map((c) => c.value);

    let pieData = [];
    for (let i = 0; i < names.length; i++) {
      pieData.push({ name: names[i], value: values[i] });
    }

    return {
      series: [
        {
          type: 'pie',
          data: pieData,
          label: {
            show: true,
            formatter: function (arg) {
              return arg.data.name + arg.percent + '%';
            },
          },
          roseType: 'radius',
        },
      ],
    };
  }

  prof: ProfessionDetail = null;
  showProf = false;

  async profDetail($event) {
    const url = `/job/${$event}.json`;
    const summary = await this.http.get(url);
    if (!summary) {
      return;
    }

    const infos = summary['result'];

    const action = '/v1/profession/list-profression-detail';
    const params = {
      code: $event,
    };

    this.prof = await this.http.post(action, params);
    if (this.prof) {
      this.prof.majors = infos;
      this.showProf = true;
    }
  }

  profClose() {
    this.showProf = false;
  }
}
