import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from 'src/app/service/user.service';
import { HttpService } from 'src/app/service/http.service';
import { Router } from '@angular/router';
import { Topics } from '~/app/shared/topics.type';
import { MyChoice } from '~/app/shared/mychoice.type';
import { teacher2StudentCode, family2StudentCode } from '~/app/utility/domain';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss'],
})
export class CareerComponent implements OnInit {
  selectCourse = '选科课堂';
  choiceCourse = '志愿课堂';
  teacherCourse = '生涯教育培训';
  familyCourse = '家校共育';

  testUrls = [
    'https://resources.delesson.cn/imgs/career/001.jpg',
    'https://resources.delesson.cn/imgs/career/002.jpg',
    'https://resources.delesson.cn/imgs/career/003.jpg',
    'https://resources.delesson.cn/imgs/career/004.jpg',
    'https://resources.delesson.cn/imgs/career/005.jpg',
    'https://resources.delesson.cn/imgs/career/006.jpg',
  ];

  data: any;
  selectShows: Topics[] = [];
  choiceShows: Topics[] = [];

  isTeacher: boolean;
  isFamily: boolean;
  careerCourses: Topics[] = [];
  familyCourses: Topics[] = [];

  myChoice?: MyChoice = null;

  univ: string = '';
  prof: string = '';
  major: string = '';
  litre: string = '';
  occupation: string = '';

  constructor(
    private message: NzMessageService,
    private userService: UserService,
    private http: HttpService,
    private router: Router
  ) {
    this.isTeacher = this.userService.isTeacher();
    this.isFamily = this.userService.isFamily();
  }
  pdfDoc: any;
  pageNum: number = 1;

  async ngOnInit() {
    this.fetchMyChoice();
    this.fetchData();
  }
  loadPDF() {
    window.open('https://resources.delesson.cn/000000/bidu.pdf','_blank');
  }

  univTitle = '我的意向大学';
  majorTitle = '我的意向专业';
  profTitle = '我的意向职业';
  litreLearnText = '我的升学通路';

  async fetchMyChoice() {
    let code = this.userService.code();
    if (this.userService.isFamily()) {
      code = family2StudentCode(code);
      this.univTitle = '孩子的意向大学';
      this.majorTitle = '孩子的意向专业';
      this.profTitle = '孩子的意向职业';
    } else if (this.userService.isTeacher) {
      this.univTitle = '学生的意向大学';
      this.majorTitle = '学生的意向专业';
      this.profTitle = '学生的意向职业';
    }

    // const action = '/v1/personal/find-my-choice';
    const action = '/v1/personal/list-my-choice';
    const params = {
      userCode: code,
    };

    // this.myChoice = await this.http.post(action, params);
    const data = await this.http.post(action, params);
    const { enrolls, colleges, jobs } = data;
    if (!this.myChoice) {
      this.myChoice = {
        myCollege: '',
        myMajor: '',
        myProfession: '',
        id: null,
        userCode: code,
      };
    }

    // this.univ = this.myChoice?.myCollege ? this.myChoice.myCollege : '未选择';
    this.univ = colleges?.length > 0 ? colleges[0]?.myCollege : '未选择';
    // this.major = this.myChoice?.myMajor ? this.myChoice.myMajor : '未选择';
    this.major = colleges?.length > 0 ? colleges[0]?.myMajor : '未选择';
    this.prof = enrolls?.length > 0 ? enrolls[0]?.myEnroll : '未选择';
    this.occupation = jobs?.length > 0 ? jobs[0]?.myJob : '未选择';
  }
  async fetchSubject() {
    let code = this.userService.code();
    const action = '/v1/personal/get-my-courses';
    const params = {
      userCode: code,
    };
    const data = await this.http.post(action, params);
    if (!data) {
      this.message.error('未选科，正在跳转学生信息页面进行选科');
      setTimeout(() => {
        this.router.navigate(['/mine']);
      }, 1000);
    } else {
      this.router.navigate(['/enc-my-univ']);
    }
  }
  async fetchData() {
    let code = this.userService.code();
    const grade = this.userService.grade();
    const name = this.userService.name();
    const province = this.userService.province();
    const unitName = this.userService.school();

    if (this.userService.isTeacher()) {
      code = teacher2StudentCode(code);
    } else if (this.userService.isFamily()) {
      code = family2StudentCode(code);
    }

    if (this.isTeacher) {
      const action = '/v1/resource/list-by-type';
      const params = {
        mainType: '生涯教育培训',
        type: '',
      };

      this.careerCourses = await this.http.post(action, params);
    }

    if (this.isTeacher || this.isFamily) {
      const action = '/v1/resource/list-by-type';
      const params = {
        mainType: '家校共育',
        type: '',
      };

      this.familyCourses = await this.http.post(action, params);
    }

    const param = {
      code: code,
      grade: grade,
      name: name,
      province: province,
      unitName: unitName,
    };

    const action = '/v1/personal/career';
    this.data = await this.http.post(action, param);

    this.selectShows = this.fetchField('courseSelectShows');
    this.choiceShows = this.fetchField('careerCourses');
  }

  private fetchField(field: string): Topics[] {
    const result = this.data[field];
    return result;
  }

  examMore() {
    this.router.navigate(['career-test']);
  }

  shorten(str: string): string {
    return str.length < 12 ? str : str.substring(0, 12) + '...';
  }

  showDetail = false;
  title: string = '';
  content: string[] = [];
  buttonText: string = '';

  show(title, content: string) {
    if (this.isTeacher) {
      this.message.warning('暂未开放, 敬请期待！');
      return;
    } else {
      this.title = title;
      this.content = content.split(',');
      this.showDetail = true;
      switch (title) {
        case '我的意向大学':
          this.buttonText = '查看更多大学';
          break;
        case '我的意向专业':
          this.buttonText = '查看更多专业';
          break;
        default:
          this.buttonText = '查看更多职业';
      }
    }
  }

  async delete($event: string) {
    this.content = this.content.filter((c) => c !== $event);

    switch (this.title) {
      case '我的意向大学':
        this.myChoice.myCollege = this.content.join(',');
        this.univ = this.myChoice.myCollege;
        break;
      case '我的意向专业':
        this.myChoice.myMajor = this.content.join(',');
        this.major = this.myChoice.myMajor;
        break;
      default:
        this.myChoice.myProfession = this.content.join(',');
        this.prof = this.myChoice.myProfession;
    }

    const action = '/v1/personal/set-my-choice';
    const params = {
      myCollege: this.myChoice.myCollege,
      myMajor: this.myChoice.myMajor,
      myProfession: this.myChoice.myProfession,
      userCode: this.userService.code(),
    };
    await this.http.post(action, params);
  }

  closeDetail() {
    this.showDetail = false;
  }
  handleEdit(type) {
    switch (type) {
      case 'univ':
        this.fetchSubject();
        break;
      case 'major':
        this.router.navigate(['/enc-my-major']);
        break;
      case 'occupation':
        this.router.navigate(['/enc-my-profession']);
        break;
      default:
        this.router.navigate(['/litre-learn']);
    }
  }
  handleOk($event) {
    this.showDetail = false;
    switch (this.title) {
      case '我的意向大学':
        this.fetchSubject();
        // this.router.navigate(['/enc-my-univ']);
        break;
      case '我的意向专业':
        this.router.navigate(['/enc-my-major']);
        break;
      default:
        this.router.navigate(['/enc-my-profession']);
    }
  }
}
