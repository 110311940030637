import {Component, Input, OnInit} from '@angular/core';
import QRCode from 'qrcode';

@Component({
  selector: 'app-wx-qrcode',
  template: `<img [src]="qrCodeSrc" style="width: 200px;height: 200px;" alt="微信二维码"/>`
})
export class WxQrcodeComponent implements OnInit {
  @Input() qrCodeSrc: string;

  // qrCodeSrc: string;

  constructor() { }

  ngOnInit(): void {
    // this.generateQRCode('https://szel.paypartner.cn/h5/#/numberCardNew/?openId=11b08dc5de283aa0c77e6a7edbf1b4b8');
    this.generateQRCode(this.qrCodeSrc);
  }

  generateQRCode(data: string) {
    QRCode.toDataURL(data, { errorCorrectionLevel: 'H' }).then(url => {
      this.qrCodeSrc = url;
    }).catch(err => {
      console.error(err);
    });
  }
}
