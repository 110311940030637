<app-header></app-header>

<div class="section">
  <div class="contain">
      <app-breadcumb [items]="cumbs"></app-breadcumb>
  </div>

  <div class="contain border">
    <p class="bold ">心理评测</p>
    <app-career-test-card *ngFor ="let item of data" [data]="item"></app-career-test-card>
  </div>
</div>

<app-footer></app-footer>
