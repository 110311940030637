import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { HttpService } from 'src/app/service/http.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '~/environments/environment';
import { CollegeScore, EnrollData, Info, MajorEnrollInfo, compareEnrollPlan, compareMajor, compareScore } from '~/app/shared/enroll.typs';
import { EnrollIntroduction, UnivDetail } from '~/app/shared/university.type';

@Component({
  selector: 'app-major-choose',
  templateUrl: './major-choose.component.html',
  styleUrls: ['./major-choose.component.scss']
})
export class MajorChooseComponent implements OnInit {
  @Input() major: Info;
  @Input() index;
  @Input() showRight =true;

  @Output() onDelete = new EventEmitter<number>();
  @Output() onMoveUp = new EventEmitter<number>();
  @Output() onMoveDown = new EventEmitter<number>();

  data: EnrollData[] = [];
  showModal = false;

  univ: UnivDetail;
  univMajors = '';

  busy = false;

  enrollPlan: EnrollData[] = [];
  enrollIntro: EnrollIntroduction[] = [];

  profession: string = '';

  constructor(private userService: UserService,
    private message: NzMessageService,
    private http: HttpService) { }

  ngOnInit(): void {
    if (!this.major.brief.logoUrl){
      this.major.brief.logoUrl = environment.noLogoUrl;
    }
  }

  up(){
    this.onMoveUp.emit(this.index);
  }

  down(){
    this.onMoveDown.emit(this.index);
  }

  delete(){
    this.onDelete.emit(this.index);
  }

  handleScores(){
    this.showModal = false;
    this.data= [];
  }

  async show(){
    const action  = "/v1/enroll2/list-more-scores";
    const params = {
      college: this.major.brief.name,
      major: this.major.info[0].major,
      provinceCode: this.userService.provinceCode()
    }

    this.data = await this.http.post(action,params);
    this.showModal = true;
  }

  showDetail = false;
  fetching = false;


  handleDetailCancel() {
    this.showDetail = false;
  }

  handleCancel() {
    this.showDetail = false;
  }

  handleOk($event) {
    this.showDetail = false;
  }

  async detail(id, name, code) {
    this.fetching = true;

    const action = '/v1/college/find';
    const params = {
      id: id,
    };

    this.univ = await this.http.post(action, params);
    if (!this.univ) {
      this.message.error('大学信息获取错误，请稍后重试！');
      this.busy = false;
      return;
    }

    this.univMajors = this.univ.majors.map((c) => c.name).join(', ');

    await this.getScores(name);
    await this.getCollegeScores(name);
    await this.getCollegePlans(name);
    await this.getEnrollIntroduction(code);

    this.fetching = false;
    this.showDetail = true;
  }

  majorScores: MajorEnrollInfo[] = [];

  async getScores(name: string) {
    const action = '/v1/enroll2/list-college-major-scores';
    const params = {
      collegeName: name,
      provinceCode: this.userService.provinceCode(),
    };

    let data = await this.http.post(action, params);
    if (data) {
      this.majorScores = data.sort((a,b)=>compareMajor(a,b));
    }
  }

  collegeScores: CollegeScore[] = [];
  async getCollegeScores(name: string) {
    const college_score_action = '/v1/college-enroll/list-province-college';

    const provinceCode = this.userService.provinceCode();
    const params = {
      code: provinceCode,
    };

    this.collegeScores = await this.http.post(college_score_action, params);
    if (this.collegeScores) {
      this.collegeScores = this.collegeScores
        .filter((c) => c.college === name)
        .sort((a, b) => compareScore(a, b));
    }
  }

  async getCollegePlans(name: string) {
    const provinceCode = this.userService.provinceCode();
    const action = '/v1/enroll2/list-college-enroll-plan';

    const params = {
      provinceCode: provinceCode,
      collegeName: name,
    };

    const data: EnrollData[] = await this.http.post(action, params);
    this.enrollPlan = data?.sort((a, b) => compareEnrollPlan(a, b));
  }

  current: EnrollIntroduction = undefined;

  async getEnrollIntroduction(code: string) {
    const action = '/v1/enroll2/find-collge-enroll-introduction';
    const params = {
      code: code,
    };

    this.enrollIntro = await this.http.post(action, params);
    this.current = this.enrollIntro?.length > 0 ? this.enrollIntro[0] : null;
  }

  showIntro(dt: EnrollIntroduction) {
    this.current = dt;
  }
}


