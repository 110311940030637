import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { PiciData } from '~/app/shared/enroll.typs';

@Component({
  selector: 'app-pici',
  templateUrl: './pici.component.html',
  styleUrls: ['./pici.component.scss'],
})

export class PiciComponent implements OnInit {
  @Input() year: string;
  @Input() data: PiciData[];
  province: string;

  constructor(private userService: UserService) {
  }


  ngOnInit(): void {
    this.province = this.userService.province();
   
  }

  ngAfterViewInit(): void {

  }
}
