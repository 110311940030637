import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-foucs',
  templateUrl: './foucs.component.html',
  styleUrls: ['./foucs.component.scss']
})
export class FoucsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
