import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MainContentComponent } from './main-content/main-content.component';
import { ProductContentComponent } from './product-content/product-content.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule} from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzImageModule } from 'ng-zorro-antd/image';
import { HomeComponent } from './home/home.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule} from "ng-zorro-antd/divider";
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzCardModule } from 'ng-zorro-antd/card'
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { CourseComponent } from './home/course/course.component';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BulletinComponent } from './home/bulletin/bulletin.component';
import { ContentComponent } from './home/content/content.component';
import { CardComponent } from './home/card/card.component';
import { InterviewComponent } from './home/interview/interview.component';
import { ShowsComponent } from './home/shows/shows.component';
import { FoucsComponent } from './home/foucs/foucs.component';
import { CoursesComponent } from './home/courses/courses.component';
import { CardDescComponent } from './home/card-desc/card-desc.component';
import { CareerComponent } from './career/career.component';
import { MineComponent } from './mine/mine.component';
import { StudyComponent } from './study/study.component';
import { PsychologyComponent } from './psychology/psychology.component';
import { TestCardComponent } from './career/test-card/test-card.component';
import { CourseSelectComponent } from './career/course-select/course-select.component';
import { InterviewsComponent } from './home/interviews/interviews.component';
import { ListMajorByCoursesComponent } from './list-major-by-courses/list-major-by-courses.component';
import { OneKeyChooseComponent } from './one-key-choose/one-key-choose.component';
import { IntelligentChooseComponent } from './intelligent-choose/intelligent-choose.component';
import { RouterModule } from '@angular/router';
import { MajorComponent } from './list-major-by-courses/major/major.component';
import { SecondComponent } from './list-major-by-courses/major/second/second.component';
import { ThirdComponent } from './list-major-by-courses/major/second/third/third.component';
import { MajorChooseComponent } from './intelligent-choose/major-choose/major-choose.component';
import { ListCourseByCollegeComponent } from './list-course-by-college/list-course-by-college.component';
import { UniversityComponent } from './list-course-by-college/university/university.component';
import { CareerTestComponent } from './career-test/career-test.component';
import { CareerTestCardComponent } from './career-test/career-test-card/career-test-card.component';
import { TestComponent } from './test/test.component';
import { StudyTestComponent } from './study-test/study-test.component';
import { ListCourseByMajorComponent } from './list-course-by-major/list-course-by-major.component';
import { MajorDescComponent } from './list-course-by-major/major-desc/major-desc.component';
import { EncMajorComponent } from './enc-major/enc-major.component';
import { EncMyMajorComponent } from './enc-my-major/enc-major.component';
import { EncProfessionComponent } from './enc-profession/enc-profession.component';
import { EncMyProfessionComponent } from './enc-my-profession/enc-profession.component';
import { UnivComponent } from './enc-university/univ/univ.component';
import { UnivMyComponent } from './enc-my-university/univ/univ.component';
import { UnivCollectComponent } from './my/univ/univ.component';
import { MajorCollectComponent } from './my/major/major.component';
import { ProfessionCollectComponent } from './my/profession/profession.component';
import { EnrollDataComponent } from './enroll-data/enroll-data.component';
import { F1d1Component } from './enroll-data/f1d1/f1d1.component';
import { EnrollPlanComponent } from './enroll-data/enroll-plan/enroll-plan.component';
import { PiciComponent } from './enroll-data/pici/pici.component';
import { EnrollScoreComponent } from './enroll-data/enroll-score/enroll-score.component';
import { SearchComponent } from './search/search.component';
import { LoginComponent } from './login/login.component';
import { LoginRegistComponent } from './login-regist/login-regist.component';
import { LoginRegistInfoComponent } from './login-regist-info/login-regist-info.component';
import { DownloadComponent } from './download/download.component';
import { AttentionsComponent } from './home/attentions/attentions.component';
import { AttentionComponent } from './home/attentions/attention/attention.component';
import { AttentionPageComponent } from './attention-page/attention-page.component';
import { ComponentsModule } from '../components/components.module';
import { EncUniversityComponent } from './enc-university/enc-university.component';
import { EncMyUniversityComponent } from './enc-my-university/enc-university.component';
import { VideoMoreComponent } from './video-more/video-more.component';
import { PsychologyTestComponent } from './psychology-test/psychology-test.component';
import { ExamListComponent } from './study/exam-list/exam-list.component';
import { MyComponent } from './my/my.component';
import { HotNewsComponent } from './home/hot-news/hot-news.component';
import { SelectMajorComponent } from './select-major/select-major.component';
import { SelectProfessionComponent } from './select-profession/select-major.component';
import { LitreLearnComponent } from './litre-learn/litre-learn.component'
import { ArchivesComponent } from './archives/archives.component'
import { CareerExamComponent } from './career-exam/career-exam.component'
import { CareerGoalComponent } from './career-goal/career-goal.component'
import { CareerProfessionComponent } from './career-profession/career-profession.component'
import { CareerEnrollComponent } from './career-enroll/career-enroll.component'
import { VolunteerSelectionComponent } from './volunteer-selection/volunteer-selection.component'
import { VolunteerReportComponent } from './volunteer-report/volunteer-report.component'
import { ScoreArchiveComponent } from './score-archives/score-archives.component'
import {AppComponent} from "~/app/layout/home/content/baike_1.component";
import {WxQrcodeComponent} from "~/app/layout/home/qrcode";

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        MainContentComponent,
        ProductContentComponent,
        HomeComponent,
        CourseComponent,
        BulletinComponent,
        ContentComponent,
        CardComponent,
        InterviewComponent,
        ShowsComponent,
        FoucsComponent,
        CoursesComponent,
        CardDescComponent,
        CareerComponent,
        MineComponent,
        StudyComponent,
        PsychologyComponent,
        TestCardComponent,
        CourseSelectComponent,
        InterviewsComponent,
        ListMajorByCoursesComponent,
        OneKeyChooseComponent,
        IntelligentChooseComponent,
        MajorComponent,
        SecondComponent,
        ThirdComponent,
        MajorChooseComponent,
        ListCourseByCollegeComponent,
        UniversityComponent,
        CareerTestComponent,
        CareerTestCardComponent,
        TestComponent,
        StudyTestComponent,
        ListCourseByMajorComponent,
        MajorDescComponent,
        EncMajorComponent,
        EncMyMajorComponent,
        EncProfessionComponent,
        EncMyProfessionComponent,
        UnivComponent,
        UnivMyComponent,
        UnivCollectComponent,
        MajorCollectComponent,
        ProfessionCollectComponent,
        EnrollDataComponent,
        F1d1Component,
        EnrollPlanComponent,
        PiciComponent,
        EnrollScoreComponent,
        SearchComponent,
        LoginComponent,
        LoginRegistComponent,
        LoginRegistInfoComponent,
        DownloadComponent,
        AttentionsComponent,
        AttentionComponent,
        AttentionPageComponent,
        EncUniversityComponent,
        EncMyUniversityComponent,
        VideoMoreComponent,
        PsychologyTestComponent,
        ExamListComponent,
        MyComponent,
        HotNewsComponent,
        SelectMajorComponent,
        SelectProfessionComponent,
        LitreLearnComponent,
        ArchivesComponent,
        CareerExamComponent,
        CareerGoalComponent,
        CareerProfessionComponent,
        CareerEnrollComponent,
        VolunteerSelectionComponent,
        VolunteerReportComponent,
        ScoreArchiveComponent,
        AppComponent,
        WxQrcodeComponent
    ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NzButtonModule,
    NzModalModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzInputNumberModule,
    NzAvatarModule,
    NzImageModule,
    NzTabsModule,
    NzCheckboxModule,
    NzDividerModule,
    NzSelectModule,
    NzTableModule,
    NzDescriptionsModule,
    NzCardModule,
    NzSpinModule,
    NzBreadCrumbModule,
    NzPaginationModule,
    NzDropDownModule,
    NzMenuModule,
    NzPopoverModule,
    NzPopconfirmModule,
    ComponentsModule
  ]
})

export class LayoutModule { }
