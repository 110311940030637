import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { environment } from 'src/environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private message: NzMessageService,
    private storageService: LocalStorageService
  ) {}

  public async post(action: string, param: any, type?: number) {
    const headers = this.getHeaders();
    const domain = type ? environment.baseTwoUrl : environment.baseApiUrl
    const url = domain + action;
    const result: any = await this.http.post(url, param, { headers }).toPromise();

    if ((result['code'] !== 200 && result['code'] !== 0) || result['message']) {
      if(result['message'] || result['msg']) {
        this.message.error(result['message'] || result['msg']);
      }
      return result['code']===1000? null: undefined;
    } else {
      return result['data'];
    }
  }

  public async postQr(action: string, param: any, type?: number) {
    const headers = this.getHeaders();
    const domain = type ? environment.cardApiUrl : environment.cardApiUrl
    const url = domain + action;
    const result: any = await this.http.post(url, param, { headers }).toPromise();

    if ((result['code'] !== 200 && result['code'] !== 0) || result['message']) {
      if(result['message'] || result['msg']) {
        this.message.error(result['message'] || result['msg']);
      }
      return result['code']===1000? null: undefined;
    } else {
      return result['data'];
    }
  }

  public async get(action: string, type?: number) {
    const url = `${type ? environment.baseTwoUrl : environment.baseUrl}${action}`;
    try {
      return await this.http.get(url).toPromise();
    } catch {
      return undefined;
    }
  }

  public async getFromBaseApi(action: string) {
    const headers = this.getHeaders();
    const url = `${environment.baseApiUrl}${action}`;

    return await this.http.get(url, { headers }).toPromise();
  }

  public async postFormData(action: string, param: any) {
    const headers = this.getHeaders();

    const url = environment.baseApiUrl + action;
    return await this.http.post(url, param, { headers });
  }

  public async postForBlob(action: string, params: any) {
    const headers = this.getHeaders();

    const url = environment.baseApiUrl + action;
    const data = await this.http
      .post(url, params, { headers, responseType: 'blob' })
      .toPromise();

    const contentType = data.type;
    return new Blob([data], { type: contentType });
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders().set(
      'Authorization',
      this.storageService.get('token') || '1'
    );
  }
}
