<footer>
  <div class="container">
    <nz-divider></nz-divider>
    <p>{{ copyRight }}</p>
    <p>{{ icpNumber }}</p>
    <div *ngIf="anquanCode && anquanCode !== ''" style="width: 300px; margin: 0 auto">
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode={{anquanCode}}"
        style="
          display: inline-block;
          text-decoration: none;
          height: 20px;
          line-height: 20px;
        "
        ><img src="../../../assets/logos/icp.jpg" style="float: left" alt="" />
        <p
          style="
            float: left;
            height: 20px;
            line-height: 20px;
            margin: 0px 0px 0px 5px;
            color: #939393;
          "
        >
          {{anquanNumber}}
        </p></a
      >
    </div>
  </div>
