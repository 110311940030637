import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-feature',
  templateUrl: './select-feature.component.html',
  styleUrls: ['./select-feature.component.scss']
})
export class SelectFeatureComponent implements OnInit {
  @Input() selected = "";
  @Output() onSelectChanged = new EventEmitter<string>();

  feature = '985,211,双一流A,双一流B,国重点,省重点,省属,';
  features : string[];

  constructor() {
    this.selected = this.feature;
   }

  ngOnInit(): void {
    this.features = this.feature.split(",").filter(c=> c && c !== "");
    this.feature = "";
  }

  contains(str, sub: string): boolean {
    return str.indexOf(sub) >= 0;
  }

  click($event: string){
    if (this.contains(this.feature, $event)){
      this.feature = this.feature.replace($event+",", "");
    }else{
      this.feature = this.feature + $event +",";
    }

    this.onSelectChanged.emit(this.feature);
  }
}
