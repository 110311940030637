import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-test-card',
  templateUrl: './test-card.component.html',
  styleUrls: ['./test-card.component.scss'],
})
export class TestCardComponent implements OnInit {
  @Input() imgUrl: string;
  @Input() id: number;

  constructor(private message: NzMessageService, private router: Router) {}

  ngOnInit(): void {
    if (!this.imgUrl) {
      this.imgUrl = 'https://resources.delesson.cn/志愿课堂/视频1.jpg';
    }
  }

  click() {
    if (!this.id || this.id <= 0) {
      this.message.warning('本功能将陆续开放，敬请等待!');
      return;
    }

   this.router.navigate(['/test', this.id]);
  }
}
