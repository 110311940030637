<div class="container" *ngIf="courses?.length > 0">
    <div class="heading">
      <p class="bold">{{header}}</p>

      <button (click)="more()">查看更多</button>
    </div>

    <nz-tabset>
      <nz-tab nzTitle="人生第一粒纽扣">
        <div class="content">
          <div class="row">
            <app-card-desc [data]="courses[0]"></app-card-desc>
            <app-card-desc [data]="courses[1]"></app-card-desc>
            <app-card-desc [data]="courses[2]"></app-card-desc>
          </div>
        </div>
      </nz-tab>

      <nz-tab nzTitle="我的高考故事">
        <div class="content">
          <div class="row">
            <app-card-desc [data]="courses[3]"></app-card-desc>
            <app-card-desc [data]="courses[4]"></app-card-desc>
            <app-card-desc [data]="courses[5]"></app-card-desc>
          </div>
        </div>
      </nz-tab>

      <nz-tab nzTitle="大咖说">
        <div class="content">
          <div class="row">
            <app-card-desc [data]="courses[6]"></app-card-desc>
            <app-card-desc [data]="courses[7]"></app-card-desc>
            <app-card-desc [data]="courses[8]"></app-card-desc>
          </div>
        </div>
      </nz-tab>

    </nz-tabset>
