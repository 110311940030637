import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-province',
  templateUrl: './select-province.component.html',
  styleUrls: ['./select-province.component.scss'],
})
export class SelectProvinceComponent implements OnInit {
  @Input() selected = '';
  @Input() multiple = true;
  @Output() onSelectChanged = new EventEmitter<string>();

  feature =
    '北京,天津,河北,辽宁,山西,内蒙古,吉林,黑龙江,上海,安徽,山东,江苏,福建,河南,浙江,江西,湖北,湖南,海南,贵州,陕西,广东,重庆,云南,甘肃,广西,四川,青海,宁夏,新疆,香港,澳门,';
  features: string[];

  constructor() {
    this.selected = this.feature;
  }

  ngOnInit(): void {
    this.features = this.feature.split(',').filter((c) => c && c !== '');
    this.feature = "";
  }

  contains(str, sub: string): boolean {
    return str.indexOf(sub) >= 0;
  }

  click($event: string){
    if (this.contains(this.feature, $event)){
      this.feature = this.feature.replace($event+",", "");
    }else{
      this.feature = this.feature + $event +",";
    }
    this.onSelectChanged.emit(this.feature);
  }
}
