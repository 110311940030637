import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-select-univ',
  templateUrl: './select-univ.component.html',
  styleUrls: ['./select-univ.component.scss'],
})
export class SelectUnivComponent implements OnInit {
  eduLevel = '本科,专科,';
  features = '985,211,双一流A,双一流B,国重点,省重点,省属,';
  natureTypes = '公办,民办,';
  categories = '综合,理工,医药,艺术,军事,师范,农林,政法,财经,语言,民族,体育,';
  provinces =
    '北京,天津,河北,辽宁,山西,内蒙,吉林,黑龙江,上海,安徽,山东,江苏,福建,河南,浙江,江西,湖北,湖南,海南,贵州,陕西,广东,重庆,云南,甘肃,广西,四川,青海,宁夏,新疆,香港,澳门,';

  @Input() multiple = true;
  @Input() eduLevelDefault = '';
  @Output() onEduLevelChange = new EventEmitter<string>();
  @Output() onFeatureChange = new EventEmitter<string>();
  @Output() onNatureTypeChange = new EventEmitter<string>();
  @Output() onCategoryChange = new EventEmitter<string>();
  @Output() onProvinceChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  changeEduLevel($event) {
    this.onEduLevelChange.emit($event);
  }

  changeFeature($event) {
    this.onFeatureChange.emit($event);
  }

  changeNatureType($event) {
    this.onNatureTypeChange.emit($event);
  }

  changeCategory($event) {
    this.onCategoryChange.emit($event);
  }

  changeProvince($event) {
    this.onProvinceChange.emit($event);
  }
}
