<div class="content">
  <div class="left">
    <span class="title bold">办学性质：</span>
  </div>

  <div class="right">
    <button *ngFor="let fea of features"  (click)="click(fea)"
      [ngClass]="{
        checked: contains(feature, fea),
        unchecked: !contains(feature, fea)
      }">
      {{ fea }}
    </button>
  </div>
</div>
