import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public isVisibleJh = false;

  public vipSF = localStorage.getItem("usertype");
  public vipType = localStorage.getItem("viptype");
  public isVip = localStorage.getItem("isvip") == 'true' ? true : false;

  constructor() { }
}
