import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit, DoCheck, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss'],
})
export class CourseComponent {
  @Input() course: string;
  @Input() checked: boolean;
  @Input() url: string
  @Output() checkChanged = new EventEmitter<String>();

  constructor() {
  }

  onCheck() {
    this.checked = !this.checked;
    this.checkChanged.emit(this.course);
  }
}
