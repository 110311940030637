import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { LocalStorageService } from "../service/local-storage.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })

export class LoginGuard implements CanActivate{
    constructor(private storage: LocalStorageService,
        private message: NzMessageService,
        private route: Router){

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        // const token = this.storage.get("token");
        // if (token){
        //     return true;
        // }

        // this.message.error("您还没有登录，请登录后继续！")
        // this.route.navigate(["/login"]);

        return true;
    }
}
