<section>
  <div class="container">
    
    <app-select-level [selected]="eduLevelDefault" [multiple]="multiple" (onSelectChanged)="changeEduLevel($event)"></app-select-level>

    <div class="divide"></div>
    <app-select-feature [selected]="features" (onSelectChanged)="changeFeature($event)"></app-select-feature>

    <div class="divide"></div>
    <app-select-province [selected]="provinces" (onSelectChanged)="changeProvince($event)"></app-select-province>

    <div class="divide"></div>
    <app-select-category [selected]="categories" (onSelectChanged)="changeCategory($event)"></app-select-category>

    <div class="divide"></div>
    <app-select-nature-type [selected]="natureTypes" (onSelectChanged)="changeNatureType($event)"></app-select-nature-type>
  </div>
</section>
