import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcumb',
  templateUrl: './breadcumb.component.html',
  styleUrls: ['./breadcumb.component.scss'],
})
export class BreadcumbComponent implements OnInit, OnChanges {
  @Input() items: string[] = [];

  path: string = '';
  last: string = '';

  constructor() {}

  ngOnInit(): void {
    if (!this.items || this.items.length === 0) {
      return;
    }

    this.path = '';
    for (let i = 0; i < this.items.length - 1; i++) {
      this.path = this.path + this.items[i] + ' > ';
    }

    this.last = this.items[this.items.length - 1];
  }

  ngOnChanges(){
    if (!this.items || this.items.length === 0) {
      return;
    }

    this.path = '';
    for (let i = 0; i < this.items.length - 1; i++) {
      this.path = this.path + this.items[i] + ' > ';
    }

    this.last = this.items[this.items.length - 1];
  }
}
