<section>
  <div class="container">
    <nz-table #selection [nzData]="selectionData.slice(0, 10)">
      <thead>
        <tr>
          <th>填报时间</th>
          <th>选科</th>
          <th>分数</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of selection.data">
          <td>{{ data.time }}</td>
          <td>{{ data.courses }}</td>
          <td>{{ data.score }}</td>
          <td>
            <a (click)="showCollegeMajor(data.id)">查看</a>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <nz-modal
      nzWidth="960px"
      [(nzVisible)]="showCollegeMajorModal"
      nzTitle="志愿报考数据"
      (nzOnCancel)="closeCollegeMajor()"
    >
      <ng-container *nzModalContent>
        <div class="content">
            <div class="title dark">
                <span>选择考试科目：&nbsp;</span
                ><span class="bold">{{ myCollegeMajor?.xuanke.courses }}</span>
                <span>&nbsp; &nbsp; 成绩：&nbsp;</span
                ><span class="bold">{{ myCollegeMajor?.xuanke.score }}</span>
                <span class="choice">&nbsp; &nbsp; 选择志愿为结果为：</span>
              </div>
      
              <div class="univs">
                <dic class="univ" *ngFor="let detail of currMajorPage">
                  <app-major-choose
                    [major]="detail"
                    [showRight]="false"
                  ></app-major-choose>
                  <nz-divider></nz-divider>
                </dic>
              </div>
      
              <nz-pagination
                class="right"
                [(nzPageIndex)]="pageIndex"
                [nzTotal]="this.myCollegeMajor?.detail.length"
                (nzPageIndexChange)="gotoPage()"
              ></nz-pagination>
        </div>
      </ng-container>
      <div *nzModalFooter>
        <button nz-button nzType="default" (click)="closeCollegeMajor()">
          关闭
        </button>
      </div>
    </nz-modal>
  </div>
</section>
