<app-header></app-header>

<section>
  <div class="container">
    <app-breadcumb [items]="cumbs"></app-breadcumb>

    <div class="content">
      <div class="left-section">
        <div *ngFor="let item of items" (click)="show(item)">
          <p
            [ngClass]="{ 'item-select': item === title, item: item !== title }"
          >
            <span>{{ item }}</span>
          </p>
          <nz-divider></nz-divider>
        </div>
      </div>
      <div class="right-section">
        <div class="college-major" *ngIf="titleShow === '我的收藏'">
          <div class="tabset">
            <nz-tabset>
              <nz-tab nzTitle="院校" (nzClick)="handletabset(1)">
                <div *ngFor="let data of myCollegePage">
                  <app-collect-univ
                    [data]="data"
                    (onCompareChanged)="addCompare($event)"
                  >
                  </app-collect-univ>
                  <nz-divider></nz-divider>
                </div>
              </nz-tab>
              <nz-tab nzTitle="专业" (nzClick)="handletabset(2)">
                  <div class="major">
                    <app-collect-major [data]="mymajorPage" (onCompareChanged)="majorCompare($event)"></app-collect-major>
                  </div>
              </nz-tab>
              <nz-tab nzTitle="职业" (nzClick)="handletabset(3)">
                <div class="major">
                  <app-collect-profession [data]="myprofessionPage" (onCompareChanged)="professionCompare($event)"></app-collect-profession>
                </div>
              </nz-tab>
              <nz-tab nzTitle="课程" (nzClick)="handletabset(4)">
                <div *ngFor="let data of mycoursePage">
                  <div class="college">
                    <div class="left">
                      <img src="{{ data.picUrl }}" alt=""/>
                    </div>

                    <div class="detail">
                      <p>
                        <span class="title bold" (click)="mycoursedetail(data)">{{ data.title }}</span>
                        <button style="margin-left: 20px;" nz-button nzType="primary" nzDanger (click)="mycourseDel(data)"> 取消收藏</button>
                      </p>
                      <p class="text">
                        <span>热力值: {{ data.hotValue }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>浏览次数: {{ data.score }}</span>
                      </p>
                    </div>
                    <div class="right">
                      <nz-spin class="center" nzSimple *ngIf="busy"></nz-spin>
                    </div>
                  </div>
                  <nz-divider></nz-divider>
                </div>
              </nz-tab>
              <nz-tab nzTitle="资讯" (nzClick)="handletabset(5)">
                <div *ngFor="let data of myInfoPage">
                  <div class="college">
                    <div class="left">
                      <img src="{{ data.picUrl }}" alt=""/>
                    </div>

                    <div class="detail">
                      <p>
                        <span class="title bold"  (click)="myInfodetail(data)">{{ data.title }}</span>
                        <button style="margin-left: 20px;" nz-button nzType="primary" nzDanger (click)="myInfoDel(data)"> 取消收藏</button>
                      </p>
                      <p class="text">
                        <span>热力值: {{ data.hotValue }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>浏览次数: {{ data.score }}</span>
                      </p>
                    </div>
                    <div class="right">
                      <nz-spin class="center" nzSimple *ngIf="busy"></nz-spin>
                    </div>
                  </div>
                  <nz-divider></nz-divider>
                </div>
              </nz-tab>
            </nz-tabset>
          </div>
        </div>
        <div class="college-major" *ngIf="titleShow === '我是家长'">
          <nz-table #basicTable0 [nzData]="data">
            <thead>
              <tr>
                <td align="center" class="dark bold">序号</td>
                <td align="center" class="dark bold">姓名</td>
                <td align="center" class="dark bold">账号</td>
                <td align="center" class="dark bold">学校</td>
                <td align="center" class="dark bold">班级</td>
                <td align="center" class="dark bold">绑定状态</td>
<!--                <td align="center" class="dark bold">-->
<!--                  <button nz-button nzType="primary" (click)="bingStudent()">-->
<!--                    绑定学生-->
<!--                  </button>-->
<!--                </td>-->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of basicTable0.data; let idx = index">
                <td align="center" class="dark">{{ idx + 1 }}</td>
                <td align="center" class="dark">{{ data.name }}</td>
                <td align="center" class="dark">{{ data.code }}</td>
                <td align="center" class="dark">{{ data.graduateschool }}</td>
                <td align="center" class="dark">{{ data.className }}</td>
                <td align="center" class="dark">
                  {{ data.activated ? "成功" : "失败" }}
                </td>
                <td align="center">
                  <button
                    nz-button
                    class="m-r"
                    nzType="primary"
                    (click)="viewStudentDetail(data.code)"
                  >
                    学生详情
                  </button>
                  <a
                    nz-popconfirm
                    nzPopconfirmTitle="确定解除绑定吗?"
                    nzPopconfirmPlacement="bottom"
                    (nzOnConfirm)="removeBindConfirm(data.code)"
                  >
<!--                    <button-->
<!--                      *ngIf="data.activated"-->
<!--                      nz-button-->
<!--                      nzDanger-->
<!--                      nzType="primary"-->
<!--                    >-->
<!--                      解除绑定-->
<!--                    </button>-->
                  </a>
                  <!-- <button
                    nz-button
                    nzType="primary"
                    nzDanger
                    nz-popconfirm
                    nzPopconfirmTitle="确定要删除么？"
                    nzPopconfirmPlacement="bottom"
                    (nzOnConfirm)="deleteCollegeMajor(data.id)"
                    nzPopconfirmPlacement="bottomLeft"
                  >
                    删除
                  </button> -->
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
        <div
          class="college-major"
          *ngIf="titleShow === '我的志愿表' || titleShow === '孩子的志愿表'"
        >
          <nz-table #basicTable0 [nzData]="data">
            <thead>
              <tr>
                <td align="center" class="dark bold">序号</td>
                <td align="center" class="dark bold">课程</td>
                <td align="center" class="dark bold">成绩</td>
                <td align="center" class="dark bold">时间</td>
                <td align="center" class="dark bold"></td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of basicTable0.data; let idx = index">
                <td align="center" class="dark">{{ idx + 1 }}</td>
                <td align="center" class="dark bold">{{ data.courses }}</td>
                <td align="center" class="dark bold">{{ data.score }}</td>
                <td align="center" class="dark">{{ data.time }}</td>
                <td align="center">
                  <button
                    nz-button
                    nzType="primary"
                    (click)="showCollegeMajor(data.id)"
                  >
                    查看
                  </button>
                  <button
                    nz-button
                    nzType="primary"
                    nzDanger
                    nz-popconfirm
                    nzPopconfirmTitle="确定要删除么？"
                    nzPopconfirmPlacement="bottom"
                    (nzOnConfirm)="deleteCollegeMajor(data.id)"
                    nzPopconfirmPlacement="bottomLeft"
                  >
                    删除
                  </button>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>

        <div
          class="exam-report"
          *ngIf="titleShow === '我的测评报告' || titleShow === '孩子的测评报告'"
        >
          <nz-table #basicTable1 [nzData]="data">
            <thead>
              <tr>
                <td align="center" class="dark bold">序号</td>
                <td align="center" class="dark bold">分类</td>
                <td align="center" class="dark bold">类型</td>
                <td align="center" class="dark bold">时间</td>
                <td align="center" class="dark bold"></td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of basicTable1.data; let idx = index">
                <td align="center" class="dark">{{ idx + 1 }}</td>
                <td align="center" class="dark">{{ data.examType }}</td>
                <td align="center" class="dark">{{ data.title }}</td>
                <td align="center" class="dark">{{ data.time }}</td>
                <td align="center">
                  <button
                    nz-button
                    nzType="primary"
                    (click)="showMyExamReport(data.id)"
                  >
                    查看结果
                  </button>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
        <div
          class="collection"
          *ngIf="titleShow === '我的题库' || titleShow === '孩子的题库'"
        >
          <nz-table #basicTable2 [nzData]="data">
            <thead>
              <tr>
                <td align="center" class="dark bold">序号</td>
                <td align="center" class="dark bold">类型</td>
                <td align="center" class="dark bold">标题</td>
                <td align="center" class="dark bold"></td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of basicTable2.data; let idx = index">
                <td align="center" class="dark">{{ idx + 1 }}</td>
                <td align="center" class="dark">{{ data.type }}</td>
                <td align="left" class="dark">{{ data.title }}</td>
                <td align="center">
                  <button nz-button nzType="primary" (click)="goto(data.url)">
                    查看
                  </button>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
        <div
          class="foot-print"
          *ngIf="titleShow === '我的足迹' || titleShow === '孩子的足迹'"
        >
          <nz-table #basicTable3 [nzData]="data">
            <thead>
              <tr>
                <td align="center" class="dark bold">序号</td>
                <td align="center" class="dark bold">分类</td>
                <td align="center" class="dark bold">类型</td>
                <td align="center" class="dark bold">标题</td>
                <td align="center" class="dark bold"></td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of basicTable3.data; let idx = index">
                <td align="center" class="dark">{{ idx + 1 }}</td>
                <td align="center" class="dark">{{ data.mainType }}</td>
                <td align="center" class="dark">{{ data.type }}</td>
                <td align="left" class="dark">{{ data.title }}</td>
                <td align="center">
                  <button nz-button nzType="primary" (click)="goto(data.url)">
                    查看
                  </button>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
        <div class="student-major-choose" *ngIf="titleShow === '学生的志愿表'">
          <nz-table #basicTable4 [nzData]="myStudents">
            <thead>
              <tr>
                <td align="center" class="dark bold">序号</td>
                <td align="center" class="dark bold">姓名</td>
                <td align="center" class="dark bold">类型</td>
                <td align="center" class="dark bold">操作</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of basicTable4.data; let idx = index">
                <td align="center" class="dark">{{ idx + 1 }}</td>
                <td align="center" class="dark">{{ data.name }}</td>
                <td align="center" class="dark">{{ data.type }}</td>
                <td align="center">
                  <button nz-button nzType="primary" (click)="goto(data.url)">
                    查看
                  </button>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>

        <div class="student-exam-report" *ngIf="titleShow === '学生的测评报告'">
          <nz-table #basicTable5 [nzData]="myStudents">
            <thead>
              <tr>
                <td align="center" class="dark bold">序号</td>
                <td align="center" class="dark bold">姓名</td>
                <td align="center" class="dark bold">类型</td>
                <td align="center" class="dark bold">操作</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of basicTable5.data; let idx = index">
                <td align="center" class="dark">{{ idx + 1 }}</td>
                <td align="center" class="dark">{{ data.name }}</td>
                <td align="center" class="dark">{{ data.type }}</td>
                <td align="center">
                  <button nz-button nzType="primary" (click)="goto(data.url)">
                    查看
                  </button>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>

        <div
          class="my-report"
          *ngIf="
            titleShow === '我的志愿深度分析报告' ||
            titleShow === '孩子的志愿深度分析报告'
          "
        >
          <nz-table #basicTable6 [nzData]="deepReports">
            <thead>
              <tr>
                <td align="center" class="dark bold">序号</td>
                <td align="center" class="dark bold">提交时间</td>
                <td align="center" class="dark bold">状态</td>
                <td align="center" class="dark bold">下载</td>
                <td align="center" class="dark bold">说明(必读)</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of basicTable6.data; let idx = index">
                <td align="center" class="dark">{{ idx + 1 }}</td>
                <td align="center" class="dark">{{ data.uploadOn }}</td>
                <td align="center" class="dark">
                  {{ data.sucess ? "成功" : "生成中" }}
                </td>
                <td align="center">
                  <button
                    *ngIf="data.sucess"
                    nz-button
                    nzType="primary"
                    (click)="down(data.id)"
                  >
                    下载
                  </button>
                </td>
                <td align="center">
                  <a
                    nz-button
                    nzType="default"
                    nzDanger
                    href="https://resources.delesson.cn/agreement/explain.pdf"
                    target="_blank"
                    >查看说明</a
                  >
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>
    <nz-modal
      [(nzVisible)]="isVisible"
      nzTitle="绑定学生"
      (nzOnCancel)="handleCancel()"
      (nzOnOk)="handleOk()"
    >
      <ng-container *nzModalContent>
        <div class="flex">
          <span>学生账户：</span>
          <input
            nz-input
            placeholder="请输入学生账户"
            [(ngModel)]="studentCode"
          />
        </div>
      </ng-container>
    </nz-modal>
  </div>
</section>

<nz-modal
  nzWidth="960px"
  [(nzVisible)]="showExamReport"
  nzTitle="评测结果分析"
  (nzOnCancel)="closeExamReport()"
>
  <ng-container *nzModalContent>
    <nz-tabset (nzSelectedIndexChange)="tabChange($event)">
      <nz-tab nzTitle="评测结果">
        <p class="title bold">{{ currentReport.title }}</p>
        <div class="know">
          <p class="text" *ngFor="let exp of currentReport.explains">
            {{ exp.label }} : {{ exp.explain }}<br />
          </p>
        </div>
      </nz-tab>
      <nz-tab nzTitle="结果对比" *ngIf="showSecondTab()">
        <div id="echart"></div>
      </nz-tab>
    </nz-tabset>
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="closeExamReport()">关闭</button>
  </div>
</nz-modal>

<nz-modal
  nzWidth="960px"
  [(nzVisible)]="showCollegeMajorModal"
  nzTitle="志愿报考数据"
  (nzOnCancel)="closeCollegeMajor()"
>
  <ng-container *nzModalContent>
    <div class="title dark">
      <span>选择考试科目：&nbsp;</span
      ><span class="bold">{{ myCollegeMajor?.xuanke.courses }}</span>
      <span>&nbsp; &nbsp; 成绩：&nbsp;</span
      ><span class="bold">{{ myCollegeMajor?.xuanke.score }}</span>
      <span class="choice">&nbsp; &nbsp; 选择志愿为结果为：</span>
    </div>

    <div class="univs">
      <dic class="univ" *ngFor="let detail of currMajorPage">
        <app-major-choose
          [major]="detail"
          [showRight]="false"
        ></app-major-choose>
        <nz-divider></nz-divider>
      </dic>
    </div>

    <nz-pagination
      class="right"
      [(nzPageIndex)]="pageIndex"
      [nzTotal]="this.myCollegeMajor?.detail.length"
      (nzPageIndexChange)="gotoPage()"
    ></nz-pagination>
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="closeCollegeMajor()">
      关闭
    </button>
  </div>
</nz-modal>

<app-footer></app-footer>
