import { Component, OnInit } from '@angular/core';
import { UserService } from './service/user.service';
import { Title } from '@angular/platform-browser';
import { environment } from '~/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit{

  constructor(private userService: UserService, private titleService: Title){
  }

  async ngOnInit() {
    this.titleService.setTitle(environment.title);
  }
}
