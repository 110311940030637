import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';;
import { UserService } from 'src/app/service/user.service';
import { F1d1Data } from '~/app/shared/enroll.typs';

@Component({
  selector: 'app-f1d1',
  templateUrl: './f1d1.component.html',
  styleUrls: ['./f1d1.component.scss'],
})
export class F1d1Component implements OnInit, AfterViewInit,  OnChanges {
  @Input() year: string;
  @Input() data: F1d1Data[];
  province: string;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.province = this.userService.province();
  }

  ngAfterViewInit(): void {
    const option = this.getOptions();
    const chart = echarts.init(document.getElementById('echart'));
    chart.setOption(option, true);
  }

  getOptions() {
    const xAxis = [];
    const yAxis = [];
    const step = 20;

    for (let i = 100; i < 750; i += step) {
      let dt = this.data
        .filter((c) => c.scoreMin >= i - step/2 && c.scoreMin < i + step/2)
        .map((c) => c.amountScore);
      if (dt && dt.length > 0) {
        let sum = dt.reduce((s, d) => s + d);
        xAxis.push(i.toString());
        yAxis.push(sum);
      }
    }

    return {
      color: ['#6D75F1'],
      tooltip: {
        show: true,
        formatter: function (params) {
          return (params["name"]-10).toString() + '~' +(params["name"] - (-9)).toString() +"分，共" + params["value"] + '人';
        },
      },
      xAxis: {
        type: 'category',
        data: xAxis,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          type: 'bar',
          data: yAxis,
        },
      ],
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const option = this.getOptions();
    const chart = echarts.init(document.getElementById('echart'));
    chart.setOption(option, true);
  }
}
