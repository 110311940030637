import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-bulletin',
  templateUrl: './bulletin.component.html',
  styleUrls: ['./bulletin.component.scss'],
})
export class BulletinComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() url: string;

  content: string;
  showLength = 1;
  timer: any;

  constructor() {}

  ngOnInit(): void {
    this.timer = setInterval(() => {
      this.setContent();
    }, 250);
  }

  setContent() {
    if (this.showLength++ >= this.title.length) {
      this.showLength = 0;
    }

    this.content = this.title.substring(0, this.showLength);
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }
}
