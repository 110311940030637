import { Component, OnInit } from '@angular/core';
import { environment } from '~/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  copyRight = '©2020-2023 北京德立信教育咨询有限公司 版权所有';
  icpNumber = '京ICP备2023002181号-1';
  anquanCode = '11010502052072';
  anquanNumber = '京公网安备11010502052072号';

  constructor() {
    if (environment.appId === '000000'){
      this.copyRight = '©2020-2023 北京德立信教育咨询有限公司 版权所有';
      this.icpNumber = '京ICP备2023002181号-1';
      this.anquanCode = '11010502052072';
      this.anquanNumber = '京公网安备11010502052072号';
    } else if (environment.appId === '370001') {
      this.copyRight = '©2020-2023 山东华鲁中原教育科技集团有限公司 版权所有';
      this.icpNumber = '鲁ICP备2023015310-1';
      this.anquanCode = '37011202001965';
      this.anquanNumber = '鲁公网安备37011202001965号';
    } else{
      this.copyRight = '';
      this.icpNumber = '';
      this.anquanCode = '';
      this.anquanNumber = '';
    }
  }

  ngOnInit(): void {}
}
