import {Injectable, NgModule} from '@angular/core';
import {RouterModule, Routes, CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { HomeComponent } from './layout/home/home.component';
import { CareerComponent } from './layout/career/career.component';
import { StudyComponent } from './layout/study/study.component';
import { PsychologyComponent } from './layout/psychology/psychology.component';
import { MineComponent } from './layout/mine/mine.component';
import { ListMajorByCoursesComponent } from './layout/list-major-by-courses/list-major-by-courses.component';
import { IntelligentChooseComponent } from './layout/intelligent-choose/intelligent-choose.component';
import { OneKeyChooseComponent } from './layout/one-key-choose/one-key-choose.component';
import { ListCourseByCollegeComponent } from './layout/list-course-by-college/list-course-by-college.component';
import { CareerTestComponent } from './layout/career-test/career-test.component';
import { TestComponent } from './layout/test/test.component';
import { LoginGuard } from './guard/login.guard';
import { StudyTestComponent } from './layout/study-test/study-test.component';
import { ListCourseByMajorComponent } from './layout/list-course-by-major/list-course-by-major.component';
import { EncMajorComponent } from './layout/enc-major/enc-major.component';
import { EncMyMajorComponent } from './layout/enc-my-major/enc-major.component';
import { EncProfessionComponent } from './layout/enc-profession/enc-profession.component';
import { EncMyProfessionComponent } from './layout/enc-my-profession/enc-profession.component';
import { EnrollDataComponent } from './layout/enroll-data/enroll-data.component';
import { LoginComponent } from './layout/login/login.component';
import { LoginRegistComponent } from './layout/login-regist/login-regist.component';
import { LoginRegistInfoComponent } from './layout/login-regist-info/login-regist-info.component';
import { DownloadComponent } from './layout/download/download.component';
import { MyComponent } from './layout/my/my.component';
import { AttentionPageComponent } from './layout/attention-page/attention-page.component';
import { EncUniversityComponent } from './layout/enc-university/enc-university.component';
import { EncMyUniversityComponent } from './layout/enc-my-university/enc-university.component';
import { VideoMoreComponent } from './layout/video-more/video-more.component';
import { PsychologyTestComponent } from './layout/psychology-test/psychology-test.component';
import { ExamListComponent } from './layout/study/exam-list/exam-list.component';
import { SearchComponent } from './layout/search/search.component';
import { SelectMajorComponent } from './layout/select-major/select-major.component';
import { SelectProfessionComponent } from './layout/select-profession/select-major.component';
import { LitreLearnComponent } from './layout/litre-learn/litre-learn.component'
import { ArchivesComponent } from './layout/archives/archives.component'
import { CareerExamComponent } from './layout/career-exam/career-exam.component'
import { CareerGoalComponent } from './layout/career-goal/career-goal.component'
import { CareerProfessionComponent } from './layout/career-profession/career-profession.component'
import { CareerEnrollComponent } from './layout/career-enroll/career-enroll.component'
import { VolunteerSelectionComponent } from './layout/volunteer-selection/volunteer-selection.component'
import { VolunteerReportComponent } from './layout/volunteer-report/volunteer-report.component'
import { ScoreArchiveComponent } from './layout/score-archives/score-archives.component'
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'download', component: DownloadComponent},
  { path: 'login', component: LoginComponent },
  { path: 'login-reg', component: LoginRegistComponent },
  { path: 'login-reg-info', component: LoginRegistInfoComponent },
  { path: 'home', component: HomeComponent, canActivate: [LoginGuard] },
  { path: 'attention/:id', component: AttentionPageComponent, canActivate: [LoginGuard] },
  { path: 'videos/:video-type', component: VideoMoreComponent, canActivate: [LoginGuard] },
  { path: 'career', component: CareerComponent, canActivate: [LoginGuard] },
  { path: 'study', component: StudyComponent, canActivate: [LoginGuard] },
  { path: 'exam-list', component: ExamListComponent, canActivate: [LoginGuard] },
  { path: 'my', component: MyComponent, canActivate: [LoginGuard] },
  { path: 'enroll-data', component: EnrollDataComponent, canActivate: [LoginGuard] },
  { path: "enc-univ", component: EncUniversityComponent, canActivate: [LoginGuard] },
  { path: "enc-my-univ", component: EncMyUniversityComponent, canActivate: [LoginGuard] },
  { path: "enc-major", component: EncMajorComponent, canActivate: [LoginGuard] },
  { path: "enc-my-major", component: EncMyMajorComponent, canActivate: [LoginGuard] },
  { path: "enc-profession", component: EncProfessionComponent, canActivate: [LoginGuard] },
  { path: "enc-my-profession", component: EncMyProfessionComponent, canActivate: [LoginGuard] },
  { path: "select-major", component: SelectMajorComponent, canActivate: [LoginGuard] },
  { path: "select-profession", component: SelectProfessionComponent, canActivate: [LoginGuard] },
  { path: "litre-learn", component: LitreLearnComponent, canActivate: [LoginGuard] },
  { path: "archives", component: ArchivesComponent, canActivate: [LoginGuard] },
  { path: "search/:str", component: SearchComponent, canActivate: [LoginGuard] },
  {
    path: 'psychology',
    component: PsychologyComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'psychology-test',
    component: PsychologyTestComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'mine',
    component: MineComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'list-major-by-courses',
    component: ListMajorByCoursesComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'list-course-by-college',
    component: ListCourseByCollegeComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'list-course-by-major',
    component: ListCourseByMajorComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'intelligent-choose',
    component: IntelligentChooseComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'onekey-choose',
    component: OneKeyChooseComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'career-test',
    component: CareerTestComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'study-test',
    component: StudyTestComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'career-exam',
    component: CareerExamComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'career-goal',
    component: CareerGoalComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'career-profession',
    component: CareerProfessionComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'career-enroll',
    component: CareerEnrollComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'volunteer-selection',
    component: VolunteerSelectionComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'volunteer-report',
    component: VolunteerReportComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'score-archives',
    component: ScoreArchiveComponent,
    canActivate: [LoginGuard],
  },
  { path: 'test/:id', component: TestComponent, canActivate: [LoginGuard] },
  { path: '**', component: LoginComponent },
];

// @Injectable()
// export class AuthInterceptorService implements HttpInterceptor  {
//
//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     // 可以在这里添加自定义逻辑来处理请求，例如添加认证token
//     // 假设我们已经有一个名为`getToken`的方法来获取认证token
//     const token = this.getToken();
//     if (token) {
//       const authReq = req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
//       return next.handle(authReq).pipe(
//         tap(event => {
//           if (event instanceof HttpResponse) {
//             // 在这里可以根据响应结果进行相应的处理，例如更新用户状态
//           }
//         })
//       );
//     } else {
//       return next.handle(req);
//     }
//   }
//
//   // 示例方法，实际情况需要根据应用逻辑来获取token
//   getToken(): string {
//     // 这里应该是获取token的逻辑
//     return 'fake-auth-token';
//   }
// }


@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload' }),],
  exports: [RouterModule],
})

export class AppRoutingModule {}
