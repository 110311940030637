<app-header></app-header>

<section>
  <div class="container">
    <app-breadcumb [items]="cumbs"></app-breadcumb>
    <div class="wait" *ngIf="busy"></div>

    <nz-tabset *ngIf="!busy">
      <nz-tab nzTitle="院校" *ngIf="colleges?.length">
        <div *ngFor="let univ of colleges">
          <app-univ [data]="univ" [compare]="false"></app-univ>
          <nz-divider></nz-divider>
        </div>
      </nz-tab>

      <nz-tab nzTitle="专业" *ngIf="majors?.length">
        <div *ngFor="let major of majors">
          <p><span class="bold" (click)="majorDetail(major.code)"> {{ major.name }}</span> <span>层次：{{major.eduLevel==='ben'?'本科':'专科'}}</span> <span>专业代码：{{ major.code }}</span></p>
          <nz-divider></nz-divider>
        </div>
      </nz-tab>

      <nz-tab nzTitle="职业" *ngIf="professions?.length">
        <div *ngFor="let prof of professions">
          <p><span class="bold" (click)="profDetail(prof.code)"> {{ prof.name }}</span> <span>职业代码：{{ prof.code }}</span></p>
          <nz-divider></nz-divider>
        </div>
      </nz-tab>
    </nz-tabset>
  </div>
</section>

<nz-modal
  nzWidth = '960px'
  [(nzVisible)]="showMajor"
  nzTitle="{{major?.name}}"
  (nzOnCancel)="majorClose()"
>
  <ng-container *nzModalContent>
    <nz-tabset (nzSelectedIndexChange)="select($event)">
      <nz-tab nzTitle="专业介绍">
        <p *ngIf='major?.introduction' class="title bold">专业介绍</p>
        <p>{{major?.introduction}}</p>
        <p *ngIf='major?.eduObjective' class="title bold">培养目标</p>
        <p>{{major?.eduObjective}}</p>
        <p *ngIf='major?.eduRequirement' class="title bold">培养要求</p>
        <p>{{major?.eduRequirement}}</p>
        <p *ngIf='major?.subjectRequirement' class="title bold">学科要求</p>
        <p>{{major?.subjectRequirement}}</p>
        <p *ngIf='major?.loreAndAbility' class="title bold">知识能力</p>
        <p>{{major?.loreAndAbility}}</p>
        <p *ngIf='major?.studyDirection || major?.zhuanToBenOrient' class="title bold">{{getDirection()}}</p>
        <p>{{getDirectionDetail()}}</p>
        <p *ngIf='major?.mainCourse' class="title bold">主要课程</p>
        <p>{{major?.mainCourse}}</p>
        <p *ngIf='major?.famousScholar' class="title bold">社会名人</p>
        <p>{{major?.famousScholar}}</p>
      </nz-tab>
      <nz-tab nzTitle="薪资评价" [nzDisabled]="!show('XinZiPJ')">
        <div id="salary"></div>
      </nz-tab>
      <nz-tab nzTitle="职业分析" [nzDisabled]="!show('ZhiYeFX')">
        <div id="zhiye"></div>
      </nz-tab>
      <nz-tab nzTitle="行业分布" [nzDisabled]="!show('HangYeFB')">
        <div id="hangye"></div>
      </nz-tab>
      <nz-tab nzTitle="城市分布" [nzDisabled]="!show('ChengShiFB')">
        <div id="chengshi"></div>
      </nz-tab>
    </nz-tabset>

  </ng-container>

  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="majorClose()">关闭</button>
  </div>
</nz-modal>

<nz-modal
  nzWidth="800px"
  [(nzVisible)]="showProf"
  nzTitle="{{ prof?.name }}"
  (nzOnCancel)="profClose()"
>
  <ng-container *nzModalContent>
    <p class="bold">职业代码: {{ prof?.code }}</p>
    <div [innerHTML]="prof.description"></div>
    <nz-divider></nz-divider>

    <p class="title bold">相关专业</p>
    <div *ngFor="let major of prof.majors">
      <p><span class="text-20 bold">{{ major.name }}</span>  &nbsp; &nbsp;
        专业代码：{{ major.code }} &nbsp; | 学制：{{ major.learnYear }}年 &nbsp;
        | 男女比例 {{ major.mfRatioView }}
      </p>
    </div>
  </ng-container>

  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="profClose()">关闭</button>
  </div>
</nz-modal>

<app-footer></app-footer>
