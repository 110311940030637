import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { isValidMobilePhone, isValidPassword } from 'src/app/utility/common';
import { HttpService } from '~/app/service/http.service';
import { LocalStorageService } from '~/app/service/local-storage.service';
import { environment } from '~/environments/environment';

@Component({
  selector: 'app-login-regist',
  templateUrl: './login-regist.component.html',
  styleUrls: ['./login-regist.component.scss'],
})
export class LoginRegistComponent implements OnInit {
  regForm: FormGroup;

  timer: any;
  time: 60;
  buttonText = '获取验证码';

  agreeUrl = '';
  protectUrl  = '';


  constructor(
    private router: Router,
    private http: HttpService,
    private storage: LocalStorageService,
    private message: NzMessageService,
    private fb: FormBuilder
  ) {
    this.regForm = this.fb.group({
      phone: ['', Validators.required],
      smsCode: ['', Validators.required],
      shenfen: ['', [Validators.required]],
      parentName: ['', [Validators.required]],
      xuejihao: ['', [Validators.required]],
      password: ['', [Validators.required]],
      agree: [true, [Validators.required]],
    });

    this.agreeUrl = 'https://resources.delesson.cn/'+environment.appId+'/agreement.html';
    this.protectUrl = 'https://resources.delesson.cn/'+environment.appId+'/protection.html';
  }

  ngOnInit(): void {
    this.storage.clear();
  }

  async mobileCode() {
    const mobilePhone = this.regForm.value['phone'];

    if (!isValidMobilePhone(mobilePhone)) {
      this.message.error('不是正常的手机号码！');
    } else {

      const action = '/v1/user/mobilephone-test';
      const params = {
        mobilePhone: mobilePhone,
      };
      const success = await this.http.post(action, params);
      if(!success){
        this.message.error('当前手机号码已注册过！');
      }else{
        const action = '/v1/user/send-verify-code';
        const params = {
          mobilePhone: mobilePhone,
        };

        await this.http.post(action, params);
        this.time = 60;
        this.timer = setInterval(() => {
          this.onTimer();
        }, 1000);
      }
    }
  }

  onTimer() {
    this.time--;
    this.buttonText = this.time + '秒';
    if (this.time === 0) {
      clearInterval(this.timer);
      this.timer = null;
      this.time = 60;
      this.buttonText = '获取验证码';
    }
  }

  async submit() {
    if (!this.regForm.value['agree']) {
      this.message.error('请同意用户协议和隐私政策后继续');
      return;
    }

    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }

    const data = {
      xuejihao: this.regForm.value['xuejihao'],
      phone: this.regForm.value['phone'],
      smsCode: this.regForm.value['smsCode'],
      shenfen: this.regForm.value['shenfen'],
      parentName: this.regForm.value['parentName'],
      password: this.regForm.value['password'],
    };
    if (!data.xuejihao || !data.xuejihao.trim()) {
      this.message.error('学籍号不能为空');
      return;
    }
    if (!isValidMobilePhone(data.phone)) {
      this.message.error('手机号码输入错误，请核查');
      return;
    }
    if (!data.smsCode || !data.smsCode.trim()) {
      this.message.error('手机验证码不能为空');
      return;
    }
    if (!data.shenfen || !data.shenfen.trim()) {
      this.message.error('身份不能为空');
      return;
    }
    if (!data.parentName || !data.parentName.trim()) {
      this.message.error('家长姓名不能为空');
      return;
    }
    if (!data.password || !data.password.trim()) {
      this.message.error('密码不能为空');
      return;
    }
    const params ={
      xuejihao:  data.xuejihao,
      mobilePhone: data.phone,
      smsCode: data.smsCode,
      parentName: data.parentName,
      shenfen: data.shenfen,
      password: data.password
    }
    console.log('params',params)


    const action = '/v1/user/verify-code';
    const params1 = {
      code:  data.smsCode,
      mobilePhone: data.phone,
    };
    const success = await this.http.post(action, params1);

    if (!success) {
      this.message.error('短信验证错误，请联系相关人员');
    } else {
      // TODO 注册并绑定学生
      const action1 = '/v1/user/web_register';
      const success1 = await this.http.post(action1, params);
      if (!success1) {
        this.message.error('注册失败，请联系相关人员');
      }else{
        this.message.info("注册成功，请切换家长身份登录进入系统!");
        this.storage.clear();
        await this.router.navigate(['/login']);
      }
    }
    // if (data.password1 !== data.password2) {
    //   this.message.error('两次输入密码不同，请核查');
    //   return;
    // } else {
    //   const action = '/v1/user/verify-code';
    //   const params = {
    //     code:  data.smsCode,
    //     mobilePhone: data.phone,
    //   };
    //   const success = await this.http.post(action, params);
    //
    //   if (!success) {
    //     this.message.error('短信验证错误，请联系相关人员');
    //   } else {
    //     this.storage.set('phone', data.phone);
    //     this.storage.set('password', data.password1);
    //
    //     await this.router.navigate(['/login-reg-info']);
    //   }
    // }



  }
}
