import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from 'src/app/service/user.service';
import { HttpService } from 'src/app/service/http.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-career-exam',
  templateUrl: './career-exam.component.html',
  styleUrls: ['./career-exam.component.scss'],
})
export class CareerExamComponent implements OnInit {
  constructor(
    private message: NzMessageService,
    private userService: UserService,
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  code = this.userService.code();
  careerExamData = []
  async ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if(params.code) {
        this.code = params.code
      }
    });
    this.fetchCareer()
  }
  // 测评报告
  async fetchCareer() {
    const action = `/v1/career-exam/list-exam-result`;
    const params = {
      userCode: this.code,
    };
    const data: any = await this.http.post(action, params);
    this.careerExamData = data;
  }
}
