import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from 'src/app/service/http.service';
import { ExamData, Question, ExamResult } from '~/app/shared/testdesc.type';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {
  id: number;
  data: ExamData = null;
  questions: Question[] = [];
  index: number = 0;
  current: Question;
  knowledges: string[] = [];
  canSubmit = false;
  examResult: ExamResult;
  showAnswer = false;

  cumbs = ['首页', '测评'];

  constructor(
    private http: HttpService,
    private message: NzMessageService,
    private route: ActivatedRoute) {
    this.id = this.route.snapshot.params['id'];
  }

  async ngOnInit() {
    this.examResult = null;
   await this.fetchData();
  }

  async fetchData() {
    const action = '/v1/career-exam/find';

    const params = {
      id: this.id,
    };

    this.data = await this.http.post(action, params);
    if (!this.data) {
      this.message.error('数据获取失败');
      return;
    } else {
      this.cumbs.push(this.data.type);
      this.cumbs.push(this.data.title);

      this.knowledges = this.data.knowledge.split('\\r\\n');
      this.data.sections.forEach((c) => {
        this.questions = this.questions.concat(c.questions);
      });

      this.index = 0;
      this.current = this.questions[0];
    }
  }

  next() {
    if (this.index === this.questions.length - 1) {
      return;
    }

    this.current = this.questions[++this.index];
  }

  prev() {
    if (this.index === 0) {
      return;
    }

    this.current = this.questions[--this.index];
  }

  submit(score: number, idx: number) {
    this.current.score = score;
    this.canSubmit = idx === this.questions.length - 1;
    this.next();
  }

  async submitExam() {
    const action = '/v1/career-exam/submit';
    this.examResult = await this.http.post(action, this.data);

    this.showAnswer = true;
  }

  showSecondTab(): boolean {
    return this.examResult.explains.length >= 3;
  }

  getEchartOptions() {
    const explains = this.examResult.explains.sort((a, b) => b.score - a.score);

    const truncted = explains.slice(0, 5);
    const indicator = [];

    for (const item of truncted) {
      const rader = {
        name: item.label,
        max: truncted[0].score,
      };
      indicator.push(rader);
    }

    const value = truncted.map((c) => c.score);

    return {
      radar: {
        indicator: indicator,
      },
      series: [
        {
          type: 'radar',
          data: [{ name: '主要结果对比', value: value }],
        },
      ],
    };
  }

  tabChange($event) {
    if ($event !== 1) {
      return;
    }

    const option = this.getEchartOptions();
    const chart = echarts.init(document.getElementById('echart'));
    chart.setOption(option, true);
  }

  handleCancel() {
    this.showAnswer = false;
  }
}
