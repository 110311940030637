import {Component, Injectable, OnInit, SecurityContext} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {HttpService} from 'src/app/service/http.service';
import {LocalStorageService} from 'src/app/service/local-storage.service';
import {UserService} from 'src/app/service/user.service';
import {PriceData} from '~/app/shared/payprice.type';
import {Topics} from '~/app/shared/topics.type';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {
  canSelectCourse,
  family2StudentCode,
  teacher2StudentCode,
} from '~/app/utility/domain';
import {environment} from '~/environments/environment';
import {isValidCourseSelection} from '~/app/utility/course-util';
import {DataService} from "~/app/layout/data.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {isValidMobilePhone} from "~/app/utility/common";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  regForm: FormGroup;
  buttonText = '获取验证码';
  time: 60;
  timer: any;
  isSendMsg = true;

  physics = '物理';
  physicsChecked = false;
  physicsUrl = 'physics.png';

  chemistry = '化学';
  chemistryChecked = false;
  chemistryUrl = 'chemistry.png';

  biology = '生物';
  biologyChecked = false;
  biologyUrl = 'biology.png';

  history = '历史';
  historyChecked = false;
  historyUrl = 'history.png';

  geography = '地理';
  geographyChecked = false;
  geographyUrl = 'geography.png';

  politics = '政治';
  politicsChecked = false;
  politicsUrl = 'politics.png';

  score: Number = null;

  data: any;
  bulletin: any;
  careerCourses: Topics[] = [];
  careerInterviews: Topics[] = [];
  collegeShows: Topics[] = [];
  interviews: Topics[] = [];
  shows: Topics[] = [];
  reportDate: any;
  isVisible: boolean;


  isVisibleJh = false;
  isVisibleJhCode = false;
  isVisibleJhUser = false;
  isVisibleJhUserOk = false;
  qrResult = undefined;

  attentions: Topics[] = [];

  hotTopics: Topics[] = [];

  private imgPath = '../../../../assets/logos/';

  courseSelected: Map<string, boolean> = new Map();
  mode: string = 'C';

  constructor(
    private messageService: NzMessageService,
    private storage: LocalStorageService,
    private userService: UserService,
    private router: Router,
    private http: HttpService,
    private sanitizer: DomSanitizer,
    private dataService: DataService,
    private fb: FormBuilder,
    private message: NzMessageService,
  ) {
    this.regForm = this.fb.group({
      phone: ['', Validators.required],
      smsCode: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.initBulletin();
    this.setSelectCourses();
    this.setChecked();
    this.fetchData();
    this.checkReportDate();
    this.mode = this.userService.enrollMode();
    const score: Number = this.storage.get('score');
    this.score = score ? score : 700;

    this.isVisibleJh = localStorage.getItem('isvip') === 'false' || localStorage.getItem('viptype') === '1' ? true : false;
  }

  toSY =()=>{
    window.open('https://www.dlxsygh.com/#/home', '_blank');
  }

  toGK =()=>{
    window.open('https://www.dlxgk.com/#/home', '_blank');
  }

  vipNum() {
    if (localStorage.getItem("isvip") == 'true') {
      if (localStorage.getItem("viptype") == '1') {
        return localStorage.getItem("vipts");
      } else {
        return 'VIP有效期: 永久';
      }
    } else {
      return 'VIP已到期';
    }
  }

  hqQrCodeUrl(){
    return this.qrResult;
  }

  isVip() {
    return localStorage.getItem("isvip") == 'true' ? true : false;
  }

  public async handleCancelJh() {
    // 获取班级激活码地址
    const studentCode = window.localStorage.getItem("studentCode");
    const action = "/v1/user/queryQrCode";
    const data = {
      "userCode": studentCode
    };
    const result =  await this.http.post(action, data);
    console.log('result',result);
    if(result){
      const qrData = result;
      const qrAction = "/prod-api/szel/open/card/getH5Url";
      const qrResult =  await this.http.postQr(qrAction, qrData);
      console.log('qrResult',qrResult);
      if(qrResult){
         this.qrResult = qrResult;
         this.isVisibleJhCode = true;
      }
    }

  }

  handleOkJh() {
    console.log('点击了确定按钮...');
    this.isVisibleJhUser = true;
  }

  public async vipJh() {
    const smsCode = this.regForm.value['smsCode'];
    // 激活vip
    const data = {
      salePhone: this.regForm.value['phone'],
      bindParentCode: window.localStorage.getItem("parentCode"),
      bindStudentCode: window.localStorage.getItem("studentCode")
    };
    if (!data.salePhone || !data.salePhone.trim()) {
      this.message.error('手机号不能为空');
      return;
    }
    if (!smsCode || !smsCode.trim()) {
      this.message.error('验证码不能为空');
      return;
    }
    // 判断验证码是否有效
    const action = '/v1/user/verify-code';
    const params1 = {
      code: smsCode.trim(),
      mobilePhone: data.salePhone.trim()
    };
    const success = await this.http.post(action, params1);

    if (!success) {
      this.message.error('短信验证错误，请联系相关人员');
    } else {
      // 激活电话卡
      const action_1 = '/v1/user/JHMobileCard';
      const result =  await this.http.post(action_1, data);
      if(result.code === 200){
        console.log('激活成功，跳转页面');
        this.isVisibleJhUser = false;
        this.isVisibleJhUserOk = true;
      }else {
        this.message.error(result.msg);
      }
    }

  }

  fhsy(){
    this.storage.clear();
    this.router.navigate(['login']);
  }

  private initBulletin() {
    this.bulletin = {
      title:
        '重磅升级，国内首创！高考志愿填报深度分析功能已震撼上线，诚邀您体验全面超越人工1对1志愿填报的超级AI算法，助您金榜题名，马到成功！',
      url: 'https://resources.delesson.cn/agreement/explain.pdf',
    };
  }

  private setSelectCourses() {
    let courses: string | null = this.storage.get('courses');
    if (!courses) {
      courses = '';
    }

    this.physicsChecked = courses.indexOf(this.physics) >= 0;
    this.courseSelected.set(this.physics, this.physicsChecked);

    this.chemistryChecked = courses.indexOf(this.chemistry) >= 0;
    this.courseSelected.set(this.chemistry, this.chemistryChecked);

    this.biologyChecked = courses.indexOf(this.biology) >= 0;
    this.courseSelected.set(this.biology, this.biologyChecked);

    this.historyChecked = courses.indexOf(this.history) >= 0;
    this.courseSelected.set(this.history, this.historyChecked);

    this.geographyChecked = courses.indexOf(this.geography) >= 0;
    this.courseSelected.set(this.geography, this.geographyChecked);

    this.politicsChecked = courses.indexOf(this.politics) >= 0;
    this.courseSelected.set(this.politics, this.politicsChecked);
  }

  async fetchData() {
    let code: string = this.userService.code();
    const grade = this.userService.grade();
    const name = this.userService.name();
    const province = this.userService.province();
    const unitName = this.userService.school();

    if (this.userService.isTeacher()) {
      code = teacher2StudentCode(code);
    } else if (this.userService.isFamily()) {
      code = family2StudentCode(code);
    }

    const param = {
      code: code,
      grade: grade,
      name: name,
      province: province,
      unitName: unitName,
    };

    const action = '/v1/personal/home';
    this.data = await this.http.post(action, param);

    // this.bulletin = this.fetchField('hotNews')[0];
    this.careerCourses = this.fetchField('careerCourses');
    this.interviews = this.fetchField('interviews');
    this.shows = this.fetchField('collegeShows');
    this.attentions = this.fetchField('attentions');
    this.hotTopics = this.fetchField('hotTopics');
  }

  private fetchField(field: string): Topics[] {
    const result = this.data[field];
    return result;
  }

  async checkReportDate() {
    const action = '/v1/enroll2/check/report-date';
    this.reportDate = await this.http.post(action, {});
  }


  courseSelect(course: string) {
    const checked = this.courseSelected.get(course);
    this.courseSelected.set(course, !checked);

    this.setChecked();

    const checkedCount = this.countSelect();
    if (checkedCount > 3) {
      this.messageService.error('选择科目不能多于3门');
      return;
    } else if (
      !canSelectCourse(this.courseSelected, course, this.mode) &&
      !checked
    ) {
      this.messageService.error('物理和历史不能同时选择');
      return;
    }
  }

  private setChecked() {
    this.physicsChecked = this.courseSelected.get(this.physics);
    this.physicsUrl = this.physicsChecked
      ? this.imgPath + 'physics.png'
      : this.imgPath + 'physics-2.png';
    this.chemistryChecked = this.courseSelected.get(this.chemistry);
    this.chemistryUrl = this.chemistryChecked
      ? this.imgPath + 'chemistry.png'
      : this.imgPath + 'chemistry-2.png';
    this.biologyChecked = this.courseSelected.get(this.biology);
    this.biologyUrl = this.biologyChecked
      ? this.imgPath + 'biology.png'
      : this.imgPath + 'biology-2.png';
    this.historyChecked = this.courseSelected.get(this.history);
    this.historyUrl = this.historyChecked
      ? this.imgPath + 'history.png'
      : this.imgPath + 'history-2.png';
    this.geographyChecked = this.courseSelected.get(this.geography);
    this.geographyUrl = this.geographyChecked
      ? this.imgPath + 'geography.png'
      : this.imgPath + 'geography-2.png';
    this.politicsChecked = this.courseSelected.get(this.politics);
    this.politicsUrl = this.politicsChecked
      ? this.imgPath + 'politics.png'
      : this.imgPath + 'politics-2.png';
  }

  countSelect(): number {
    return Array.from(this.courseSelected.values()).filter((c) => c).length;
  }

  private mergeCourse(): string {
    let result = '';

    this.courseSelected.forEach((value, key) => {
      if (value) {
        result = result + ',' + key;
      }
    });

    if (result.length > 0) {
      result = result.substring(1, result.length);
    }

    return result;
  }

  private storeCourses() {
    const courses = this.mergeCourse();
    this.storage.set('courses', courses);
  }

  private storeCoursesAndScore() {
    const courses = this.mergeCourse();
    this.storage.set('courses', courses);
    this.storage.set('score', this.score);
  }

  async simuChoice() {
    if (this.userService.isGuest()) {
      await this.showMPay();
      return;
    } else if (this.countSelect() !== 3) {
      this.messageService.error('需要选择三门课程');
      return;
    } else if (!isValidCourseSelection(this.mode, this.mergeCourse())) {
      this.messageService.error("物理和历史不能同选");
      return;
    } else {
      this.storeCourses();
      this.router.navigate(['list-major-by-courses']);
    }
  }

  handleJump() {
    window.open('https://bk.dlxgk.com#/home?sign-'+ window.btoa( localStorage.getItem("username")+','+localStorage.getItem("pwd")), '_top')
  }

  async courseQuery() {
    if (this.userService.isGuest()) {
      await this.showMPay();
      return;
    }

    this.router.navigate(['list-course-by-college']);
  }

  async intelliChoose() {
    if (this.userService.isGuest()) {
      await this.showMPay();
      return;
    } else if (this.countSelect() !== 3) {
      this.messageService.error('需要选择三门课程');
      return;
    } else if (!this.validScore()) {
      this.messageService.error('输入成绩应该在0~750分之间');
      return;
    } else if (!isValidCourseSelection(this.mode, this.mergeCourse())) {
      this.messageService.error("物理和历史不能同选");
      return;
    } else {
      this.storeCoursesAndScore();
      this.router.navigate(['onekey-choose']);
    }
  }

  async oneKeyChoose() {
    if (this.userService.isGuest()) {
      await this.showMPay();
      return;
    } else if (this.countSelect() !== 3) {
      this.messageService.error('需要选择三门课程');
      return;
    } else if (!this.validScore()) {
      this.messageService.error('输入成绩应该在0~750分之间');
      return;
    } else if (!isValidCourseSelection(this.mode, this.mergeCourse())) {
      this.messageService.error("物理和历史不能同选");
      return;
    } else {
      this.storeCoursesAndScore();
      this.router.navigate(['intelligent-choose']);
    }
  }

  validScore(): boolean {
    const score = this.score ? this.score.valueOf() : -1;
    return score >= 0 && score <= 750;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  showHints = false;

  async generateReport() {
    if (this.reportDate?.locked) {
      this.isVisible = true
      return
    }
    if (this.userService.isGuest()) {
      await this.showMPay();
      return;
    } else if (this.countSelect() !== 3) {
      this.messageService.error('需要选择三门课程');
      return;
    } else if (!this.validScore()) {
      this.messageService.error('输入成绩应该在0~750分之间');
      return;
    } else if (!isValidCourseSelection(this.mode, this.mergeCourse())) {
      this.messageService.error("物理和历史不能同选");
      return;
    } else {
      this.showHints = true;
    }
  }

  cancel() {
    this.showHints = false;
  }

  async confirmReport() {
    this.showHints = false;

    const courses = this.mergeCourse();

    const action = '/v1/enroll2/list-province-report';
    const params = {
      course: courses,
      provinceCode: this.userService.provinceCode(),
      score: this.score,
      userCode: this.userService.code(),
    };

    const result = await this.http.post(action, params);
    if (result === null) {
      await this.showMPay();
    }
  }

  showPay = false;
  prices: PriceData[] = [];
  cur: PriceData = null;

  async showMPay() {
    this.cur = null;

    const action = '/v1/pay-ali/pay-sys';
    const param = {
      code: environment.appId,
    };

    this.prices = await this.http.post(action, param);
    this.showPay = true;
  }

  async pay(cur: PriceData) {
    this.cur = cur;

    const action = '/v1/pay-ali/qrcode';
    const param = {
      appId: environment.appId,
      id: cur.id,
      userCode: this.userService.code(),
    };

    const zhifu = await this.http.post(action, param);
    const zf = zhifu['codeStr'];
    if (!zf) {
      this.messageService.error(
        '您无权再次购买此服务，如有问题，请联系服务人员！'
      );
    } else {
      this.setFrameContent(zf);
    }

    if (cur.name.includes('志愿')) {
      this.hintMessage =
        '郑重提示：高三志愿填报专属会员（自充值之日起1年内，可畅享平台所有功能，“志愿深度分析报告”功能不受时间限制，建议高三学生或家长购买）';
    } else {
      this.hintMessage =
        '郑重提示：生涯规划教育会员（尽享平台所有功能，但为保障当年高考学生的志愿填报，每年6月1日至9月1日期间暂不能申请生成“志愿深度分析报告”';
    }
  }

  hintMessage =
    '郑重提示：高三志愿填报专属会员（自充值之日起1年内，可畅享平台所有功能，“志愿深度分析报告”功能不受时间限制，建议高三学生或家长购买）';

  setFrameContent(zhifu) {
    //const zf = this.sanitizer.bypassSecurityTrustHtml(zhifu);
    const div = document.getElementById('right');
    div.innerHTML =
      '<iframe id="alipayFrame" height="100%" frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no" allowtransparency="yes"></iframe>';
    const iframe = document.getElementById('alipayFrame');
    const window = (<HTMLIFrameElement>iframe).contentWindow;
    const ed = window.document;

    ed.open();
    ed.write(zhifu);
    ed.close();

    ed.designMode = 'on';

    this.messageService.info(
      '扫码支付成功后，请退出系统重新登录，尽享会员服务！'
    );
  }

  payClose() {
    this.showPay = false;
  }

  cardNo: string = '';
  password: string = '';

  async paybyCard() {
    if (this.cardNo.trim() === '' || this.password.trim() === '') {
      this.messageService.error('充值卡号和验证码不能为空');
      return;
    } else {
      const action = '/v1/pay-ali/pay-via-code_appid';
      const params = {
        appId: environment.appId,
        code: this.cardNo,
        phoneId: this.userService.mobilePhone(),
        userCode: this.userService.code(),
        verifyCode: this.password,
      };

      const result = await this.http.post(action, params);

      if (!result) {
        this.messageService.info(
          '充值成功后，请退出系统重新登录，尽享会员服务！'
        );
      }

      this.showPay = false;
    }
  }

  handleFootCancel() {
    console.log('关闭', this.dataService.isVip);
    if (localStorage.getItem("isvip") === 'true') {
      this.isVisibleJh = false;
    }
  }

  handleFootCancelCode() {
    this.isVisibleJhCode = false;
  }

  handleFootCancelUser() {
    this.isVisibleJhUser = false;
  }

  async mobileCode() {
    if (!this.isSendMsg) {
      return;
    }

    const mobilePhone = this.regForm.value['phone'];

    if (!isValidMobilePhone(mobilePhone)) {
      this.message.error('不是正常的手机号码！');
    } else {
      this.isSendMsg = false;
      const action = '/v1/user/mobile-test';
      const params = {
        mobilePhone: mobilePhone,
      };
      const success = await this.http.post(action, params);
      if (!success) {
        this.message.error('当前手机号码已注册过！');
        this.isSendMsg = true;
      } else {
        const action = '/v1/user/send-verify-code';
        const params = {
          mobilePhone: mobilePhone,
        };

        await this.http.post(action, params);
        this.time = 60;
        this.timer = setInterval(() => {
          this.onTimer();
        }, 1000);
      }
    }
  }

  onTimer() {
    this.time--;
    this.buttonText = this.time + '秒';
    if (this.time === 0) {
      this.isSendMsg = true;
      clearInterval(this.timer);
      this.timer = null;
      this.time = 60;
      this.buttonText = '获取验证码';
    }
  }

}
