<section>
  <div class="container">
    <nz-card
      [nzTitle]="element.createTime"
      class="m-b"
      *ngFor="let element of colleges"
    >
      <nz-descriptions [nzTitle]="item.name" *ngFor="let item of element.list">
        <nz-descriptions-item
          [nzTitle]="item.type == '专业' ? '目标大学' : '目标专业'"
          *ngFor="let child of item.list"
        >
          <span *ngIf="item.type == '专业'">{{ child.myCollege }}</span>
          <span *ngIf="item.type == '大学'">{{ child.myMajor }}</span>
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-card>
  </div>
</section>
