import { Component, Input, OnInit } from '@angular/core';
import { Topics } from '~/app/shared/topics.type';

@Component({
  selector: 'app-card-desc',
  templateUrl: './card-desc.component.html',
  styleUrls: ['./card-desc.component.scss']
})
export class CardDescComponent implements OnInit {
  @Input() data: Topics;

  constructor() { }

  ngOnInit(): void {
  }

  hot($event){
    console.log($event);
  }
}
