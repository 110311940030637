<app-header> </app-header>
<section>
  <div class="container">
    <div class="wait" *ngIf="busy"></div>
    <div class="crumbs">
      <app-breadcumb *ngIf="!busy" [items]="cumbs"></app-breadcumb>
      <div class="search">
        <button nz-button nzType="primary" (click)="back()">返回</button>
        <button nz-button nzType="primary" [nzLoading]="loading" (click)="save()">保存</button>
      </div>
    </div>
    <div class="select border" *ngIf="!busy">
      <div class="target" *ngFor="let item of colleges; let idx = index">
        <div class="title">
          <span class="name">{{ item.myCollege }}</span>
          <span class="num">第{{ idx + 1 }}目标</span>
          <span *ngIf="idx != 0" class="text" (click)="moveUp(item, idx)">上移</span>
          <span *ngIf="idx < colleges.length - 1" (click)="moveDown(item, idx)" class="text">下移</span>
          <span
            class="del"
            nz-icon
            nzType="close-circle"
            nzTheme="fill"
            (click)="deleteCompare(idx)"
          ></span>
        </div>
        <div class="choose">
          <div *ngFor="let tmp of item.major; let i = index">
            <span>{{ tmp.name }}：</span>
            <nz-select
              [(ngModel)]="tmp.value"
              nzShowSearch
              nzAllowClear
              (ngModelChange)="handleSelect($event, item.options, tmp)"
              nzPlaceHolder="请选择专业"
            >
              <nz-option
                [nzLabel]="option?.major"
                [nzValue]="option?.major"
                *ngFor="let option of item.options"
              ></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
