import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from 'src/app/service/http.service';
import { LocalStorageService } from 'src/app/service/local-storage.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss']
})
export class MainContentComponent implements OnInit {
  showLogin = false;
  loginForm: FormGroup;

  constructor(private router: Router,
    private userService: UserService,
    private message: NzMessageService,
    private fb: FormBuilder) {
    this.loginForm = this.fb.group({
      userName: ['', Validators.required],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  login() {
    this.showLogin = true;
  }

  handleCancel() {
    this.showLogin = false;
  }

  async submit() {
    const data = {
      'userName': this.loginForm.value['userName'],
      'password': this.loginForm.value['password']
    }

    const login = await this.userService.login(data.userName, data.password);
    if (!login){
      this.message.error("登录失败，请检查用户名及密码");
      return;
    }


    this.showLogin = false;
    await this.router.navigate(['/home']);
  }
}
