import { Component, OnInit } from '@angular/core';
import { HttpService } from '~/app/service/http.service';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LocalStorageService } from '~/app/service/local-storage.service';
import { Topics } from '~/app/shared/topics.type';

@Component({
  selector: 'app-video-more',
  templateUrl: './video-more.component.html',
  styleUrls: ['./video-more.component.scss']
})
export class VideoMoreComponent implements OnInit {
  cumbs = ['首页'];
  type = "";
  index? = 0;

  types = [];
  data: Topics[]= [];
  group: Topics[][] = [];

  constructor(private http: HttpService,
    private storage: LocalStorageService,
    private message: NzMessageService,
    private route: ActivatedRoute) {
    this.type = this.route.snapshot.params['video-type'];
    this.cumbs.push(this.type);
  }

  async ngOnInit() {
    await this.fetchData();
    this.groupByType();
  }

  async fetchData() {
    let action = '/v1/resource/list-by-type';
    let params = {
      mainType: this.type,
      type: '',
    };

    this.data = await this.http.post(action, params);
    if (this.data){
    this.getTypes();
    this.groupByType();
    this.setCurrPage();
    }
  }

  getTypes(){
    let types = this.data.map(c=>c.type);
    let result = [...new Set(types)];
    this.types = result;
  }

  groupByType(){
    for (let type of this.types){
      let filtered = this.data.filter(c=>c.type === type);
      filtered.forEach(c=>this.setPicUrl(c, type));
      this.group.push(filtered);
    }
  }

  setPicUrl(topic: Topics, type: string){
    if (!topic.picUrl){
      topic.picUrl = "../../../assets/courses/"+type+".png";
    }
  }

  setCurrPage(){
    let currPage = this.storage.get("currPage");
    if (currPage){
      this.index = this.types.findIndex(c=>c == currPage);
      if (this.index < 0){
        this.index = 0;
      }
    }
  }
}
