import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from 'src/app/service/user.service';
import { HttpService } from 'src/app/service/http.service';
import { Router, ActivatedRoute } from '@angular/router';

interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'app-archives',
  templateUrl: './archives.component.html',
  styleUrls: ['./archives.component.scss'],
})
export class ArchivesComponent implements OnInit {
  constructor(
    private message: NzMessageService,
    private userService: UserService,
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  code = this.userService.code();
  info = {
    studentName: undefined,
    sex: undefined,
    studentAccount: undefined,
    schoolName: undefined,
    gradeName: undefined,
    className: undefined,
    subjectSelection: '',
  };
  target = {
    myCollege: undefined,
    myMajor: undefined,
    cityName: undefined,
    eduLevel: undefined,
    myJob: undefined,
    myEnroll: undefined,
  };
  myCourses = ''
  courses = [
    {
      value: '1',
      label: '语文',
    },
    {
      value: '2',
      label: '数学',
    },
    {
      value: '3',
      label: '外语',
    },
    {
      value: '4',
      label: '政治',
    },
    {
      value: '5',
      label: '历史',
    },
    {
      value: '6',
      label: '地理',
    },
    {
      value: '7',
      label: '物理',
    },
    {
      value: '8',
      label: '化学',
    },
    {
      value: '9',
      label: '生物',
    },
  ];
  selectionData = [];
  careerExamData = [];
  footprintData = [];
  reportData = [];
  scoreData = [];
  favorite = {
    colleges: [],
    majors: [],
    professions: [],
    resources: [],
  };
  isVisible = false;
  isfavorite = false;
  listOfData: Person[] = [
    {
      key: '1',
      name: '',
      age: 0,
      address: '',
    },
    {
      key: '2',
      name: '',
      age: 0,
      address: '',
    },
    {
      key: '3',
      name: '',
      age: 0,
      address: '',
    },
  ];
  filterCourse(subjectSelection) {
    if (subjectSelection) {
      const course = subjectSelection.split(',');
      const subject = course?.reduce((prev, cur, index) => {
        const temp: any = this.courses.find((item) => cur == item.value);
        if (temp) {
          prev.push(temp.label);
        }
        return prev;
      }, []);
      return subject?.join('、');
    }
    return '';
  }
  async ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.code) {
        this.code = params.code;
      }
    });
    await this.fetchSubject()
    await this.getXueqiListByCode();
    await this.fetchData();
    await this.fetchParents();
    await this.fetchChoice();
    await this.fetchScore();
    await this.fetchMySelection();
    await this.fetchReportList();
    await this.fetchCareer();
    await this.fetchFavorite();
    await this.fetchFootprint();
  }
  handleScoreArchives() {
    this.router.navigate(['/score-archives'], {
      queryParams: { code: this.code },
    });
  }
  handleVolunteerSelection() {
    this.router.navigate(['/volunteer-selection'], {
      queryParams: { code: this.code },
    });
  }
  handleVolunteerReport() {
    this.router.navigate(['/volunteer-report'], {
      queryParams: { code: this.code },
    });
  }
  handleCareerExam() {
    this.router.navigate(['/career-exam'], {
      queryParams: { code: this.code },
    });
  }
  handleEnroll() {
    this.router.navigate(['/career-enroll'], {
      queryParams: { code: this.code },
    });
  }
  handleProfession() {
    this.router.navigate(['/career-profession'], {
      queryParams: { code: this.code },
    });
  }
  handleTarget() {
    this.router.navigate(['/career-goal'], {
      queryParams: { code: this.code },
    });
  }
  handlefavorite() {
    this.isfavorite = true;
  }
  handlefavoriteCancel() {
    this.isfavorite = false;
  }
  handleFootprint() {
    this.isVisible = true;
  }
  handleFootCancel(): void {
    this.isVisible = false;
  }
  filteTime(timestamp) {
    const date = new Date(timestamp);
    // 获取年、月、日
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    // 获取时、分、秒
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    return (
      year +
      '-' +
      month +
      '-' +
      day +
      ' ' +
      hours +
      ':' +
      minutes +
      ':' +
      seconds
    );
  }
  scoreTh = []
  scoreTd = []
  async fetchSubject() {
    const action = '/v1/personal/get-my-courses';
    const params = {
      userCode: this.code,
    };
    const data = await this.http.post(action, params);
    this.myCourses = data ? data.courses : ''
    if(this.myCourses.indexOf('政治') >= 0) {
      this.scoreTh.push('政治')
      this.scoreTd.push('politicsScore')
    }
    if(this.myCourses.indexOf('历史') >= 0) {
      this.scoreTh.push('历史')
      this.scoreTd.push('historyScore')
    }
    if(this.myCourses.indexOf('地理') >= 0) {
      this.scoreTh.push('地理')
      this.scoreTd.push('geographyScore')
    }
    if(this.myCourses.indexOf('物理') >= 0) {
      this.scoreTh.push('物理')
      this.scoreTd.push('physicsScore')
    }
    if(this.myCourses.indexOf('化学') >= 0) {
      this.scoreTh.push('化学')
      this.scoreTd.push('chemistryScore')
    }
    if(this.myCourses.indexOf('生物') >= 0) {
      this.scoreTh.push('生物')
      this.scoreTd.push('biologyScore')
    }
  }
  async fetchData() {
    const action = `/app-api/teach/student/getByCode?code=${this.code}`;
    const data: any = await this.http.get(action, 1);
    if (data.code == 200) {
      this.info = data.data;
    }
  }
  parentsList = [];
  // 列出学生所有家长
  async fetchParents() {
    const action = `/v1/user/list-student-parents`;
    const params = {
      userCode: this.code,
    };
    const data: any = await this.http.post(action, params);
    this.parentsList = data;
  }
  // 获取全部我的意向学校专业职业入学通路列表
  async fetchChoice() {
    const action = `/v1/personal/list-my-choice-all`;
    const params = {
      userCode: this.code,
    };
    const { colleges, enrolls, jobs } = await this.http.post(action, params);
    if (colleges?.length > 0) {
      this.target.cityName = colleges[0]?.list[0]?.list[0]?.cityName;
      this.target.myCollege = colleges[0]?.list[0]?.list[0]?.myCollege;
      this.target.myMajor = colleges[0]?.list[0]?.list[0]?.myMajor;
      this.target.eduLevel = colleges[0]?.list[0]?.list[0]?.eduLevel;
    }
    if (enrolls?.length > 0) {
      this.target.myEnroll = enrolls[0]?.myEnroll;
    }
    if (jobs?.length > 0) {
      this.target.myJob = jobs[0]?.myJob;
    }
  }
  filterScore(value) {
    if (value > 0) {
      return `上升${value}`;
    }
    if (value < 0) {
      return `下降${value}`;
    }
    return value;
  }
  // 根据学生code获取学生成绩档案
  async fetchScore() {
    const action = `/app-api/teach/student/getScoreInfoByCodeAndTime?code=${this.code}&pageNo=1&pageSize=10`;
    const data: any = await this.http.get(action, 1);
    if (data.code == 200) {
      this.scoreData = data.data.list;
    }
  }
  // 模拟填报志愿
  async fetchMySelection() {
    const action = `/v1/enroll2/list-my-selection`;
    const params = {
      userCode: this.code,
    };
    const data: any = await this.http.post(action, params);
    this.selectionData = data;
  }
  private saveAs(bdata: Blob, fileName: string, id: any) {
    let u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
    if(isAndroid) {
      //@ts-ignore
      Android.saveAs(id)
      return
    }
    const tmpLink = document.createElement('a');
    const blob = new Blob([bdata], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const objectUrl = URL.createObjectURL(blob);
    tmpLink.href = objectUrl;
    tmpLink.download = fileName;

    tmpLink.click();
    URL.revokeObjectURL(objectUrl);
  }
  async dowload(id) {
    const action = `/v1/enroll2/get-my-report-byid`;
    const params = {
      id,
    };
    const data: any = await this.http.postForBlob(action, params);
    this.saveAs(data, '志愿深度分析报告.xlsx', id);
  }
  // 深度志愿分析报告
  async fetchReportList() {
    const action = `/v1/enroll2/list-my-report-listnew`;
    const params = {
      userCode: this.code,
    };
    const data: any = await this.http.post(action, params);
    this.reportData = data;
  }
  // 学期
  xueqiList = [];
  xueqiValue = [];
  xueqiData = [];
  async getXueqiListByCode() {
    const action = `/app-api/teach/student/getXueqiListByCode?studentCode=${this.code}`;
    const data: any = await this.http.get(action, 1);
    if (data.code == 200) {
      this.xueqiList = data.data;
      if (data.data?.length > 0) {
        this.xueqiValue = data.data[0]?.xqDate;
        this.getScoreTargetInfoByCodeAndTime();
      }
    }
  }
  handleXueqi(e) {
    this.xueqiValue = e
    this.getScoreTargetInfoByCodeAndTime();
  }
  // 目标方向
  xueqifirstcolleges = {
    minScore: null,
    minRank: null,
    myCollege: null,
    myMajor: null,
    year: null,
    amount: null,
  };
  xueqitwocolleges = {
    minScore: null,
    minRank: null,
    myCollege: null,
    myMajor: null,
    year: null,
    amount: null,
  };
  xueqithreecolleges = {
    minScore: null,
    minRank: null,
    myCollege: null,
    myMajor: null,
    year: null,
    amount: null,
  };
  firstText = '';
  firstRankText = '';
  firstAmountText = '';
  twoText = '';
  twoRankText = '';
  twoAmountText = '';
  thereText = '';
  thereRankText = '';
  thereAmountText = '';
  provinceRankingXd = '';
  provinceRankingXdMax = ''
  totalScore = 0;
  async getScoreTargetInfoByCodeAndTime() {
    const action = `/app-api/teach/student/getScoreTargetInfoByCodeAndTime?code=${this.code}&pageNo=1&beginTime=${this.xueqiValue[0]}&endTime=${this.xueqiValue[1]}`;
    const data: any = await this.http.get(action, 1);
    if (data.code == 200) {
      if (data.data.targetInfo.colleges[0]?.list[0]?.list?.length > 0) {
        this.xueqifirstcolleges =
          data.data.targetInfo.colleges[0]?.list[0]?.list[0];
        this.firstText = `${this.xueqifirstcolleges?.myCollege}${this.xueqifirstcolleges?.myMajor}专业${this.xueqifirstcolleges?.year}年最低录取线`;
        this.firstRankText = `${this.xueqifirstcolleges?.myCollege}${this.xueqifirstcolleges?.myMajor}专业${this.xueqifirstcolleges?.year}年最低位次`;
        this.firstAmountText = `${this.xueqifirstcolleges?.myCollege}${this.xueqifirstcolleges?.myMajor}专业${this.xueqifirstcolleges?.year}年录取人数`;
      }
      if (data.data.targetInfo.colleges[1]?.list[0]?.list?.length > 0) {
        this.xueqitwocolleges =
          data.data.targetInfo.colleges[1]?.list[0]?.list[0];
        this.twoText = `${this.xueqitwocolleges?.myCollege}${this.xueqitwocolleges?.myMajor}专业${this.xueqitwocolleges?.year}年最低录取线`;
        this.twoRankText = `${this.xueqitwocolleges?.myCollege}${this.xueqitwocolleges?.myMajor}专业${this.xueqitwocolleges?.year}年最低位次`;
        this.twoAmountText = `${this.xueqitwocolleges?.myCollege}${this.xueqitwocolleges?.myMajor}专业${this.xueqitwocolleges?.year}年录取人数`;
      }
      if (data.data.targetInfo.colleges[2]?.list[0]?.list?.length > 0) {
        this.xueqithreecolleges =
          data.data.targetInfo.colleges[2]?.list[0]?.list[0];
        this.thereText = `${this.xueqithreecolleges?.myCollege}${this.xueqithreecolleges?.myMajor}专业${this.xueqithreecolleges?.year}年最低录取线`;
        this.thereRankText = `${this.xueqithreecolleges?.myCollege}${this.xueqithreecolleges?.myMajor}专业${this.xueqithreecolleges?.year}年最低位次`;
        this.thereAmountText = `${this.xueqithreecolleges?.myCollege}${this.xueqithreecolleges?.myMajor}专业${this.xueqithreecolleges?.year}年录取人数`;
      }
      const provinceRankingXd =
        data.data?.scoreInfo?.total > 0
          ? data.data?.scoreInfo?.list[0].provinceRankingXd
          : 0;
      const provinceRankingXdMax =
        data.data?.scoreInfo?.total > 0
          ? data.data?.scoreInfo?.list[0].provinceRankingXdMax
          : 0;
          
      this.provinceRankingXd = provinceRankingXd + '-' + provinceRankingXdMax
      this.totalScore =
        data.data?.scoreInfo?.total > 0
          ? data.data?.scoreInfo?.list[0].totalScore
          : 0;
      const first =
        data.data?.scoreInfo?.total > 0
          ? data.data?.scoreInfo?.list[0]
          : {
              chineseScore: 0,
              mathScore: 0,
              foreignLanguageScore: 0,
              politicsScore: 0,
              historyScore: 0,
              geographyScore: 0,
              physicsScore: 0,
              chemistryScore: 0,
              biologyScore: 0,
            };
      const two =
        data.data?.targetScoreInfo?.total > 0
          ? data.data?.targetScoreInfo?.list[0]
          : {
              chineseScore: 0,
              mathScore: 0,
              foreignLanguageScore: 0,
              politicsScore: 0,
              historyScore: 0,
              geographyScore: 0,
              physicsScore: 0,
              chemistryScore: 0,
              biologyScore: 0,
            };
      const three = {};
      three['chineseScore'] =
        Number(first['chineseScore']) - Number(two['chineseScore']);
      three['mathScore'] =
        Number(first['mathScore']) - Number(two['mathScore']);
      three['foreignLanguageScore'] =
        Number(first['foreignLanguageScore']) -
        Number(two['foreignLanguageScore']);
      three['politicsScore'] =
        Number(first['politicsScore']) - Number(two['politicsScore']);
      three['historyScore'] =
        Number(first['historyScore']) - Number(two['historyScore']);
      three['geographyScore'] =
        Number(first['geographyScore']) - Number(two['geographyScore']);
      three['physicsScore'] =
        Number(first['physicsScore']) - Number(two['physicsScore']);
      three['chemistryScore'] =
        Number(first['chemistryScore']) - Number(two['chemistryScore']);
      three['biologyScore'] =
        Number(first['biologyScore']) - Number(two['biologyScore']);
      const arr = [];
      arr[0] = { ...first, names: '最新成绩' };
      arr[1] = { ...two, names: '目标成绩' };
      arr[2] = { ...three, names: '目标完成' };
      this.xueqiData = [...arr];
    }
  }
  // 测评报告
  async fetchCareer() {
    const action = `/v1/career-exam/list-exam-result`;
    const params = {
      userCode: this.code,
    };
    const data: any = await this.http.post(action, params);
    this.careerExamData = data;
  }
  // 我的收藏
  async fetchFavorite() {
    const action = `/v1/MyFavorite/list-my-favorite`;
    const params = {
      userCode: this.code,
    };
    const data: any = await this.http.post(action, params);
    this.favorite = data;
  }
  // 我的足迹
  async fetchFootprint() {
    const action = `/v1/resource/list-my-footprint`;
    const params = {
      userCode: this.code,
    };
    const data: any = await this.http.post(action, params);
    this.footprintData = data;
  }
  // 查看模拟志愿
  showCollegeMajorModal = false;
  closeCollegeMajor() {
    this.showCollegeMajorModal = false;
  }
  myCollegeMajor: any = null;
  async findMyCollegeMajor(id: number) {
    const action = '/v1/enroll2/find-my-selection';
    const params = {
      id: id,
    };

    this.myCollegeMajor = await this.http.post(action, params);
  }
  currMajorPage = [];
  pageIndex = 1;
  pageSize = 5;
  gotoPage() {
    this.currMajorPage = this.myCollegeMajor.detail.slice(
      (this.pageIndex - 1) * this.pageSize,
      this.pageIndex * this.pageSize
    );
  }
  async showCollegeMajor(id: number) {
    await this.findMyCollegeMajor(id);
    this.gotoPage();
    this.showCollegeMajorModal = true;
  }
}
