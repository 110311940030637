import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from 'src/app/service/user.service';
import { HttpService } from 'src/app/service/http.service';
import { Router } from '@angular/router';
import { family2StudentCode } from '~/app/utility/domain';

@Component({
  selector: 'app-mine',
  templateUrl: './mine.component.html',
  styleUrls: ['./mine.component.scss'],
})
export class MineComponent implements OnInit {
  modeName = '';
  code = this.userService.isFamily()
    ? family2StudentCode(this.userService.code())
    : this.userService.code();
  options = [
    {
      label: '物理',
      value: '物理',
    },
    {
      label: '化学',
      value: '化学',
    },
    {
      label: '生物',
      value: '生物',
    },
    {
      label: '历史',
      value: '历史',
    },
    {
      label: '地理',
      value: '地理',
    },
    {
      label: '政治',
      value: '政治',
    },
  ];
  setCourse = true
  personalInfo = {
    schoolName: '',
    gradeName: '',
    className: '',
    teachTeacherName: '', //教学班主任
    headTeacherName: '', //行政班主任
    subjectSelection: '',
    subjectStatus: 0,
  };
  mainCourses = ['语文', '数学', '外语'];
  sstList = []
  tableData = [
    {
      key: 1,
      course: '语文',
      teacherName:'',
      score: undefined,
    },
    {
      key: 2,
      course: '数学',
      teacherName:'',
      score: undefined,
    },
    {
      key: 3,
      course: '外语',
      teacherName:'',
      score: undefined,
    },
  ]
  listOfData: any[] = [
    {
      key: 4,
      course: '',
      teacherName:'',
      score: undefined,
    },
    {
      key: 5,
      course: '',
      teacherName:'',
      score: undefined,
    },
    {
      key: 6,
      course: '',
      teacherName:'',
      score: undefined,
    },
    {
      key: 7,
      course: '',
      teacherName:'',
      score: undefined,
    },
    {
      key: 8,
      course: '',
      teacherName:'',
      score: undefined,
    },
    {
      key: 9,
      course: '',
      teacherName:'',
      score: undefined,
    },
  ];
  constructor(
    private message: NzMessageService,
    private userService: UserService,
    private http: HttpService,
    private router: Router
  ) {}
  async ngOnInit() {
    await this.fetchData();
    await this.fetchSubject()
    await this.fetchMode();
  }
  maxValue(value){
    return this.mainCourses.includes(value) ? 150 : 100
  }
  async fetchMode() {
    let code = this.userService.code().substring(0, 2);
    const action = '/v1/enroll2/find-province-mode';
    const params = {
      code,
    };
    const data: any = await this.http.post(action, params);
    this.modeName = data?.modeName;
  }
  async fetchData() {
    const action = `/app-api/teach/student/getByCode?code=${this.code}`;
    const data: any = await this.http.get(action, 1);
    this.personalInfo = data.data;
    this.sstList = data.data?.sstList
    if(this.personalInfo?.subjectStatus === 0) {
      if(this.sstList?.length > 0) {
        const courseList = this.sstList.map((item, index) => {
          return {
            key: item.subjectId,
            course: item.subjectName,
            teacherName: item.teacherName,
            score: item.subjectTargetscore ? parseFloat(item.subjectTargetscore) : null
          }
        })
        const historyName = courseList.find(itme => itme.course == '历史')
        const geograpName = courseList.find(itme => itme.course == '地理')
        const politicsName = courseList.find(itme => itme.course == '政治')
        const arr = courseList.filter(item => !['历史', '地理', '政治'].includes(item.course))
        historyName && arr.push(historyName)
        geograpName && arr.push(geograpName)
        politicsName && arr.push(politicsName)
        this.listOfData = arr
      }else {
        const courseList = [...this.tableData, ...this.listOfData]
        const historyName = courseList.find(itme => itme.course == '历史')
        const geograpName = courseList.find(itme => itme.course == '地理')
        const politicsName = courseList.find(itme => itme.course == '政治')
        const arr = courseList.filter(item => !['历史', '地理', '政治'].includes(item.course))
        historyName && arr.push(historyName)
        geograpName && arr.push(geograpName)
        politicsName && arr.push(politicsName)
        this.listOfData = arr
      }
    }
  }
  async fetchSubject() {
    let code = this.userService.code();
    const action = '/v1/personal/get-my-courses';
    const params = {
      userCode: code,
    };
    const data = await this.http.post(action, params);
    if(!data) {
      const list = this.listOfData.slice(0, 3)
      let courseList = []
      if(this.personalInfo?.subjectStatus === 1 && this.sstList.length > 0) {
        const temp = this.sstList?.filter(item => this.mainCourses.includes(item.subjectName)).map((item, index) => {
          return {
            key: index,
            course: item.subjectName,
            teacherName: item.teacherName,
            score: item.subjectTargetscore ? parseFloat(item.subjectTargetscore) : null
          }
        })
        courseList = [...temp, ...list]
      }else {
        courseList = [...this.tableData, ...list]?.sort((a, b) => a.key - b.key)
      }
      const historyName = courseList.find(itme => itme.course == '历史')
      const geograpName = courseList.find(itme => itme.course == '地理')
      const politicsName = courseList.find(itme => itme.course == '政治')
      const arr = courseList.filter(item => !['历史', '地理', '政治'].includes(item.course))
      historyName && arr.push(historyName)
      geograpName && arr.push(geograpName)
      politicsName && arr.push(politicsName)
      this.listOfData = arr
    }else {
      const checkCourse = data?.courses?.split(',')
      const allCheck = [...this.mainCourses, ...checkCourse]
      const temp = this.sstList?.filter(item => allCheck.includes(item.subjectName)).map((item, index) => {
        return {
          key: index,
          course: item.subjectName,
          teacherName: item.teacherName,
          score: item.subjectTargetscore ? parseFloat(item.subjectTargetscore) : null
        }
      })
      this.setCourse = !(this.personalInfo?.subjectStatus == 2)
      const courseList = temp?.sort((a, b) => b.key - a.key)
      const historyName = courseList.find(itme => itme.course == '历史')
      const geograpName = courseList.find(itme => itme.course == '地理')
      const politicsName = courseList.find(itme => itme.course == '政治')
      const arr = courseList.filter(item => !['历史', '地理', '政治'].includes(item.course))
      historyName && arr.push(historyName)
      geograpName && arr.push(geograpName)
      politicsName && arr.push(politicsName)
      this.listOfData = arr
    }
  }
  async applyForSubject() {
    const action = `/app-api/teach/student/applyForSubject?code=${this.code}`;
    const data: any = await this.http.get(action, 1);
    if(data.code == 0 || data.code == 200) {
      this.message.success('申请成功')
      await this.fetchData();
      await this.fetchSubject()
      await this.fetchMode();
    }else{
      this.message.error(data.msg)
    }
  }
  async submit() {
    const action = `/app-api/teach/student/updateTargetScore`;
    const list = this.listOfData.map(item => {
      return {
        subjectName: item.course,
        subjectTargetscore: item.score
      }
    })
    const params = {
      studentAccount: this.userService.code(),
      subjectTargetScoreList: list
    }
    const data = await this.http.post(action, params, 1);
    if(data) {
      this.message.success('设置成功')
    }
  }
  async setting() {
    // if(this.personalInfo?.subjectStatus === 0) {
    //   this.message.error('选科未开启');
    //   return
    // }
    const courses = this.listOfData?.map((item) => {
      return item.course;
    }).filter(item => !this.mainCourses.includes(item));
    if (courses.includes('')) {
      this.message.error('请选择科目');
      return;
    }
    const repeat = Array(...new Set(courses));
    if (courses.length > repeat?.length) {
      this.message.error('科目存在重复，请检查所选择的科目');
      return;
    }
    if (
      this.modeName == '3+1+2' &&
      courses.includes('物理') &&
      courses.includes('历史')
    ) {
      this.message.error('物理和历史只能选择一个');
      return;
    }
    if (
      this.modeName == '3+1+2' &&
      !courses.includes('物理') &&
      !courses.includes('历史')
    ) {
      this.message.error('物理和历史必须选择一个');
      return;
    }
    const action = '/v1/personal/set-my-course';
    const params = {
      courses: courses.join(),
      userCode: this.code,
    };
    const data =  await this.http.post(action, params);
    console.log(data)
    // if(this?.personalInfo?.subjectStatus == 1) {
    let course = '综合'
    if(this.modeName == '3+1+2'){
      course = courses.includes('物理') ? '物理' : (courses.includes('历史') ? '历史' : '综合');
    }
    await this.http.post('/app-api/teach/student/setSelectSubjects', {
      studentCode: this.code,
      ...data
    }, 1);
    // }
    this.message.success('设置成功');
    setTimeout(() => {
      this.router.navigate(['/career'])
    }, 1000)
  }
}
