import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from 'src/app/service/http.service';
import { LocalStorageService } from 'src/app/service/local-storage.service';
import { UserService } from 'src/app/service/user.service';

export interface MajorDesc{
  majorCode: string;
  major: string;
  eduLevel: string;
  courses: string;
}

@Component({
  selector: 'app-list-course-by-major',
  templateUrl: './list-course-by-major.component.html',
  styleUrls: ['./list-course-by-major.component.scss']
})
export class ListCourseByMajorComponent implements OnInit {
  cumbs = ['首页', '科目查询'];
  data: MajorDesc[] = [];
  filtered: MajorDesc[] = [];

  constructor(private http: HttpService,
    private route: Router,
    private userService: UserService,
    private message: NzMessageService) {
     }

  async ngOnInit() {
    this.fetchData();
  }

  async fetchData(){
    const action = "/v1/enroll2/list-course-by-major";
    const provinceCode = this.userService.provinceCode();
    const params = {
      "provinceCode": provinceCode
    }

    this.data = await this.http.post(action, params);
    if (!this.data){
      this.message.error("数据获取失败");
    }

    this.data = this.data.filter(c=>c.major);
    this.filtered = this.data;
    this.gotoPage(1);
  }

  listByCollgege(){
    this.route.navigate(["list-course-by-college"]);
  }

  pageSize = 20;
  currPage: any[] = [];

  gotoPage(idx: number) {
    this.currPage = this.filtered.slice(
      (idx - 1) * this.pageSize,
      idx * this.pageSize
    );
  }

  value: string= '';
  search(){
    if (!this.value || this.value===''){
      this.filtered = this.data;
      return;
    }

    this.filtered = this.data.filter(c=>c.major.includes(this.value));
    this.gotoPage(1);
  }
}
