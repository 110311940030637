<div class="card">
  <app-card
    [id]="data?.id"
    [imgUrl]="data?.picUrl"
    [url]="data?.url"
  ></app-card>
  <p class="title bold">{{ data?.title ? data?.title : "标题-01" }}</p>
  <p class="small">
    <span nz-icon class="cur" [nzType]="'fire'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#f00'" (click)="hot(data?.id)"></span>&nbsp;热力值: {{ data?.hotValue }}
    &nbsp;&nbsp;
    <span nz-icon [nzType]="'eye'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#f00'"></span>&nbsp;浏览量: {{ data?.score }}
  </p>
</div>
