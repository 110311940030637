<div class="container">
  <div class="login-box">
    <div class="left"></div>
    <div class="right">
      <nz-tabset nzCentered nzSize="large">
        <nz-tab *ngFor="let tab of tabs" [nzTitle]="tab.text" >
          <div *ngIf="tab.index == 2">
            <form nz-form [formGroup]="loginForm">
              <p class="title">手机号</p>
              <nz-form-item>
                <nz-form-control nzErrorTip="输入用户名">
                  <nz-input-group nzPrefixIcon="user" nzSize="large">
                    <input
                      type="text"
                      nz-input
                      formControlName="userName"
                      placeholder="用户名"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>

              <p class="title">密码</p>
              <nz-form-item>
                <nz-form-control nzErrorTip="输入密码">
                  <nz-input-group nzPrefixIcon="lock" nzSize="large">
                    <input
                      type="password"
                      nz-input
                      formControlName="password"
                      placeholder="密码"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>

              <p class="buttons">
                <span>
                  <label nz-checkbox formControlName="rememberMe">记住密码</label>
                </span>
                <span routerLink="/login-reg" class="fore-color">注册会员</span>
                <span (click)="forget()">
                    忘记密码
                </span>
                <span>
                  <button nz-button nzType="primary" (click)="submit(2)">
                    登录
                  </button>
                </span>
              </p>
            </form>
          </div>


          <div *ngIf="tab.index == 1">
            <form nz-form [formGroup]="loginForm">
              <p class="title">学籍号</p>
              <nz-form-item>
                <nz-form-control nzErrorTip="输入用户名">
                  <nz-input-group nzPrefixIcon="user" nzSize="large">
                    <input
                      type="text"
                      nz-input
                      formControlName="userName"
                      placeholder="用户名"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>

              <p class="title">密码</p>
              <nz-form-item>
                <nz-form-control nzErrorTip="输入密码">
                  <nz-input-group nzPrefixIcon="lock" nzSize="large">
                    <input
                      type="password"
                      nz-input
                      formControlName="password"
                      placeholder="密码"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>

              <p class="buttons_xs">
                <button nz-button nzType="primary" (click)="submit(1)">
                  登录
                </button>
              </p>
            </form>
          </div>

        </nz-tab>
      </nz-tabset>

    </div>
  </div>

</div>

<nz-modal
  nzWidth="600px"
  [(nzVisible)]="changedPasswordModal"
  nzTitle="重置密码"
  (nzOnCancel)="passwordCancel()"
>
  <ng-container *nzModalContent>
    <div class="modal">
      <p class="title dark">手机号码</p>
      <span>
        <nz-input-group nzPrefixIcon="lock" class="wide">
          <input type="text" nz-input placeholder="手机号码" [(ngModel)]="mobile"/>
        </nz-input-group>
      </span>

      <p class="title dark">手机验证码</p>
      <span class="left">
        <nz-input-group nzPrefixIcon="message">
          <input type="text" nz-input placeholder="输入验证码" [(ngModel)]="smsCode"/>
        </nz-input-group>
      </span>
      <span class="next">
        <button nz-button nzType="primary" (click)="sendSms()">{{ buttonText }}</button>
      </span>

      <p class="title dark">输入密码</p>
      <span>
        <nz-input-group nzPrefixIcon="lock" class="wide">
          <input type="password" nz-input placeholder="第一次输入密码" [(ngModel)]="password1"/>
        </nz-input-group>
      </span>

      <p class="title dark">再次输入密码</p>
      <span>
        <nz-input-group nzPrefixIcon="lock" class="wide">
          <input type="password" nz-input placeholder="再次输入密码" [(ngModel)]="password2"/>
        </nz-input-group>
      </span>
    </div>
  </ng-container>

  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="passwordCancel()">取消</button>
    <button nz-button nzType="primary" (click)="passwordChanged()">提交</button>
  </div>
</nz-modal>







