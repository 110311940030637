import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from 'src/app/service/user.service';
import { HttpService } from 'src/app/service/http.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-career-enroll',
  templateUrl: './career-enroll.component.html',
  styleUrls: ['./career-enroll.component.scss'],
})
export class CareerEnrollComponent implements OnInit {
  constructor(
    private message: NzMessageService,
    private userService: UserService,
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  code = this.userService.code();
  colleges = []
  careerExamData = []
  async ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if(params.code) {
        this.code = params.code
      }
    });
    this.fetchChoice()
  }
  // 获取全部我的意向学校专业职业入学通路列表
  async fetchChoice() {
    const action = `/v1/personal/list-my-choice-all`;
    const params = {
      userCode: this.code,
    };
    const { colleges, enrolls, jobs } = await this.http.post(action, params);
    const choiceNo = enrolls?.reduce((prev, item, index,arr) => {
      if(!prev.includes(item.choiceNo)) {
        prev.push(item.choiceNo)
      }
      return prev
    }, [])
    this.colleges = choiceNo.map(item => {
      let options = []
      let myCollege = ""
      let createdOn = ""
      enrolls.forEach(child => {
        if(child.choiceNo == item) {
          options.push(child)
          myCollege = child.myEnroll
          createdOn = child.createdOn
        }
      })
      return {
        options,
        myCollege,
        createdOn
      }
    })
  }
}
