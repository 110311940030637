import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from 'src/app/service/http.service';
import { isValidMobilePhone } from 'src/app/utility/common';

export interface DownloadInfo {
	unitCode?: string;
	unitName?: string;
	mobilePhone?: string;
	appId?: string;
	appUrl: string;
}

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
})

/**
 * 使用方式   http://localhost:4200/#/download?unitCode=1234
 */
export class DownloadComponent implements OnInit {
  unitCode: string;
  regForm: FormGroup;
  timer: any;
  time: 60;
  buttonText = '获取验证码';

  constructor(
    private route: ActivatedRoute,
    private http: HttpService,
    private fb: FormBuilder,
    private message: NzMessageService) {
    this.regForm = this.fb.group({
      phone: ['', Validators.required],
      smsCode: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.unitCode = params.unitCode;
    });
  }

  async mobileCode() {
    const mobilePhone = this.regForm.value['phone'];

    if (!isValidMobilePhone(mobilePhone)) {
      this.message.error('不是正常的手机号码！');
    } else {
      const action = '/v1/user/send-verify-code';
      const params = {
        mobilePhone: mobilePhone,
      };

      await this.http.post(action, params);
      this.time = 60;
      this.timer = setInterval(() => {
        this.onTimer();
      }, 1000);
    }
  }

  onTimer() {
    this.time--;
    this.buttonText = this.time + '秒';
    if (this.time === 0) {
      clearInterval(this.timer);
      this.time = 60;
      this.buttonText = '获取验证码';
    }
  }

  async submit() {
    const params = {
      mobilePhone: this.regForm.value['phone'],
      code: this.regForm.value['smsCode'],
    };

    if (!isValidMobilePhone(params.mobilePhone)) {
      this.message.error('手机号码输入错误！');
    } else if (params.code.length != 6) {
      this.message.error('手机验证码输入错误！');
    } else {
      const action = '/v1/user/verify-code';
      const success = await this.http.post(action, params);

      if (!success) {
        this.message.error('短信验证错误，请联系相关人员');
      } else {
        const action = '/v1/user/submit-info';
        const params = {
          mobilePhone: this.regForm.value['phone'],
          unitCode: this.unitCode,
        };

        let data: DownloadInfo = await this.http.post(action, params);
        if (!data) {
          this.message.error('获取下载地址失败，请联系相关人员！');
        } else {
          window.open(data.appUrl, '_self');
        }
      }
    }
  }
}
