<app-header></app-header>

<section>
  <div class="container">
    <app-breadcumb [items]="cumbs"></app-breadcumb>

    <div class="content">
      <div *ngFor="let item of currPage">
        <app-comp-video [data]="item"></app-comp-video>
        <nz-divider></nz-divider>
      </div>

      <nz-pagination class="right"
          [nzPageIndex]="1"
          [nzTotal]="this.data.length"
          (nzPageIndexChange)="gotoPage($event)"
        ></nz-pagination>
    </div>
  </div>
</section>
