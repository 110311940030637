import { Component, Input, OnInit } from '@angular/core';
import { MajorDesc } from '~/app/shared/major.type';

@Component({
  selector: 'app-major',
  templateUrl: './major.component.html',
  styleUrls: ['./major.component.scss']
})
export class MajorComponent implements OnInit {
  @Input() major: MajorDesc;
  expanded = false;

  constructor() { }

  ngOnInit(): void {
  }

  expand(){
    this.expanded = !this.expanded;
  }

  hasChildren(): boolean{
    return this.major.children && this.major.children.length >0
  }
}
