import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss'],
})
export class CourseComponent implements OnInit {
  @Input() course: string;
  @Input() checked: boolean;
  @Input() url: string;
  @Input() canSelect = false;

  @Output() checkChanged = new EventEmitter<String>();

  constructor(private message: NzMessageService) {}
  ngOnInit(): void {}

  onCheck() {
    if (!this.canSelect && !this.checked) {
      this.message.error("选科数量不能超过三科！");
      return;
    } else {
      this.checked = !this.checked;
      this.checkChanged.emit(this.course);
    }
  }
}
