<app-header></app-header>

<section>
  <div class="container">
    <div class="career">
      <div class="test">
        <div class="test-heading">
          <p>心理测评</p>
          <button *ngIf="!isTeacher && !isFamily" (click)="more()">查看更多</button>
        </div>
        <div class="row">
          <app-test-card
            imgUrl="{{ testUrls[0] }}"
            id="{{ testIds[0] }}"
          ></app-test-card>
          <app-test-card
            imgUrl="{{ testUrls[1] }}"
            id="{{ testIds[1] }}"
          ></app-test-card>
          <app-test-card
            imgUrl="{{ testUrls[2] }}"
            id="{{ testIds[2] }}"
          ></app-test-card>
        </div>
        <div class="row">
          <app-test-card
            imgUrl="{{ testUrls[3] }}"
            id="{{ testIds[3] }}"
          ></app-test-card>
          <app-test-card
            imgUrl="{{ testUrls[4] }}"
            id="{{ testIds[4] }}"
          ></app-test-card>
          <app-test-card
            imgUrl="{{ testUrls[5] }}"
            id="{{ testIds[5] }}"
          ></app-test-card>
        </div>
      </div>

      <div class="my-career">
        <div class="warning" (click)="warningInfo()">
          <img src="{{ warningUrls[0] }}" alt=""/>
        </div>
        <div class="warning" (click)="reserve()">
          <img src="{{ warningUrls[1] }}"  alt=""/>
        </div>
      </div>
    </div>

    <div class="banner"></div>
    <app-interview [header]="titles[0]" [courses]="courses"></app-interview>
    <app-interview [header]="titles[1]" [courses]="familyCourses" *ngIf="isFamily || isTeacher"></app-interview>
  </div>
</section>

<app-footer></app-footer>
