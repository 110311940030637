import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Topics } from '~/app/shared/topics.type';

@Component({
  selector: 'app-hot-news',
  templateUrl: './hot-news.component.html',
  styleUrls: ['./hot-news.component.scss']
})
export class HotNewsComponent implements OnInit {
  @Input() data: Topics[] = [];

  header="热门资讯";

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  more():void {
    this.router.navigate(["videos", "热门资讯"]);
  }

}
