<div class="container">
  <div class="left">
    <img src="{{ univ.brief.logoUrl }}" alt=""/>
  </div>

  <div class="detail">
    <p>
      <span class="large bold" (click)="detail(univ.brief.id, univ.brief.name, univ.brief.collegeCode)">{{ univ.brief.name }}</span>
    </p>
    <p>
      <span>推荐组合:&nbsp;</span>
      <span class="emphsis large">{{ univ.info[0].courses }}</span>
    </p>
    <p>
      <span>省份: {{ univ.brief.provinceName }}</span>
      &nbsp;&nbsp;&nbsp;
      <span>{{ univ.brief.feature }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
      <span>{{ natureType }}/{{ eduLevel }}</span>
    </p>
  </div>

  <div class="right">
    <button nz-button  *ngIf="!busy" nzType="primary" (click)="detail(univ.brief.id, univ.brief.name, univ.brief.collegeCode)">学校详情</button>
    <nz-spin nzSimple *ngIf="busy"></nz-spin>
  </div>
</div>

<nz-modal
[(nzVisible)]="showDetail"
nzTitle="{{university?.brief?.name}}"
nzWidth='1200px'
(nzOnCancel)="handleCancel()"
(nzOnOk)="handleCancel()"
>
<ng-container *nzModalContent>
  <nz-tabset>
    <nz-tab nzTitle="院校概况">
      <p><span class="dark bold">官网地址：</span> <a href="{{university?.brief?.url}}" target="_blank">{{univ?.brief?.url}}</a></p>
      <p><span class="dark bold">招办电话：</span>{{university?.brief?.zhaobanTel}}</p>
      <p><span class="dark bold">学校简介：</span>{{university?.brief?.introduction}}</p>
      <p><span class="dark bold">特色专业：</span>{{univMajors}}</p>
    </nz-tab>
    <nz-tab nzTitle="院系设置">
      <nz-table #basicTable1 [nzData]="university?.departments">
        <thead>
            <tr>
                <td align="center" class=" dark bold">序号</td>
                <td align="center" class=" dark bold">院系名称</td>
                <td align="center" class=" dark bold">专业数量</td>
                <td align="center" class=" dark bold">备注</td>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor=" let dt of basicTable1.data; let idx = index">
               <td align="center" class=" dark small">{{idx+1}}</td>
               <td align="center" class=" dark small">{{dt.name}}</td>
               <td align="center" class=" dark small">{{dt.majorCount==0?"不详":dt.majorCount}}</td>
               <td align="center" class=" dark small"><span nz-button nzType="link" nZprimary><a href="{{dt.url}}" target="_blank">查看</a></span></td>
            </tr>
        </tbody>
    </nz-table>
    </nz-tab>
    <nz-tab nzTitle="招生简章">
      <nz-table #basicTable3 [nzData]="enrollIntro" nzFrontPagination="false">
        <thead>
            <tr>
                <td align="center" class=" dark bold">序号</td>
                <td align="center" class=" dark bold">年度</td>
                <td align="center" class=" dark bold">学校</td>
                <td align="center" class=" dark bold">招生简章</td>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor=" let dt of basicTable3.data; let idx = index" (click)="showIntro(dt)">
                <td align="center" class=" dark">{{idx+1}}</td>
                <td align="center" class=" dark">{{dt.year}}</td>
                <td align="center" class=" dark">{{dt.collegeName}}</td>
                <td align="center" class=" dark">{{dt.title}}</td>
            </tr>
        </tbody>
      </nz-table>

      <p class="large">{{current?.title}}</p>
      <div [innerHTML]="current?.content"></div>
    </nz-tab>

    <nz-tab nzTitle="院校录取分数线">
      <nz-table #basicTable [nzData]="collegeScores">
        <thead>
            <tr>
              <td align="center" class="dark bold">年度</td>
              <td align="center" class="dark bold">科类</td>
              <td align="center" class="dark bold">选科要求</td>
              <td align="center" class="dark bold">计划录取人数</td>
              <td align="center" class="dark bold">最低分数</td>
              <td align="center" class="dark bold">最低位次</td>
              <td align="center" class="dark bold">备注</td>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor=" let dt of basicTable.data; let idx = index">
              <td align="center" class="dark">{{dt.year}}</td>
              <td align="center" class="dark">{{dt.course}}</td>
              <td align="center" class="dark">{{dt.majorRequirement}}</td>
              <td align="center" class="dark">{{dt.amount}}</td>
              <td align="center" class="dark">{{dt.scoreMin}}</td>
              <td align="center" class="dark">{{dt.rankMin}}</td>
              <td align="center" class="dark short">{{dt.memo}}</td>
            </tr>
        </tbody>
      </nz-table>
    </nz-tab>

    <nz-tab nzTitle="专业录取分数线">
      <nz-table #basicTable4 [nzData]="majorScores">
        <thead>
            <tr>
                <td align="center" class="dark bold">年度</td>
                <td align="center" class="dark bold">层次</td>
                <td align="center" class="dark bold">批次</td>
                <td align="center" class="dark bold">专业</td>
                <td align="center" class="dark bold">科类</td>
                <td align="center" class="dark bold">选科要求</td>
                <td align="center" class="dark bold">录取人数</td>
                <td align="center" class="dark bold">最低分数</td>
                <td align="center" class="dark bold">最低位次</td>
                <td align="center" class="dark bold">专业说明</td>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor=" let dt of basicTable4.data; let idx = index">
                <td align="center" class="dark">{{dt.year}}</td>
                <td align="center" class="dark">{{dt.eduLevel}}</td>
                <td align="center" class="dark">{{dt.batch}}</td>
                <td align="center" class="dark">{{dt.major}}</td>
                <td align="center" class="dark">{{dt.course}}</td>
                <td align="center" class="dark">{{dt.majorRequirement}}</td>
                <td align="center" class="dark">{{dt.amount}}</td>
                <td align="center" class="dark">{{dt.scoreMin}}</td>
                <td align="center" class="dark">{{dt.rankMin}}</td>
                <td align="center" class="dark short">{{dt.majorRemark}}</td>
            </tr>
        </tbody>
      </nz-table>
    </nz-tab>

    <nz-tab nzTitle="招生计划">
      <nz-table #basicTable2 [nzData]="enrollPlan">
        <thead>
            <tr>
                <td align="center" class="dark bold">序号</td>
                <td align="center" class="dark bold">年度</td>
                <td align="center" class="dark bold">类型</td>
                <td align="center" class="dark bold">专业招生代码</td>
                <td align="center" class="dark bold">专业名称</td>
                <td align="center" class="dark bold">选科要求</td>
                <td align="center" class="dark bold">招生人数</td>
                <td align="center" class="dark bold">学习年限</td>
                <td align="center" class="dark bold">学费</td>
                <td align="center" class="dark bold">备注</td>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor=" let dt of basicTable2.data; let idx = index">
               <td align="center" class="small dark dark">{{idx+1}}</td>
               <td align="center" class="small dark">{{dt.year}}</td>
               <td align="center" class="small dark">{{dt.course}}</td>
               <td align="center" class="small dark">{{dt.majorEnrollCode}}</td>
               <td align="center" class="small dark">{{dt.major}}</td>
               <td align="center" class="small dark">{{dt.majorOptions}}</td>
               <td align="center" class="small dark">{{dt.amount}}</td>
               <td align="center" class="small dark">{{dt.studyYear}}</td>
               <td align="center" class="small dark">{{dt.studyFee}}</td>
               <td align="center" class="small dark short">{{dt.others}}</td>
            </tr>
        </tbody>
    </nz-table>
    </nz-tab>
  </nz-tabset>
</ng-container>

<div *nzModalFooter>
  <button nz-button nzType="default" (click)="handleCancel()">关闭</button>
  <button nz-button nzType="primary" (click)="handleOk(university?.brief?.name)">收藏</button>
</div>
</nz-modal>

