import {  Component,  OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Attention } from '~/app/shared/attention.type';



@Component({
  selector: 'app-attentions',
  templateUrl: './attentions.component.html',
  styleUrls: ['./attentions.component.scss'],
})
export class AttentionsComponent implements OnInit {
   data: Attention[] = [
    { id: 0, title: '教育部名单', picUrl: '../../../../assets/attentions/001.png', desc: '由教育部颁布的985、211、双一流等系列院校' },
    { id: 1, title: '权威榜单', picUrl: '../../../../assets/attentions/002.png', desc: '汇集展国外内具有超高影响力的各类院校排名榜单' },
    { id: 2, title: '特色榜单', picUrl: '../../../../assets/attentions/003.png', desc: '汇集展示了在不同领域的系列排名榜单' },
    { id: 3, title: '专科院校', picUrl: '../../../../assets/attentions/004.png', desc: '“双高计划”的高职院校和专业群名单' },
    { id: 4, title: '专业之最', picUrl: '../../../../assets/attentions/005.png', desc: '列举展示了各个方向之“最”的专业有哪些' },
    { id: 5, title: '专科怎么选', picUrl: '../../../../assets/attentions/006.png', desc: '多维度诠释如何选择高职专科院校和专业' },
    { id: 6, title: '国家公务员', picUrl: '../../../../assets/attentions/007.png', desc: '汇总发布最新的各省市国家公务员岗位信息' },
    { id: 7, title: '大厂揭秘', picUrl: '../../../../assets/attentions/008.png', desc: ' 国企、银行、IT、新能源等大厂的招聘需求揭秘' },
    { id: 8, title: '高校盘点', picUrl: '../../../../assets/attentions/009.png', desc: '各类型及主要省市高校汇总分析和推荐' },
    { id: 9, title: '避坑锦囊', picUrl: '../../../../assets/attentions/010.png', desc: '为莘莘学子排忧解难，少走弯路不踩坑' },
    { id: 10, title: '专业扫盲', picUrl: '../../../../assets/attentions/011.png', desc: '对易混淆的院校和专业进行全面对比分析' },
    { id: 11, title: '专家解惑', picUrl: 'h../../../../assets/attentions/012.png', desc: '高考遇到各种难题？权威专家为您解惑答疑' },
  ];

  shows: Attention[] = [];
  start = 0;

  constructor(private route: Router) {}

  ngOnInit(): void {
    this.getShows();
  }

  getShows() {
    let topics = [];

    const length = 4;
    for (let i = 0; i < length; i++) {
      let idx = (this.start + i) % this.data.length;
      topics.push(this.data[idx]);
    }

    this.shows = topics;
  }

  increase() {
    this.start++;
    if (this.start > this.data.length - 4) {
      this.start = this.data.length - 4;
    }

    this.getShows();
  }

  decrease() {
    this.start--;
    if (this.start < 0) {
      this.start = 0;
    }

    this.getShows();
  }

  more(){
    this.route.navigate(["attention", 0]);
  }
}
