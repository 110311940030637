import { Component, Input, OnInit } from '@angular/core';
import { HttpService } from '~/app/service/http.service';
import { MajorDesc, MajorDetail } from '~/app/shared/major.type';
import { Future, TypeResult } from '~/app/shared/majordesc.type';

@Component({
  selector: 'app-third',
  templateUrl: './third.component.html',
  styleUrls: ['./third.component.scss'],
})
export class ThirdComponent implements OnInit {
  @Input() major: MajorDesc;
  expanded = false;

  constructor(private http: HttpService) {}

  ngOnInit(): void {}

  expand() {
    this.expanded = !this.expanded;
  }

  showModal = false;
  detail: MajorDetail;
  majorDesc: TypeResult[] = [];

  async showMajor(code: string) {
    this.detail = null;

    let url = `/major/details/${code}.json`;
    let detail = await this.http.get(url);
    this.detail = detail['major'];

    url = `/major/feature/${code}.json`;
    let desc = await this.http.get(url);
    this.majorDesc = desc['result'];

    this.showModal = this.detail !== null;
  }

  getDirection(): string {
    return this.detail.eduLevel === 'zhuan' ? '升本方向' : '考研方向';
  }

  getDirectionDetail(): string {
    return this.detail.studyDirection && this.detail.studyDirection !== ''
      ? this.detail.studyDirection
      : this.detail.zhuanToBenOrient;
  }

  handleCancel() {
    this.showModal = false;
  }

  select($event) {
    let div: string = null;
    let option: any = [];

    if ($event === 1) {
      div = 'salary';
      option = this.getXinZiPJOption();
    } else if ($event === 2) {
      div = 'zhiye';
      option = this.getPieOption('ZhiYeFX');
    } else if ($event === 3) {
      div = 'hangye';
      option = this.getPieOption('HangYeFB');
    } else if ($event === 4) {
      div = 'chengshi';
      option = this.getPieOption('ChengShiFB');
    }

    if (div) {
      const doc = document.getElementById(div);
      const echart = echarts.init(doc);
      echart.setOption(option, true);
    }
  }

  show(type: string): boolean {
    const data = this.getFutureData(type);
    if (!data) {
      return false;
    }

    const filtered = data.filter((c) => c.name && c.value);
    return filtered.length > 0;
  }

  getFutureData(type: string): Future[] {
    return this.majorDesc.find((c) => c.type === type)?.futures;
  }

  getXinZiPJOption() {
    let data = this.getFutureData('XinZiPJ');

    if (!data) {
      return;
    }

    const years = data.map((c) => c.name);
    const values = data.map((c) => c.value);

    return {
      title: {
        text: '月薪资(元)',
      },
      tooltip: {
        show: true,
        formatter: function (params) {
          return params["name"] + '年平均' + params["value"] + '元';
        },
      },
      xAxis: {
        type: 'category',
        data: years,
        name: '年'
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: values,
          type: 'line',
          itemStyle: {
            normal: {
                color: '#FF0000', //改变折线点的颜色
                lineStyle: {
                    color: '#a80000' //改变折线颜色
                }
            }
          },
        },
      ],
    };
  }

  getPieOption(type: string) {
    let data = this.getFutureData(type);

    if (!data) {
      return;
    }

    const names = data.map((c) => c.name);
    const values = data.map((c) => c.value);

    const pieData = [];
    for (let i = 0; i < names.length; i++) {
      pieData.push({ name: names[i], value: values[i] });
    }

    return {
      series: [
        {
          type: 'pie',
          data: pieData,
          label: {
            show: true,
            formatter: function (arg) {
              return arg.data.name + arg.percent + '%';
            },
          },
          roseType: 'radius',
        },
      ],
    };
  }
}
