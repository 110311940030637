<app-header></app-header>

<section>
  <div class="container">
    <app-breadcumb  *ngIf="!busy" [items]="cumbs"></app-breadcumb>
    <div class="wait" *ngIf="busy"></div>

    <div *ngIf="!busy" class="search">
      <input nz-input  placeholder="查找院校" [(ngModel)]="value" />
      <button nz-button nzType="primary" (click)="search()">查找</button>
    </div>

    <p *ngIf="!busy">
      <span>按院校查询</span>
      <button nz-button nzType="default" nzDanger (click)="listByMajor()">
        按专业查询
      </button>
    </p>
    <div class="univ" *ngFor="let univ of currPage">
      <app-university [univ]="univ"></app-university>
      <nz-divider></nz-divider>
    </div>

    <nz-pagination class="right"
          [nzPageIndex]="1"
          [nzTotal]="this.filtered.length"
          (nzPageIndexChange)="gotoPage($event)"
        ></nz-pagination>
  </div>
</section>

<app-footer></app-footer>
