<div class="total">
  <div
    [ngClass]="{ box: !checked, 'box-selected': checked }"
    (click)="onCheck()"
  >
    <img src="{{ url }}" alt=""/>
  </div>
  <div class="course">
    {{ course }}
  </div>
</div>
