<div class="contain">
  <div class="left">
    <span>{{ data.majorCode }}</span> &nbsp;
    <span>{{ data.major }}</span>
  </div>
  <div class="center1">
    <span>{{ eduLevel }}</span>
  </div>

  <div class="center2">
    <span>{{ data.courses }}</span>
  </div>

  <div class="right">
    <button nz-button nzType="primary" (click)="detail(data.majorCode)">专业详情</button>
  </div>
</div>

<nz-modal
  nzWidth = '960px'
  [(nzVisible)]="showDetail"
  nzTitle="{{major?.name}}"
  (nzOnCancel)="handleCancel()"
>
  <ng-container *nzModalContent>
    <nz-tabset (nzSelectedIndexChange)="select($event)">
      <nz-tab nzTitle="专业介绍">
        <p *ngIf='major?.introduction' class="title bold">专业介绍</p>
        <p>{{major?.introduction}}</p>
        <p *ngIf='major?.eduObjective' class="title bold">培养目标</p>
        <p>{{major?.eduObjective}}</p>
        <p *ngIf='major?.eduRequirement' class="title bold">培养要求</p>
        <p>{{major?.eduRequirement}}</p>
        <p *ngIf='major?.subjectRequirement' class="title bold">学科要求</p>
        <p>{{major?.subjectRequirement}}</p>
        <p *ngIf='major?.loreAndAbility' class="title bold">知识能力</p>
        <p>{{major?.loreAndAbility}}</p>
        <p *ngIf='major?.studyDirection' class="title bold">考研方向</p>
        <p>{{major?.studyDirection}}</p>
        <p *ngIf='major?.mainCourse' class="title bold">主要课程</p>
        <p>{{major?.mainCourse}}</p>
        <p *ngIf='major?.famousScholar' class="title bold">社会名人</p>
        <p>{{major?.famousScholar}}</p>
      </nz-tab>
      <nz-tab nzTitle="薪资评价" [nzDisabled]="!show('XinZiPJ')">
        <div id="salary"></div>
      </nz-tab>
      <nz-tab nzTitle="职业分析" [nzDisabled]="!show('ZhiYeFX')">
        <div id="zhiye"></div>
      </nz-tab>
      <nz-tab nzTitle="行业分布" [nzDisabled]="!show('HangYeFB')">
        <div id="hangye"></div>
      </nz-tab>
      <nz-tab nzTitle="城市分布" [nzDisabled]="!show('ChengShiFB')">
        <div id="chengshi"></div>
      </nz-tab>

    </nz-tabset>

  </ng-container>

  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">关闭</button>
    <button nz-button nzType="primary" (click)="handleOk(major?.name)">收藏</button>
  </div>
</nz-modal>

