<app-header></app-header>

<section>
  <div class="container">
  <app-breadcumb [items]="cumbs"></app-breadcumb>
  <div class="divide"></div>

  <div class="left">
    <p class="title bold">{{ data?.title }}</p>
    <div class="know">
      <span class="text" *ngFor="let know of knowledges">{{ know }}<br /></span>
    </div>
  </div>

  <div class="right">
    <p>
      <span>共{{ questions.length }}题, 第{{ index + 1 }}题</span>
    </p>
    <p class="text">{{ current?.question }}</p>

    <p>
      <span *ngFor="let ans of current?.options">
        <button
          nz-button
          nzType="default"
          nzDanger
          (click)="submit(ans.score, index)"
        >
          {{ ans.answer }}
        </button>
      </span>
    </p>

    <p *ngIf="index > 0">
      <button nz-button nzType="primary" class="center" (click)="prev()">
        前一题
      </button>
    </p>
    <p *ngIf="canSubmit">
      <button
        nz-button
        nzType="primary"
        nzDanger
        class="center"
        (click)="submitExam()"
      >
        提交
      </button>
    </p>
  </div>
</div>
</section>

<app-footer></app-footer>

<nz-modal
  nzWidth="800px"
  [(nzVisible)]="showAnswer"
  nzTitle="评测结果分析"
  (nzOnCancel)="handleCancel()"
>
  <ng-container *nzModalContent>
    <nz-tabset (nzSelectedIndexChange)="tabChange($event)">
      <nz-tab nzTitle="评测结果">
        <p class="title bold">{{ examResult.title }} </p>
        <div class="know">
          <p class="text" *ngFor="let exp of examResult.explains">
            {{ exp.label }} : {{ exp.explain }}<br />
          </p>
        </div>
      </nz-tab>
      <nz-tab nzTitle="结果对比" *ngIf="showSecondTab()">
        <div id="echart" style="width:800px; height:600px"></div>
      </nz-tab>
    </nz-tabset>
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">关闭</button>
  </div>
</nz-modal>
