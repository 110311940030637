<div class="card" (click)="click()">
  <!-- <img src = "../../../../assets/logos/生涯评测.png" /> -->
  <div class="left">
    <img src="{{ data?.picUrl }}" alt=""/>
  </div>
  <div class="right">
    <p class="title bold">{{ data?.title }}</p>
    <p class="text"> {{data.desc}}</p>
  </div>
</div>
