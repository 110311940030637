import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {LocalStorageService} from 'src/app/service/local-storage.service';
import {HttpService} from '~/app/service/http.service';
import {UserService} from '~/app/service/user.service';
import {isValidPassword} from '~/app/utility/common';
import {environment} from '~/environments/environment';
import {family2StudentCode} from '~/app/utility/domain';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  name: string;
  grade: String;
  school: string;
  avatar_url: string;
  code = this.userService.code();

  search: string = '';
  isStudent = false;
  erCodeUrl = '';
  agreeUrl = '';
  protectUrl = '';
  manualUrl = '';
  showStudentInfo = true;

  constructor(
    private storage: LocalStorageService,
    private message: NzMessageService,
    private userService: UserService,
    private http: HttpService,
    private router: ActivatedRoute,
    private route: Router
  ) {
    this.agreeUrl =
      'https://resources.delesson.cn/' + environment.appId + '/agreement.html';
    this.protectUrl =
      'https://resources.delesson.cn/' + environment.appId + '/protection.html';
    this.manualUrl =
      'https://resources.delesson.cn/' + environment.appId + '/about.html';
    this.erCodeUrl =
      'https://resources.delesson.cn/' + environment.appId + '/2DCode.png';
  }

  isParent = false;

  async ngOnInit() {
    await this.fetchIsParent();
    this.isStudent =
      this.isParent || this.userService.isTeacher() ? false : true;
    this.name = this.storage.get('name');
    this.grade = this.storage.get('gradeChinese');
    this.school = this.storage.get('school');
    this.avatar_url = this.storage.get('avatar_url');
    if (!this.avatar_url) {
      this.avatar_url = 'https://resources.delesson.cn/avator/boy001.png';
    }
    if (this.userService.isTeacher()) {
      this.showStudentInfo = false;
    }
  }

  async fetchIsParent() {
    const action = '/v1/user/user-is-parent';
    const params = {
      userCode: this.code,
    };

    this.isParent = await this.http.post(action, params);
  }

  doSearch() {
    if (!this.search || this.search === '') {
      this.message.error('查询字符串不能为空！');
    } else {
      this.route.navigate(['search', this.search]);
    }
  }

  archives() {
    // this.route.navigate(['/archives'], { queryParams: { code: this.code } });
    window.open(
      `${environment.baseFilesUrl}/#/archives?code=${this.code}`,
      '_blank'
    );
  }

  exit() {
    this.storage.clear();
    this.route.navigate(['login']);
  }

  avatarModal = false;
  avatarUrls = [];

  async changeAvatar() {
    const action = '/v1/user/list-avators';
    const params = {};
    this.avatarUrls = await this.http.post(action, params);

    this.avatarModal = true;
  }

  avatarCancel() {
    this.avatarModal = false;
    this.avatar_url = this.storage.get('avatar_url');
  }

  changeAvatarTo(url) {
    this.avatar_url = url;
  }

  async avatarChanged() {
    const action = '/v1/user/set-avator-url';
    const params = {
      code: this.code,
      url: this.avatar_url,
    };

    this.http.post(action, params);
    this.avatarModal = false;
  }

  changedPasswordModal = false;
  smsCode = '';
  password1 = '';
  password2 = '';
  buttonText = '获取手机验证码';

  changePassword() {
    this.changedPasswordModal = true;
  }

  passwordCancel() {
    this.changedPasswordModal = false;
  }

  time = 60;
  timer: any;

  async sendSms() {
    const action = '/v1/user/send-verify-code';
    const params = {
      mobilePhone: this.userService.mobilePhone(),
    };

    await this.http.post(action, params);
    this.time = 60;
    this.timer = setInterval(() => {
      this.onTimer();
    }, 1000);
  }

  onTimer() {
    this.time--;
    this.buttonText = this.time + '秒';
    if (this.time === 0) {
      clearInterval(this.timer);
      this.timer = null;
      this.time = 60;
      this.buttonText = '获取验证码';
    }
  }

  async passwordChanged() {
    if (this.timer) {
      clearInterval(this.timer);
    }

    if (!isValidPassword(this.password1)) {
      this.message.error('密码必须为至少8位字符, 且包含字母和数字！');
      return;
    }

    if (this.password1 !== this.password2) {
      this.message.error('两次输入密码不同，请核查');
      return;
    }
    if (!(await this.verifySmsCode(this.smsCode))) {
      this.message.error('验证码输入错误，请核查');
    } else {
      this.password(this.userService.mobilePhone(), this.password1);
    }

    this.changedPasswordModal = false;
  }

  async verifySmsCode(smsCode: string) {
    const action = '/v1/user/verify-code';
    const params = {
      code: smsCode,
      mobilePhone: this.userService.mobilePhone(),
    };

    const result = await this.http.post(action, params);
    return result;
  }

  async password(mobilePhone, password: string) {
    const action = '/v1/user/change-password-by-phone';
    const params = {
      mobilePhone: mobilePhone,
      password: password,
    };

    await this.http.post(action, params);
  }

  feedBackModal = false;
  feedback = '';

  showFeedback() {
    this.feedBackModal = true;
  }

  feedBackCancel() {
    this.feedBackModal = false;
  }

  async feedBackSubmitted() {
    if (!this.feedback) {
      this.message.error('您的意见不能为空');
    } else {
      const action = '/v1/my-message/submit-feedback';
      const params = {
        feedback: this.feedback,
        userCode: this.code,
        userName: this.userService.name,
      };

      await this.http.post(action, params);

      this.message.info('感谢您提出宝贵意见');
    }

    this.feedBackModal = false;
  }

  showDownLoad = false;

  download() {
    this.showDownLoad = true;
  }

  downLoadClose() {
    this.showDownLoad = false;
  }

  vipNum() {
    if (localStorage.getItem("isvip") == 'true') {
      if (localStorage.getItem("viptype") == '1') {
        return 'VIP有效期: ' + localStorage.getItem("vipts") + '天';
      } else {
        return 'VIP有效期: 永久';
      }
    } else {
      return 'VIP已到期';
    }
  }

  vipSF() {
    return localStorage.getItem("usertype")
  }

  isVip() {
    return localStorage.getItem("isvip") == 'true' ? true : false;
  }
}
