<div class="card">
  <div class="left">
    <img src="{{data.imgUrl}}" alt=""> 
  </div>

  <div class="center">
    <p class="bold">{{data.caption}}</p>
    <p class="small">{{data.count}}人已经完成</p>
  </div>

  <div class="right">
    <button nz-button nzType="primary" (click)="startExam()">开始评测</button>
  </div>
</div>
