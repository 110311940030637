<section>
    <div class="major">
      <button *ngFor = "let child of data" (click)="detail(child.code)"> {{child.name}} </button>
    </div>
  </section>

  <nz-modal
  nzWidth="800px"
  [(nzVisible)]="showDetail"
  nzTitle="{{ details?.name }}"
  (nzOnCancel)="handleCancel()"
>
  <ng-container *nzModalContent>
    <p class="bold">职业代码: {{ details?.code }}</p>
    <div [innerHTML]="details.description"></div>
    <nz-divider></nz-divider>

    <p class="title bold">相关专业</p>
    <div *ngFor="let major of details.majors">
      <p><span class="text-20 bold">{{ major.name }}</span>  &nbsp; &nbsp;
        专业代码：{{ major.code }} &nbsp; | 学制：{{ major.learnYear }}年 &nbsp;
        | 男女比例 {{ major.mfRatioView }}
      </p>
    </div>
  </ng-container>

  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">关闭</button>
    <button nz-button style="width: auto;" nzType="primary" (click)="handleOk(details)">取消收藏</button>
  </div>
</nz-modal>