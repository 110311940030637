import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from 'src/app/service/user.service';
import { HttpService } from 'src/app/service/http.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-score-archives',
  templateUrl: './score-archives.component.html',
  styleUrls: ['./score-archives.component.scss'],
})
export class ScoreArchiveComponent implements OnInit {
  constructor(
    private message: NzMessageService,
    private userService: UserService,
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  code = this.userService.code();
  scoreData = [];
  total = 0
  pageNo = 1
  async ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if(params.code) {
        this.code = params.code
      }
    });
    this.fetchScore();
  }
  filterScore(value) {
    if(value > 0) {
      return `上升${value}`
    }
    if(value < 0) {
      return `下降${value}`
    }
    return value
  }
  filteTime(timestamp) {
    const date = new Date(timestamp);
    // 获取年、月、日
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    // 获取时、分、秒
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds
  }
  // 根据学生code获取学生成绩档案
  async fetchScore() {
    const action = `/app-api/teach/student/getScoreInfoByCodeAndTime?code=${this.code}&pageNo=${this.pageNo}&pageSize=10`;
    const data: any = await this.http.get(action, 1);
    if (data.code == 200) {
      this.scoreData = data.data.list;
      this.total = data.data.total
    }
  }
  gotoPage(event) {
    this.pageNo = event
    this.fetchScore()
  }
}
