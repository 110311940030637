<app-header></app-header>

<section>
  <div class="container">
    <div class="career">
      <div class="test">
        <div class="test-heading">
          <p>学科课程</p>
        </div>
        <div class="row">
          <div class="course-box">
            <div class="course" (click)="course('语文')">
              <img src="{{ coursesUrls[0] }}" alt=""/>
            </div>
            <p>语文</p>
          </div>
          <div class="course-box">
            <div class="course" (click)="course('数学')">
              <img src="{{ coursesUrls[1] }}" alt=""/>
            </div>
            <p>数学</p>
          </div>
          <div class="course-box">
            <div class="course" (click)="course('外语')">
              <img src="{{ coursesUrls[2] }}" alt=""/>
            </div>
            <p>外语</p>
          </div>
          <div class="course-box">
            <div class="course" (click)="course('物理')">
              <img src="{{ coursesUrls[3] }}" alt=""/>
            </div>
            <p>物理</p>
          </div>
          <div class="course-box">
            <div class="course" (click)="course('历史')">
              <img src="{{ coursesUrls[4] }}" alt=""/>
            </div>
            <p>历史</p>
          </div>
        </div>
        <div class="row">
          <div class="course-box">
            <div class="course" (click)="course('化学')">
              <img src="{{ coursesUrls[5] }}" alt=""/>
            </div>
            <p>化学</p>
          </div>
          <div class="course-box">
            <div class="course" (click)="course('生物')">
              <img src="{{ coursesUrls[6] }}" alt=""/>
            </div>
            <p>生物</p>
          </div>
          <div class="course-box">
            <div class="course" (click)="course('地理')">
              <img src="{{ coursesUrls[7] }}" alt=""/>
            </div>
            <p>地理</p>
          </div>
          <div class="course-box">
            <div class="course" (click)="course('政治')">
              <img src="{{ coursesUrls[8] }}" alt=""/>
            </div>
            <p>政治</p>
          </div>
          <div class="course-box">
            <div class="course">
              <img src="{{ coursesUrls[9] }}" alt=""/>
            </div>
            <p>技术</p>
          </div>
        </div>
      </div>

      <div class="my-career">
        <div class="row">
          <div class="plan" (click)="studyExam()">
            <p class="title bold">学业测评</p>
            <p class="detail">Academic Evaluation</p>
            <div class="mark">
              <img src="{{ academicPicUrl[0] }}" alt=""/>
            </div>
          </div>
          <div class="plan" (click)="info()">
            <p class="title bold">成绩档案</p>
            <p class="detail">Academic Achieves</p>
            <div class="mark">
              <img src="{{ academicPicUrl[1] }}" alt=""/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="plan" (click)="info()">
            <p class="title bold">学习效能</p>
            <p class="detail">Learning Efficency</p>
            <div class="mark">
              <img src="{{ academicPicUrl[2] }}" alt=""/>
            </div>
          </div>
          <div class="plan" (click)="info()">
            <p class="title bold">学业规划</p>
            <p class="detail">Academic Planning</p>
            <div class="mark">
              <img src="{{ academicPicUrl[3] }}" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="banner"></div>
    <app-interview [header]="titles[0]" [courses]="myResources"></app-interview>
    <app-interview [header]="titles[1]" [courses]="msjtShows"></app-interview>
    <app-interview [header]="titles[2]" [courses]="xxxnShows"></app-interview>
    <app-interview [header]="titles[3]" [courses] = "teacherCourses" *ngIf="isTeacher"></app-interview>
  </div>
</section>

<app-footer></app-footer>
