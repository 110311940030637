import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Topics } from '~/app/shared/topics.type';

@Component({
  selector: 'app-interviews',
  templateUrl: './interviews.component.html',
  styleUrls: ['./interviews.component.scss']
})
export class InterviewsComponent implements OnInit {
  @Input() header?: string;
  @Input() courses: Topics[] = [];

  constructor(private route: Router) {
   }

  ngOnInit(): void {
    if (!this.header){
      this.header = "生涯访谈";
    }
  }

  more(){
    this.route.navigate(["videos", "生涯访谈"]);
  }
}
