import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Topics } from '~/app/shared/topics.type';

@Component({
  selector: 'app-shows',
  templateUrl: './shows.component.html',
  styleUrls: ['./shows.component.scss']
})
export class ShowsComponent implements OnInit {
  @Input() courses: Topics[];

  constructor(private route: Router) { }

  ngOnInit(): void {
  }

  more(){
    this.route.navigate(["videos", "高校展播"]);
  }

}
