import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpService } from 'src/app/service/http.service';
import { UserService } from 'src/app/service/user.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { contains } from 'src/app/utility/collection';
import { environment } from '~/environments/environment';
import {
  EnrollIntroduction,
  UnivDetail,
  UnivInfo,
} from '~/app/shared/university.type';
import {
  CollegeScore,
  EnrollData,
  MajorEnrollInfo,
  compareEnrollPlan,
  compareMajor,
  compareScore,
} from '~/app/shared/enroll.typs';
import { MyChoice } from '~/app/shared/mychoice.type';

@Component({
  selector: 'app-collect-univ',
  templateUrl: './univ.component.html',
  styleUrls: ['./univ.component.scss'],
})
export class UnivCollectComponent implements OnInit {
  @Input() data: UnivInfo;
  @Input() myChoice: MyChoice;
  @Input() compareUnivs: string[];
  @Input() compare : boolean = true;
  @Output() onCompareChanged: EventEmitter<string> = new EventEmitter();

  univ: UnivDetail;
  univMajors = '';

  busy = false;

  constructor(
    private http: HttpService,
    private userService: UserService,
    private message: NzMessageService) {
    this.compareUnivs = [];
  }

  async ngOnInit() {
    if (!this.data.logoUrl) {
      this.data.logoUrl = environment.noLogoUrl;
    }
  }

  favoriteUniv(univ: string): boolean {
    if (!this.myChoice) {
      return false;
    }

    return contains(this.myChoice?.myCollege?.split(','), univ);
  }

  addFavorite(univ: string) {
    if (this.myChoice.myCollege.indexOf(',' + univ) < 0) {
      this.myChoice.myCollege = this.myChoice.myCollege + ',' + univ;
      this.saveFavorite();
    }
  }
  filternatureType(natureType) {
    let type = ''
    if (natureType === 'public' || natureType ==='公办') {
      type = '公办';
    } else if (natureType === 'private' || natureType === '民办') {
      type = '民办';
    } else {
      type = '中外合作';
    }
    return type
  }
  filtereduLevel(eduLevel) {
    let str = ''
    if (eduLevel === 'zhuan' || eduLevel === '专科') {
      str = '专科';
    } else {
      str = '本科';
    }
    return str
  }
  async saveFavorite() {
    const action = '/v1/personal/set-my-choice';

    const params = {
      myCollege: this.myChoice.myCollege,
      myMajor: this.myChoice.myMajor,
      myProfession: this.myChoice.myProfession,
      userCode: this.userService.code(),
    };

    await this.http.post(action, params);
  }

  compareUniv(univ: string): boolean {
    return contains(this.compareUnivs, univ);
  }

  addCompare(univ: string) {
    this.onCompareChanged.emit(univ);
  }

  showDetail = false;

  handleCancel() {
    this.showDetail = false;
  }

  async handleOk($event) {
    // this.addFavorite($event);
    const action = '/v1/MyFavorite/delete-my-favorite';
    const params = {
      code: '',
      resourceType: '院校',
      userCode: this.userService.code(),
      resourceId: this.data.id
    };
    await this.http.post(action, params);
    this.message.success('取消收藏成功');
    this.onCompareChanged.emit($event);
    this.showDetail = false;
  }

  async detail(id, name, code) {
    this.busy = true;

    const action = '/v1/college/find';
    const params = {
      id: id,
    };

    this.univ = await this.http.post(action, params);
    if (!this.univ) {
      this.message.error('大学信息获取错误，请稍后重试！');
      this.busy = false;
      return;
    }

    this.univMajors = this.univ.majors.map((c) => c.name).join(', ');

    await this.getCollegeScores(name);
    await this.getMajorScores(name);
    await this.getCollegePlans(name);
    await this.getEnrollIntroduction(code);

    this.busy = false;
    this.showDetail = true;
  }

  majorScores: MajorEnrollInfo[] = [];

  async getMajorScores(name: string) {
    const action = '/v1/enroll2/list-college-major-scores';
    const params = {
      collegeName: name,
      provinceCode: this.userService.provinceCode(),
    };

    let data = await this.http.post(action, params);
    if (data) {
      this.majorScores = data.sort((a,b)=>compareMajor(a,b));
    }
  }

  collegeScores: CollegeScore[] = [];
  async getCollegeScores(name: string) {
    const college_score_action = '/v1/college-enroll/list-province-college';

    const provinceCode = this.userService.provinceCode();
    const params = {
      code: provinceCode,
    };

    this.collegeScores = await this.http.post(college_score_action, params);
    if (this.collegeScores) {
      this.collegeScores = this.collegeScores
        .filter((c) => c.college === name)
        .sort((a, b) => compareScore(a, b));
    }
  }

  enrollPlan: EnrollData[] = [];
  async getCollegePlans(name: string) {
    const provinceCode = this.userService.provinceCode();
    const action = '/v1/enroll2/list-college-enroll-plan';

    const params = {
      provinceCode: provinceCode,
      collegeName: name,
    };

    const data: EnrollData[] = await this.http.post(action, params);
    this.enrollPlan = data?.sort((a, b) => compareEnrollPlan(a, b));
  }

  current: EnrollIntroduction = undefined;
  enrollIntro: EnrollIntroduction[] = [];
  async getEnrollIntroduction(code: string) {
    const action = '/v1/enroll2/find-collge-enroll-introduction';
    const params = {
      code: code,
    };

    this.enrollIntro = await this.http.post(action, params);
    this.current = this.enrollIntro?.length > 0 ? this.enrollIntro[0] : null;
  }

  showIntro(dt: EnrollIntroduction) {
    this.current = dt;
  }
}
