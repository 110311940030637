<div class="card" (click)="click()">
  <img src="{{ imgUrl }}" alt="" />
  <!-- <img src = "../../../../assets/logos/生涯评测.png" /> -->
</div>

<nz-modal
  [(nzVisible)]="showPay"
  nzTitle="会员充值"
  nzWidth="800px"
  (nzOnCancel)="payClose()"
>
  <ng-container *nzModalContent>
    <p>您目前的身份为游客，无法使用该功能。请升级至会员，让我们更好为您服务!</p>

    <div class="m-content">
      <div class="left">
        <!-- <div *ngFor="let p of prices">
          <button nz-button nzType="primary" (click)="pay(p)">会员服务</button>
          &nbsp; &nbsp;
          <span>{{ p.description }}</span>
        </div> -->
        <div>
          <button nz-button nzType="primary" (click)="pay(prices[0])">生涯规划教育会员</button>  &nbsp;  &nbsp;
          <span>{{prices[0].description}}</span>
        </div>
        <!-- <div>
          <button nz-button nzType="primary" (click)="pay(prices[1])">生涯规划教育会员</button>  &nbsp;  &nbsp;
          <span>365元/年，原价699元</span>
        </div>
        <div>
          <button nz-button nzType="primary" (click)="pay(prices[2])">生涯规划教育会员</button>  &nbsp;  &nbsp;
          <span>599元/2年，原价1199元</span>
        </div>
        <div>
          <button nz-button nzType="primary" (click)="pay(prices[3])">生涯规划教育会员</button>  &nbsp;  &nbsp;
          <span>799元/3年，原价1999元</span>
        </div>
        <div>
          <button nz-button nzType="primary" (click)="pay(prices[4])">志愿填报会员</button>  &nbsp;  &nbsp;
          <span>999/1年，原价2999</span>
        </div> -->
      </div>

      <div id="right"></div>
    </div>

    <p *ngIf="cur" class="bold">您当前选择：{{ cur?.description }}</p>
    <!-- <p class="red bold">
      高三志愿填报会员提示：高三志愿填报专属会员（自充值之日起1年内，可畅享平台所有功能，“志愿深度分析报告”功能不受时间限制，建议高三学生或家长购买）
    </p> -->

    <!-- <div class="imgs">
      <img src = '../../../../assets/banners/001.jpg' alt="">
      <img src = '../../../../assets/banners/002.jpg' alt="">
      <img src = '../../../../assets/banners/003.jpg' alt="">
      <img src = '../../../../assets/banners/004.jpg' alt="">
      <img src = '../../../../assets/banners/005.jpg' alt="">
    </div> -->
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="payClose()">关闭</button>
  </div>
</nz-modal>
