import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-select',
  templateUrl: './course-select.component.html',
  styleUrls: ['./course-select.component.scss']
})
export class CourseSelectComponent implements OnInit {
  imgUrls = ["https://resources.delesson.cn/imgs/模拟选科.png",
  "https://resources.delesson.cn/imgs/志愿填报.png",
  "https://resources.delesson.cn/imgs/招考数据.png"];

  constructor() { }

  ngOnInit(): void {
  }

}
