import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from 'src/app/service/http.service';
import { LocalStorageService } from 'src/app/service/local-storage.service';
import { UserService } from 'src/app/service/user.service';
import { Info } from '~/app/shared/enroll.typs';

@Component({
  selector: 'app-intelligent-choose',
  templateUrl: './intelligent-choose.component.html',
  styleUrls: ['./intelligent-choose.component.scss'],
})
export class IntelligentChooseComponent implements OnInit {
  cumbs = ['首页', '一键填报'];

  busy: boolean = false;
  isVisible: boolean = false;
  data: Info[] = [];
  filtered: Info[] = [];
  courses: string;
  value: string='';
  score: number;

  typeSelected: string = '';
  typeCount = [0, 0, 0];

  eduLevel = '本科,专科,';
  features = '985,211,双一流A,双一流B,国重点,省重点,省属,';
  provinces =
    '北京,天津,河北,辽宁,山西,内蒙,吉林,黑龙江,上海,安徽,山东,江苏,福建,河南,浙江,江西,湖北,湖南,海南,贵州,陕西,广东,重庆,云南,甘肃,广西,四川,青海,宁夏,新疆,香港,澳门,';
  categories = '综合,理工,医药,艺术,军事,师范,农林,政法,财经,语言,民族,体育,';
  natureTypes = '公办,民办,合作办学,';

  constructor(
    private userService: UserService,
    private storage: LocalStorageService,
    private http: HttpService,
    private message: NzMessageService,
    private route: Router
  ) {}

  showModal() {
    this.isVisible = true;
  }
  async ngOnInit() {
    this.courses = this.storage.get('courses');
    this.score = this.storage.get('score');
    if (!this.score) {
      this.score = 700;
    }
    await this.fetchMyChoice()
    this.fetchData();
  }
  selectedValue = ''
  myCourses = []
  school = []
  defaultSchool = {
    name: '',
    label: '',
    options: []
  }
  allSchool = []
  nums = ['一', '二', '三', '四', '五', '六']
  async fetchMyChoice() {
    const action = '/v1/personal/list-my-choice';
    const params = {
      userCode: this.userService.code(),
    };
    const { colleges } = await this.http.post(action, params);
    colleges.forEach(item => {
      if(!this.school.includes(item.myCollege)) {
          this.school.push(item.myCollege)
      }
    })
    this.school.forEach((item, index) => {
      const obj: any = {
        name: item,
        label: `第${this.nums[index]}目标大学`,
        options: []
      }
      colleges.forEach((child, idx) => {
        if(obj.name == child.myCollege) {
          obj.options.push({
            ...child, 
            label: `第${this.nums[idx]}目标专业`,
            poor: child.minScore - this.score,
            match: this.courses.includes(child.course)
          })
        }
      })
      this.allSchool.push(obj)
    })
    this.defaultSchool = this.allSchool[0]
    this.selectedValue = this.defaultSchool?.name
  }
  handleOk(): void {
    this.defaultSchool = this.allSchool.find(item => item.name == this.selectedValue)
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  async fetchData() {
    this.busy = true;
    const action = '/v1/enroll2/alist-enroll-by-score-courses-all';

    const provinceCode = this.userService.provinceCode();
    const params = {
      courses: this.courses,
      score: this.score,
      provinceCode: provinceCode,
    };

    const data = await this.http.post(action, params);
    if (!data) {
      this.message.error('数据获取失败');
      this.busy = false;
      return;
    } else {
      this.data = data;
      this.data.forEach((c) => this.processUnivInfo(c));

      this.calcType(this.data);
      this.setOrder(this.data);
      this.filter();

      this.busy = false;
    }
  }

  processUnivInfo(data: Info) {
    if (data.brief.eduLevel === 'zhuan') {
      data.brief.eduLevel = '专科';
    } else {
      data.brief.eduLevel = '本科';
    }

    if (data.brief.natureType === 'public') {
      data.brief.natureType = '公办';
    } else if (data.brief.natureType === 'private') {
      data.brief.natureType = '民办';
    } else {
      data.brief.natureType = '中外合作';
    }

    return data;
  }

  private setOrder(data: Info[]): void {
    for (let i = 0; i < data.length; i++) {
      data[i].ord = i;
    }
  }

  private calcType(data: Info[]) {
    for (let dt of data) {
      if (dt.probability <= 59) {
        dt.type = '冲';
      } else if (dt.probability <= 79) {
        dt.type = '稳';
      } else {
        dt.type = '保';
      }
    }

    this.calcCount();
  }

  private calcCount() {
    this.typeCount[0] = this.data.filter((c) => c.type === '冲').length;
    this.typeCount[1] = this.data.filter((c) => c.type === '稳').length;
    this.typeCount[2] = this.data.filter((c) => c.type === '保').length;
  }

  filterType(type: string) {
    if (this.typeSelected.indexOf(type) >= 0) {
      this.typeSelected = this.typeSelected.replace(type + ',', '');
    } else {
      this.typeSelected = this.typeSelected.concat(type + ',');
    }

    this.filter();
  }

  filterEduLevel(eduLevel: string) {
    if (this.contains(this.eduLevel, eduLevel)) {
      this.eduLevel = this.eduLevel.replace(eduLevel + ',', '');
    } else {
      this.eduLevel = this.eduLevel.concat(eduLevel + ',');
    }

    this.filter();
  }

  filterFeatures(feature: string) {
    if (this.contains(this.features, feature)) {
      this.features = this.features.replace(feature + ',', '');
    } else {
      this.features = this.features.concat(feature + ',');
    }

    this.filter();
  }

  filterProvince(province: string) {
    if (this.contains(this.provinces, province)) {
      this.provinces = this.provinces.replace(province + ',', '');
    } else {
      this.provinces = this.provinces.concat(province + ',');
    }

    this.filter();
  }

  filterCategory(category: string) {
    if (this.contains(this.categories, category)) {
      this.categories = this.categories.replace(category + ',', '');
    } else {
      this.categories = this.categories.concat(category + ',');
    }

    this.filter();
  }

  filterNatureType(natureType: string) {
    if (this.contains(this.natureTypes, natureType)) {
      this.natureTypes = this.natureTypes.replace(natureType + ',', '');
    } else {
      this.natureTypes = this.natureTypes.concat(natureType + ',');
    }

    this.filter();
  }

  contains(str, pattern: string): boolean {
    const str1Arr = str.split(',').filter((c) => c !== '');
    const patternArr = pattern.split(',').filter((c) => c !== '');

    return str1Arr.some((c) => patternArr.includes(c));
  }

  filter() {
    let result = this.data;
    if (this.typeSelected) {
      result = result.filter((c) => this.contains(this.typeSelected, c.type));
    }

    if (this.eduLevel) {
      result = result.filter((c) =>
        this.contains(this.eduLevel, c.brief.eduLevel)
      );
    }

    if (this.features) {
      result = result.filter((c) =>
        this.contains(this.features, c.brief.feature)
      );
    }

    if (this.provinces) {
      result = result.filter((c) =>
        this.contains(this.provinces, c.brief.provinceName)
      );
    }

    if (this.categories) {
      result = result.filter((c) =>
        this.contains(this.categories, c.brief.category)
      );
    }

    if (this.natureTypes) {
      result = result.filter((c) =>
        this.contains(this.natureTypes, c.brief.natureType)
      );
    }

    this.filtered = result;
//    this.calcCount();

    this.gotoPage();
  }

  delete(i: number) {
    let idx = (this.pageIndex - 1) * this.pageSize + i;
    let ord = this.filtered[idx].ord;
    this.filtered.splice(idx, 1);
    this.data.splice(ord, 1);

    this.calcCount();

    this.gotoPage();
  }

  moveUp(i: number) {
    let idx = (this.pageIndex - 1) * this.pageSize + i;
    this.swap(idx, idx - 1);

    this.gotoPage();
  }

  moveDown(i: number) {
    let idx = (this.pageIndex - 1) * this.pageSize + i;
    this.swap(idx, idx + 1);

    this.gotoPage();
  }
  swap(i, j: number) {
    if (i >= this.filtered.length || j >= this.filtered.length) {
      return;
    }

    if (i < 0 || j < 0) {
      return;
    }

    let cloned = this.filtered.slice();
    [cloned[i], cloned[j]] = [cloned[j], cloned[i]];

    this.filtered = cloned;

    const ord1 = this.filtered[i].ord;
    const ord2 = this.filtered[j].ord;
    [this.data[ord1], this.data[ord2]] = [this.data[ord2], this.data[ord1]];
  }

  eduLevelChanged($event) {
    this.eduLevel = $event;
    this.filter();
  }

  categoryChanged($event) {
    this.categories = $event;
    this.filter();
  }

  featureChanged($event) {
    this.features = $event;
    this.filter();
  }

  natureTypeChanged($event) {
    this.natureTypes = $event;
    this.filter();
  }

  provinceChanged($event) {
    this.provinces = $event;
    this.filter();
  }

  currPage: Info[] = [];
  pageSize = 10;
  pageIndex = 1;

  gotoPage() {
    this.currPage = this.filtered.slice(
      (this.pageIndex - 1) * this.pageSize,
      this.pageIndex * this.pageSize
    );
  }

  async save() {
    const action = '/v1/enroll2/submit-my-selection';
    let xuanke = {
      id: 0,
      courses: this.courses,
      provinceCode: this.userService.provinceCode(),
      score: this.score,
    };

    let params = {
      detail: this.filtered,
      userCode: this.userService.code(),
      xuanke: xuanke,
    };

    await this.http.post(action, params);

    this.message.info('保存成功');
  }

  changeCourse($event) {
    this.courses = $event;
  }

  changeScore($event) {
    this.score = $event;
    this.storage.set('courses', this.courses);

    this.fetchData();
  }

  search(){
    if (!this.value || this.value === ''){
      this.filter();
      return;
    }

    this.filtered = this.data.filter(c=>c.brief.name.includes(this.value) || c.brief.shortName.includes(this.value) ||c.info[0]?.major.includes(this.value));
    this.pageIndex= 1;
    this.gotoPage();
  }

}
