<section>
  <div class="container">
    <nz-card [nzTitle]="item.createdOn" class="m-b" *ngFor="let item of colleges">
      <nz-descriptions>
        <nz-descriptions-item nzTitle="目标职业" *ngFor="let child of item.options">
          {{child.myJob}}
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-card>
  </div>
</section>
