import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from 'src/app/service/http.service';
import { UserService } from 'src/app/service/user.service';
import { findKeyByValue, getValues } from 'src/app/utility/collection';
import { getFutureYears, isValidName } from 'src/app/utility/common';
import { LocalStorageService } from '~/app/service/local-storage.service';

@Component({
  selector: 'app-login-regist-info',
  templateUrl: './login-regist-info.component.html',
  styleUrls: ['./login-regist-info.component.scss'],
})
export class LoginRegistInfoComponent implements OnInit {
  rememberMe = false;
  years = [];
  provinces: Map<string, string> = new Map();
  provNames: string[] = [];

  infoForm: FormGroup;

  constructor(
    private http: HttpService,
    private router: Router,
    private storage: LocalStorageService,
    private message: NzMessageService,
    private fb: FormBuilder
  ) {
    this.years = getFutureYears(3);
    const year = this.years[0];

    this.infoForm = this.fb.group({
      name: ['', Validators.required],
      year: [year.toString(), [Validators.required]],
      gender: ['男', Validators.required],
      province: ['北京市', Validators.required],
    });
  }

  async ngOnInit() {
    await this.getProvinces();
  }

  async submit() {
    const data = {
      name: this.infoForm.value['name'],
      year: this.infoForm.value['year'],
      gender: this.infoForm.value['gender'],
      province: this.infoForm.value['province'],
    };

    const provCode = findKeyByValue(this.provinces, data.province);
    if (!isValidName(data.name)) {
      this.message.error('姓名不能为空, 且至少包含字数为2！');
      return;
    }

    const action = '/v1/user/register';
    const params = {
      appId: '000000',
      enrollYear: data.year,
      mobilePhone: this.storage.get('phone'),
      name: data.name,
      password: this.storage.get('password'),
      provinceCode: provCode,
    };

    const result = await this.http.post(action, params);
    if (result) {
      this.message.info("注册成功，请登录进入系统!");
      this.storage.clear();
      await this.router.navigate(['/login']);
    }
  }

  async getProvinces() {
    const action = '/v1/user/list-provinces';
    const params = {};

    this.provinces = await this.http.post(action, params);
    this.provNames = getValues(this.provinces);
  }
}
