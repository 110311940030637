import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from 'src/app/service/http.service';
import { UserService } from '~/app/service/user.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MyChoice } from '~/app/shared/mychoice.type';
import {
  ProfessionDetail,
  ProfessionInfo,
  ProfessionSummary,
} from '~/app/shared/profession.type';
import { family2StudentCode } from '~/app/utility/domain';

@Component({
  selector: 'app-enc-profession',
  templateUrl: './enc-profession.component.html',
  styleUrls: ['./enc-profession.component.scss'],
})
export class EncMyProfessionComponent implements OnInit {
  data: ProfessionInfo[] = [];
  current: ProfessionInfo[] = [];
  jobs = [];
  loading = false;
  code = this.userService.isFamily()
    ? family2StudentCode(this.userService.code())
    : this.userService.code();
  cumbs = ['首页', '职业百科'];

  constructor(
    private http: HttpService,
    private message: NzMessageService,
    private location: Location,
    private router: Router,
    private userService: UserService
  ) {}

  async ngOnInit() {
    await this.fetchData();
    await this.fetchMyChoice();
    this.showProfession(this.data[0].code);
  }

  async fetchData() {
    const action = '/v1/profession/list-profression-tree';
    const params = {};

    this.data = await this.http.post(action, params);
    if (!this.data) {
      this.message.error('数据获取失败');
    }
  }
  async fetchMyChoice() {
    const action = '/v1/personal/list-my-choice';
    const params = {
      userCode: this.userService.code()
    };
    const {jobs} = await this.http.post(action, params);
    this.jobs = jobs.map(item => {
      return {
        name: item.myJob,
        code: item.myJobParent
      }
    })
  }
  showProfession(code: string) {
    this.current = this.data.find((c) => c.code === code).children;
  }

  details: ProfessionDetail = null;
  showDetail = false;

  async detail($event) {
    if (this.jobs.length >= 6) {
      this.message.error('最多选择6个职业');
      return;
    }
    const temp = this.jobs.find((item) => item.name == $event.name);
    if (temp) return;
    this.jobs.push($event);
    // const url = `/job/${$event}.json`;
    // const summary = await this.http.get(url);
    // if (!summary){
    //   return;
    // }

    // const infos = summary['result'];

    // const action = '/v1/profession/list-profression-detail';
    // const params = {
    //   code: $event,
    // };

    // this.details = await this.http.post(action, params);
    // this.details.majors = infos;

    // this.showDetail = true;
  }
  async save() {
    if (this.jobs.length === 0) {
      this.message.error('至少选择一个职业');
      return;
    }
    this.loading = true;
    const action = '/v1/personal/set-user-choice';
    const jobs = this.jobs.map((item, index) => {
      return {
        choiceOrder: index,
        myJob: item.name,
        myJobParent: item.code,
        userCode: this.code,
      };
    });
    const params = {
      jobs,
    };
    try {
      await this.http.post(action, params);
      this.loading = false;
      this.message.success('保存成功');
      this.router.navigate(['/career']);
    } catch {
      this.loading = false;
    }
  }
  deleteJob(index) {
    this.jobs.splice(index, 1);
  }
  handleCancel() {
    this.showDetail = false;
  }

  async handleOk($event: string) {
    if (!$event) {
      return;
    }

    const action = '/v1/personal/find-my-choice';
    const params = {
      userCode: this.userService.code(),
    };

    let myChoice: MyChoice = await this.http.post(action, params);
    if (!myChoice) {
      myChoice = {
        myCollege: '',
        myMajor: '',
        myProfession: '',
        id: null,
        userCode: this.userService.code(),
      };
    }

    if (myChoice?.myProfession.indexOf(',' + $event) < 0) {
      myChoice.myProfession += ',' + $event;

      const action = '/v1/personal/set-my-choice';
      const params = {
        myCollege: myChoice.myCollege,
        myMajor: myChoice.myMajor,
        myProfession: myChoice.myProfession,
        userCode: this.userService.code(),
      };

      await this.http.post(action, params);
    }

    this.showDetail = false;
  }

  value: string = '';
  search() {
    if (!this.value || this.value === '') {
      this.showProfession(this.data[0].code);
      return;
    }

    let selected: ProfessionInfo[] = [];
    for (let pro of this.data) {
      const children = pro.children;
      for (let child of children) {
        if (child.name.includes(this.value)) {
          selected.push(child);
          break;
        }

        const grand = child.children;
        if (!grand) {
          continue;
        }
        let contained = false;

        for (let g of grand) {
          if (g.name.includes(this.value)) {
            selected.push(child);
            contained = true;
            break;
          }
        }

        if (contained) {
          break;
        }
      }
    }

    this.current = selected;
  }
}
