<div class="container">
  <div class="login-box">
    <div class="left"></div>
    <div class="right">
      <form nz-form [formGroup]="regForm">
        <p class="center title">新用户注册</p>
        <p class="center normal">在您创建平台新账号时，平台需要验证您的身份，请输入孩子的学籍号进行匹配。（询问孩子或班主任获得学籍号）</p>

        <p class="title">孩子学籍号</p>
        <nz-form-item>
          <nz-form-control nzErrorTip="孩子学籍号">
            <nz-input-group  nzSize="large">
              <input
                type="text"
                nz-input
                formControlName="xuejihao"
                placeholder="孩子学籍号"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <p class="title">手机号码</p>
        <nz-form-item>
          <nz-form-control nzErrorTip="手机号码">
            <nz-input-group  nzSize="large">
              <input
                type="text"
                nz-input
                formControlName="phone"
                placeholder="手机号码"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <p class="title">手机验证码</p>
        <nz-form-item>
          <nz-form-control nzErrorTip="验证码">
            <nz-input-group class="code" nzSize="large">
              <input
                type="text"
                nz-input
                formControlName="smsCode"
                placeholder="验证码"
              />
            </nz-input-group>
            <button class="code-button" nz-button nzType="primary" (click)="mobileCode()">
              {{buttonText}}
            </button>
          </nz-form-control>
        </nz-form-item>

        <p class="title">家长姓名</p>
        <nz-form-item>
          <nz-form-control nzErrorTip="家长姓名">
            <nz-input-group  nzSize="large">
              <input
                type="text"
                nz-input
                formControlName="parentName"
                placeholder="家长姓名"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <p class="title">家长身份</p>
        <nz-form-item>
          <nz-form-control nzErrorTip="选择身份" >
            <nz-select formControlName="shenfen" nzPlaceHolder="选择身份" nzSize="large">
              <nz-option nzValue="爸爸" nzLabel="爸爸"></nz-option>
              <nz-option nzValue="妈妈" nzLabel="妈妈"></nz-option>
              <nz-option nzValue="爷爷" nzLabel="爷爷"></nz-option>
              <nz-option nzValue="奶奶" nzLabel="奶奶"></nz-option>
              <nz-option nzValue="外公" nzLabel="外公"></nz-option>
              <nz-option nzValue="外婆" nzLabel="外婆"></nz-option>
              <nz-option nzValue="其他" nzLabel="其他"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <p class="title">输入密码</p>
        <nz-form-item>
          <nz-form-control nzErrorTip="输入密码">
            <nz-input-group nzPrefixIcon="lock" nzSize="large">
              <input
                type="password"
                nz-input
                formControlName="password"
                placeholder="密码"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>


        <p class="buttons">
          <span>
            <label nz-checkbox formControlName="agree"> </label>
            我同意<a href ="{{agreeUrl}}" target="_blank">用户协议</a>和<a href ="{{protectUrl}}" target="_blank">隐私保护协议</a>
          </span>
          <span>
            <button nz-button nzType="primary" (click)="submit()">
              下一步
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</div>
