import { Component, Input, OnInit } from '@angular/core';
import { Topics } from '~/app/shared/topics.type';

@Component({
  selector: 'app-comp-attention',
  templateUrl: './comp-attention.component.html',
  styleUrls: ['./comp-attention.component.scss']
})
export class CompAttentionComponent implements OnInit {

  @Input() data: Topics;

  constructor() { }

  ngOnInit(): void {
  }

}
