/**
 * 将学校类型转换为汉字
 * @param natureType public--公办  private--民办  其它--合作办学
 * @returns  汉字表示
 */
export function getNatureType(natureType: string): string {
  if (natureType?.indexOf('public') >= 0) {
    return '公办';
  }

  if (natureType?.indexOf('private') >= 0) {
    return '民办';
  }

  return '合作办学';
}

/**
 * 将办学类型转换为汉字
 * @param level 办学类型，ben--本科  zhuan--专科
 * @returns  中文表达
 */
export function getBXLevel(level: string): string {
  return level === 'ben' ? '本科' : '专科';
}

/**
 * 得到一个学生的编码，用于页面中，通过学生代码获取数据
 * @param code 教师代码
 * @returns 学生代码
 */
export function teacher2StudentCode(code: string): string {
  return code.substring(0, 9) + "S250101";
}

/**
 * 将家长代码转换为学生代码
 * @param code 家长代码
 * @returns 学生代码
 */
export function family2StudentCode(code: string): string{
  return code.substring(0, 16);
}

/**
 * 根据招生模式和选科情况，判断能否选择某个课程，主要针对A和B，物理和历史不同同时选择
 * @param courseSelected 已选课程
 * @param select  要选课程
 * @param mode  招生模式
 * @returns  能否选择该课程
 */
export function canSelectCourse(courseSelected: Map<string, boolean>, select, mode: string): boolean {
  let result = true;
  if (mode === 'A' || mode === "B"){
    if (select === "历史"){
      result = !courseSelected.get("物理")
    }

    if (select === "物理"){
      result = !courseSelected.get("历史");
    }
  }

  return result;
}
