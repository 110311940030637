import { Component, OnInit,Output, Input, EventEmitter } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from 'src/app/service/http.service';
import { UserService } from '~/app/service/user.service';
import { MajorDetail, MajorInfo } from '~/app/shared/major.type';
import { Future, TypeResult } from '~/app/shared/majordesc.type';
import { MyChoice } from '~/app/shared/mychoice.type';

@Component({
  selector: 'app-collect-major',
  templateUrl: './major.component.html',
  styleUrls: ['./major.component.scss'],
})
export class MajorCollectComponent implements OnInit {
  @Input() data;
  @Output() onCompareChanged: EventEmitter<string> = new EventEmitter();
  constructor(
    private http: HttpService,
    private userService: UserService,
    private message: NzMessageService
  ) {}

  async ngOnInit() {
  }
  showDetail = false;
  major: MajorDetail;

  majorDesc: TypeResult[] = [];
  async detail($event) {
    this.major = null;

    let url = `/major/details/${$event}.json`;
    let detail = await this.http.get(url);
    this.major = detail['major'];

    url = `/major/feature/${$event}.json`;
    let desc = await this.http.get(url);
    this.majorDesc = desc['result'];

    this.showDetail = this.major !== null;
  }
  handleCancel() {
    this.showDetail = false;
  }
  getXinZiPJOption() {
    let data = this.getFutureData('XinZiPJ');

    if (!data) {
      return;
    }
    let years = data.map((c) => c.name);
    let values = data.map((c) => c.value);

    return {
      title: {
        text: '月薪资(元)',
      },
      tooltip: {
        show: true,
        formatter: function (params) {
          return params["name"] + '年平均' + params["value"] + '元';
        },
      },
      xAxis: {
        type: 'category',
        data: years,
        name: '年',
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: values,
          type: 'line',
          itemStyle: {
            normal: {
              color: '#FF0000', //改变折线点的颜色
              lineStyle: {
                color: '#a80000', //改变折线颜色
              },
            },
          },
        },
      ],
    };
  }
  getFutureData(type: string): Future[] {
    return this.majorDesc.find((c) => c.type === type)?.futures;
  }
  getPieOption(type: string) {
    let data = this.getFutureData(type);

    if (!data) {
      return;
    }

    let names = data.map((c) => c.name);
    let values = data.map((c) => c.value);

    let pieData = [];
    for (let i = 0; i < names.length; i++) {
      pieData.push({ name: names[i], value: values[i] });
    }

    return {
      series: [
        {
          type: 'pie',
          data: pieData,
          label: {
            show: true,
            formatter: function (arg) {
              return arg.data.name + arg.percent + '%';
            },
          },
          roseType: 'radius',
        },
      ],
    };
  }
  getDirection(): string {
    return this.major.eduLevel === 'zhuan' ? '升本方向' : '考研方向';
  }

  getDirectionDetail(): string {
    return this.major.studyDirection && this.major.studyDirection !== ''
      ? this.major.studyDirection
      : this.major.zhuanToBenOrient;
  }
  select($event) {
    let div: string = null;
    let option: any = [];

    if ($event === 1) {
      div = 'salary';
      option = this.getXinZiPJOption();
    } else if ($event === 2) {
      div = 'zhiye';
      option = this.getPieOption('ZhiYeFX');
    } else if ($event === 3) {
      div = 'hangye';
      option = this.getPieOption('HangYeFB');
    } else if ($event === 4) {
      div = 'chengshi';
      option = this.getPieOption('ChengShiFB');
    }

    if (div) {
      const doc = document.getElementById(div);
      const echart = echarts.init(doc);
      echart.setOption(option, true);
    }
  }
  show(type: string): boolean {
    let data = this.getFutureData(type);
    if (!data) {
      return false;
    }

    let filtered = data.filter((c) => c.name && c.value);
    return filtered.length > 0;
  }
  async handleOk($event) {
    const action = '/v1/MyFavorite/delete-my-favorite';
    const params = {
      code: $event?.code,
      resourceType: '专业',
      userCode: this.userService.code(),
      resourceId: ''
    };
    await this.http.post(action, params);
    this.message.success('取消收藏成功');
    this.onCompareChanged.emit($event);
    this.showDetail = false;
  }
}
