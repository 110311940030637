import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from 'src/app/service/http.service';
import { UserService } from '~/app/service/user.service';
import { MajorDetail, MajorInfo } from '~/app/shared/major.type';
import { Future, TypeResult } from '~/app/shared/majordesc.type';
import { MyChoice } from '~/app/shared/mychoice.type';
import { Router } from '@angular/router';
import { Major } from '~/app/shared/university.type';
import { contains } from '~/app/utility/collection';

@Component({
  selector: 'app-enc-major',
  templateUrl: './enc-major.component.html',
  styleUrls: ['./enc-major.component.scss'],
})
export class EncMyMajorComponent implements OnInit {
  benke: MajorInfo[] = [];
  zhuanke: MajorInfo[] = [];
  data: MajorInfo[] = [];
  professions = [];
  course = ''
  courses = ''
  current: MajorInfo[];
  tabSelect = 0;
  direction = '';
  code = this.userService.code().substring(0, 2);

  cumbs = ['首页', '专业百科'];

  constructor(
    private http: HttpService,
    private userService: UserService,
    private router: Router,
    private message: NzMessageService
  ) {}

  async ngOnInit() {
    await this.fetchSubject()
    await this.fetchData();
    // await this.fetchMyChoice();
    this.initMajor();
  }
  async fetchSubject() {
    let code = this.userService.code();
    const action = '/v1/personal/get-my-courses';
    const params = {
      userCode: code,
    };
    const data = await this.http.post(action, params);
    this.course = data?.course;
    this.courses = data?.courses;
  }
  async fetchData() {
    const action = '/v1/major/list-by-level_province';
    let params = {
      code: this.code,
      level: 'ben',
      courses: this.courses,
      course: this.course,
    };

    this.benke = await this.http.post(action, params);
    if (!this.benke) {
      this.message.error('数据获取失败');
    }

    params = {
      code: this.code,
      level: 'zhuan',
      courses: this.courses,
      course: this.course,
    };

    this.zhuanke = await this.http.post(action, params);
    if (!this.zhuanke) {
      this.message.error('数据获取失败');
    }

    this.data = [];
    this.data = this.data.concat(this.benke);
    this.data = this.data.concat(this.zhuanke);
  }
  async fetchMyChoice() {
    const action = '/v1/personal/list-my-choice';
    const params = {
      userCode: this.userService.code(),
    };

    const { colleges } = await this.http.post(action, params);
    this.professions = colleges.map((item) => {
      return {
        code: item.myMajorCode,
        name: item.myMajor,
      };
    });
  }
  initMajor() {
    this.showMajor(
      this.tabSelect === 0 ? this.benke[0].code : this.zhuanke[0].code
    );
  }

  selectChange() {
    this.initMajor();
  }

  showMajor(majorCode: string) {
    this.current = this.data.find((c) => c.code === majorCode).children;
  }

  getMajorCode(majorCode: string): string {
    return majorCode.padEnd(6, '0');
  }

  showDetail = false;
  major: MajorDetail;

  majorDesc: TypeResult[] = [];

  async detail($event) {
    if (this.professions.length >= 6) {
      this.message.error('最多选择6个专业');
      return;
    }
    const temp = this.professions.find((item) => item.name == $event.name);
    if (temp) return;
    this.professions.push({...$event, edulevel: this.tabSelect == 0 ? 'ben' : 'zhuan'});
    // this.major = null;

    // let url = `/major/details/${$event}.json`;
    // let detail = await this.http.get(url);
    // this.major = detail['major'];

    // url = `/major/feature/${$event}.json`;
    // let desc = await this.http.get(url);
    // this.majorDesc = desc['result'];

    // this.showDetail = this.major !== null;
  }
  deleteProfession(index) {
    this.professions.splice(index, 1);
  }
  handleMajor() {
    if (this.professions.length === 0) {
      this.message.error('请选择专业');
      return;
    }
    localStorage.setItem('professions', JSON.stringify(this.professions));
    this.router.navigate(['/select-profession']);
  }
  getDirection(): string {
    return this.major.eduLevel === 'zhuan' ? '升本方向' : '考研方向';
  }

  getDirectionDetail(): string {
    return this.major.studyDirection && this.major.studyDirection !== ''
      ? this.major.studyDirection
      : this.major.zhuanToBenOrient;
  }

  handleCancel() {
    this.showDetail = false;
  }

  async handleOk($event: string) {
    if (!$event) {
      return;
    }

    const action = '/v1/personal/find-my-choice';
    const params = {
      userCode: this.userService.code(),
    };

    let myChoice: MyChoice = await this.http.post(action, params);
    if (!myChoice) {
      myChoice = {
        myCollege: '',
        myMajor: '',
        myProfession: '',
        id: null,
        userCode: this.userService.code(),
      };
    }

    if (myChoice?.myMajor.indexOf(',' + $event) < 0) {
      myChoice.myMajor += ',' + $event;

      const action = '/v1/personal/set-my-choice';
      const params = {
        myCollege: myChoice.myCollege,
        myMajor: myChoice.myMajor,
        myProfession: myChoice.myProfession,
        userCode: this.userService.code(),
      };

      await this.http.post(action, params);
    }

    this.showDetail = false;
  }

  select($event) {
    let div: string = null;
    let option: any = [];

    if ($event === 1) {
      div = 'salary';
      option = this.getXinZiPJOption();
    } else if ($event === 2) {
      div = 'zhiye';
      option = this.getPieOption('ZhiYeFX');
    } else if ($event === 3) {
      div = 'hangye';
      option = this.getPieOption('HangYeFB');
    } else if ($event === 4) {
      div = 'chengshi';
      option = this.getPieOption('ChengShiFB');
    }

    if (div) {
      const doc = document.getElementById(div);
      const echart = echarts.init(doc);
      echart.setOption(option, true);
    }
  }

  show(type: string): boolean {
    let data = this.getFutureData(type);
    if (!data) {
      return false;
    }

    let filtered = data.filter((c) => c.name && c.value);
    return filtered.length > 0;
  }

  getFutureData(type: string): Future[] {
    return this.majorDesc.find((c) => c.type === type)?.futures;
  }
  handleskip() {
    this.router.navigate(['/enc-my-univ']);
  }
  getXinZiPJOption() {
    let data = this.getFutureData('XinZiPJ');

    if (!data) {
      return;
    }
    let years = data.map((c) => c.name);
    let values = data.map((c) => c.value);

    return {
      title: {
        text: '月薪资(元)',
      },
      tooltip: {
        show: true,
        formatter: function (params) {
          return params['name'] + '年平均' + params['value'] + '元';
        },
      },
      xAxis: {
        type: 'category',
        data: years,
        name: '年',
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: values,
          type: 'line',
          itemStyle: {
            normal: {
              color: '#FF0000', //改变折线点的颜色
              lineStyle: {
                color: '#a80000', //改变折线颜色
              },
            },
          },
        },
      ],
    };
  }

  getPieOption(type: string) {
    let data = this.getFutureData(type);

    if (!data) {
      return;
    }

    let names = data.map((c) => c.name);
    let values = data.map((c) => c.value);

    let pieData = [];
    for (let i = 0; i < names.length; i++) {
      pieData.push({ name: names[i], value: values[i] });
    }

    return {
      series: [
        {
          type: 'pie',
          data: pieData,
          label: {
            show: true,
            formatter: function (arg) {
              return arg.data.name + arg.percent + '%';
            },
          },
          roseType: 'radius',
        },
      ],
    };
  }

  value: string;
  search() {
    if (!this.value || this.value === '') {
      this.showMajor(
        this.tabSelect === 0 ? this.benke[0].code : this.zhuanke[0].code
      );

      return;
    }

    const selected: MajorInfo[] = [];
    for (let item of this.data) {
      const children = item.children;
      for (let child of children) {
        if (child.name.includes(this.value)) {
          selected.push(child);
          break;
        }

        const grand = child.children;
        let contained = false;
        for (let g of grand) {
          if (g.name.includes(this.value)) {
            selected.push(child);
            contained = true;
            break;
          }
        }

        if (contained) {
          break;
        }
      }
    }

    this.current = selected;
  }
}
