import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '~/app/service/http.service';
import { UserService } from '~/app/service/user.service';
import { Location } from '@angular/common';
import { family2StudentCode } from '~/app/utility/domain';

@Component({
  selector: 'select-major',
  templateUrl: './select-major.component.html',
  styleUrls: ['./select-major.component.scss'],
})
export class SelectProfessionComponent implements OnInit {
  cumbs = ['首页', '专业百科'];
  busy = false;
  loading = false
  major = []
  professions = [];
  course = ""
  courses = ""
  code = this.userService.isFamily()
    ? family2StudentCode(this.userService.code())
    : this.userService.code();
  constructor(
    private http: HttpService,
    private message: NzMessageService,
    private userService: UserService,
    private location: Location,
    private router: Router
  ) {}
  async ngOnInit() {
    this.professions = JSON.parse(localStorage.getItem('professions'));
    await this.fetchSubject()
    await this.fetchData();
  }
  async fetchSubject() {
    let code = this.userService.code();
    const action = '/v1/personal/get-my-courses';
    const params = {
      userCode: code,
    };
    const data = await this.http.post(action, params);
    this.course = data.course
    this.courses = data.courses
  }
  async fetchData() {
    const list = [];
    const promises = [];
    this.professions.forEach(async (item) => {
      const colleges = [
        {
          name: '第一目标大学',
          code: null,
          value: null
        },
        {
          name: '第二目标大学',
          code: null,
          value: null
        },
        {
          name: '第三目标大学',
          code: null,
          value: null
        },
        {
          name: '第四目标大学',
          code: null,
          value: null
        },
        {
          name: '第五目标大学',
          code: null,
          value: null
        },
        {
          name: '第六目标大学',
          code: null,
          value: null
        },
      ]
      list.push({
        myMajor: item.name,
        myMajorCode: item.code,
        gbCode: '',
        feature: '',
        cityName: '',
        eduLevel: '',
        userCode: this.code,
        myCollege: '',
        colleges,
        options: [],
      });
      promises.push(this.makeRequest(item));
    });
    const data = await Promise.all(promises);
    data.forEach((item, index) => {
      list[index].options = item;
    });
    this.major = list
  }
  async makeRequest(item) {
    const action = '/v1/college/query-by-province_majorCode';
    const params = {
      majorCode: item.code,
      name: item.name,
      category: '',
      eduLevel: item.edulevel,
      feature: '',
      natureType: '',
      province: '',
      course: this.course,
      courses: this.courses,
      provinceCode: this.userService.code().substring(0, 2),
    };
    const data = await this.http.post(action, params);
    return data;
  }
  handleSelect(event, options, tmp) {
    const item = options.find(item => item.name == event)
    tmp.code = item.gbCode;
    tmp.feature = item.feature;
    tmp.cityName = item.provinceName + item.cityName;
    tmp.eduLevel = item.eduLevel
    tmp.category = item.category
  }
  back() {
    this.location.back();
  }
  moveUp(item, index) {
    const temp = this.major[index - 1]
    this.major.splice(index - 1, 1, item)
    this.major.splice(index, 1, temp)
  }
  moveDown(item, index) {
    const temp = this.major[index + 1]
    this.major.splice(index + 1, 1, item)
    this.major.splice(index, 1, temp)
  }
  async save() {
    const colleges = this.major.map(item => {
      let value = item.colleges.find(tmp => tmp.value)
      return value
    })
    if(!colleges.find(item => item)) {
      this.message.warning('至少选择一个大学');
      return
    }
    const myColleges = []
    const temp = []
    const repeat = []
    this.major.forEach(el => {
      el.colleges.forEach((item, index) => {
        if(item.value) {
          if(!temp.includes(`${item.value}${el.myMajor}`)) {
            temp.push(`${item.value}${el.myMajor}`)
          }else {
            !repeat.includes(`${el.myMajor}-${item.value}`) && repeat.push(`${el.myMajor}-${item.value}`)
          }
          myColleges.push({
            choiceOrder: index,
            eduLevel: item.eduLevel,
            gbCode: item.code,
            myCollege: item.value,
            feature: item.feature,
            selectType: '专业',
            course: this.courses,
            cityName: item.cityName,
            category: item.category,
            myMajor: el.myMajor,
            myMajorCode: el.myMajorCode,
            userCode: el.userCode,
          })
        }
      })
    })
    if(temp.length < myColleges.length) {
      this.message.warning(`目标大学"${repeat.join()}"重复，请重新选择`);
      return
    }
    this.loading = true
    const action = '/v1/personal/set-user-choice';
    const params = {
      colleges: myColleges,
    };
    try{
      await this.http.post(action, params);
      this.loading = false
      this.message.success('保存成功');
      this.router.navigate(['/career'])
    }catch {
      this.loading = false
    }
  }
}
