import { Component, OnInit } from '@angular/core';
import { HttpService } from '~/app/service/http.service';
import { UserService } from '~/app/service/user.service';
import { TestDesc } from '~/app/shared/testdesc.type';
import { padLeft } from '~/app/utility/common';

@Component({
  selector: 'app-psychology-test',
  templateUrl: './psychology-test.component.html',
  styleUrls: ['./psychology-test.component.scss'],
})
export class PsychologyTestComponent implements OnInit {
  data: TestDesc[] = [];
  cumbs = ['心理', '心理测评'];

  constructor(private http: HttpService, private userService: UserService) {}

  async ngOnInit() {
    await this.fetchData();
  }

  async fetchData() {
    const action = '/v1/career-exam/list-exams';
    const params = {};

    let data = await this.http.post(action, params);
    data = data.filter(c=>c.type==='心理测评');

    let i = 1;
    for (var item of data) {
      let str = padLeft(i, 3);
      i ++;
      const test: TestDesc = {
        id: item["id"],
        caption: item["title"],
        count: item["readTimes"],
        userType: item["userType"],
        imgUrl: "https://resources.delesson.cn/imgs/psychology/icons/"+str+".png",
      };

      this.data.push(test);
    }

    if (this.userService.isFamily()){
      this.data = this.data.filter(c=>"家长"===c.userType);
    } else if (this.userService.isTeacher()){
      this.data = this.data.filter(c=>"教师"===c.userType)
    }else {
      this.data = this.data.filter(c=>"家长"!==c.userType && "教师"!==c.userType)
    }
  }
}
