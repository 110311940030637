<app-header></app-header>

<div class="contain">
  <div class="banner">
    <div class="content">
      <div class="splash"></div>

      <div class="course">
        <nz-tabset nzType="card" [nzAnimated]="true">
          <nz-tab nzTitle="模拟选科">
            <div class="row">
              <app-course
                [course]="physics"
                [checked]="physicsChecked"
                [url]="physicsUrl"
                (checkChanged)="courseSelect($event)"
              ></app-course>
              <app-course
                [course]="chemistry"
                [checked]="chemistryChecked"
                [url]="chemistryUrl"
                (checkChanged)="courseSelect($event)"
              ></app-course>
              <app-course
                [course]="biology"
                [checked]="biologyChecked"
                [url]="biologyUrl"
                (checkChanged)="courseSelect($event)"
              ></app-course>
            </div>
            <div class="row">
              <app-course
                [course]="history"
                [checked]="historyChecked"
                [url]="historyUrl"
                (checkChanged)="courseSelect($event)"
              ></app-course>
              <app-course
                [course]="geography"
                [checked]="geographyChecked"
                [url]="geographyUrl"
                (checkChanged)="courseSelect($event)"
              ></app-course>
              <app-course
                [course]="politics"
                [checked]="politicsChecked"
                [url]="politicsUrl"
                (checkChanged)="courseSelect($event)"
              ></app-course>
            </div>

            <div class="row">
              <button nz-button nzType="primary" (click)="simuChoice()">
                选科专业覆盖率
              </button>
              <button nz-button nzType="primary" (click)="courseQuery()">
                按院校专业选科
              </button>
            </div>
          </nz-tab>
          <nz-tab nzTitle="模拟填报">
            <div class="row-near">
              <app-course
                [course]="physics"
                [checked]="physicsChecked"
                [url]="physicsUrl"
                (checkChanged)="courseSelect($event)"
              ></app-course>
              <app-course
                [course]="chemistry"
                [checked]="chemistryChecked"
                [url]="chemistryUrl"
                (checkChanged)="courseSelect($event)"
              ></app-course>
              <app-course
                [course]="biology"
                [checked]="biologyChecked"
                [url]="biologyUrl"
                (checkChanged)="courseSelect($event)"
              ></app-course>
            </div>
            <div class="row-near">
              <app-course
                [course]="history"
                [checked]="historyChecked"
                [url]="historyUrl"
                (checkChanged)="courseSelect($event)"
              ></app-course>
              <app-course
                [course]="geography"
                [checked]="geographyChecked"
                [url]="geographyUrl"
                (checkChanged)="courseSelect($event)"
              ></app-course>
              <app-course
                [course]="politics"
                [checked]="politicsChecked"
                [url]="politicsUrl"
                (checkChanged)="courseSelect($event)"
              ></app-course>
            </div>
            <div class="row-near">
              <nz-input-group nzSuffixIcon="search">
                <input
                  type="text"
                  nz-input
                  placeholder="输入成绩"
                  [(ngModel)]="score"
                />
              </nz-input-group>
            </div>
            <div class="row-near">
              <button nz-button nzType="primary" (click)="oneKeyChoose()">
                一键填报
              </button>
              <button nz-button nzType="primary" (click)="intelliChoose()">
                智能填报
              </button>
            </div>
            <div class="row-near">
              <button
                nz-button
                nzType="primary"
                class="long bold"
                (click)="generateReport()"
              >
                生成志愿深度分析报告
              </button>
            </div>
          </nz-tab>
          <nz-tab nzTitle="高考志愿">
                       
            <div class="volunteer">
                           
              <h3>高考志愿（专业版）</h3>
                           
              <p>
                是否跳转第三方网站，由北京德立信教育咨询有限公司提供"高考志愿数据分析”服务，该功能为付费服务，请按需自愿购买。
              </p>
                           
              <div class="operate">
                               
                <button nz-button nzType="primary" (click)="handleJump()">
                  确认，我已知晓
                </button>
                             
              </div>
                         
            </div>
                     
          </nz-tab>
        </nz-tabset>
      </div>
    </div>
  </div>

  <app-bulletin
    [title]="bulletin ? bulletin['title'] : ''"
    [url]="bulletin ? bulletin['url'] : ''"
  ></app-bulletin>

  <app-content></app-content>
  <app-courses [courses]="careerCourses"></app-courses>
  <app-interviews [courses]="interviews"></app-interviews>
  <app-shows [courses]="shows"></app-shows>
  <app-attentions></app-attentions>

  <app-hot-news [data]="hotTopics"></app-hot-news>
</div>

<nz-modal [(nzVisible)]="isVisible" nzTitle="温馨提示" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <ng-container *nzModalContent>
    <p>为保障高三学生高考志愿填报，本功能暂时关闭，高考生请使用“高考志愿”功能。</p>
  </ng-container>
</nz-modal>

<nz-modal
  [(nzVisible)]="showHints"
  nzTitle="重要提示"
  nzWidth="960px"
  (nzOnCancel)="cancel()"
>
  <ng-container *nzModalContent>
    <p>
      1.“志愿填报深度分析报告”由于数据计算量非常庞大，目前暂为离线计算模式。
    </p>
    <p>
      2.AI系统会根据您所在省或直辖市近五年招考数据及学生用户数据（包含生涯档案，测评报告，浏览、收藏数据，成绩档案等）进行深度画像分析并进行计算，AI系统计算结束后可在“我的”页面点击志愿表中“志愿填报深度分析报告”功能按钮查看或下载。
    </p>
    <p>
      3.为避免AI系统算力浪费，尽可能服务更多的用户，限每账户每天提交“志愿填报深度分析报告”的请求不超过两次，提交计算前请认真核对成绩及选科信息是否准确。
    </p>
    <p>
      4.招考数据均来自您所在省或直辖市考试院公布的当年招生计划和历年录取数据，分析报告仅供您模拟参考使用。正式填报时请务必参阅您所在省或直辖市考试院发布的相关招生计划书籍，如遇数据错漏请以考试院公布的信息为准。
    </p>
    <p>
      5.在志愿填报高峰期因使用本功能人数较多，系统将按提交时间的先后顺序进行排队，请您耐心等待。
    </p>
  </ng-container>
  <div *nzModalFooter>
    <button class="slong" nz-button nzType="primary" (click)="confirmReport()">
      我已知晓，并同意
    </button>
    <button nz-button nzType="default" (click)="cancel()">取消</button>
  </div>
</nz-modal>

<nz-modal
  [(nzVisible)]="showPay"
  nzTitle="会员充值"
  nzWidth="800px"
  (nzOnCancel)="payClose()"
>
  <ng-container *nzModalContent>
    <p>
      您目前暂无法使用该功能。请充值或升级至相应级别的会员，让我们更好为您服务!
    </p>

    <nz-tabset>
      <nz-tab nzTitle="充值卡充值">
        <p>
          充值卡号：&nbsp;
          <input
            nz-input
            placeholder="充值卡号"
            nzSize="default"
            [(ngModel)]="cardNo"
          />
        </p>
        <p>
          充值验证码：&nbsp;
          <input
            nz-input
            placeholder="充值验证码"
            nzSize="default"
            [(ngModel)]="password"
          />
        </p>
        <button nz-button nzType="primary" (click)="paybyCard()">充值</button>
      </nz-tab>

      <nz-tab nzTitle="支付宝充值">
        <div class="m-content">
          <div class="left">
            <div *ngFor="let p of prices">
              <button nz-button nzType="primary" (click)="pay(p)">
                {{ p.name }}
              </button>
              &nbsp; &nbsp;
              <span>{{ p.description }}</span>
            </div>
          </div>

          <div id="right"></div>
        </div>
        <p *ngIf="cur" class="bold">您当前选择：{{ cur?.description }}</p>
        <p class="red bold">{{ hintMessage }}</p>
      </nz-tab>
    </nz-tabset>
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="payClose()">关闭</button>
  </div>
</nz-modal>

<nz-modal [(nzVisible)]="isVisibleJh" [nzFooter]="modalFooter" [nzBodyStyle]="{padding:'30px'}"
          [nzStyle]="{height:'768px',overflow:'auto'}"
          [nzClosable]="isVip()" nzKeyboard="false"
          nzMaskClosable="false" nzTitle="" nzCentered="true" nzWidth="1100px" class="custom-modal"
          (nzOnCancel)="handleFootCancel()">
  <ng-container *nzModalContent style="border-radius: 15px;">
    <!--    <p> 尊敬的家长，您使用的生涯账号已到期，请去激活账号再继续使用。</p>-->
    <div class="bk">
      <div class="head">

        <div class="head_left bgc_img" *ngIf="isVip()">
          <p>账号VIP即将到期</p>
          <p>有效期：{{ vipNum() }}天</p>
          <p>尊敬的家长，您使用的生涯账号即将到期，可</p>
          <p>使用<em style="color: #E50B0B;font-size: 15px;">生涯专属卡</em>激活账号再继续使用。</p>
        </div>
        <div class="head_left bgc_img_dq" *ngIf="!isVip()">
          <p style="color: #194261;">账号VIP到期</p>
          <p style="color: #194261;">剩余0天</p>
          <p>尊敬的家长，您使用的生涯账号已到期，可</p>
          <p>使用<em style="color: #E50B0B;font-size: 15px;">生涯专属卡</em>激活账号再继续使用。</p>
        </div>

        <div class="head_right">
          <div class="display-flex align-items-center padding-w-h-15">
            <div class="icon"></div>
            <div>
              <p class="text-title margin-b-8">家长注册生涯网帐号并绑定学生</p>
              <p class="text-c">德立信生涯网：<a (click)="toSY()">www.dlxsygh.com</a></p>
              <p class="text-c">APP：德立信生涯</p>
            </div>
          </div>

          <div class="display-flex align-items-center padding-w-h-15">
            <div class="icon"></div>
            <p class="text-title">免费申领大流量低资费“生涯专属卡”</p>
          </div>

          <div class="display-flex align-items-center padding-w-h-15">
            <div class="icon"></div>
            <div>
              <p class="text-title">激活“生涯专属卡”并登陆生涯网“<a (click)="toSY()">www.dlxsygh.com</a>”
              </p>
              <p class="text-title">使用新卡免费开通权限。</p>
            </div>
          </div>

          <div class="display-flex padding-w-h-15">
            <div class="icon"></div>
            <div>
              <p class="text-title margin-b-8">1.免费使用三年生涯网</p>
              <p class="text-c">生涯资讯、选科、查院校、查专业、查职业、生涯档案、目标规划等功能通通畅享。</p>
              <p class="text-c margin-b-16">德立信生涯网：<a (click)="toSY()">www.dlxsygh.com</a>
              </p>

              <p class="text-title margin-b-8">2.免费使用“高考AI大数据志愿填报系统"，轻松填报，科学决策。高分不滑档，低分有选择。</p>
              <p class="text-c">德立信高考网：<a (click)="toGK()">www.dlxgk.com</a></p>
              <p class="text-c">小程序：德立信志愿</p>
            </div>
          </div>
        </div>
      </div>
      <div class="cont">
        <p class="cont-title">生涯专属卡相关规则</p>
        <p>
          1.家长用户注册后进入生涯系可免费试用1天，1试用到期后，账号将暂时锁定，需家长办理“生涯专属卡”以重新激活账号；账号激活成功后，可继续使用到您的孩子高中毕业，并赠送价值985元的高考网志愿填报大礼包！</p>
        <p>
          2.什么是“生涯专属卡”：运营商本着惠及广大高中学子、减轻家庭教育负担为初心，在成本范围内，为家长们定制的专属号卡，物美价廉，低资费大流量，不仅满足家长平时的通讯需求，还能确保每个高中学子都能顺畅无忧的登录系统，免费学习生涯知识和免费志愿填报，使得几干上万的机构服务费从此成为历史。</p>
        <p>
          3.免费申领“生涯专属卡”：用户可点击”办理生涯专属卡"前往活动广场办理生涯卡(活动广场内的所有卡均可办理)</p>
        <p>
          4.怎么用“生涯专属卡”激活账号：用户购买并激活“生涯专属卡”后，在德立信生涯网点击"激活账号"前往激活使用生涯专属卡收取验证码以激活账号；激活成功后，账号即可免费使用到学生高中毕业(德立信生涯网+德立信高考网AI大数据志愿填报系统)。后续请使用新号进行登录，新账号开通后直接关联老号试用时产生的所有数据，请放心使用!</p>

        <p class="cont-title">生涯系统服务权益</p>
        <p>1.开通志愿卡畅享查、测、填、学、问5大核心功能。</p>
        <p>2.适用对象:普通类文理科考生、新高考选科类考生(提前批次及艺术体育类考生暂不适用。</p>
        <p>3.使用时效:有效期截止到用户自行选择高考年的9月1日为止(非高考年,高考期间仅提供数据査询服务)。</p>
      </div>
    </div>
  </ng-container>
</nz-modal>

<ng-template #modalFooter>
  <button nz-button nzType="primary" style="background-color: #00BD6D" (click)="handleCancelJh()">办理生涯专属卡
  </button>
  <button nz-button nzType="primary" (click)="handleOkJh()">激活账号</button>
</ng-template>


<nz-modal [(nzVisible)]="isVisibleJhCode" [nzBodyStyle]="{textAlign:'center',padding:'20px'}"
          [nzStyle]="{height:'400px',overflow:'auto'}" nzClosable="true"
          nzKeyboard="false"
          [nzFooter]="null"
          nzMaskClosable="false" nzTitle="" nzCentered="true" nzWidth="350px" class="custom-modal"
          (nzOnCancel)="handleFootCancelCode()">
  <ng-container *nzModalContent style="border-radius: 15px;">
    <app-wx-qrcode [qrCodeSrc]="hqQrCodeUrl()"></app-wx-qrcode>
    <div>请使用手机扫描二维码前往活动广场</div>
  </ng-container>
</nz-modal>


<nz-modal [(nzVisible)]="isVisibleJhUser" [nzFooter]="modalFooter_1" [nzStyle]="{height:'660px'}" nzClosable="true"
          nzKeyboard="false"
          nzMaskClosable="false" nzTitle="" nzCentered="true" nzWidth="760px" class="custom-modal"
          (nzOnCancel)="handleFootCancelUser()">
  <ng-container *nzModalContent>
    <div class="jh">
      <div class="head" style="text-align: center;">
        <p style="padding-top: 42px;font-size: 28px;color: #194261;font-weight: 600;">账号激活</p>
        <p style="color: #FF0000;font-size: 18px;">请使用办理的生涯专属卡进行激活账号</p>
      </div>

      <div class="cont" style="margin: 40px 100px">
        <form nz-form [formGroup]="regForm">
          <nz-form-item>
            <p class="title">手机号:</p>
            <nz-form-control nzErrorTip="手机号码">
              <nz-input-group nzSize="large">
                <input
                  style="width: 400px;margin-left: 20px"
                  type="text"
                  nz-input
                  formControlName="phone"
                  placeholder="请输入手机号码"/>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <p class="title">验证码:</p>
            <nz-form-control nzErrorTip="验证码">
              <nz-input-group nzSearch class="code" nzSize="large" [nzAddOnAfter]="suffixButton">
                <input
                  style="width: 400px;margin-left: 20px"
                  type="text"
                  nz-input
                  formControlName="smsCode"
                  placeholder="请输入验证码"/>
              </nz-input-group>
              <ng-template #suffixButton>
                <button style="margin-left: 0" nz-button nzType="primary" nzSize="large" nzSearch
                        (click)="mobileCode()"> {{ buttonText }}
                </button>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </form>

      </div>
    </div>
  </ng-container>
</nz-modal>


<ng-template #modalFooter_1>
  <button nz-button nzType="primary" (click)="vipJh()">激活账号</button>
</ng-template>


<ng-template #modalFooter_2>
  <button nz-button nzType="primary" (click)="fhsy()">返回重新登陆</button>
</ng-template>

<nz-modal [(nzVisible)]="isVisibleJhUserOk" [nzClosable]="false" [nzFooter]="modalFooter_2" [nzStyle]="{height:'660px'}"
          nzClosable="true" nzKeyboard="false"
          nzMaskClosable="false" nzTitle="" nzCentered="true" nzWidth="760px" class="VisibleJhUserOk"
          (nzOnCancel)="handleFootCancelUser()">
  <ng-container *nzModalContent>
    <div class="jh">
      <div class="head">
        <div style="padding-top: 64px;display: flex;justify-content: center;">
          <div style=""></div>
          <div style="font-weight: 600;color: #25C165;font-size: 28px;">激活成功</div>
        </div>
      </div>
      <div class="cont" style="display: block;padding: 0">
        <div style="text-indent: 2em;font-size: 18px;">恭喜您激活生涯账号vip，感谢您对生涯系统的支持，这里赠送您高考志愿大礼包，后续可用手机号和学生生涯账号进行登录使用。</div>
        <div style="margin-top: 20px;font-size: 18px;">高考网：<a (click)="toGK()">www.dlxgk.com</a></div>
        <div style="font-size: 18px;">小程序：德立信高考</div>
        <div style="margin-top: 64px;text-align: center;color: #FF0000;font-size: 16px;">后续登录请使用专属卡卡号登录，为保障账号正常登录，请重新登录使用。（密码不变）</div>
      </div>
    </div>
  </ng-container>
</nz-modal>


<app-footer></app-footer>
