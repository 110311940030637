<div class="container" *ngIf="shows?.length > 0">
  <div class="heading">
    <p class="bold">大家关注</p>
    <button (click)="more()">查看更多</button>
  </div>

  <div class="content">
    <div class="row">
      <span nz-icon nzType="left-circle" nzTheme="outline" (click)="decrease()"></span>
      <app-attention [data]="shows[0]"></app-attention>
      <app-attention [data]="shows[1]"></app-attention>
      <app-attention [data]="shows[2]"></app-attention>
      <app-attention [data]="shows[3]"></app-attention>
      <span nz-icon nzType="right-circle" nzTheme="outline" (click)="increase()"></span>
    </div>
  </div>

</div>
