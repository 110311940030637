<div class="product">
    <div class="products-functions">
        <div class="section-inner">
            <h2>产品功能</h2>
        </div>
    </div>

    <div class="products-features">
        <div class="section-inner">
            <h2>产品特色</h2>
        </div>
    </div>

    <div class="products-solutions">
        <div class="section-inner">
            <h2>解决方案</h2>
        </div>
    </div>
</div>