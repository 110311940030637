<app-header></app-header>

<section>
  <div class="container">
    <app-breadcumb [items]="cumbs"></app-breadcumb>

    <div class="header">
      <div class="left">
        <button nz-button nzType="primary" (click)="showPici()">批次线</button>
        <button nz-button nzType="primary" (click)="show1f1d()">一分一段</button>
        <button nz-button nzType="primary" (click)="showCollegeScore()">院校分数线</button>
        <button nz-button nzType="primary" (click)="showEnrollPlan()">招生计划</button>
      </div>
      <div class="right">
        <div class="inline">
          <span>年度：</span>
          <nz-select [(ngModel)]="year" (ngModelChange)="filterYear(year)">
            <nz-option
              *ngFor="let y of years"
              nzValue="{{ y }}"
              nzLabel="{{ y }}"
            ></nz-option>
          </nz-select>
        </div>
        <div class="inline" *ngIf="isShow('一分一段')">
          <span>科目：</span>
          <nz-select [(ngModel)]="course" (ngModelChange)="showf1d1()">
            <nz-option
              *ngFor="let c of courses"
              nzValue="{{ c }}"
              nzLabel="{{ c }}"
            ></nz-option>
          </nz-select>
          <button id="search" nz-button nzType="primary" (click)="search1f1d()">
            换算
          </button>
        </div>
        <div class="inline" *ngIf="isShow('招生计划') || isShow('院校分数线')">
          <span>院校：&nbsp;&nbsp;&nbsp;</span>
          <input nz-input placeholder="院校名称" [(ngModel)]="univ" />
          <button nz-button nzType="primary" nzSearch (click)="search()">
            查找
          </button>
        </div>
      </div>
    </div>

    <div class="content">
      <app-pici [year]="year" [data]="data" *ngIf="isShow('批次线')"></app-pici>
      <app-f1d1
        [year]="year"
        [data]="data"
        *ngIf="isShow('一分一段')"
      ></app-f1d1>
      <app-enroll-score
        [year]="year"
        [data]="data"
        *ngIf="isShow('院校分数线')"
      ></app-enroll-score>
      <app-enroll-plan
        [year]="year"
        [data]="data"
        *ngIf="isShow('招生计划')"
      ></app-enroll-plan>
    </div>
  </div>
</section>

<nz-modal
  [(nzVisible)]="showf1d1Detail"
  nzTitle="成绩位次换算"
  nzWidth="960px"
  (nzOnCancel)="close1f1dDetail()"
>
  <ng-container *nzModalContent>
    <div class="top">
      <span>输入成绩: &nbsp;</span>
      <input nz-input placeholder="成绩" [(ngModel)]="score" />
      <span>选科：</span>
      <nz-select [(ngModel)]="cc">
        <nz-option nzValue="物理" nzLabel="物理"></nz-option>
        <nz-option nzValue="历史" nzLabel="历史"></nz-option>
      </nz-select>
      <button nz-button nzType="primary" (click)="convert()">
        换算
      </button>
    </div>
    <div class="content">
      <nz-table #basicTable [nzData]="scoreF1D1" [nzShowPagination]="false">
        <thead>
          <tr>
            <td align="center" class="dark bold">序号</td>
            <td align="center" class="dark bold">年度</td>
            <td align="center" class="dark bold">最高分</td>
            <td align="center" class="dark bold">最低分</td>
            <td align="center" class="dark bold">最高排名</td>
            <td align="center" class="dark bold">最低排名</td>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let dt of basicTable.data; let idx = index">
            <td align="center" class="dark">{{ idx + 1 }}</td>
            <td align="center" class="dark">{{ dt.year }}</td>
            <td align="center" class="dark">{{ dt.maxScore }}</td>
            <td align="center" class="dark">{{ dt.minScore }}</td>
            <td align="center" class="dark">{{ dt.maxRank }}</td>
            <td align="center" class="dark">{{ dt.minRank }}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="close1f1dDetail()">关闭</button>
  </div>
</nz-modal>

<app-footer></app-footer>
