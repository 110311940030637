import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '~/app/service/http.service';
import { Topics } from '~/app/shared/topics.type';

@Component({
  selector: 'app-attention-page',
  templateUrl: './attention-page.component.html',
  styleUrls: ['./attention-page.component.scss'],
})
export class AttentionPageComponent implements OnInit {
  cumbs = ['首页', '大家关注'];
  active = 0;

  types = ['教育部','权威榜单','特色榜单','专科院校','专业之最','专科怎么选','国家公务员','大厂揭秘','高校盘点','避坑锦囊','专业扫盲', '专家解惑'];
  data: Topics[]= [];
  group: Topics[][] = [];

  constructor(
    private http: HttpService,
    private message: NzMessageService,
    private route: ActivatedRoute
  ) {
    this.active = this.route.snapshot.params['id'];
  }

  async ngOnInit() {
    await this.fetchData();
    this.groupByType();
  }

  async fetchData() {
    let action = '/v1/resource/list-by-type';
    let params = {
      mainType: '大家关注',
      type: '',
    };

    this.data = await this.http.post(action, params);

    this.getTypes();
  }

  getTypes(){
    let types = this.data.map(c=>c.type);
    let result = [...new Set(types)];
  }

  groupByType(){
    for (let type of this.types){
      let filtered = this.data.filter(c=>c.type === type);
      this.group.push(filtered);
    }
  }
}
