import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TestDesc } from '~/app/shared/testdesc.type';

@Component({
  selector: 'app-career-test-card',
  templateUrl: './career-test-card.component.html',
  styleUrls: ['./career-test-card.component.scss']
})

export class CareerTestCardComponent implements OnInit {
  @Input() data: TestDesc;

  constructor(private router: Router,
    private message: NzMessageService) {
    }

  ngOnInit(): void {
  }

  startExam(){
    if (!this.data.id){
      this.message.info("本功能正陆续开放，敬请期待");
      return;
    }

   this.router.navigate(['/test', this.data.id]);
  }
}
