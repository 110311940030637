<div class="row">
  <app-course
    [course]="physics"
    [checked]="physicsChecked"
    [url]="physicsUrl"
    [canSelect]="canSelect"
    (checkChanged)="courseSelect($event)"
  ></app-course>
  <app-course
    [course]="chemistry"
    [checked]="chemistryChecked"
    [url]="chemistryUrl"
    [canSelect]="canSelect"
    (checkChanged)="courseSelect($event)"
  ></app-course>
  <app-course
    [course]="biology"
    [checked]="biologyChecked"
    [url]="biologyUrl"
    [canSelect]="canSelect"
    (checkChanged)="courseSelect($event)"
  ></app-course>
  <app-course
    [course]="history"
    [checked]="historyChecked"
    [url]="historyUrl"
    [canSelect]="canSelect"
    (checkChanged)="courseSelect($event)"
  ></app-course>
  <app-course
    [course]="geography"
    [checked]="geographyChecked"
    [url]="geographyUrl"
    [canSelect]="canSelect"
    (checkChanged)="courseSelect($event)"
  ></app-course>
  <app-course
    [course]="politics"
    [checked]="politicsChecked"
    [url]="politicsUrl"
    [canSelect]="canSelect"
    (checkChanged)="courseSelect($event)"
  ></app-course>

  <div *ngIf="showScore" class="score">
    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
      <input type="text" nz-input placeholder="输入成绩" [(ngModel)]="score" />
    </nz-input-group>
    <ng-template #suffixIconButton>
      <button nzSearch>
        <span nz-icon nzType="search" (click)="scoreOk()">确定</span>
      </button>
    </ng-template>
  </div>
</div>
