<section>
    <div class="major">
      <div class="title" (click)="showMajor(major.majorCode)">
        <p>{{ major.major }} &nbsp; ({{ major.majorCode }})</p>
        <p></p>
      </div>
      <div class="detail">
        <p>招生数量：{{ major.total }}人， 选科覆盖：{{ major.count }} 人 &nbsp;<span> 覆盖率：{{ major.percent }}% </span></p>
      </div>
    </div>
  </section>

  <nz-modal
  nzWidth = '960px'
  [(nzVisible)]="showModal"
  nzTitle="{{detail?.name}}"
  (nzOnCancel)="handleCancel()"
>
<ng-container *nzModalContent>
  <nz-tabset (nzSelectedIndexChange)="select($event)">
    <nz-tab nzTitle="专业介绍">
      <p *ngIf='detail?.introduction' class="m-title bold">专业介绍</p>
      <p>{{detail?.introduction}}</p>
      <p *ngIf='detail?.eduObjective' class="m-title bold">培养目标</p>
      <p>{{detail?.eduObjective}}</p>
      <p *ngIf='detail?.eduRequirement' class="m-title bold">培养要求</p>
      <p>{{detail?.eduRequirement}}</p>
      <p *ngIf='detail?.subjectRequirement' class="m-title bold">学科要求</p>
      <p>{{detail?.subjectRequirement}}</p>
      <p *ngIf='detail?.loreAndAbility' class="m-title bold">知识能力</p>
      <p>{{detail?.loreAndAbility}}</p>
      <p *ngIf='detail?.studyDirection || detail?.zhuanToBenOrient' class="m-title bold">{{getDirection()}}</p>
      <p>{{getDirectionDetail()}}</p>
      <p *ngIf='detail?.mainCourse' class="m-title bold">主要课程</p>
      <p>{{detail?.mainCourse}}</p>
      <p *ngIf='detail?.famousScholar' class="m-title bold">社会名人</p>
      <p>{{detail?.famousScholar}}</p>
    </nz-tab>
    <nz-tab nzTitle="薪资评价" [nzDisabled]="!show('XinZiPJ')">
      <div id="salary"></div>
    </nz-tab>
    <nz-tab nzTitle="职业分析" [nzDisabled]="!show('ZhiYeFX')">
      <div id="zhiye"></div>
    </nz-tab>
    <nz-tab nzTitle="行业分布" [nzDisabled]="!show('HangYeFB')">
      <div id="hangye"></div>
    </nz-tab>
    <nz-tab nzTitle="城市分布" [nzDisabled]="!show('ChengShiFB')">
      <div id="chengshi"></div>
    </nz-tab>
  </nz-tabset>

</ng-container>

  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">关闭</button>
  </div>
</nz-modal>
