<div class="content" *ngIf="picUrls?.length > 0">
  <div class="row">
<!--    <app-bk-1 imgUrl='{{picUrls[3]}}'>1111</app-bk-1>-->
<!--    <app-card imgUrl='{{picUrls[3]}}' url="/enc-major"></app-card>-->
<!--    <app-card imgUrl='{{picUrls[4]}}' url="/enc-univ"></app-card>-->
<!--    <app-card imgUrl='{{picUrls[5]}}' url="/enc-profession"></app-card>-->
    <app-card imgUrl='{{picUrls[3]}}' types="1"></app-card>
    <app-card imgUrl='{{picUrls[4]}}' types="2"></app-card>
    <app-card imgUrl='{{picUrls[5]}}' types="3"></app-card>
  </div>
  <div class="row">
    <app-card imgUrl='{{picUrls[6]}}' types="4"></app-card>
    <app-card imgUrl='{{picUrls[7]}}' types="5"></app-card>
    <app-card imgUrl='{{picUrls[8]}}' types="6"></app-card>
  </div>
  <div class="row">
    <app-card imgUrl='{{picUrls[1]}}' url="/enroll-data"></app-card>
    <app-card imgUrl='{{picUrls[0]}}' url="/career-test" type="1"></app-card>
    <app-card imgUrl='{{picUrls[2]}}' url=""></app-card>
  </div>
</div>
