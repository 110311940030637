import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LocalStorageService {
  private readonly localStorage: Storage = window?.localStorage;

  public set<T>(key: string, value: T): void{
   this.localStorage.setItem(key, JSON.stringify(value));
  }

  public get<T>(key: string): T | null {
   const value = this.localStorage.getItem(key);

   return value? JSON.parse(value) as T : null;
  }

  public remove(key: string): void{
   this.localStorage.removeItem(key);
  }

  public clear(): void{
   this.localStorage.clear();
  }
}
