<section>
  <div class="container">
    <nz-table #scores [nzData]="scoreData" [nzShowPagination]="false" [nzScroll]="{ x: '2000px' }">
      <thead>
        <tr>
          <th>序号</th>
          <th>考试名称</th>
          <th>考试类型</th>
          <th>考试时间</th>
          <th>语文</th>
          <th>数学</th>
          <th>英语</th>
          <th>历史</th>
          <th>地理</th>
          <th>政治</th>
          <th>物理</th>
          <th>化学</th>
          <th>生物</th>
          <th>总分</th>
          <th>位次变化</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of scores.data; let idx = index">
          <td>{{ idx + 1 }}</td>
          <td>{{ data.examName }}</td>
          <td>
            {{
              data.examCategory == 1
                ? "校考"
                : data.examCategory == 2
                ? "区县考"
                : data.examCategory == 3
                ? "市考"
                : data.examCategory == 4
                ? "省考"
                : ""
            }}
          </td>
          <td>{{ filteTime(data.startTime) }}</td>
          <td>{{ data.chineseScore }}</td>
          <td>{{ data.mathScore }}</td>
          <td>{{ data.foreignLanguageScore }}</td>
          <td>{{ data.historyScore }}</td>
          <td>{{ data.geographyScore }}</td>
          <td>{{ data.politicsScore }}</td>
          <td>{{ data.physicsScore }}</td>
          <td>{{ data.chemistryScore }}</td>
          <td>{{ data.biologyScore }}</td>
          <td>{{ data.totalScore }}</td>
          <td>{{ filterScore(data.rankVary) }}</td>
        </tr>
      </tbody>
    </nz-table>
    <nz-pagination
      class="right"
      [(nzPageIndex)]="pageNo"
      [nzTotal]="total"
      (nzPageIndexChange)="gotoPage($event)"
    ></nz-pagination>
  </div>
</section>
