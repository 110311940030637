<div class="header">
  <div class="brand"></div>
  <a class="download" href="#">下载Android APP</a>
</div>

<div class="nav">
  <div class="menu">
    <div class="normal">
      <div routerLink="/home" class="menu-item active false">首页</div>
      <div class="menu-item has-children">
        <span>
          产品
          <svg
            t="1656271440053"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="8359"
            width="14"
            height="14"
          >
            <path
              d="M951.405714 390.948571a36.516571 36.516571 0 0 0-33.828571-22.857142H106.605714c-14.994286 0-28.342857 9.142857-33.828571 22.857142s-2.377143 29.622857 8.411428 40.045715l405.394286 391.497143c7.131429 6.765714 16.274286 10.24 25.417143 10.24s18.285714-3.474286 25.417143-10.24l405.394286-391.497143c10.788571-10.422857 14.08-26.148571 8.594285-40.045715z"
              fill="#262626"
              p-id="8360"
            ></path>
          </svg>
          <div class="child-box">
            <a routerLink="/product">生涯辅导系统</a>
            <a routerLink="/product">心理健康系统</a>
            <a routerLink="/product">学业评估系统</a>
            <a routerLink="/product">职业体验课程</a>
            <a routerLink="/product">生涯教师培训</a>
            <a routerLink="/product">家长学习学校</a>
          </div>
        </span>
      </div>

      <div class="menu-item has-children">
        <span>
          解决方案
          <svg
            t="1656271440053"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="8359"
            width="14"
            height="14"
          >
            <path
              d="M951.405714 390.948571a36.516571 36.516571 0 0 0-33.828571-22.857142H106.605714c-14.994286 0-28.342857 9.142857-33.828571 22.857142s-2.377143 29.622857 8.411428 40.045715l405.394286 391.497143c7.131429 6.765714 16.274286 10.24 25.417143 10.24s18.285714-3.474286 25.417143-10.24l405.394286-391.497143c10.788571-10.422857 14.08-26.148571 8.594285-40.045715z"
              fill="#262626"
              p-id="8360"
            ></path>
          </svg>
          <div class="child-box">
            <a routerLink="/product">学生发展解决方案</a>
            <a routerLink="/product">高考选科解决方案</a>
            <a routerLink="/product">心理健康提升平台</a>
            <a routerLink="/product">学习能力提升平台</a>
            <a routerLink="/product">学生发展指导中心</a>
          </div>
        </span>
      </div>

      <a href="#">
        <div class="menu-item">客户案例</div>
      </a>

      <a href="#">
        <div class="menu-item">商务合作</div>
      </a>

      <a routerLink="/read-list">
        <div class="menu-item">付费阅读</div>
      </a>

      <div class="menu-item login" (click)="login()">系统登录</div>
    </div>
  </div>
</div>

<div class="section banner">
  <div class="section—inner">
    <span>
      <h1>德立信教育</h1>
      <h2>值得信赖的 <em>学生发展指导共构伙伴</em></h2>
      <p class="intro">
        围绕中学生职业生涯规划、学生发展指导以及学生报考升学，依托顶尖高校各学科教授设计研发，基于互联网、大数据及人工智能等现代信息技术，提供中学生学生心理评测、发展指导、生涯规划、报考辅导等教育产品和服务。
      </p>

      <a class="button" href="#">预约我们</a>
    </span>
  </div>
</div>

<div class="section product">
  <div class="section—inner">
    <h2 class="red">核心产品</h2>
    <div class="product-block">
      <div class="product">
        <div class="tips">软件产品</div>
        <div class="title">生涯辅导平台</div>
        <div class="eng-title">Career System</div>
        <div class="arrows"></div>
      </div>

      <div class="product">
        <div class="tips">软件产品</div>
        <div class="title">心理健康测评</div>
        <div class="eng-title">Psychology Health System</div>
        <div class="arrows"></div>
      </div>

      <div class="product">
        <div class="tips">软件产品</div>
        <div class="title">学业评估测评</div>
        <div class="eng-title">Study Evaluation System</div>
        <div class="arrows"></div>
      </div>

      <div class="product">
        <div class="tips">软件产品</div>
        <div class="title">高考志愿辅导</div>
        <div class="eng-title">Major And University</div>
        <div class="arrows"></div>
      </div>

      <div class="product">
        <div class="tips">教师培训</div>
        <div class="title">生涯教师培训</div>
        <div class="eng-title">Career Training</div>
        <div class="arrows"></div>
      </div>

      <div class="product">
        <div class="tips">家长学习</div>
        <div class="title">职业体验课程</div>
        <div class="eng-title">Family Train Course</div>
        <div class="arrows"></div>
      </div>
    </div>
  </div>
</div>

<div class="section solution-block" style="display: none">
  <div class="section—inner">
    <h2 class="red">解决方案</h2>
    <div class="overall">
      <div class="left">
        <img src="https://resources.delesson.cn/imgs/building2.jpg" />
      </div>
      <div class="right">
        <div class="solution-details">
          <div class="solution-details-title">学生发展指导·整体解决方案</div>
          <div class="solution-details-desc">
            德立信教育总结多年实践探索，结合不同学校需求特点，设计综合、全面的学生发展指导建设方案。
          </div>
        </div>
        <div class="solution-details-btn">查看详情</div>
      </div>
    </div>
  </div>
</div>

<div class="section carousel-block" style="display: none">
  <div class="section—inner">
    <h2 class="red">平台特色</h2>
    <div class="carousel">
      <div class="carousel-center">
        <div class="carousel-left">
          <div class="carousel-detail">
            <div class="carousel-title">生涯教育与新高考选科</div>
            <div class="carousel-desc">
              助力学校快速落地体系化的生涯教育，包含顶层设计、课程、活动、教材、师训等，科学体系开展生涯指导工作。
            </div>
          </div>

          <div class="carousel-btn">查看详情</div>
        </div>
        <div class="carousel-right">
          <img src="https://resources.delesson.cn/imgs/building2.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="section expert-block" style="display: none">
  <div class="section—inner">
    <h2 class="red">专家团队</h2>
    <div class="experts"></div>
  </div>
</div>

<div class="section choice-block" style="display: none">
  <div class="section—inner">
    <h2 class="red">专家高论</h2>
    <div class="experts"></div>
  </div>
</div>

<div class="section coop-block" style="display: none">
  <div class="section—inner">
    <h2 class="red">深度合作</h2>
    <div class="partners">
      <img
        src="https://www.shijieu.cn/_next/static/images/school-logo-xl-1-a04fcf3004207e8392aa978932af8c3c.png"
      />
    </div>
  </div>
</div>

<app-footer></app-footer>

<nz-modal
  [(nzVisible)]="showLogin"
  nzTitle="系统登录"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="submit()"
>
  <ng-container *nzModalContent>
    <form nz-form [formGroup]="loginForm" (ngSubmit)="submit()">
      <nz-form-item>
        <nz-form-control nzErrorTip="输入用户名">
          <nz-input-group nzPrefixIcon="user" nzSize="large">
            <input
              type="text"
              nz-input
              formControlName="userName"
              placeholder="用户名"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control nzErrorTip="输入密码">
          <nz-input-group nzPrefixIcon="lock" nzSize="large">
            <input
              type="password"
              nz-input
              formControlName="password"
              placeholder="密码"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>
</nz-modal>
