import { Component, Input, OnInit } from '@angular/core';
import { MajorDesc } from '../list-course-by-major.component';
import { HttpService } from '~/app/service/http.service';
import { UserService } from '~/app/service/user.service';
import { MajorDetail } from '~/app/shared/major.type';
import { MyChoice } from '~/app/shared/mychoice.type';
import { Future, TypeResult } from '~/app/shared/majordesc.type';

@Component({
  selector: 'app-major-desc',
  templateUrl: './major-desc.component.html',
  styleUrls: ['./major-desc.component.scss']
})
export class MajorDescComponent implements OnInit {
  @Input() data: MajorDesc;
  eduLevel : string;

  constructor(private http: HttpService,
    private userService: UserService) { }

  ngOnInit(): void {
      this.eduLevel = this.data?.eduLevel ==="ben"?"本科":"专科";
  }

  showDetail = false;
  major: MajorDetail;

  async detail($event) {
    this.major = null;

    let url = `/major/details/${$event}.json`;
    let detail = await this.http.get(url);
    this.major = detail["major"];

    url = `/major/feature/${$event}.json`;
    let desc = await this.http.get(url);
    this.majorDesc = desc['result'];

    this.showDetail = this.major !== null;
  }

  handleCancel(){
    this.showDetail = false;
  }

  async handleOk($event: string) {
    if (!$event) {
      return;
    }

    const action = '/v1/personal/find-my-choice';
    const params = {
      userCode: this.userService.code(),
    };

    let myChoice: MyChoice = await this.http.post(action, params);
    if (!myChoice) {
      myChoice = {
        myCollege: '',
        myMajor: '',
        myProfession: '',
        id: null,
        userCode: this.userService.code(),
      };
    }

    if (myChoice?.myMajor.indexOf($event + ',') < 0) {
      myChoice.myMajor += $event + ',';

      const action = '/v1/personal/set-my-choice';
      const params = {
        myCollege: myChoice.myCollege,
        myMajor: myChoice.myMajor,
        myProfession: myChoice.myProfession,
        userCode: this.userService.code(),
      };

      await this.http.post(action, params);
    }

    this.showDetail = false;
  }

  majorDesc: TypeResult[] = [];

  select($event) {
    let div: string = null;
    let option: any = [];

    if ($event === 1) {
      div = 'salary';
      option = this.getXinZiPJOption();
    } else if ($event === 2) {
      div = 'zhiye';
      option = this.getPieOption('ZhiYeFX');
    } else if ($event === 3) {
      div = 'hangye';
      option = this.getPieOption('HangYeFB');
    } else if ($event === 4) {
      div = 'chengshi';
      option = this.getPieOption('ChengShiFB');
    }

    if (div) {
      const doc = document.getElementById(div);
      const echart = echarts.init(doc);
      echart.setOption(option, true);
    }
  }

  show(type: string): boolean {
    let data = this.getFutureData(type);
    if (!data) {
      return false;
    }

    let filtered = data.filter((c) => c.name && c.value);
    return filtered.length > 0;
  }

  getFutureData(type: string): Future[] {
    return this.majorDesc.find((c) => c.type === type)?.futures;
  }

  getXinZiPJOption() {
    let data = this.getFutureData('XinZiPJ');

    if (!data) {
      return;
    }
    let years = data.map((c) => c.name);
    let values = data.map((c) => c.value);

    return {
      title: {
        text: '月薪资(元)',
      },
      tooltip: {
        show: true,
        formatter: function (params) {
          return params["name"] + '年平均' + params["value"] + '元';
        },
      },
      xAxis: {
        type: 'category',
        data: years,
        name: '年',
      },
      yAxis: {
        type: 'value',
        name: '月薪资(元)',
      },
      series: [
        {
          data: values,
          type: 'line',
          itemStyle: {
            normal: {
              color: '#FF0000', //改变折线点的颜色
              lineStyle: {
                color: '#a80000', //改变折线颜色
              },
            },
          },
        },
      ],
    };
  }

  getPieOption(type: string) {
    let data = this.getFutureData(type);

    if (!data) {
      return;
    }

    let names = data.map((c) => c.name);
    let values = data.map((c) => c.value);

    let pieData = [];
    for (let i = 0; i < names.length; i++) {
      pieData.push({ name: names[i], value: values[i] });
    }

    return {
      series: [
        {
          type: 'pie',
          data: pieData,
          label: {
            show: true,
            formatter: function (arg) {
              return arg.data.name + arg.percent + '%';
            },
          },
          roseType: 'radius',
        },
      ],
    };
  }
}
