<app-header></app-header>

<section>
  <div class="container">
    <div class="mine">
      <div class="message">
        <div class="title">
          <p>学生信息</p>
        </div>
        <div class="details">
          <p><span>学校名称</span>：{{personalInfo?.schoolName}}</p>
          <p><span>年级名称</span>：{{personalInfo?.gradeName}}</p>
          <p><span>班级名称</span>：{{personalInfo?.className}}</p>
          <p><span>班主任</span>：{{personalInfo?.teachTeacherName}}</p>
          <p><span>选科</span>：{{personalInfo?.subjectStatus == 1 ? '选科中' :  personalInfo?.subjectStatus == 2 ? '已完成' : personalInfo?.subjectStatus == 3 ? '待审批' : '未开启'}}</p>
        </div>
      </div>
      <div class="mine-table">
        <nz-table #basicTable [nzData]="listOfData">
          <thead>
            <tr>
              <td align="center" class="dark bold">科目</td>
              <td align="center" class="dark bold">任课老师</td>
              <td align="center" class="dark bold">目标分数</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of basicTable.data">
              <td align="center" class="dark">
                <span *ngIf="mainCourses.includes(data.course) else elseBlock">{{data.course}}</span>
                <ng-template #elseBlock>
                  <nz-select [(ngModel)]="data.course" class="subject" nzPlaceHolder="请选择科目" nzBorderless>
                    <nz-option *ngFor="let item of options" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
                  </nz-select>
                </ng-template>
              </td>
              <td align="center" class="dark">{{data?.teacherName}}</td>
              <td align="center" class="dark score">
                <nz-input-number  [(ngModel)]="data.score" [nzMax]="maxValue(data.course)"  [nzStep]="1"></nz-input-number> 
                <!-- <input nz-input nzBorderless [nzMax]="100" placeholder="请输入分数" [(ngModel)]="data.score" type="number" /> -->
              </td>
            </tr>
          </tbody>
        </nz-table>
        <div class="btn">
          <button *ngIf="setCourse" nz-button nzType="primary" class="m-2" (click)="setting()">设置选科</button>
          <!-- <button *ngIf="personalInfo?.subjectStatus == 2" nz-button nzType="primary" class="m-2" (click)="applyForSubject()">申请修改选科</button> -->
          <button nz-button nzType="primary" class="m-2" (click)="submit()">设置目标分数</button>
        </div>
      </div>
    </div>
  </div>
</section>
