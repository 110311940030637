import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/service/http.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from '~/app/service/user.service';
import { Router } from '@angular/router';
import { family2StudentCode } from '~/app/utility/domain';
@Component({
  selector: 'app-litre-learn',
  templateUrl: './litre-learn.component.html',
  styleUrls: ['./litre-learn.component.scss'],
})
export class LitreLearnComponent implements OnInit {
  cumbs = ['首页', '职业百科'];
  currPage = [];
  filtered = [];
  intentionList = [];
  pageIndex = 0;
  pageSize = 10;
  code = this.userService.isFamily() ? family2StudentCode(this.userService.code()) : this.userService.code()
  constructor(
    private http: HttpService, 
    private message: NzMessageService,
    private userService: UserService,
    private router: Router
  ) {}
  async ngOnInit() {
    await this.fetchData();
    await this.getMyEnrolls()
  }
  async fetchData() {
    const action = '/v1/personal/list-sys-enrolls';
    const params = {};
    const data = await this.http.post(action, params);
    this.filtered = data;
    this.pageIndex = 1;
    this.gotoPage(this.pageIndex);
  }
  async getMyEnrolls() {
    const action = '/v1/personal/list-my-choice';
    const params = {
      userCode: this.code,
    };
    const {enrolls, colleges, jobs} = await this.http.post(action, params);
    this.intentionList = enrolls.map(item => {
      return {
        ...item,
        name: item.myEnroll
      }
    })
  }
  gotoPage(idx: number) {
    this.currPage = this.filtered.slice(
      (idx - 1) * this.pageSize,
      idx * this.pageSize
    );
  }
  filter(name) {
    return this.intentionList.find((el) => el.name === name);
  }
  addIntention(item) {
    if (this.filter(item.name)) {
      this.intentionList = this.intentionList.filter((el) => el.name !== item.name);
    } else {
      if (this.intentionList.length >= 6) {
        this.message.error('最多只能选择6个');
        return;
      }
      this.intentionList.push(item);
    }
  }
  removeIntention(index) {
    this.intentionList.splice(index, 1);
  }
  isVisible = false
  handleCancel(): void {
    this.isVisible = false;
  }
  viewenroll() {
    this.isVisible = true
  }
  async confirm() {
    if(this.intentionList.length === 0) {
      this.message.error('请选择升学通路');
      return;
    }
    const action = '/v1/personal/set-user-choice';
    const enrolls = this.intentionList.map((item, index) => {
      return {
        choiceOrder: index + 1,
        myEnroll: item.name,
        userCode: this.code
      }
    })
    const params = {
      enrolls
    };
    await this.http.post(action, params);
    this.message.success('保存成功')
    this.router.navigate(['/career']);
  }
}
