// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://resources.delesson.cn',
  noLogoUrl: 'https://resources.delesson.cn/logo/nologo.jpeg',
  baseApiUrl: 'https://api.delesson.cn',
  // baseApiUrl: 'http://127.0.0.1:8088',
  // baseTwoUrl: 'https://teaching-manage-backend-yfb.lastbs.com',
  // baseApiUrl: 'https://api.dlxsygh.com',
  baseTwoUrl: 'https://api.dlxsygh.cn',
  baseFilesUrl: 'https://www.dlxsygh.com', //生涯档案跳转地址

  cardApiUrl: 'https://eliancard.com',
  title: '中学生生涯规划平台',
  appId: '000000',
  // appId: '370001',
  // appId: '532301001',
};
