import { Component, Input, OnInit } from '@angular/core';
import { HttpService } from '~/app/service/http.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from '~/app/service/user.service';
import { CollegeScore, EnrollData, Info, MajorEnrollInfo, compareEnrollPlan, compareMajor, compareScore } from '~/app/shared/enroll.typs';
import { EnrollIntroduction, UnivDetail } from '~/app/shared/university.type';
import { getBXLevel, getNatureType } from '~/app/utility/domain';

@Component({
  selector: 'app-university',
  templateUrl: './university.component.html',
  styleUrls: ['./university.component.scss']
})
export class UniversityComponent implements OnInit {
  @Input() univ: Info;

  eduLevel: string;
  natureType: string;

  constructor(private http: HttpService,
    private message: NzMessageService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.eduLevel = getBXLevel(this.univ.brief.eduLevel);
    this.natureType = getNatureType(this.univ.brief.natureType);
  }

  showDetail = false;
  busy = false;

  university: UnivDetail;
  univMajors = "";
  collegeScores: CollegeScore[] = [];
  enrollPlan: EnrollData[] = [];
  enrollIntro: EnrollIntroduction[] = [];

  async detail(id, name, code) {
    this.busy = true;

    const action = '/v1/college/find';
    const params = {
      id: id,
    };

    this.university = await this.http.post(action, params);
    if (!this.univ) {
      this.message.error('大学信息获取错误，请稍后重试！');
      this.busy = false;
      return;
    }

    this.univMajors = this.university.majors.map((c) => c.name).join(', ');

    await this.getCollegeScores(name);
    await this.getMajorScores(name);
    await this.getCollegePlans(name);
    await this.getEnrollIntroduction(code);

    this.busy = false;
    this.showDetail = true;
  }

  async getCollegeScores(name: string) {
    const college_score_action = '/v1/college-enroll/list-province-college';

    const provinceCode = this.userService.provinceCode();
    const params = {
      code: provinceCode,
    };

    this.collegeScores = await this.http.post(college_score_action, params);
    if (this.collegeScores) {
      this.collegeScores = this.collegeScores
        .filter((c) => c.college === name)
        .sort((a, b) => compareScore(a, b));
    }
  }

  majorScores: MajorEnrollInfo[] = [];

  async getMajorScores(name: string) {
    const action = '/v1/enroll2/list-college-major-scores';
    const params = {
      collegeName: name,
      provinceCode: this.userService.provinceCode(),
    };

    let data = await this.http.post(action, params);
    if (data) {
      this.majorScores = data.sort((a,b)=>compareMajor(a,b));
    }
  }

  async getCollegePlans(name: string) {
    const provinceCode = this.userService.provinceCode();
    const action = '/v1/enroll2/list-college-enroll-plan';

    const params = {
      provinceCode: provinceCode,
      collegeName: name,
    };

    const data: EnrollData[] = await this.http.post(action, params);
    this.enrollPlan = data?.sort((a, b) => compareEnrollPlan(a, b));
  }

  current: EnrollIntroduction = undefined;

  async getEnrollIntroduction(code: string) {
    const action = '/v1/enroll2/find-collge-enroll-introduction';
    const params = {
      code: code,
    };

    this.enrollIntro = await this.http.post(action, params);
    this.current = this.enrollIntro?.length > 0 ? this.enrollIntro[0] : null;
  }

  showIntro(dt: EnrollIntroduction) {
    this.current = dt;
  }

  handleOk(){
    this.showDetail = false;
  }

  handleCancel(){
    this.showDetail = false;
  }
}
