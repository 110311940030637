import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from 'src/app/service/http.service';
import { UserService } from 'src/app/service/user.service';
import {
  CollegeScore,
  F1d1Data,
  PiciData,
  ScoreF1D1,
} from '~/app/shared/enroll.typs';
import { UnivInfo } from '~/app/shared/university.type';
import { removeDuplicate } from '~/app/utility/collection';

@Component({
  selector: 'app-enroll-data',
  templateUrl: './enroll-data.component.html',
  styleUrls: ['./enroll-data.component.scss'],
})
export class EnrollDataComponent implements OnInit {
  cumbs = [];

  type = '批次线';
  year: string;
  years: number[];

  pici_data: PiciData[];
  f1d1_data: F1d1Data[];
  college_score: CollegeScore[];
  colleges: UnivInfo[];

  data: any[];
  univ: string = '北京';

  courses = [];
  course = '';

  constructor(
    private http: HttpService,
    private userService: UserService,
    private message: NzMessageService
  ) {}

  async ngOnInit() {
    await this.fetchData('批次线');
    this.showPici();
    this.getYears();
  }

  async fetchData(type: string) {
    const pici_action = '/v1/college-enroll/list-province-score';
    const f1d1_action = '/v1/college-enroll/list-1f1d';
    const college_score_action = '/v1/college-enroll/list-province-college';

    const provinceCode = this.userService.provinceCode();
    const params = {
      code: provinceCode,
    };

    if (type === '批次线' && !this.pici_data) {
      this.pici_data = await this.http.post(pici_action, params);
      if (!this.pici_data) {
        this.message.error('数据获取失败');
      }
    }

    if (type === '一分一段' && !this.f1d1_data) {
      this.f1d1_data = await this.http.post(f1d1_action, params);
      if (!this.f1d1_data) {
        this.message.error('数据获取失败');
      }
    }

    if (type === '院校分数线' && !this.college_score) {
      this.college_score = await this.http.post(college_score_action, params);
      this.college_score = this.college_score.sort((a, b) => {
        return b.scoreMin.valueOf() - a.scoreMin.valueOf();
      });

      if (!this.college_score) {
        this.message.error('数据获取失败');
      }
    }
  }

  async showPici() {
    await this.fetchData('批次线');
    this.showType('批次线');
    this.filterYear(this.year);
  }

  async show1f1d() {
    await this.fetchData('一分一段');
    this.showType('一分一段');
    this.filterYear(this.year);
  }

  async showCollegeScore() {
    await this.fetchData('院校分数线');
    this.showType('院校分数线');
    this.filterYear(this.year);
  }

  async showEnrollPlan() {
    this.showType('招生计划');
    this.search();
  }

  showType(type: string) {
    this.type = type;
    this.cumbs = ['首页', '招考数据'];
    this.cumbs.push(type);

    this.getYears();
  }

  async search() {
    if (!this.univ || this.univ.trim().length == 0) {
      this.message.error('院校名称不能为空');
      return;
    }

    if (this.type === '招生计划') {
      const action = '/v1/college/list-by-bx-name';
      const params = {
        name: this.univ.trim(),
      };
      this.data = await this.http.post(action, params);
    } else if (this.type === '院校分数线') {
      this.filterYear(this.year);
    }
  }

  isShow(type: string): boolean {
    const result = this.type === type;
    return result;
  }

  filterYear(year: string) {
    if (this.isShow('批次线')) {
      this.data = this.pici_data.filter((c) => c.year.toString() === year);
    }

    if (this.isShow('一分一段')) {
      this.courses = removeDuplicate(
        this.f1d1_data
          .filter((c) => c.year.toString() === year)
          .map((c) => c.course)
      );
      this.course = this.courses.length >= 0 ? this.courses[0] : '';

      this.data = this.f1d1_data.filter(
        (c) => c.year.toString() === year && c.course.indexOf(this.course) >= 0
      );
    }

    if (this.isShow('院校分数线')) {
      this.data = this.college_score
        .filter(
          (c) => c.year.toString() === year && c.college.indexOf(this.univ) >= 0
        )
        .sort((a, b) => this.compare(a, b));
    }
  }

  getYears() {
    this.years = Array.from(new Set(this.pici_data.map((c) => c.year)));

    if (this.type === "招生计划" && !this.years.includes(2023)){
      this.years.push(2023)
    };

    this.years = this.years.sort(
      (a, b) => b - a
    );

    this.year = this.years.length > 0 ? this.years[0].toString() : '2022';
  }

  compare(c1: CollegeScore, c2: CollegeScore): number {
    if (c1.course && c1.course < c2.course) {
      return 1;
    } else if (c1.course && c1.course > c2.course) {
      return -1;
    }

    return c2.scoreMin.valueOf() - c1.scoreMin.valueOf();
  }

  showf1d1() {
    this.data = this.f1d1_data.filter(
      (c) =>
        c.year.toString() === this.year && c.course.indexOf(this.course) >= 0
    );
  }

  showf1d1Detail = false;

  async search1f1d() {
    this.cc = this.course;
    this.cc = this.cc === "理科"? "物理": this.cc;
    this.cc = this.cc === "文科"? "历史": this.cc;

    this.convert();
    this.showf1d1Detail = true;
  }

  close1f1dDetail() {
    this.showf1d1Detail = false;
  }

  score = 700;
  cc=""
  scoreF1D1: ScoreF1D1[] = [];

  async convert() {
    const provCode = this.userService.provinceCode();
    if (!this.cc || this.cc===""){
      this.cc = this.course;
    }

    this.scoreF1D1 = await this.fetchScoreF1D1(this.cc, provCode, this.score);
  }

  async fetchScoreF1D1(course, provinceCode: string, score: number): Promise<ScoreF1D1[]> {
    if (course === '理科') {
      course = '物理';
    }

    if (course === '文科') {
      course = '历史';
    }

    const action = '/v1/college-enroll/list-1f1d-score';
    const params = {
      course: course,
      provinceCode: provinceCode,
      score: score
    };

    return await this.http.post(action, params);
  }
}
