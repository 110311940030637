import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from 'src/app/service/user.service';
import { HttpService } from 'src/app/service/http.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-volunteer-report',
  templateUrl: './volunteer-report.component.html',
  styleUrls: ['./volunteer-report.component.scss'],
})
export class VolunteerReportComponent implements OnInit {
  constructor(
    private message: NzMessageService,
    private userService: UserService,
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  code = this.userService.code();
  reportData = []
  async ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if(params.code) {
        this.code = params.code
      }
    });
    this.fetchReportList()
  }
  // 深度志愿分析报告
  async fetchReportList() {
    const action = `/v1/enroll2/list-my-report-listnew`;
    const params = {
      userCode: this.code,
    };
    const data: any = await this.http.post(action, params);
    this.reportData = data;
  }
  private saveAs(bdata: Blob, fileName: string) {
    let u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
    if(isAndroid) {
      //@ts-ignore
      Android.saveAs(id)
      return
    }
    const tmpLink = document.createElement('a');
    const blob = new Blob([bdata], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const objectUrl = URL.createObjectURL(blob);
    tmpLink.href = objectUrl;
    tmpLink.download = fileName;

    tmpLink.click();
    URL.revokeObjectURL(objectUrl);
  }
  async dowload(id) {
    const action = `/v1/enroll2/get-my-report-byid`;
    const params = {
      id
    };
    const data: any = await this.http.postForBlob(action, params);
    this.saveAs(data, "志愿深度分析报告.xlsx");
  }
}