/**
 * 在Map中寻找value对应的key
 * @param map Map
 * @param value 值
 * @returns 值对应的key
 */
export function findKeyByValue<V>(map: Map<string, V>, value: V): string {
  for (const key in map) {
    if (map[key] === value) {
      return key;
    }
  }

  return undefined;
}

/**
 * 得到Map的键
 * @param map map
 * @returns 键值
 */
export function getKeys<K,V>(map: Map<K, V>): K[] {
  let result = [];

  for (const key in map){
    result.push(key);
  }

  return result;
}

/**
 * 得到Map的值，放入数组中
 * @param map Map对象
 * @returns Map中value的数组
 */
export function getValues<K, V>(map: Map<K, V>): V[]{
  let result = [];
  for (const key in map){
    result.push(map[key]);
  }

  return result;
}

/**
 * 判断数组arr中是否包含元素ele
 * @param arr 数组
 * @param ele 元素
 * @returns 是否包含
 */
export function contains<T>(arr: T[], ele: T): boolean {
  if (!arr){
    return false;
  }

  return arr.indexOf(ele) >= 0;
}

/**
 * 判断字符串str中是否包含sub子串
 * @param str 字符串
 * @param sub 子字符串
 * @returns 是否包含
 */
export function stringContains(str, sub: string): boolean {
  return str.index(sub) >= 0;
}

export function removeDuplicate<T>(arr: T[]): T[]{
  return  [... new Set(arr)];
}
