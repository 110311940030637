import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Attention } from '~/app/shared/attention.type';

@Component({
  selector: 'app-attention',
  templateUrl: './attention.component.html',
  styleUrls: ['./attention.component.scss']
})
export class AttentionComponent implements OnInit {
  @Input() data: Attention;

  constructor(private route: Router) { }

  ngOnInit(): void {
  }

  click(){
    this.route.navigate(["attention", this.data.id]);
  }
}
