<app-header></app-header>

<section>
  <div class="container">
    <p>学业测评</p>
    <app-career-test-card [data]="data[0]"></app-career-test-card>
    <app-career-test-card [data]="data[1]"></app-career-test-card>
    <app-career-test-card [data]="data[2]"></app-career-test-card>
    <app-career-test-card [data]="data[3]"></app-career-test-card>
    <app-career-test-card [data]="data[4]"></app-career-test-card>
    <app-career-test-card [data]="data[5]"></app-career-test-card>
    <app-career-test-card [data]="data[6]"></app-career-test-card>
  </div>
</section>

<app-footer></app-footer>
