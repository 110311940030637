<app-header></app-header>

<section>
  <div class="container">
    <app-breadcumb [items]="cumbs"></app-breadcumb>
    <div class="search">
      <input nz-input  placeholder="查找专业" [(ngModel)]="value" />
      <button nz-button nzType="primary" (click)="search()">查找</button>
    </div>

    <p>
      <span>按专业查询</span>
      <button nz-button nzType="default" nzDanger (click)="listByCollgege()">
        按院校查询
      </button>
    </p>
    <div *ngFor="let major of currPage">
      <app-major-desc [data]="major"></app-major-desc>
      <nz-divider></nz-divider>
    </div>

    <nz-pagination
      class="right"
      [nzPageIndex]="1"
      [nzPageSize]="20"
      [nzTotal]="this.filtered.length"
      (nzPageIndexChange)="gotoPage($event)"
    ></nz-pagination>
  </div>
</section>

<app-footer></app-footer>
