<app-header></app-header>

<section>
  <div class="container">
    <div class="career">
      <div class="test">
        <div class="test-heading">
          <p>生涯测评</p>
          <button (click)="examMore()">查看更多</button>
        </div>
        <div class="row">
          <app-test-card imgUrl="{{ testUrls[0] }}" id="19"></app-test-card>
          <app-test-card imgUrl="{{ testUrls[1] }}" id="1"></app-test-card>
          <app-test-card imgUrl="{{ testUrls[2] }}" id="11"></app-test-card>
        </div>
        <div class="row">
          <app-test-card imgUrl="{{ testUrls[3] }}" id="2"></app-test-card>
          <app-test-card imgUrl="{{ testUrls[4] }}" id="24"></app-test-card>
          <app-test-card imgUrl="{{ testUrls[5] }}" id="3"></app-test-card>
        </div>
      </div>

      <div class="my-career">
        <div class="test-heading right-title">
          <p>我的生涯</p>
          <span class="plan" (click)="loadPDF()">升学规划介绍</span>
        </div>

        <p class="heading">{{univTitle}}</p>
        <p class="info">
          <span (click)="show('我的意向大学',univ)"> {{ shorten(univ) }}</span>
          <span class="edit" (click)="handleEdit('univ')">编辑</span>
          <span class="view"  (click)="show('我的意向大学',univ)">查看</span>
          <!-- <span
            nz-icon
            nzType="caret-left"
            nzTheme="outline"
            (click)="show('我的意向大学',univ)"
          ></span> -->
        </p>

        <p class="heading">{{majorTitle}}</p>
        <p class="info">
          <span (click)='show("我的意向专业",major)'>{{ shorten(major)}}</span>
          <!-- <span class="edit" (click)="handleEdit('major')">编辑</span>
          <span class="view"  (click)="show('我的意向专业',major)">查看</span> -->
          <!-- <span
            nz-icon
            nzType="caret-left"
            nzTheme="outline"
            (click)='show("我的意向专业",major)'
          ></span> -->
        </p>

        <p class="heading">{{profTitle}}</p>
        <p class="info" >
          <span (click)='show("我的意向职业",occupation)'>{{ shorten(occupation) }}</span>
          <span class="edit" (click)="handleEdit('occupation')">编辑</span>
          <span class="view"  (click)="show('我的意向职业',occupation)">查看</span>
          <!-- <span
            nz-icon
            nzType="caret-left"
            nzTheme="outline"
            (click)='show("我的意向职业",prof)'
          ></span> -->
        </p>
        <p class="heading">{{litreLearnText}}</p>
        <p class="info" >
          <span (click)='show("我的升学通路",litre)'>{{ shorten(prof) }}</span>
          <span class="edit" (click)="handleEdit('litre')">编辑</span>
        </p>
      </div>
    </div>

    <app-course-select></app-course-select>
    <div class="banner"></div>
    <app-interview
      [header]="selectCourse"
      [courses]="selectShows"
    ></app-interview>
    <app-interview
      [header]="choiceCourse"
      [courses]="choiceShows"
    ></app-interview>
    <app-interview [header]="teacherCourse" [courses]="careerCourses" *ngIf="isTeacher"></app-interview>
    <app-interview [header]="familyCourse"  [courses]="familyCourses" *ngIf="isFamily || isTeacher"></app-interview>
  </div>
</section>

<nz-modal
  [(nzVisible)]="showDetail"
  nzTitle="{{title}}"
  nzWidth="800px"
  (nzOnCancel)="closeDetail()">

  <ng-container *nzModalContent>
    <p *ngFor="let cnt of content" class="detail">{{cnt}}
    <span class="right" nz-icon nzType="close-circle" nzTheme="fill" (click)="delete(cnt)"></span>
    </p>
  </ng-container>

  <div *nzModalFooter>
    <button (click)="closeDetail()">关闭</button>
    <button (click)="handleOk(title)">{{buttonText}}</button>
  </div>
</nz-modal>

<app-footer></app-footer>

