<app-header> </app-header>

<section>
  <div class="container">
    <app-breadcumb [items]="cumbs"></app-breadcumb>
    <div class="search">
      <input nz-input  placeholder="查找专业" [(ngModel)]="value" />
      <button nz-button nzType="primary" (click)="search()">查找</button>
    </div>
    <div class="content">
      <div class="left">
        <nz-tabset [(nzSelectedIndex)]="tabSelect" (nzSelectedIndexChange)="selectChange()">
          <nz-tab nzTitle="本科">
            <p *ngFor = "let major of benke" (click)="showMajor(major.code)">
              <span class="title bold">{{major.name}}</span>
              &nbsp;   &nbsp;  &nbsp;
              <span class="text">专业代码: {{getMajorCode(major.code)}}</span>
              <span nz-icon nzType="right-circle" nzTheme="fill"></span>
              <nz-divider></nz-divider>
            </p>
          </nz-tab>

          <nz-tab nzTitle="专科">
            <p *ngFor = "let major of zhuanke" (click)="showMajor(major.code)">
              <span class="title bold">{{major.name}}</span>
              &nbsp;   &nbsp;  &nbsp;
              <span class="text">专业代码: {{getMajorCode(major.code)}}</span>
              <span nz-icon nzType="right-circle" nzTheme="fill"></span>
              <nz-divider></nz-divider>
            </p>
          </nz-tab>
        </nz-tabset>
      </div>

      <div class="right">
        <div *ngFor = "let major of current">
          <div >
            <span class="title bold">{{major.name}}</span>
            &nbsp;   &nbsp;  &nbsp;
            <span class="text">专业代码: {{getMajorCode(major.code)}}</span>
          </div>

          <div class="major">
            <button *ngFor = "let child of major.children" (click)="detail(child.code)"> {{child.name}} </button>
          </div>

          <nz-divider></nz-divider>
        </div>
      </div>
    </div>
  </div>
</section>

<nz-modal
  nzWidth = '960px'
  [(nzVisible)]="showDetail"
  nzTitle="{{major?.name}}"
  (nzOnCancel)="handleCancel()"
>
  <ng-container *nzModalContent>
    <nz-tabset (nzSelectedIndexChange)="select($event)">
      <nz-tab nzTitle="专业介绍">
        <p *ngIf='major?.introduction' class="title bold">专业介绍</p>
        <p>{{major?.introduction}}</p>
        <p *ngIf='major?.eduObjective' class="title bold">培养目标</p>
        <p>{{major?.eduObjective}}</p>
        <p *ngIf='major?.eduRequirement' class="title bold">培养要求</p>
        <p>{{major?.eduRequirement}}</p>
        <p *ngIf='major?.subjectRequirement' class="title bold">学科要求</p>
        <p>{{major?.subjectRequirement}}</p>
        <p *ngIf='major?.loreAndAbility' class="title bold">知识能力</p>
        <p>{{major?.loreAndAbility}}</p>
        <p *ngIf='major?.studyDirection || major?.zhuanToBenOrient' class="title bold">{{getDirection()}}</p>
        <p>{{getDirectionDetail()}}</p>
        <p *ngIf='major?.mainCourse' class="title bold">主要课程</p>
        <p>{{major?.mainCourse}}</p>
        <p *ngIf='major?.famousScholar' class="title bold">社会名人</p>
        <p>{{major?.famousScholar}}</p>
      </nz-tab>
      <nz-tab nzTitle="薪资评价" [nzDisabled]="!show('XinZiPJ')">
        <div id="salary"></div>
      </nz-tab>
      <nz-tab nzTitle="职业分析" [nzDisabled]="!show('ZhiYeFX')">
        <div id="zhiye"></div>
      </nz-tab>
      <nz-tab nzTitle="行业分布" [nzDisabled]="!show('HangYeFB')">
        <div id="hangye"></div>
      </nz-tab>
      <nz-tab nzTitle="城市分布" [nzDisabled]="!show('ChengShiFB')">
        <div id="chengshi"></div>
      </nz-tab>
    </nz-tabset>

  </ng-container>

  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">关闭</button>
    <button nz-button nzType="primary" (click)="handleOk(major)">收藏</button>
  </div>
</nz-modal>

<app-footer></app-footer>

