import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  picUrls = ["https://resources.delesson.cn/imgs/生涯评测.png",
    "https://resources.delesson.cn/imgs/招考数据.png",
    "https://resources.delesson.cn/imgs/学业规划.png",
    "https://resources.delesson.cn/imgs/专业百科.png",
    "https://resources.delesson.cn/imgs/院校百科.png",
    "https://resources.delesson.cn/imgs/职业百科.png",
    "https://resources.delesson.cn/imgs/提前批.png",
    "https://resources.delesson.cn/imgs/查扩缩招.png",
    "https://resources.delesson.cn/imgs/测概率.png"
  ]

  constructor() {
  }

  ngOnInit(): void {
  }

}
