import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from 'src/app/service/user.service';
import { HttpService } from '~/app/service/http.service';
import { LocalStorageService } from '~/app/service/local-storage.service';
import { isValidPassword } from '~/app/utility/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  constructor(
    private router: Router,
    private storage: LocalStorageService,
    private userService: UserService,
    private message: NzMessageService,
    private fb: FormBuilder,
    private http: HttpService
  ) {
    this.loginForm = this.fb.group({
      userName: ['', Validators.required],
      password: ['', [Validators.required]],
      rememberMe: [false, [Validators.required]],
    });
  }

  async ngOnInit() {
    this.tryDirectLogin();
  }

  async tryDirectLogin() {
    const rememberMe = this.storage.get('rememberMe');
    if (rememberMe) {
      const userName: string = this.storage.get('userName');
      const password: string = this.storage.get('password');

      const login = await this.userService.login(userName, password);
      if (!login) {
        this.message.error('直接登录失败，请检查用户名及密码');
        return;
      } else {
        await this.router.navigate(['/home']);
      }
    }
  }

  // type=1 学生登录  type=2 家长登录
  async submit(types) {
    const data = {
      userName: this.loginForm.value['userName'],
      password: this.loginForm.value['password'],
    };

    const login = await this.userService.login_new(data.userName, data.password,types);
    if (!login) {
      // this.message.error('登录失败，请检查用户名及密码');
      return;
    } else {
      if (this.loginForm.value['rememberMe']) {
        this.storage.set('rememberMe', true);
        this.storage.set('password', this.loginForm.value['password']);
        this.storage.set('userName', this.loginForm.value['userName']);
      }

      await this.router.navigate(['/home']);
    }
  }

  changedPasswordModal = false;
  mobile = '';
  smsCode = '';
  password1 = '';
  password2 = '';
  buttonText = '获取手机验证码';

  // 登录tabs
  tabs = [{
    'index':1,'text':'学生登录'
  }, {'index':2,'text':'家长登录'}];

  forget() {
    this.changedPasswordModal = true;
  }

  passwordCancel() {
    this.changedPasswordModal = false;
  }

  time = 60;
  timer: any;

  async sendSms() {
    let action = '/v1/user/mobilephone-test';
    let params = {
      mobilePhone: this.mobile,
    };

    let result = await this.http.post(action, params);

    if (result === true) {
      this.message.error('手机号码没有注册！');
      return;
    } else {
      action = '/v1/user/send-verify-code';
      params = {
        mobilePhone: this.mobile,
      };

      await this.http.post(action, params);
      this.time = 60;
      this.timer = setInterval(() => {
        this.onTimer();
      }, 1000);
    }
  }

  onTimer() {
    this.time--;
    this.buttonText = this.time + '秒';
    if (this.time === 0) {
      clearInterval(this.timer);
      this.timer = null;
      this.time = 60;
      this.buttonText = '获取验证码';
    }
  }

  async passwordChanged() {
    if (this.timer) {
      clearInterval(this.timer);
    }

    if (!isValidPassword(this.password1)) {
      this.message.error('密码必须为至少8位字符, 且包含字母和数字！');
      return;
    }

    if (this.password1 !== this.password2) {
      this.message.error('两次输入密码不同，请核查');
      return;
    }
    if (!(await this.verifySmsCode(this.smsCode))) {
      this.message.error('验证码输入错误，请核查');
    } else {
      this.password(this.mobile, this.password1);
    }

    this.changedPasswordModal = false;
  }

  async verifySmsCode(smsCode: string) {
    const action = '/v1/user/verify-code';
    const params = {
      code: smsCode,
      mobilePhone: this.mobile,
    };

    const result = await this.http.post(action, params);
    return result;
  }

  async password(mobilePhone, password: string) {
    const action = '/v1/user/change-password-by-phone';
    const params = {
      mobilePhone: mobilePhone,
      password: password,
    };

    await this.http.post(action, params);
    this.message.info("密码重置成功");
  }
}
