<section>
  <div class="container">
    <nz-card nzTitle="测评报告" class="card">
      <div nz-row *ngFor="let item of careerExamData" class="m-b item">
        <div nz-col nzSpan="8">
          <span>测评类别：</span>
          <span>{{ item.examType }}</span>
        </div>
        <div nz-col nzSpan="8">
          <span>测评名称：</span>
          <span>{{ item.title }}</span>
        </div>
        <div nz-col nzSpan="8">
          <span>测评时间：</span>
          <span>{{ item.time }}</span>
        </div>
        <div nz-col nzSpan="24">
          <span>测评结果：</span>
          <div *ngFor="let child of item.explains" class="result-content">
            <span>{{ child.label }}：</span>
            <span>{{ child.explain }}</span>
          </div>
        </div>
      </div>
    </nz-card>
  </div>
</section>
