<section>
  <div class="container">
    <nz-card nzTitle="基本信息" class="card">
      <nz-descriptions>
        <nz-descriptions-item nzTitle="姓名">{{
          info.studentName
        }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="性别">{{
          info.sex == 1 ? "男" : info.sex == 2 ? "女" : "未知"
        }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="学生账号">{{
          info.studentAccount
        }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="学校名称">{{
          info.schoolName
        }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="年级名称">{{
          info.gradeName
        }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="班级名称">{{
          info.className
        }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="选科">
          {{ filterCourse(info.subjectSelection) }}
        </nz-descriptions-item>
      </nz-descriptions>
      <nz-descriptions *ngFor="let item of parentsList">
        <nz-descriptions-item nzTitle="家长姓名">
          {{ item.name }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="家长账号">
          {{ item.userName }}
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-card>
    <nz-card nzTitle="生涯目标" class="card" [nzExtra]="targetTemplate">
      <ng-template #targetTemplate>
        <a (click)="handleTarget()">查看历史</a>
      </ng-template>
      <nz-descriptions [nzColumn]="1">
        <nz-descriptions-item nzTitle="目标大学">
          {{ target?.myCollege }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="目标专业">
          {{ target?.myMajor }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="目标城市">
          {{ target?.cityName }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="目标大学层次">
          {{
            target?.eduLevel == "ben"
              ? "本科"
              : target?.eduLevel == "zhuan"
              ? "专科"
              : ""
          }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="目标职业">
          <div>
            <span>{{ target?.myJob }}</span>
            <a style="margin-left: 10px" (click)="handleProfession()"
              >查看更多</a
            >
          </div>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="升学通路">
          <div>
            <span>{{ target?.myEnroll }}</span>
            <a style="margin-left: 10px" (click)="handleEnroll()">查看更多</a>
          </div>
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-card>
    <nz-card nzTitle="成绩档案" class="card" [nzExtra]="scoreTemplate">
      <ng-template #scoreTemplate>
        <a (click)="handleScoreArchives()">查看更多</a>
      </ng-template>
      <nz-table #scores [nzData]="scoreData" [nzShowPagination]="false" [nzScroll]="{ x: '2400px' }">
        <thead>
          <tr>
            <th>序号</th>
            <th>考试名称</th>
            <th>考试类型</th>
            <th>考试时间</th>
            <th>语文</th>
            <th>数学</th>
            <th>英语</th>
            <th>历史</th>
            <th>地理</th>
            <th>政治</th>
            <th>物理</th>
            <th>化学</th>
            <th>生物</th>
            <th>总分</th>
            <!-- <th>较上一次总分</th> -->
            <th>班次</th>
            <th>较上一次班次</th>
            <th>级次</th>
            <th>较上一次级次</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of scores.data; let idx = index">
            <td>{{ idx + 1 }}</td>
            <td>{{ data.examName }}</td>
            <td>
              {{
                data.examCategory == 1
                  ? "校考"
                  : data.examCategory == 2
                  ? "区县考"
                  : data.examCategory == 3
                  ? "市考"
                  : data.examCategory == 4
                  ? "省考"
                  : ""
              }}
            </td>
            <td>{{ filteTime(data.startTime) }}</td>
            <td>{{ data.chineseScore }}</td>
            <td>{{ data.mathScore }}</td>
            <td>{{ data.foreignLanguageScore }}</td>
            <td>{{ data.historyScore }}</td>
            <td>{{ data.geographyScore }}</td>
            <td>{{ data.politicsScore }}</td>
            <td>{{ data.physicsScore }}</td>
            <td>{{ data.chemistryScore }}</td>
            <td>{{ data.biologyScore }}</td>
            <td>{{ data.totalScore }}</td>
            <!-- <td>
              {{ filterScore(data.totalScoreVary) }}
            </td> -->
            <td>{{ data.classRanking }}</td>
            <td>
              {{ filterScore(data.classRankingVary) }}
            </td>
            <td>{{ data.gradeRanking }}</td>
            <td>
              {{ filterScore(data.gradeRankingVary) }}
            </td>
          </tr>
        </tbody>
      </nz-table>
    </nz-card>
    <nz-card nzTitle="目标方向" class="card" [nzExtra]="extraTemplate">
      <ng-template #extraTemplate>
        <nz-select
          [ngModel]="xueqiValue"
          style="width: 200px"
          (ngModelChange)="handleXueqi($event)"
        >
          <nz-option
            *ngFor="let item of xueqiList"
            [nzValue]="item.xqDate"
            [nzLabel]="item.xq"
          ></nz-option>
        </nz-select>
      </ng-template>
      <div class="course" *ngIf="!myCourses">请先完成选科</div>
      <nz-descriptions [nzColumn]="2">
        <nz-descriptions-item nzTitle="最新成绩">
          {{ totalScore }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="高考位次换算">
          {{ provinceRankingXd }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="第一目标大学">
          {{ xueqifirstcolleges?.myCollege }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="目标专业">
          {{ xueqifirstcolleges?.myMajor }}
        </nz-descriptions-item>
      </nz-descriptions>
      <nz-descriptions [nzColumn]="2" *ngIf="xueqifirstcolleges?.minScore">
        <nz-descriptions-item [nzTitle]="firstText">
          {{ xueqifirstcolleges?.minScore }}
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="firstRankText">
          {{ xueqifirstcolleges?.minRank }}
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="firstAmountText">
          {{ xueqifirstcolleges?.amount }}
        </nz-descriptions-item>
      </nz-descriptions>
      <nz-descriptions [nzColumn]="2" *ngIf="xueqitwocolleges?.minScore">
        <nz-descriptions-item [nzTitle]="twoText">
          {{ xueqitwocolleges?.minScore }}
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="twoRankText">
          {{ xueqitwocolleges?.minRank }}
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="twoAmountText">
          {{ xueqitwocolleges?.amount }}
        </nz-descriptions-item>
      </nz-descriptions>
      <nz-descriptions [nzColumn]="2" *ngIf="xueqithreecolleges?.minScore">
        <nz-descriptions-item [nzTitle]="thereText">
          {{ xueqithreecolleges?.minScore }}
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="thereRankText">
          {{ xueqithreecolleges?.minRank }}
        </nz-descriptions-item>
        <nz-descriptions-item [nzTitle]="thereAmountText">
          {{ xueqithreecolleges?.amount }}
        </nz-descriptions-item>
      </nz-descriptions>
      <nz-table #basicTable [nzData]="xueqiData" [nzShowPagination]="false">
        <thead>
          <tr>
            <th>科目</th>
            <th>语文</th>
            <th>数学</th>
            <th>英语</th>
            <th *ngFor="let item of scoreTh">{{item}}</th>
            <!-- <th>政治</th>
            <th>历史</th>
            <th>地理</th>
            <th>物理</th>
            <th>化学</th>
            <th>生物</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>{{ data.names }}</td>
            <td>{{ data.chineseScore || 0 }}</td>
            <td>{{ data.mathScore || 0 }}</td>
            <td>{{ data.foreignLanguageScore || 0 }}</td>
            <td *ngFor="let item of scoreTd">
              {{data[item] || 0}}
            </td>
            <!-- <td>{{ data.politicsScore || 0 }}</td>
            <td>{{ data.historyScore || 0 }}</td>
            <td>{{ data.geographyScore || 0 }}</td>
            <td>{{ data.physicsScore || 0 }}</td>
            <td>{{ data.chemistryScore || 0 }}</td>
            <td>{{ data.biologyScore || 0 }}</td> -->
          </tr>
        </tbody>
      </nz-table>
    </nz-card>
    <nz-card nzTitle="模拟填报志愿" class="card" [nzExtra]="selectionTemplate">
      <ng-template #selectionTemplate>
        <a (click)="handleVolunteerSelection()">查看更多</a>
      </ng-template>
      <nz-table
        #selection
        [nzData]="selectionData?.slice(0, 10)"
        [nzShowPagination]="false"
      >
        <thead>
          <tr>
            <th>填报时间</th>
            <th>选科</th>
            <th>分数</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of selection.data">
            <td>{{ data.time }}</td>
            <td>{{ data.courses }}</td>
            <td>{{ data.score }}</td>
            <td>
              <a (click)="showCollegeMajor(data.id)">查看</a>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </nz-card>
    <nz-card nzTitle="志愿深度分析报告" class="card" [nzExtra]="reportTemplate">
      <ng-template #reportTemplate>
        <a (click)="handleVolunteerReport()">查看更多</a>
      </ng-template>
      <nz-table
        #report
        [nzData]="reportData?.slice(0, 10)"
        [nzShowPagination]="false"
      >
        <thead>
          <tr>
            <th>填报时间</th>
            <th>选科</th>
            <th>分数</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of report.data">
            <td>{{ data.uploadOn }}</td>
            <td>{{ data.courses }}</td>
            <td>{{ data.score }}</td>
            <td>
              <a (click)="dowload(data.id)">下载</a>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </nz-card>
    <nz-card nzTitle="测评报告" class="card" [nzExtra]="careerExamTemplate">
      <ng-template #careerExamTemplate>
        <a (click)="handleCareerExam()">查看更多</a>
      </ng-template>
      <div
        nz-row
        *ngFor="let item of careerExamData?.slice(0, 10)"
        class="m-b item"
      >
        <div nz-col nzSpan="8">
          <span>测评类别：</span>
          <span>{{ item.examType }}</span>
        </div>
        <div nz-col nzSpan="8">
          <span>测评名称：</span>
          <span>{{ item.title }}</span>
        </div>
        <div nz-col nzSpan="8">
          <span>测评时间：</span>
          <span>{{ item.time }}</span>
        </div>
        <div nz-col nzSpan="24">
          <span>测评结果：</span>
          <div *ngFor="let child of item.explains" class="result-content">
            <span>{{ child.label }}：</span>
            <span>{{ child.explain }}</span>
          </div>
        </div>
      </div>
    </nz-card>
    <nz-card nzTitle="收藏" class="card" [nzExtra]="favoriteTemplate">
      <ng-template #favoriteTemplate>
        <a (click)="handlefavorite()">查看更多</a>
      </ng-template>
      <nz-descriptions [nzColumn]="1">
        <nz-descriptions-item nzTitle="院校">
          <span
            *ngFor="
              let item of favorite.colleges?.slice(0, 10);
              let idx = index
            "
          >
            <span *ngIf="idx != 0">、</span>
            {{ item.name }}
          </span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="专业">
          <span
            *ngFor="let item of favorite.majors?.slice(0, 10); let idx = index"
          >
            <span *ngIf="idx != 0">、</span>
            {{ item.name }}
          </span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="职业">
          <span
            *ngFor="
              let item of favorite.professions?.slice(0, 10);
              let idx = index
            "
          >
            <span *ngIf="idx != 0">、</span>
            {{ item.name }}
          </span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="资讯">
          <span
            *ngFor="
              let item of favorite.resources?.slice(0, 10);
              let idx = index
            "
          >
            <span *ngIf="idx != 0">、</span>
            {{ item.title }}
          </span>
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-card>
    <nz-card nzTitle="足迹" class="card" [nzExtra]="footprintTemplate">
      <ng-template #footprintTemplate>
        <a (click)="handleFootprint()">查看更多</a>
      </ng-template>
      <nz-table
        #footprint
        [nzData]="footprintData?.slice(0, 10)"
        [nzShowPagination]="false"
      >
        <thead>
          <tr>
            <th>序号</th>
            <th>浏览时间</th>
            <th>类别</th>
            <th nzWidth="400px">名称</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of footprint.data; let idx = index">
            <td>{{ idx + 1 }}</td>
            <td>{{ data.uploadOn }}</td>
            <td>{{ data.type }}</td>
            <td>{{ data.title }}</td>
          </tr>
        </tbody>
      </nz-table>
    </nz-card>
    <!-- 足迹 -->
    <nz-modal
      [(nzVisible)]="isVisible"
      nzWidth="960px"
      nzTitle="足迹"
      nzCentered="true"
      (nzOnCancel)="handleFootCancel()"
      (nzOnOk)="handleFootCancel()"
    >
      <ng-container *nzModalContent>
        <nz-table #footprint [nzData]="footprintData" [nzShowPagination]="true">
          <thead>
            <tr>
              <th>序号</th>
              <th>浏览时间</th>
              <th>类别</th>
              <th nzWidth="400px">名称</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of footprint.data; let idx = index">
              <td>{{ idx + 1 }}</td>
              <td>{{ data.uploadOn }}</td>
              <td>{{ data.type }}</td>
              <td>{{ data.title }}</td>
            </tr>
          </tbody>
        </nz-table>
      </ng-container>
    </nz-modal>
    <!-- 收藏 -->
    <nz-modal
      [(nzVisible)]="isfavorite"
      nzWidth="960px"
      nzTitle="收藏"
      nzCentered="true"
      (nzOnCancel)="handlefavoriteCancel()"
      (nzOnOk)="handlefavoriteCancel()"
    >
      <ng-container *nzModalContent>
        <nz-descriptions [nzColumn]="1">
          <nz-descriptions-item nzTitle="院校">
            <span *ngFor="let item of favorite.colleges; let idx = index">
              <span *ngIf="idx != 0">、</span>
              {{ item.name }}
            </span>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="专业">
            <span *ngFor="let item of favorite.majors; let idx = index">
              <span *ngIf="idx != 0">、</span>
              {{ item.name }}
            </span>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="职业">
            <span *ngFor="let item of favorite.professions; let idx = index">
              <span *ngIf="idx != 0">、</span>
              {{ item.name }}
            </span>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="资讯" class="resources">
            <span *ngFor="let item of favorite.resources; let idx = index">
              <span *ngIf="idx != 0">、</span>
              {{ item.title }}
            </span>
          </nz-descriptions-item>
        </nz-descriptions>
      </ng-container>
    </nz-modal>
    <!-- 模拟志愿 -->
    <nz-modal
      nzWidth="960px"
      [(nzVisible)]="showCollegeMajorModal"
      nzTitle="志愿报考数据"
      (nzOnCancel)="closeCollegeMajor()"
    >
      <ng-container *nzModalContent>
        <div class="content">
          <div class="title dark">
            <span>选择考试科目：&nbsp;</span
            ><span class="bold">{{ myCollegeMajor?.xuanke.courses }}</span>
            <span>&nbsp; &nbsp; 成绩：&nbsp;</span
            ><span class="bold">{{ myCollegeMajor?.xuanke.score }}</span>
            <span class="choice">&nbsp; &nbsp; 选择志愿为结果为：</span>
          </div>

          <div class="univs">
            <dic class="univ" *ngFor="let detail of currMajorPage">
              <app-major-choose
                [major]="detail"
                [showRight]="false"
              ></app-major-choose>
              <nz-divider></nz-divider>
            </dic>
          </div>

          <nz-pagination
            class="right"
            [(nzPageIndex)]="pageIndex"
            [nzTotal]="this.myCollegeMajor?.detail.length"
            (nzPageIndexChange)="gotoPage()"
          ></nz-pagination>
        </div>
      </ng-container>
      <div *nzModalFooter>
        <button nz-button nzType="default" (click)="closeCollegeMajor()">
          关闭
        </button>
      </div>
    </nz-modal>
  </div>
</section>
