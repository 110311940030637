<div class="container">
  <div class="login-box">
    <div class="left"></div>
    <div class="right">
      <p class="center title">用户注册</p>
      <p class="center normal">以下信息填写后不可更改，请谨慎填写</p>

      <form nz-form [formGroup]="infoForm">
        <p class="title">姓名</p>
        <nz-form-item>
          <nz-form-control nzErrorTip="输入您的姓名">
            <nz-input-group nzPrefixIcon="user" nzSize="large">
              <input
                type="text"
                nz-input
                formControlName="name"
                placeholder="姓名"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <div class="info">
          <div class="rleft">
            <p class="title">毕业年份</p>
            <nz-form-item>
              <nz-form-control nzErrorTip="选择毕业年份">
                <nz-select formControlName="year">
                  <nz-option *ngFor="let year of years"  nzValue="{{year}}" nzLabel="{{year}}年"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="rright">
            <p class="title">性别</p>
            <nz-form-item>
              <nz-form-control nzErrorTip="选择性别">
                <nz-select formControlName="gender">
                  <nz-option nzValue="男" nzLabel="男"></nz-option>
                  <nz-option nzValue="女" nzLabel="女"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <p class="title">省份</p>
        <nz-form-item>
          <nz-form-control nzErrorTip="选择您所在身份">
            <nz-select formControlName="province">
              <nz-option *ngFor="let prov of provNames" nzValue="{{prov}}" nzLabel="{{prov}}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <p class="buttons">
          <span>
            <button nz-button nzType="primary" (click)="submit()">
              立即注册->
            </button>
          </span>
        </p>
      </form>
    </div>
  </div>
</div>
