<app-header></app-header>

<div class="section">
  <div class="header">
    <app-breadcumb [items]="cumbs"> </app-breadcumb>
  </div>

  <div class="search">
    <input nz-input  placeholder="查找院校" [(ngModel)]="value" />
    <button nz-button nzType="primary" (click)="search()">查找</button>
  </div>

  <div class="header">
    <div class="course">
      <app-com-courses
        [courses]="courses"
        [score]="score"
        (courseSelectChanged)="changeCourse($event)"
        (scoreConfirmed)="changeScore($event)"
      ></app-com-courses>
    </div>

    <div class="buttons">
      <button
        [ngClass]="{
          button_checked: contains(typeSelected, '冲'),
          button_unchecked: !contains(typeSelected, '冲')
        }"
        (click)="filterType('冲')"
      >
        冲({{ typeCount[0] }})
      </button>
      <button
        [ngClass]="{
          button_checked: contains(typeSelected, '稳'),
          button_unchecked: !contains(typeSelected, '稳')
        }"
        (click)="filterType('稳')"
      >
        稳({{ typeCount[1] }})
      </button>
      <button
        [ngClass]="{
          button_checked: contains(typeSelected, '保'),
          button_unchecked: !contains(typeSelected, '保')
        }"
        (click)="filterType('保')"
      >
        保({{ typeCount[2] }})
      </button>
      &nbsp;
      <button
        class="button"
        nz-button
        nzType="primary"
        nzDanger
        (click)="save()"
      >
        保存
      </button>
    </div>
  </div>

  <div class="select">
    <app-select-univ
      (onEduLevelChange)="eduLevelChanged($event)"
      (onCategoryChange)="categoryChanged($event)"
      (onFeatureChange)="featureChanged($event)"
      (onNatureTypeChange)="natureTypeChanged($event)"
      (onProvinceChange)="provinceChanged($event)"
    ></app-select-univ>
  </div>

  <div class="univs border">
    <div *ngFor="let univ of currPage; let idx = index">
      <app-major-choose [major]="univ" [index]="idx" (onDelete) = "delete($event)" (onMoveUp) = "moveUp($event)" (onMoveDown) = "moveDown($event)"></app-major-choose>
      <nz-divider></nz-divider>
    </div>
    <nz-pagination class="right"
    [(nzPageIndex)]="pageIndex"
    [nzTotal]="this.filtered.length"
    (nzPageIndexChange)="gotoPage()"
  ></nz-pagination>
  </div>
  </div>

  <app-footer></app-footer>
