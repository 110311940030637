<nz-table #basicTable [nzData]="data">
  <thead>
    <tr>
      <td align="center" class="dark bold">序号</td>
      <td align="center" class="dark bold">省份</td>
      <td align="center" class="dark bold">年度</td>
      <td align="center" class="dark bold">批次</td>
      <td align="center" class="dark bold">类型</td>
      <td align="center" class="dark bold">分数</td>
      <td align="center" class="dark bold">备注</td>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let dt of basicTable.data; let idx = index">
      <td align="center" class="dark">{{ idx + 1 }}</td>
      <td align="center" class="dark">{{ dt.province }}</td>
      <td align="center" class="dark">{{ dt.year }}</td>
      <td align="center" class="dark">{{ dt.batch }}</td>
      <td align="center" class="dark">{{ dt.course }}</td>
      <td align="center" class="dark">{{ dt.score }}</td>
      <td align="center" class="dark">{{ dt.memo }}</td>
    </tr>
  </tbody>
</nz-table>
