<div class="content">
  <div class="left">
    <img src="{{ data?.picUrl }}" alt=""/>
  </div>
  <div class="center">
    <a class="title bold darken" href="{{data?.url}}" target="_blank">{{ data?.title }}</a>
    <p class="text">
      热力值：{{ data.hotValue }} &nbsp; &nbsp; 浏览次数：{{ data.score }}
    </p>
  </div>
  <div class="right">
    <a href="{{data?.url}}" target="_blank"><button nz-button nzType="primary">查看</button></a>
  </div>
</div>
