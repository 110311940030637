import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-bk-1',
  template: `<button (click)="goToBaidu()">跳转到专业百科</button>`
})
export class AppComponent {
  @Input() imgUrl: string;

  // tslint:disable-next-line:typedef
  goToBaidu() {
    // 直接将window的location.href属性设置为百度的URL
    // window.location.href = 'https://www.baidu.com';
    // window.open('https://www.baidu.com', '_top');
    // window.open('http://localhost:9527/#/majors', '_blank');


    window.open('http://localhost:9527/#/majors?sign-'+ window.btoa( localStorage.getItem("username")+','+localStorage.getItem("pwd")), '_top');
  }
}
