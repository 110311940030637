import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from 'src/app/service/user.service';
import { HttpService } from 'src/app/service/http.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-volunteer-selection',
  templateUrl: './volunteer-selection.component.html',
  styleUrls: ['./volunteer-selection.component.scss'],
})
export class VolunteerSelectionComponent implements OnInit {
  constructor(
    private message: NzMessageService,
    private userService: UserService,
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  code = this.userService.code();
  selectionData = []
  async ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if(params.code) {
        this.code = params.code
      }
    });
    this.fetchMySelection()
  }
  async fetchMySelection() {
    const action = `/v1/enroll2/list-my-selection`;
    const params = {
      userCode: this.code,
    };
    const data: any = await this.http.post(action, params);
    this.selectionData = data;
  }
  showCollegeMajorModal = false;
  closeCollegeMajor() {
    this.showCollegeMajorModal = false;
  }
  myCollegeMajor: any = null
  async findMyCollegeMajor(id: number) {
    const action = '/v1/enroll2/find-my-selection';
    const params = {
      id: id,
    };

    this.myCollegeMajor = await this.http.post(action, params);
  }
  currMajorPage = []
  pageIndex = 1;
  pageSize = 5;
  gotoPage() {
    this.currMajorPage = this.myCollegeMajor.detail.slice(
      (this.pageIndex - 1) * this.pageSize,
      this.pageIndex * this.pageSize
    );
  }
  async showCollegeMajor(id: number) {
    await this.findMyCollegeMajor(id);
    this.gotoPage();
    this.showCollegeMajorModal = true;
  }
}