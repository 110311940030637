<app-header></app-header>

<section>
  <div class="container">
    <app-breadcumb [items]="cumbs"></app-breadcumb>

    <div class="content">
      <nz-tabset [nzSelectedIndex]="active">
        <nz-tab *ngFor = "let type of types, let idx = index" nzTitle="{{type}}">
          <div *ngFor="let item of group[idx]">
            <app-comp-attention [data]="item"></app-comp-attention>
            <nz-divider></nz-divider>
          </div>
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
</section>

<app-footer></app-footer>
