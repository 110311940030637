import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { HttpService } from 'src/app/service/http.service';
import { CollegeScore, EnrollData, MajorEnrollInfo, compareEnrollPlan, compareMajor, compareScore } from '~/app/shared/enroll.typs';
import { EnrollIntroduction, UnivDetail } from '~/app/shared/university.type';
import { NzMessageService } from 'ng-zorro-antd/message';
import { getBXLevel, getNatureType } from '~/app/utility/domain';

@Component({
  selector: 'app-enroll-plan',
  templateUrl: './enroll-plan.component.html',
  styleUrls: ['./enroll-plan.component.scss'],
})
export class EnrollPlanComponent implements OnInit {
  @Input() year: number;
  @Input() data: CollegeScore[];

  province: string;
  showModal: boolean = false;
  enrollPlan: EnrollData[];

  constructor(private userService: UserService, private message: NzMessageService, private http: HttpService) {}

  ngOnInit(): void {
    this.province = this.userService.province();
  }

  async viewEnrollData(college: string) {
    const provinceCode = this.userService.provinceCode();
    const action = '/v1/enroll2/list-college-enroll-plan';

    const params = {
      provinceCode: provinceCode,
      collegeName: college,
    };

    const data: EnrollData[] = await this.http.post(action, params);
    this.enrollPlan = data.filter(c=>c.year == this.year);
    this.showModal = true;
  }

  handleCancel(){
    this.showModal = false;
  }

  univ: UnivDetail;
  univMajors = '';
  busy = false;
  showDetail = false;
  enrollIntro: EnrollIntroduction[] = [];

  async detail(id, name, code) {
    this.busy = true;

    const action = '/v1/college/find';
    const params = {
      id: id,
    };

    this.univ = await this.http.post(action, params);
    if (!this.univ) {
      this.message.error('大学信息获取错误，请稍后重试！');
      this.busy = false;
      return;
    }

    this.univMajors = this.univ.majors.map((c) => c.name).join(', ');

    await this.getCollegeScores(name);
    await this.getMajorScores(name);
    await this.getCollegePlans(name);
    await this.getEnrollIntroduction(code);

    this.busy = false;
    this.showDetail = true;
  }

  handleUnivCancel(){
    this.showDetail = false;
  }

  majorScores: MajorEnrollInfo[] = [];

  async getMajorScores(name: string) {
    const action = '/v1/enroll2/list-college-major-scores';
    const params = {
      collegeName: name,
      provinceCode: this.userService.provinceCode(),
    };

    let data = await this.http.post(action, params);
    if (data) {
      this.majorScores = data.sort((a,b)=> compareMajor(a,b));
    }
  }

  collegeScores: CollegeScore[] = [];
  async getCollegeScores(name: string) {
    const college_score_action = '/v1/college-enroll/list-province-college';

    const provinceCode = this.userService.provinceCode();
    const params = {
      code: provinceCode,
    };

    this.collegeScores = await this.http.post(college_score_action, params);
    if (this.collegeScores) {
      this.collegeScores = this.collegeScores
        .filter((c) => c.college === name)
        .sort((a, b) => compareScore(a, b));
    }
  }

  async getCollegePlans(name: string) {
    const provinceCode = this.userService.provinceCode();
    const action = '/v1/enroll2/list-college-enroll-plan';

    const params = {
      provinceCode: provinceCode,
      collegeName: name,
    };

    const data: EnrollData[] = await this.http.post(action, params);
    this.enrollPlan = data?.sort((a, b) => compareEnrollPlan(a, b));
  }

  current: EnrollIntroduction = undefined;

  async getEnrollIntroduction(code: string) {
    const action = '/v1/enroll2/find-collge-enroll-introduction';
    const params = {
      code: code,
    };

    this.enrollIntro = await this.http.post(action, params);
    this.current = this.enrollIntro?.length > 0 ? this.enrollIntro[0] : null;
  }

  showIntro(dt: EnrollIntroduction) {
    this.current = dt;
  }

  getEduLevel(eduLevel: string){
    return getBXLevel(eduLevel);
  }

  getNType(nt: string){
    return getNatureType(nt);
  }
}
