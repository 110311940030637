import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from 'src/app/service/http.service';
import { UserService } from 'src/app/service/user.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '~/app/service/local-storage.service';
import { Topics } from '~/app/shared/topics.type';
import { family2StudentCode, teacher2StudentCode } from '~/app/utility/domain';

@Component({
  selector: 'app-study',
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.scss'],
})
export class StudyComponent implements OnInit {
  titles = ['我的题库', '名师讲堂', '学习效能课堂', '教师进修'];
  academicPicUrl = [
    'https://resources.delesson.cn/imgs/学业测评.png',
    'https://resources.delesson.cn/imgs/成绩档案.png',
    'https://resources.delesson.cn/imgs/学习效能.png',
    'https://resources.delesson.cn/imgs/学业规划-1.png',
  ];
  coursesUrls = [
    'https://resources.delesson.cn/imgs/语文.png',
    'https://resources.delesson.cn/imgs/数学.png',
    'https://resources.delesson.cn/imgs/外语.png',
    'https://resources.delesson.cn/imgs/物理.png',
    'https://resources.delesson.cn/imgs/历史.png',
    'https://resources.delesson.cn/imgs/化学.png',
    'https://resources.delesson.cn/imgs/生物.png',
    'https://resources.delesson.cn/imgs/地理.png',
    'https://resources.delesson.cn/imgs/政治.png',
    'https://resources.delesson.cn/imgs/技术.png',
  ];

  data: any;
  myResources: Topics[] = [];
  msjtShows: Topics[] = [];
  xxxnShows: Topics[] = [];

  isTeacher: boolean = false;
  isFamily: boolean = false;
  teacherCourses: Topics[] = [];

  constructor(
    private router: Router,
    private message: NzMessageService,
    private stoage: LocalStorageService,
    private userService: UserService,
    private http: HttpService
  ) {}

  async ngOnInit() {
    this.isTeacher = this.userService.isTeacher();
    this.isFamily = this.userService.isFamily();
    
    this.fetchData();
  }

  async fetchData() {
    let code = this.userService.code();
    const grade = this.userService.grade();
    const name = this.userService.name();
    const province = this.userService.province();
    const unitName = this.userService.school();

    if (this.isTeacher) {
      code = teacher2StudentCode(code);
    } else if (this.isFamily) {
      code = family2StudentCode(code);
    }

    const param = {
      code: code,
      grade: grade,
      name: name,
      province: province,
      unitName: unitName,
    };

    const action = '/v1/personal/study';
    this.data = await this.http.post(action, param);

    this.myResources = this.fetchField('myResources');
    this.msjtShows = this.fetchField('msjtShows');
    this.xxxnShows = this.fetchField('xxxnShows');

    if (this.isTeacher) {
      const action = '/v1/resource/list-by-type';
      const params = {
        mainType: '教师进修',
        type: '',
      };

      this.teacherCourses = await this.http.post(action, params);
    }
  }

  private fetchField(field: string): Topics[] {
    const result = this.data[field];
    return result;
  }

  info() {
    this.message.info('本功能将陆续开放，敬请期待！');
  }

  studyExam() {
    this.router.navigate(['study-test']);
  }

  course(course: string) {
    this.stoage.set('currPage', course);
    this.router.navigate(['videos', '课程']);
  }
}
