import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Topics } from '~/app/shared/topics.type';

@Component({
  selector: 'app-interview',
  templateUrl: './interview.component.html',
  styleUrls: ['./interview.component.scss'],
})
export class InterviewComponent implements OnInit {
  @Input() header?: string;
  @Input() courses: Topics[] = [];

  constructor(private route: Router) {}

  ngOnInit(): void {
    if (!this.header) {
      this.header = '生涯访谈';
    }
  }

  more() {
    if (this.header === '我的题库') {
      this.route.navigate(["exam-list"]);
    } else {
      this.route.navigate(['videos', this.header]);
    }
  }
}
