import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '~/app/service/http.service';
import { UserService } from '~/app/service/user.service';
import { Topics } from '~/app/shared/topics.type';

@Component({
  selector: 'app-exam-list',
  templateUrl: './exam-list.component.html',
  styleUrls: ['./exam-list.component.scss']
})
export class ExamListComponent implements OnInit {
  cumbs = ["学业","我的题库"];

  data: Topics[]= [];

  constructor(private http: HttpService,
    private message: NzMessageService,
    private userService: UserService) { }

  async ngOnInit() {
    await this.fetchData();
    this.gotoPage(1);
  }

  async fetchData(){
    const action = "/v1/MyResource/list-my-resource";
    const params = {
      userCode: this.userService.code(),
    }

    this.data = await this.http.post(action,params);
    if (!this.data){
      this.message.error("数据获取错误");
    }

    for (let dt of this.data){
      if (!dt.picUrl || dt.picUrl === ""){
        dt.picUrl = "https://resources.delesson.cn/resources/我的题库/001.jpg";
      }
    }
  }

  pageSize = 10;
  currPage : Topics[] = [];

  gotoPage(idx: number) {
    this.currPage = this.data.slice(
      (idx - 1) * this.pageSize,
      idx * this.pageSize
    );
  }
}
