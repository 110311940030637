import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzMessageModule} from 'ng-zorro-antd/message';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NzMessageModule
  ]
})
export class ServiceModule { }
