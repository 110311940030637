<app-header></app-header>

<section>
  <div class="container">
    <app-breadcumb [items]="cumbs"></app-breadcumb>
    <div class="search">
      <input nz-input  placeholder="查找专业" [(ngModel)]="value" />
      <button (click)="doSearch()">查找</button>
    </div>

    <div class="majors narrow">
      <app-com-courses [courses]="courses" (courseSelectChanged)="changeCourse($event)"></app-com-courses>
    </div>

    <div class="majors">
      <div class="major" *ngFor="let item of filtered">
        <app-major [major]="item"></app-major>
        <nz-divider></nz-divider>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>