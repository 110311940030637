import { Component, OnInit } from '@angular/core';
import { TestDesc } from '~/app/shared/testdesc.type';

@Component({
  selector: 'app-study-test',
  templateUrl: './study-test.component.html',
  styleUrls: ['./study-test.component.scss']
})
export class StudyTestComponent implements OnInit {
  data: TestDesc[] =[];
  basePath = '../../../../assets/study-test/';

  constructor() {}

  ngOnInit(): void {
    this.data = [
      { id: 17, caption: '托兰斯创造性测评', userType:'学生', count: 1, imgUrl: '001.png' },
      { id: 16, caption: '一般自我效能测评', userType:'学生', count: 1, imgUrl: '002.png' },
      { id: 15, caption: '青少年时间管理测评', userType:'学生', count: 1, imgUrl: '003.png' },
      { id: 39, caption: '中学生学习方法测评', userType:'学生', count: 1, imgUrl: '004.png' },
      { id: 40, caption: '中学生学习动机测评', userType:'学生', count: 1, imgUrl: '005.png' },
      { id: 18, caption: '考试焦虑测评', userType:'学生', count: 1, imgUrl: '006.png' },
      { id: 13, caption: '自信度测评',userType:'学生', count: 1, imgUrl: '007.png' },
    ];

    this.data.forEach((c) => {
      c.imgUrl = this.basePath + c.imgUrl;
    });
  }
}
