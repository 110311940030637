import { Component, OnInit } from '@angular/core';
import { TestDesc } from '~/app/shared/testdesc.type';

@Component({
  selector: 'app-career-test',
  templateUrl: './career-test.component.html',
  styleUrls: ['./career-test.component.scss'],
})
export class CareerTestComponent implements OnInit {
  data: TestDesc[];
  basePath = '../../../../assets/career-test/';
  cumbs = ["首页", "生涯测评"];

  constructor() {}

  ngOnInit(): void {
    this.data = [
      { id: null, caption: '选科定位测评', userType:'学生', count: 128, imgUrl: '001.png' },
      { id: 19, caption: '专业定位测评', userType:'学生', count: 130, imgUrl: '002.png' },
      { id: null, caption: '家庭资源分析测评', userType:'学生', count: 135, imgUrl: '003.png' },
      { id: 1, caption: 'MBTI人格特质测评', userType:'学生', count: 145, imgUrl: '004.png' },
      { id: 11, caption: '中学生多元智能测评', userType:'学生', count: 160, imgUrl: '005.png' },
      { id: 24, caption: '自我价值测评', userType:'学生', count: 171, imgUrl: '006.png' },
      { id: 2, caption: '气质类型测评',userType:'学生', count: 172, imgUrl: '007.png' },
      { id: 3, caption: '职业价值观测评',userType:'学生', count: 173, imgUrl: '008.png' },
      { id: 31, caption: '艾森克人格个性测评',userType:'学生', count: 175, imgUrl: '009.png' },
      { id: 41, caption: '霍兰德职业倾向测评',userType:'学生', count: 177, imgUrl: '010.png' }
    ];

    this.data.forEach((c) => {
      c.imgUrl = this.basePath + c.imgUrl;
    });
  }
}
