<app-header> </app-header>

<section>
  <div class="container">
    <app-breadcumb [items]="cumbs"></app-breadcumb>
    <div class="search">
      <input nz-input  placeholder="查找职业" [(ngModel)]="value" />
      <button nz-button nzType="primary" (click)="search()">查找</button>
    </div>

    <div class="content">
      <div class="left">
        <nz-tabset>
          <nz-tab nzTitle="职业百科">
            <p *ngFor = "let prof of data" (click)="showProfession(prof.code)">
              <span class="title bold">{{prof.name}}</span>
              &nbsp;
              <span class="text">({{prof.code}})</span>
              <span nz-icon nzType="right-circle" nzTheme="fill"></span>
              <nz-divider></nz-divider>
            </p>
          </nz-tab>
        </nz-tabset>
      </div>

      <div class="right">
        <div *ngFor = "let prof of current">
          <div >
            <span class="title bold link" (click)="detail(prof.code)">{{prof.name}}</span>
            &nbsp;   &nbsp;  &nbsp;
            <span class="text">专业代码: {{prof.code}}</span>
          </div>

          <div class="major">
            <button *ngFor = "let child of prof.children" (click)="detail(child.code)"> {{child.name}} </button>
          </div>

          <nz-divider></nz-divider>
        </div>
      </div>
    </div>
  </div>
</section>

<nz-modal
  nzWidth="800px"
  [(nzVisible)]="showDetail"
  nzTitle="{{ details?.name }}"
  (nzOnCancel)="handleCancel()"
>
  <ng-container *nzModalContent>
    <p class="bold">职业代码: {{ details?.code }}</p>
    <div [innerHTML]="details.description"></div>
    <nz-divider></nz-divider>

    <p class="title bold">相关专业</p>
    <div *ngFor="let major of details.majors">
      <p><span class="text-20 bold">{{ major.name }}</span>  &nbsp; &nbsp;
        专业代码：{{ major.code }} &nbsp; | 学制：{{ major.learnYear }}年 &nbsp;
        | 男女比例 {{ major.mfRatioView }}
      </p>
    </div>
  </ng-container>

  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">关闭</button>
    <button nz-button nzType="primary" (click)="handleOk(details)">收藏</button>
  </div>
</nz-modal>

<app-footer></app-footer>
