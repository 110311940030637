import { Component, Input, OnInit,Output,EventEmitter } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from 'src/app/service/http.service';
import { UserService } from '~/app/service/user.service';
import { MyChoice } from '~/app/shared/mychoice.type';
import {
  ProfessionDetail,
  ProfessionInfo,
  ProfessionSummary,
} from '~/app/shared/profession.type';

@Component({
  selector: 'app-collect-profession',
  templateUrl: './profession.component.html',
  styleUrls: ['./profession.component.scss'],
})
export class ProfessionCollectComponent implements OnInit {
  @Input() data;
  //   data: ProfessionInfo[] = [];
  @Output() onCompareChanged: EventEmitter<string> = new EventEmitter();
  current: ProfessionInfo[] = [];

  cumbs = ['首页', '职业百科'];

  constructor(
    private http: HttpService,
    private message: NzMessageService,
    private userService: UserService
  ) {}

  async ngOnInit() {
    // await this.fetchData();
    // this.showProfession(this.data[0].code);
  }

  async fetchData() {
    const action = '/v1/profession/list-profression-tree';
    const params = {};

    this.data = await this.http.post(action, params);
    if (!this.data) {
      this.message.error('数据获取失败');
    }
  }

  showProfession(code: string) {
    this.current = this.data.find((c) => c.code === code).children;
  }

  details: ProfessionDetail = null;
  showDetail = false;

  async detail($event) {
    const url = `/job/${$event}.json`;
    const summary = await this.http.get(url);
    if (!summary) {
      return;
    }

    const infos = summary['result'];

    const action = '/v1/profession/list-profression-detail';
    const params = {
      code: $event,
    };

    this.details = await this.http.post(action, params);
    this.details.majors = infos;

    this.showDetail = true;
  }

  handleCancel() {
    this.showDetail = false;
  }

  async handleOk($event) {
    const action = '/v1/MyFavorite/delete-my-favorite';
    const params = {
      code: $event?.code,
      resourceType: '职业',
      userCode: this.userService.code(),
      resourceId: ''
    };
    await this.http.post(action, params);
    this.message.success('取消收藏成功');
    this.onCompareChanged.emit($event);
    this.showDetail = false;
  }

  value: string = '';
  search() {
    if (!this.value || this.value === '') {
      this.showProfession(this.data[0].code);
      return;
    }

    let selected: ProfessionInfo[] = [];
    for (let pro of this.data) {
      const children = pro.children;
      for (let child of children) {
        if (child.name.includes(this.value)) {
          selected.push(child);
          break;
        }

        const grand = child.children;
        if (!grand) {
          continue;
        }
        let contained = false;

        for (let g of grand) {
          if (g.name.includes(this.value)) {
            selected.push(child);
            contained = true;
            break;
          }
        }

        if (contained) {
          break;
        }
      }
    }

    this.current = selected;
  }
}
