<header>
  <div class="container">
    <div class="nav menu normal" style="position: relative">
      <div class="menu-item center active logo">&nbsp;</div>
      <div routerLink="/home" class="menu-item center active false">首页</div>
      <div routerLink="/career" class="menu-item center active false">生涯</div>
      <div routerLink="/study" class="menu-item center active false">学业</div>
      <div routerLink="/psychology" class="menu-item center active false">心理</div>
      <div routerLink="/my" class="menu-item center active false">我的</div>

      <nz-input-group nzSuffixIcon="search">
        <input
          type="text"
          nz-input
          placeholder="搜索高校/专业/职业"
          [(ngModel)]="search"
          (keyup.enter)="doSearch()"
        />
      </nz-input-group>
      <nz-avatar nzIcon="user" [nzSrc]="avatar_url"></nz-avatar>

      <div id="profile">
        <p>{{ name }} {{ grade }}</p>
        <p>{{ school }}</p>
      </div>

      <div class="menu-item has-children my arrow">
        &nbsp;
        <span>
          <svg
            t="1656271440053"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="8359"
            width="14"
            height="14"
          >
            <path
              d="M951.405714 390.948571a36.516571 36.516571 0 0 0-33.828571-22.857142H106.605714c-14.994286 0-28.342857 9.142857-33.828571 22.857142s-2.377143 29.622857 8.411428 40.045715l405.394286 391.497143c7.131429 6.765714 16.274286 10.24 25.417143 10.24s18.285714-3.474286 25.417143-10.24l405.394286-391.497143c10.788571-10.422857 14.08-26.148571 8.594285-40.045715z"
              fill="#262626"
              p-id="8360"
            ></path>
          </svg>
          <div class="child-box">
            <a *ngIf="showStudentInfo" class="sub" routerLink="/mine">学生信息</a>
            <a *ngIf="isStudent" class="sub" (click)="archives()">生涯档案</a>
            <a class="sub" (click)="download()">下载APP</a>
            <a class="sub" (click)="changePassword()">修改密码</a>
            <a class="sub" (click)="changeAvatar()">更改头像</a>
            <a class="sub" (click)="showFeedback()">意见反馈</a>
            <a class="sub" href="{{agreeUrl}}" target="_blank">用户协议</a>
            <a class="sub" href="{{protectUrl}}" target="_blank">隐私保护协议</a>
            <a class="sub" href="{{manualUrl}}" target="_blank">操作说明</a>
            <a class="sub" (click)="exit()">退出系统</a>
          </div>
        </span>
      </div>

      <div class="vip" *ngIf="vipSF() == '2' && vipNum() != 'VIP已到期'">
        <div class="logo"></div>
        <div>{{vipNum()}}</div>
      </div>

      <div class="vip" *ngIf="vipSF() == '2' && vipNum() == 'VIP已到期'">
        <div class="logo2"></div>
        <div style="color: #194261">{{vipNum()}}</div>
      </div>

    </div>
  </div>
</header>

<nz-modal
  nzWidth="800px"
  [(nzVisible)]="feedBackModal"
  nzTitle="提交反馈意见"
  (nzOnCancel)="feedBackCancel()"
>
  <ng-container *nzModalContent>
    <p class="title dark bold">您的意见:</p>
    <p>
      <textarea
        nz-input
        placeholder="输入您的宝贵意见"
        [nzAutosize]="{ minRows: 3, maxRows: 5 }"
        [(ngModel)]="feedback"
      ></textarea>
    </p>
  </ng-container>

  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="feedBackCancel()">取消</button>
    <button nz-button nzType="primary" (click)="feedBackSubmitted()">
      提交
    </button>
  </div>
</nz-modal>

<nz-modal
  nzWidth="800px"
  [(nzVisible)]="avatarModal"
  nzTitle="更换我的头像"
  (nzOnCancel)="avatarCancel()"
>
  <ng-container *nzModalContent>
    <p class="title dark bold">选择新的头像:</p>
    <div class="avatars">
      <span *ngFor="let url of avatarUrls" (click)="changeAvatarTo(url)">
        <img class="avatar" src="{{ url }}" alt=""
        /></span>
    </div>
  </ng-container>

  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="avatarCancel()">取消</button>
    <button nz-button nzType="primary" (click)="avatarChanged()">提交</button>
  </div>
</nz-modal>

<nz-modal
  nzWidth="600px"
  [(nzVisible)]="changedPasswordModal"
  nzTitle="修改密码"
  (nzOnCancel)="passwordCancel()"
>
  <ng-container *nzModalContent>
    <div class="modal">
      <p class="title dark">手机验证码</p>
      <span class="left">
        <nz-input-group nzPrefixIcon="message">
          <input type="text" nz-input placeholder="输入验证码" [(ngModel)]="smsCode"/>
        </nz-input-group>
      </span>
      <span class="next">
        <button nz-button nzType="primary" (click)="sendSms()">{{ buttonText }}</button>
      </span>

      <p class="title dark">输入密码</p>
      <span>
        <nz-input-group nzPrefixIcon="lock" class="wide">
          <input type="password" nz-input placeholder="第一次输入密码" [(ngModel)]="password1"/>
        </nz-input-group>
      </span>

      <p class="title dark">再次输入密码</p>
      <span>
        <nz-input-group nzPrefixIcon="lock" class="wide">
          <input type="password" nz-input placeholder="再次输入密码" [(ngModel)]="password2"/>
        </nz-input-group>
      </span>
    </div>
  </ng-container>

  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="passwordCancel()">取消</button>
    <button nz-button nzType="primary" (click)="passwordChanged()">提交</button>
  </div>
</nz-modal>

<nz-modal
  nzWidth="640px"
  [(nzVisible)]="showDownLoad"
  nzTitle="扫码下载APP"
  (nzOnCancel)="downLoadClose()"
>
  <ng-container *nzModalContent>
    <!-- <h2>请在手机应用商城搜索“德立信生涯”下载安装手机APP！</h2> -->
    <img class="d2code" src="{{erCodeUrl}}" alt="">
  </ng-container>

  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="downLoadClose()">关闭</button>
  </div>
</nz-modal>
