<app-header> </app-header>

<section>
  <div class="container">
    <div class="wait" *ngIf="busy"></div>

    <app-breadcumb *ngIf="!busy" [items]="cumbs"></app-breadcumb>
    <div class="search">
      <input nz-input placeholder="查找院校" [(ngModel)]="value" />
      <button nz-button nzType="primary" (click)="search()">查找</button>
    </div>

    <div class="select border" *ngIf="!busy">
      <app-select-univ
        (onEduLevelChange)="eduLevelChanged($event)"
        (onCategoryChange)="categoryChanged($event)"
        (onFeatureChange)="featureChanged($event)"
        (onNatureTypeChange)="natureTypeChanged($event)"
        (onProvinceChange)="provinceChanged($event)"
      ></app-select-univ>
    </div>

    <div class="content" *ngIf="!busy">
      <div class="left normal-color border">
        <div *ngFor="let univ of currPage">
          <app-univ
            [data]="univ"
            [myChoice]="myChoice"
            [compareUnivs]="compareUnivs"
            (onCompareChanged)="addCompare($event)"
          >
          </app-univ>
          <nz-divider></nz-divider>
        </div>
        <nz-pagination
          class="pright"
          [nzPageIndex]="1"
          [nzTotal]="this.filtered.length"
          (nzPageIndexChange)="gotoPage($event)"
        ></nz-pagination>
      </div>
      <div class="right">
        <div class="compare border">
          <p class="title bold">院校对比</p>
          <div class="title" *ngFor="let univ of compareUnivs">
            <span>{{ univ }}</span>
            <span
              nz-icon
              nzType="close-circle"
              nzTheme="fill"
              (click)="deleteCompare(univ)"
            ></span>
            <nz-divider></nz-divider>
          </div>
          <button nz-button nzType="primary" (click)="doCompare()">对比</button>
          <nz-spin nzSimple *ngIf="fetching"></nz-spin>
        </div>
        <!-- <div class="intention border">
          <p class="title bold">我的目标大学</p>
          <div class="title" *ngFor="let univ of compareUnivs">
            <span>{{ univ }}</span>
            <span
              nz-icon
              nzType="close-circle"
              nzTheme="fill"
              (click)="deleteCompare(univ)"
            ></span>
            <nz-divider></nz-divider>
          </div>
          <button nz-button nzType="primary" (click)="selectTarget()">选择目标大学专业</button>
          <nz-spin nzSimple *ngIf="fetching"></nz-spin>
        </div> -->
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>

<nz-modal
  [(nzVisible)]="comparing"
  nzTitle="高校对比"
  nzWidth="1200px"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleCancel()"
>
  <ng-container *nzModalContent>
    <nz-table nzTemplateMode nzFrontPagination="false">
      <thead>
        <tr>
          <td></td>
          <td align="center" class="bold" *ngFor="let univ of compData">
            {{ univ.name }}
          </td>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td align="center" class="bold">办学地点</td>
          <td align="center" *ngFor="let univ of compData">
            {{ univ.provinceName }}
          </td>
        </tr>
        <tr>
          <td align="center" class="bold">创办时间</td>
          <td align="center" *ngFor="let univ of compData">
            {{ univ.createdYear }}
          </td>
        </tr>
        <tr>
          <td align="center" class="bold">办学性质</td>
          <td align="center" *ngFor="let univ of compData">
            {{ getNType(univ.natureType) }}
          </td>
        </tr>
        <tr>
          <td align="center" class="bold">管理部门</td>
          <td align="center" *ngFor="let univ of compData">
            {{ univ.belong }}
          </td>
        </tr>
        <tr>
          <td align="center" class="bold">办学层次</td>
          <td align="center" *ngFor="let univ of compData">
            {{ univ.bxLevel }}
          </td>
        </tr>
        <tr>
          <td align="center" class="bold">办学类型</td>
          <td align="center" *ngFor="let univ of compData">
            {{ univ.category }}
          </td>
        </tr>
        <tr>
          <td align="center" class="bold">985</td>
          <td align="center" *ngFor="let univ of compData">
            {{ contain(univ.feature, "985") }}
          </td>
        </tr>
        <tr>
          <td align="center" class="bold">211</td>
          <td align="center" *ngFor="let univ of compData">
            {{ contain(univ.feature, "211") }}
          </td>
        </tr>
        <tr>
          <td align="center" class="bold">双一流</td>
          <td align="center" *ngFor="let univ of compData">
            {{ contain(univ.feature, "双一流") }}
          </td>
        </tr>
        <tr>
          <td align="center" class="bold">国重点</td>
          <td align="center" *ngFor="let univ of compData">
            {{ contain(univ.feature, "国重点") }}
          </td>
        </tr>
        <tr>
          <td align="center" class="bold">省重点</td>
          <td align="center" *ngFor="let univ of compData">
            {{ contain(univ.feature, "省重点") }}
          </td>
        </tr>
        <tr>
          <td align="center" class="bold">省属</td>
          <td align="center" *ngFor="let univ of compData">
            {{ contain(univ.feature, "省属") }}
          </td>
        </tr>
        <tr>
          <td align="center" class="bold">星级</td>
          <td align="center" *ngFor="let univ of compData">
            {{ univ.star }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </ng-container>
</nz-modal>
