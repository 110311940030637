import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from 'src/app/service/http.service';
import { UserService } from 'src/app/service/user.service';
import { Router } from '@angular/router';
import { Info } from '~/app/shared/enroll.typs';

@Component({
  selector: 'app-list-course-by-college',
  templateUrl: './list-course-by-college.component.html',
  styleUrls: ['./list-course-by-college.component.scss'],
})
export class ListCourseByCollegeComponent implements OnInit {
  cumbs = ['首页', '科目查询'];
  data: Info[] = [];
  filtered: Info[] = [];
  busy = false;

  constructor(
    private http: HttpService,
    private route: Router,
    private userService: UserService,
    private message: NzMessageService
  ) {}

  async ngOnInit() {
    this.fetchData();
  }

  async fetchData() {
    this.busy = true;
    const action = '/v1/enroll2/list-course-by-college';
    const provinceCode = this.userService.provinceCode();
    const params = {
      provinceCode: provinceCode,
    };

    this.data = await this.http.post(action, params);
    if (!this.data) {
      this.message.error('数据获取失败');
    } else {
      this.filtered = this.data;
      this.gotoPage(1);
    }

    this.busy = false;
  }

  listByMajor() {
    this.route.navigate(['list-course-by-major']);
  }

  pageSize = 10;
  currPage: Info[] = [];

  gotoPage(idx: number) {
    this.currPage = this.filtered.slice(
      (idx - 1) * this.pageSize,
      idx * this.pageSize
    );
  }

  value: string = '';
  search() {
    if (!this.value || this.value === '') {
      this.filtered = this.data;
      return;
    }

    this.filtered = this.data?.filter(
      (c) =>
        c?.brief?.name?.includes(this.value) ||
        c?.brief?.shortName?.includes(this.value)
    );
    this.gotoPage(1);
  }
}
