import { contains } from './collection';

/**
 * 判断手机号码是否符合规则
 * @param str 手机号码
 * @returns 是否符合规则
 */
export function isValidMobilePhone(str: string): boolean {
  const regExp = /^1[356789]\d{9}$/; // 手机号码正则表达式
  return regExp.test(str);
}

/**
 * 将整数转换为字符串，前面补0
 * @param num 整数
 * @param length 转换后的字符串长度
 * @returns  转换后的字符串
 */
export function padLeft(num: number, length: number): string{
  let result = num.toString();
  while (result.length<length){
    result = '0' + result;
  }

  return result;
}

/**
 * 是否一个网上URL地址
 * @param url url
 * @returns 是否网上url地址
 */
export function isWeb(url: string) {
  if (!url) {
    return false;
  }

  const lowerCaseUrl = url.trim().toLowerCase();
  return lowerCaseUrl.startsWith('http') || lowerCaseUrl.startsWith('www');
}

/**
 * 判断密码是否符合规则，规则为必须包含字母和数字，长度至少为 6
 * @param password 密码
 * @returns 是否符合规则
 */
export function isValidPassword(password: string): boolean {
  if (password.length > 12 || password.length < 8){
    return false;
  }

  const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8,}$/;
  return PASSWORD_REGEX.test(password);
}

/**判断name是否复合规则，规则为至少两个字符的字符串
 * @param name 字符串
 * @returns 是否符合规则
 */
export function isValidName(name: string): boolean {
  return name && name.length >= 2;
}

export function getCurrentYear(): number {
  return new Date().getFullYear();
}

/**
 * 得到当前年后n年的名称
 * @param n 年度数量
 * @returns 年度名称字符串
 */
export function getFutureYears(n: number): string[] {
  let result = [];

  let year = getCurrentYear();
  result.push(year);

  for (let i = 1; i < n; i++) {
    result.push(year + i);
  }

  return result;
}

/**
 * 严格判断身份证号码是否合法
 * @param idNumber 身份证号码
 * @returns 是否合法
 */
export function isValidIdNo(idNumber: string): boolean {
  const ID_NUMBER_REGEX =
    /^\d{6}(19\d{2}|20\d{2})(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}[xX\d]$/; // 身份证号码正则表达式
  if (!ID_NUMBER_REGEX.test(idNumber)) {
    return false;
  }

  //判断前两位上省份代码是否正确
  const provCode = idNumber.substring(0, 1);
  if (!contains(provinceCodes, provCode)) {
    return false;
  }

  // 检查出生年月日是否正确
  const year = parseInt(idNumber.substring(6, 9), 10);
  const month = parseInt(idNumber.substring(10, 11), 10);
  const day = parseInt(idNumber.substring(12, 13), 10);
  const date = new Date(year, month - 1, day);
  if (
    date.getFullYear() !== year ||
    date.getMonth() + 1 !== month ||
    date.getDate() !== day
  ) {
    return false;
  }

  const WEIGHTS = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
  const REMAINDERS = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']; // 校验码

  // 计算校验码
  const idArray = idNumber.split('');
  let sum = 0;
  for (let i = 0; i < 17; i++) {
    sum += parseInt(idArray[i], 10) * WEIGHTS[i];
  }

  return REMAINDERS[sum % 11] === idArray[17].toUpperCase();
}

/**
 * 各省行政区划代码的前两位
 */

export const provinceCodes = [
  '11',
  '12',
  '13',
  '14',
  '15',
  '21',
  '22',
  '23',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '50',
  '51',
  '52',
  '53',
  '54',
  '61',
  '62',
  '63',
  '64',
  '65',
  '81',
  '82',
];
