<app-header></app-header>

<div class="section">
  <div class="wait" *ngIf="busy"></div>
  <div class="header" *ngIf="!busy">
    <app-breadcumb [items]="cumbs"> </app-breadcumb>
  </div>

  <div *ngIf="!busy" class="search">
    <input nz-input placeholder="查找院校/专业" [(ngModel)]="value" />
    <button nz-button nzType="primary" (click)="search()">查找</button>
  </div>

  <div class="header" *ngIf="!busy">
    <div class="course">
      <app-com-courses
        [courses]="courses"
        [score]="score"
        (courseSelectChanged)="changeCourse($event)"
        (scoreConfirmed)="changeScore($event)"
      ></app-com-courses>
    </div>

    <div class="buttons">
      <button
        [ngClass]="{
          button_checked: contains(typeSelected, '冲'),
          button_unchecked: !contains(typeSelected, '冲')
        }"
        (click)="filterType('冲')"
      >
        冲({{ typeCount[0] }})
      </button>
      <button
        [ngClass]="{
          button_checked: contains(typeSelected, '稳'),
          button_unchecked: !contains(typeSelected, '稳')
        }"
        (click)="filterType('稳')"
      >
        稳({{ typeCount[1] }})
      </button>
      <button
        [ngClass]="{
          button_checked: contains(typeSelected, '保'),
          button_unchecked: !contains(typeSelected, '保')
        }"
        (click)="filterType('保')"
      >
        保({{ typeCount[2] }})
      </button>
      &nbsp;
      <button
        class="button"
        nz-button
        nzType="primary"
        nzDanger
        (click)="save()"
      >
        保存
      </button>
    </div>
  </div>
  <div class="target border" *ngIf="!busy && defaultSchool?.name">
    <div class="title">
      <span>{{ defaultSchool?.label }}：{{ defaultSchool?.name }}</span>
      <div (click)="showModal()">
        <span>更换目标</span>
        <span nz-icon nzType="swap" nzTheme="outline"></span>
      </div>
    </div>
    <nz-descriptions [nzColumn]="6">
      <nz-descriptions-item
        [nzTitle]="item.label"
        *ngFor="let item of defaultSchool?.options"
      >
        {{ item.myMajor }}
      </nz-descriptions-item>
    </nz-descriptions>
    <nz-descriptions [nzColumn]="6">
      <nz-descriptions-item
        nzTitle="去年录取分数"
        *ngFor="let item of defaultSchool?.options"
      >
        {{ item.minScore }}
      </nz-descriptions-item>
    </nz-descriptions>
    <nz-descriptions [nzColumn]="6">
      <nz-descriptions-item
        nzTitle="模拟分数差距"
        *ngFor="let item of defaultSchool?.options"
      >
        {{ item.poor }}
      </nz-descriptions-item>
    </nz-descriptions>
    <nz-descriptions [nzColumn]="6" [nzColon]="false">
      <nz-descriptions-item
        nzTitle=""
        *ngFor="let item of defaultSchool?.options"
      >
        <span style="color: red;">{{ item.match ? '' : '选科与专业不符' }}</span>
      </nz-descriptions-item>
    </nz-descriptions>
  </div>
  <nz-modal
    [(nzVisible)]="isVisible"
    nzTitle="更换目标大学"
    (nzOnCancel)="handleCancel()"
    (nzOnOk)="handleOk()"
  >
    <ng-container *nzModalContent>
      <div>
        <span>目标大学：</span>
        <nz-select
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="请选择目标大学"
          [(ngModel)]="selectedValue"
        >
          <nz-option
            [nzLabel]="item"
            [nzValue]="item"
            *ngFor="let item of school"
          ></nz-option>
        </nz-select>
      </div>
    </ng-container>
  </nz-modal>
  <div class="select border" *ngIf="!busy">
    <app-select-univ
      (onEduLevelChange)="eduLevelChanged($event)"
      (onCategoryChange)="categoryChanged($event)"
      (onFeatureChange)="featureChanged($event)"
      (onNatureTypeChange)="natureTypeChanged($event)"
      (onProvinceChange)="provinceChanged($event)"
    ></app-select-univ>
  </div>

  <div class="container" *ngIf="!busy">
    <div class="univs border">
      <div *ngFor="let univ of currPage; let idx = index">
        <app-major-choose
          [major]="univ"
          [index]="idx"
          (onDelete)="delete($event)"
          (onMoveUp)="moveUp($event)"
          (onMoveDown)="moveDown($event)"
        ></app-major-choose>
        <nz-divider></nz-divider>
      </div>
      <nz-pagination
        class="right"
        [(nzPageIndex)]="pageIndex"
        [nzTotal]="this.filtered.length"
        (nzPageIndexChange)="gotoPage()"
      ></nz-pagination>
    </div>
  </div>
</div>

<app-footer></app-footer>
