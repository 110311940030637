<div class="container" *ngIf="data?.length > 0">
  <div class="heading">
    <p class="bold">{{ header }}</p>

    <button (click)="more()">查看更多</button>
  </div>

  <div class="content">
    <div class="news">
      <div class="ltop">
        <a href="{{ data[0]?.url }}" target="_blank"><img src="{{ data[0]?.picUrl }}" alt="" /></a>
      </div>
      <p class="bold">
        <span> {{ data[0]?.title }} </span>
      </p>
      <p class="small">
        <span>热力值：{{ data[0]?.hotValue }}</span> &nbsp; &nbsp;
        <span>浏览量: {{ data[0]?.score }}</span>
      </p>
    </div>
    <div class="news">
      <div class="part">
        <div class="rleft">
          <a href="{{ data[1]?.url }}" target="_blank"><img src="{{ data[0]?.picUrl }}" alt="" /></a>
        </div>
        <div class="rtext">
          <p class="rtitle"> {{data[1]?.title}}</p>
          <p class="small space">
            <span>热力值：{{ data[1]?.hotValue }}</span> &nbsp; &nbsp;
            <span>浏览量: {{ data[1]?.score }}</span>
          </p>
        </div>
      </div>
      <div class="part">
        <div class="rleft">
          <a href="{{ data[2]?.url }}" target="_blank"><img src="{{ data[0]?.picUrl }}" alt="" /></a>
        </div>
        <div class="rtext">
          <p class="rtitle"> {{data[2]?.title}}</p>
          <p class="small space">
            <span>热力值：{{ data[2]?.hotValue }}</span> &nbsp; &nbsp;
            <span>浏览量: {{ data[2]?.score }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
