<div class="section">
  <div class="major">
    <div class="title">
      <p>{{ major.major }}</p>
      <p>({{ major.majorCode }})</p>
    </div>
    <div class="detail">
      <p>招生数量：{{ major.total }}人，选科覆盖：{{ major.count }} 人</p>
      <p class="emphsis">覆盖率：{{ major.percent }}%</p>
    </div>
    <div class="right" *ngIf="hasChildren()" (click)="expand()">
      <span
        nz-icon
        nzType="caret-down"
        nz-theme="fill"
        *ngIf="!expanded"
      ></span>
      <span nz-icon nzType="caret-up" nz-theme="fill" *ngIf="expanded"></span>
    </div>
    <div class="second" *ngIf="expanded">
      <div *ngFor="let second of major.children">
        <app-second [major]="second"></app-second>
      </div>
    </div>
  </div>
</div>