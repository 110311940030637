import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from '~/app/service/http.service';
import { UserService } from '~/app/service/user.service';
import { MyChoice } from '~/app/shared/mychoice.type';
import { UnivInfo } from '~/app/shared/university.type';
import { contains } from '~/app/utility/collection';
import { getNatureType } from '~/app/utility/domain';

@Component({
  selector: 'app-enc-university',
  templateUrl: './enc-university.component.html',
  styleUrls: ['./enc-university.component.scss'],
})
export class EncMyUniversityComponent implements OnInit {
  multiple = false;
  cumbs = ['首页', '院校百科'];
  eduLevel = '本科';
  course = ''
  courses = ''
  features = '985,211,双一流A,双一流B,国重点,省重点,省属,';
  provinces =
    '北京,天津,河北,辽宁,山西,内蒙,吉林,黑龙江,上海,安徽,山东,江苏,福建,河南,浙江,江西,湖北,湖南,海南,贵州,陕西,广东,重庆,云南,甘肃,广西,四川,青海,宁夏,新疆,香港,澳门,';
  categories = '综合,理工,医药,艺术,军事,师范,农林,政法,财经,语言,民族,体育,';
  natureTypes = '公办,民办,合作办学,';

  busy = true;

  data: UnivInfo[] = [];
  filtered: UnivInfo[] = [];
  university = [];

  currPage: UnivInfo[] = [];
  pageIndex = 0;
  pageSize = 10;

  myChoice: MyChoice;

  constructor(
    private http: HttpService,
    private message: NzMessageService,
    private userService: UserService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.reset();
    await this.fetchSubject();
    await this.fetchData();
    // await this.fetchMyChoice();
  }

  async fetchMyChoice() {
    const action = '/v1/personal/list-my-choice';
    const params = {
      userCode: this.userService.code(),
    };

    const { colleges } = await this.http.post(action, params);
    this.university = colleges.map((item) => {
      return { ...item, name: item.myCollege };
    });
    // if (!this.myChoice) {
    //   this.myChoice = {
    //     myCollege: '',
    //     myMajor: '',
    //     myProfession: '',
    //     id: null,
    //     userCode: this.userService.code(),
    //   };
    // }
  }
  async fetchSubject() {
    let code = this.userService.code();
    const action = '/v1/personal/get-my-courses';
    const params = {
      userCode: code,
    };
    const data = await this.http.post(action, params);
    this.course = data?.course;
    this.courses = data?.courses;
  }
  async fetchData() {
    this.busy = true;
    // const action = '/v1/college/list';
    const action = '/v1/college/query-by-province';
    const params = {
      category: '',
      eduLevel: this.eduLevel.indexOf('本科') >= 0 ? 'ben' : 'zhuan',
      feature: '',
      name: '',
      courses: this.courses,
      course: this.course,
      natureType: '',
      province: '',
      provinceCode: this.userService.code().substring(0, 2),
    };

    let data = await this.http.post(action, params);
    this.data = this.processUnivInfos(data);
    this.reset();
    this.filter();
    this.busy = false;
  }

  processUnivInfo(data: UnivInfo) {
    if (data.eduLevel === 'zhuan' || data.eduLevel === '专科') {
      data.eduLevel = '专科';
    } else {
      data.eduLevel = '本科';
    }

    if (data.natureType === 'public' || data.natureType === '公办') {
      data.natureType = '公办';
    } else if (data.natureType === 'private' || data.natureType === '民办') {
      data.natureType = '民办';
    } else {
      data.natureType = '中外合作';
    }

    return data;
  }

  processUnivInfos(data: UnivInfo[]): UnivInfo[] {
    data?.forEach((c) => this.processUnivInfo(c));
    return data;
  }

  reset() {
    // this.eduLevel = '本科';
    this.features = '985,211,双一流A,双一流B,国重点,省重点,省属,';
    this.provinces =
      '北京,天津,河北,辽宁,山西,内蒙,吉林,黑龙江,上海,安徽,山东,江苏,福建,河南,浙江,江西,湖北,湖南,海南,贵州,陕西,广东,重庆,云南,甘肃,广西,四川,青海,宁夏,新疆,香港,澳门,';
    this.categories =
      '综合,理工,医药,艺术,军事,师范,农林,政法,财经,语言,民族,体育,';
    this.natureTypes = '公办,民办,中外合作,';

    this.filter();
  }

  filterEduLevel(eduLevel: string) {
    if (this.contains(this.eduLevel, eduLevel)) {
      this.eduLevel = this.eduLevel.replace(eduLevel + ',', '');
    } else {
      this.eduLevel = this.eduLevel.concat(eduLevel + ',');
    }

    this.filter();
  }

  filterFeatures(feature: string) {
    if (this.contains(this.features, feature)) {
      this.features = this.features.replace(feature + ',', '');
    } else {
      this.features = this.features.concat(feature + ',');
    }

    this.filter();
  }

  filterProvince(province: string) {
    if (this.contains(this.provinces, province)) {
      this.provinces = this.provinces.replace(province + ',', '');
    } else {
      this.provinces = this.provinces.concat(province + ',');
    }

    this.filter();
  }

  filterCategory(category: string) {
    if (this.contains(this.categories, category)) {
      this.categories = this.categories.replace(category + ',', '');
    } else {
      this.categories = this.categories.concat(category + ',');
    }

    this.filter();
  }

  filterNatureType(natureType: string) {
    if (this.contains(this.natureTypes, natureType)) {
      this.natureTypes = this.natureTypes.replace(natureType + ',', '');
    } else {
      this.natureTypes = this.natureTypes.concat(natureType + ',');
    }

    this.filter();
  }

  contains(str, sub: string): boolean {
    const strArr = str.split(',')?.filter((c) => c !== '');
    const subArr = sub.split(',')?.filter((c) => c !== '');

    return strArr.some((c) => subArr.includes(c));
  }

  filter() {
    let result = this.data || [];

    if (this.eduLevel) {
      result = result?.filter((c) => this.contains(this.eduLevel, c.eduLevel));
    }

    if (this.features) {
      result = result?.filter((c) => this.contains(this.features, c.feature));
    }

    if (this.provinces) {
      result = result?.filter((c) =>
        this.contains(this.provinces, c.provinceName)
      );
    }

    if (this.categories) {
      result = result.filter((c) => this.contains(this.categories, c.category));
    }

    if (this.natureTypes) {
      result = result.filter((c) =>
        this.contains(this.natureTypes, c.natureType)
      );
    }

    this.filtered = result;

    this.pageIndex = 1;
    this.gotoPage(this.pageIndex);
  }

  gotoPage(idx: number) {
    this.currPage = this.filtered.slice(
      (idx - 1) * this.pageSize,
      idx * this.pageSize
    );
  }

  async eduLevelChanged($event) {
    this.eduLevel = $event;
    // this.filter();
    await this.fetchData();
  }

  categoryChanged($event) {
    this.categories = $event;
    this.filter();
  }

  featureChanged($event) {
    this.features = $event;
    this.filter();
  }

  natureTypeChanged($event) {
    this.natureTypes = $event;
    this.filter();
  }

  provinceChanged($event) {
    this.provinces = $event;
    this.filter();
  }

  compareUnivs = [];
  compData: UnivInfo[] = [];

  addCompare(event) {
    if (this.university.find((item) => item.id == event.id)) {
      this.university = this.university.filter((item) => item.id !== event.id);
    } else {
      if (this.university.length >= 6) {
        this.message.warning('最多选择6所大学');
        return;
      }
      this.university.push(event);
    }
    // if (contains(this.compareUnivs, $event)) {
    //   this.compareUnivs = this.compareUnivs.filter((c) => c !== $event);
    // } else {
    //   this.compareUnivs.push($event);
    // }
  }

  deleteCompare($event, index) {
    this.university.splice(index, 1);
    // this.compareUnivs = this.compareUnivs.filter((c) => c !== $event);
  }

  comparing = false;
  fetching = false;
  async doCompare() {
    if (this.compareUnivs.length <= 1) {
      this.message.warning('请选择两个或以上院校进行对比');
      return;
    } else {
      this.fetching = true;
      let ids = this.getUnivIds(this.compareUnivs);
      const action = '/v1/college/list-by-ids';
      const params = {
        str: ids,
      };

      this.compData = await this.http.post(action, params);
      this.fetching = false;
      this.comparing = true;
    }
  }

  selectTarget() {
    if (this.university.length === 0) {
      this.message.warning('请选择大学');
      return;
    }
    localStorage.setItem('university', JSON.stringify(this.university));
    this.router.navigate(['/select-major']);
  }

  getUnivIds(univ: string[]): string {
    let result = '';
    let univIds = this.data
      .filter((c) => contains(univ, c.name))
      .map((c) => c.id.toString());

    result = univIds.join(',');
    return result;
  }

  getNType(ntype: string) {
    return getNatureType(ntype);
  }

  contain(univ, prop: string): string {
    return univ.indexOf(prop) >= 0 ? '√' : '';
  }

  handleCancel() {
    this.comparing = false;
  }
  handleskip() {
    this.router.navigate(['/enc-my-major']);
  }
  value: string = '';
  search() {
    if (!this.value || this.value === '') {
      this.filter();
      return;
    }

    this.filtered = this.data.filter((c) => c.name.includes(this.value));
    this.pageIndex = 1;
    this.gotoPage(this.pageIndex);
  }
}
